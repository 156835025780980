/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LIST_FADE_ANIMATION } from '../../../../@fury/shared/list.animation';
import { apiConstants } from '../../../../../src/app/constants/apiConstants';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { CommonService } from '../../../../../src/app/shared/services/commonServices/common.service';
import { LocalStoreService } from '../../../../../src/app/shared/services/commonServices/local-store.service';
import { AuthService } from '../../../../../src/app/shared/services/commonServices/auth.service';
import { BehaviorSubject } from 'rxjs';
import { SidenavService } from '../../sidenav/sidenav.service';
import { LayoutService } from '../../layout.service';
import { DataService } from '../../../../../src/app/shared/services/commonServices/data.service';


@Component({
  selector: 'fury-toolbar-view',
  templateUrl: './toolbar-view.component.html',
  styleUrls: ['./toolbar-view.component.scss'],
  animations: [...LIST_FADE_ANIMATION],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarViewComponent implements OnInit {

  notifications: any[];
  isOpen: boolean;
  subscription1;
  subscription2;
  public columnDefs: any;
  public gridHeaders: any;
  public viewData: {};
  public tradeData: [];
  public portfolioData: [];
  public AccountingData: [];
  public investorData: [];
  viewDataJsons: any[];
  viewDataJsonsArray = [];
  viewModuleJsons;
  favModuleJson;
  public favComponentJson = {};
  favSaveJsons;
  public favSaveJsonArray = {};
  // favSaveModulesJsonArray =[];
  favSaveJsonArrayValue;
  viewModule;
  countTrade = 0;
  countPortfolio = 0;
  countAccounting = 0;
  countInvestor = 0;
  public currentUser: any;
  public modules: any = [];
  roleAs: string;
  username: any;
  viewQueryParam = {openModel:true}
  userPerferenceList: any;
  constructor(private httpClient: HttpClient, handler: HttpBackend,
    private dataService: DataService,
    private commonService: CommonService, private localStoreService: LocalStoreService, private authService: AuthService, private sidenavService: SidenavService, private layoutService: LayoutService) {
    this.httpClient = new HttpClient(handler);
  }

  ngOnDestroy(): void {
    this.subscription1.unsubscribe();
  }

  ngOnInit() {
    this.roleAs = JSON.parse(this.localStoreService.getItem('fss'))['ROLE']
    this.username = JSON.parse(this.localStoreService.getItem('fss'))['userName'];
    this.currentUser = JSON.parse(this.localStoreService.getItem('fss'));
    if (this.roleAs == 'SystemManager') {
      this.gridHeaders = apiConstants['admintoolbarView']['headers'];
      if (this.currentUser.authList.includes('SEAMLESS_SIDEMENU_SYSADMIN')) {

        this.modules.push({ name: 'manager', column: 2 });
      }
      if (this.currentUser.authList.includes('SEAMLESS_SIDEMENU_MIS')) {
        this.modules.push({ name: 'MIS', column: 1 });
      }

    } else if (this.roleAs == 'Manager') {
      this.gridHeaders = apiConstants['ent&usertoolbarView']['headers'];
      this.modules.push({ name: 'setup', column: 1 })

      if (this.currentUser.authList.indexOf('SEAMLESS_SIDEMENU_USERMANAGEMENT') > -1) {
        this.modules.push({ name: 'usermanagement', column: 1 });
      }
      // if (this.currentUser.authList.indexOf('SEAMLESS_SIDEMENU_CUSTOMIZATION') > -1) {
      //   this.modules.push({ name: 'customization', column: 1 });
      // }
      if (this.currentUser['authList'].includes("USER_CREATE") && this.currentUser.authList.indexOf('SEAMLESS_SIDEMENU_ADMIN') > -1) {
        this.modules.push({ name: 'manager', column: 2 });
      }
      // if(  this.currentUser['authList'].includes("USER_CREATE") && this.currentUser.authList.indexOf('USER_SETUP') > -1 ){
      //   this.gridHeaders = apiConstants['enttoolbarView']['headers'];
      // this.modules = [{ name: 'manager', column: 2 }];
      // }
      // if(this.currentUser['authList'].includes("SEAMLESS_INPUT_VIEW_TRADES")){
      //   this.modules.push({ name: 'trades_module', column: 2,auth:'SEAMLESS_INPUT_VIEW_TRADES' })
      // }
      if(this.currentUser['authList'].includes("SEAMLESS_INPUT_VIEW_PORTFOLIO")){
        this.modules.push({ name: 'portfolio', column: 2,auth:'SEAMLESS_INPUT_VIEW_PORTFOLIO' })
      }
      if(this.currentUser['authList'].includes("SEAMLESS_INPUT_VIEW_INVESTORS")){
        this.modules.push({ name: 'investor', column: 2 ,auth:'SEAMLESS_INPUT_VIEW_INVESTORS'})
      }
      if(this.currentUser['authList'].includes("SEAMLESS_INPUT_VIEW_ACCOUNTING")){
        this.modules.push({ name: 'accounting', column: 2,auth:'SEAMLESS_INPUT_VIEW_ACCOUNTING' })
      }
      

    } else {
      this.gridHeaders = apiConstants['toolbarView']['headers'];
      this.modules = [
        { name: 'trades_module', column: 1 ,auth:'SEAMLESS_INPUT_VIEW_TRADES' },
        { name: 'portfolio', column: 2 ,auth:'SEAMLESS_INPUT_VIEW_PORTFOLIO' },
        { name: 'accounting', column: 3 ,auth:'SEAMLESS_INPUT_VIEW_ACCOUNTING' },
        { name: 'investor', column: 1 ,auth:'SEAMLESS_INPUT_VIEW_INVESTORS' }
      ];
    }

    this.subscription1 = this.httpClient.get(this.gridHeaders).subscribe(headers => {
      this.viewData = headers;
      this.dataService.data.subscribe(info => {
        if (info) {
          this.userPerferenceList = info.view;
          if (this.userPerferenceList && this.userPerferenceList.length) {
            this.userPerferenceList.map(item => {
              if (item.status) {
                this.modules.forEach(module => {
                  if (module.name == item['setting']['pivotMode']) {
                    this.viewData[module.name].map(element => {
                      if (element.component_name == item.settingName) {
                        element.isFavourite = item.status;
                        element.isFav = item.status;
                        element.id = item.id;
                      }
                    });
                  }
                })
              }

            });
          }
        }
      })
      this.columnDefs = this.commonService.formatDataUsingGridHeaders(this.viewData);
      this.modules?.forEach(module => {
        // this.favComponentJson[module] = this.columnDefs[module]?.isFavourite
        this.favComponentJson[module.name] = this.columnDefs[module.name].filter(item => {
          return item.isFav
        });
      })
      Object.assign(this.favSaveJsonArray, this.columnDefs);
      // })
    })
  }

  addToFavourite(viewFavJson, viewModuleJson, event) {
    event.stopPropagation();
    this.viewModule = viewModuleJson;
    if (viewFavJson.isFav != undefined) {
      var reqBody = {
        "id": viewFavJson.id,
        "userName": this.username,
        "view": 'view',
        "settingName": viewFavJson.component_name,
        "setting": {
          "pivotMode": viewModuleJson,
        },
        status: !viewFavJson.isFav,
      }

      this.layoutService.updateToFav(reqBody).subscribe(res => {

      })
    } else {
      let reqBody = {
        "userName": this.username,
        "view": 'view',
        "settingName": viewFavJson.component_name,
        "setting": {
          "pivotMode": viewModuleJson,
        },
        status: true,
      }
      this.layoutService.addToFav(reqBody).subscribe(res => {
        viewFavJson.id = res['id'];
      })
    }
    this.favSaveJsonArray[viewModuleJson].map(item => {
      if (item.component_name == viewFavJson.component_name && item.isFavourite) {
        item.isFavourite = false;
        item.isFav = false;
      } else if (item.component_name == viewFavJson.component_name) {
        item.isFavourite = true;
        item.isFav = true;
      }
    })

    this.favComponentJson[viewModuleJson] = this.favSaveJsonArray[viewModuleJson].filter(item => { return item.isFavourite });
  }

  markAsRead(notification) {
    notification.read = true;
  }

  dismiss(notification, event) {
    event.stopPropagation();
    this.notifications.splice(this.notifications.indexOf(notification), 1);
  }

  toggleDropdown(item?) {
    if (item && item.breadcrumbs) {
      this.commonService.moduleNameObs$.next(item.breadcrumbs);
    }
    this.isOpen = !this.isOpen;
    setTimeout(() => {
      this.sidenavService.setCollapsed(this.isOpen);
    }, 0)


  }

  onClickOutside() {
    if (this.isOpen) {
      this.sidenavService.setCollapsed(false);
    }
    this.isOpen = false;

  }

  markAllAsRead() {
    this.notifications.forEach(notification => notification.read = true);
  }
  isRapidUser() {
    return this.currentUser['authList'].includes("USER_CREATE") && this.roleAs == 'Manager'
  }
}
