/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/

import {
  Component,
  OnInit,
  Input,
  ViewChild,
  EventEmitter,
  Output
} from "@angular/core";
import { GlobalService } from "../../../services/commonServices/global.service";
import { GridService } from "../../../services/gridServices/grid.service";
import { UploadService } from "../../../services/uploadService/upload.service";
import alasql from "../../../../../../node_modules/alasql";
import * as XLSX from "../../../../../../node_modules/xlsx";
import { apiConstants } from "src/app/constants/apiConstants";
import { GlobalMessageService } from "src/app/shared/services/commonServices/global-message.service";
import { MatDialog } from "@angular/material/dialog";
import { UploadDialogComponent } from "../../../dialogs/upload-dialog-component";
import { fadeInUpAnimation } from "src/@fury/animations/fade-in-up.animation";
import { fadeInRightAnimation } from "src/@fury/animations/fade-in-right.animation";
import { CommonService } from "../../../services/commonServices/common.service";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

import { FormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import moment from "moment";
import { ProcessScreenService } from "../../../services/processService/process-screen.service";
import FileSaver from "file-saver";
import JSZip from "jszip";
import { environment } from "../../../../../../src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { SetupPercentageService } from "src/app/shared/services/commonServices/setup-percentage.service";
import { LocalStoreService } from '../../../services/commonServices/local-store.service';

alasql["utils"].isBrowserify = false;
alasql["utils"].global.XLSX = XLSX;


@Component({
  selector: "dynamic-files-upload",
  templateUrl: "./dynamic-files-upload.component.html",
  styleUrls: ["./dynamic-files-upload.component.scss"],
  animations: [fadeInRightAnimation, fadeInUpAnimation]
})
export class DynamicFilesUploadComponent implements OnInit {
  @Input() isUploadBtn: Boolean;
  @Input() isSubmitBtn: Boolean;
  @Input() isShowCustomLBtn: Boolean;
  @Input() showSelectClient: Boolean;
  @Input() isTemplateBtn: Boolean;
  @Input() showSmType: Boolean;
  @Input() operationType: Boolean;
  @Input() heading: String;
  @Input() isCloseBtn: Boolean;
  @Input() componentName: any;
  @Input() isCarryWeekend: Boolean = false;
  @Input() isShowThirdParySource: Boolean = false;
  @Input() isNotShowBreadCrum: Boolean;
  @Input() isNotHeading: Boolean = false;
  @Input() templateFillData: any = [];
  @Input() isShow: any;
  @Input() uniqueId: any;
  @ViewChild("fileUpload") fileUpload: any;
  @Output() uploadResult: EventEmitter<any> = new EventEmitter();
  public isDoubleClick: boolean = false;

  public displayBasic: boolean;
  public displayBasic2: boolean;
  public UploadApi: string;
  public domainCustomTypes = [];
  public prop;
  public thirdParySource;
  public smTypes;
  public operationTypes;
  public value: any;
  selectedOption: any;
  public tradeData = [];
  public configFields = {};
  public currentUser: any;
  public uploadedData: any;
  public uploadDetails: any;
  public uploadDataName: string;
  public resName: string;
  public sampleName: string;
  public dataStringify: Boolean = false;
  public moduleName: any;
  public selectedList = [false];
  public nextBtn: Boolean = false;
  submitDisable: boolean;
  reqObj: string | Object;
  selectedOptionVar: any;
  carryWeekend: boolean = true;
  public headertitle: string;
  uploadUrl: string;
  smtype: string;
  opType: string;
  displayNoCustom: boolean = false;
  public uploadTitleName: string;
  filename: any;
  dragDrop: boolean = true;
  public formValid: boolean = true;
  public daysInMilliSecs: any;
  getConfigCheck: boolean;
  smName: any;
  fileData: File;
  successListCount: any;
  failureListCount: any;
  tradeUploadId: any;
  selectPreviewAndAggregation: boolean;
  pathName: any;
  card: any;
  public roleAs: any = localStorage.getItem("ROLE");
  redirectionUrl: any;
  redirectionUrl2: any;
  createDefaultConfigModule: any = ['vendorsUpload', 'allsmupdate', 'fschartOfAccounts', 'fsAllSMupdate', 'chartOfAccountTaxUpdate', 'setupJeTransfer', 'reBudgets', 'MISFundList', 'investorUpdate', 'periodEndIndicatorsByFund', 'taxFundList', 'fSInvestor', 'fSPortfolio', 'fschartOfAccountsRapid', 'setupamcIMProgram', 'fSManagementCompanyInvestor', "taxAdditionK1Details"];
  userFieldArray = [];

  constructor(
    private uploadService: UploadService,
    private globalService: GlobalService,
    private gridService: GridService,
    private messageService: GlobalMessageService,
    private commonService: CommonService,
    private dialog: MatDialog,
    private processScreenService: ProcessScreenService,
    private translateService: TranslateService,
    private setuppercentage: SetupPercentageService,
    private router: Router,
    private http: HttpClient,
    private localService: LocalStoreService
  ) {
    this.prop = [{ name: "Client" }, { name: "Broker" }];
    this.operationTypes = [{ name: "Update", value: "Update" }];

    this.thirdParySource = [{ name: "Client" }, { name: "Third Party Source" }];
    this.translateService.onLangChange.subscribe(() => {
      this.moduleName = this.router.url.split("/")[1];
      switch (this.moduleName) {
        case "trades":
          this.moduleName = "trades_module";
          break;
        case "portfolio":
          this.moduleName = "portfolio";
          break;
        case "accounting":
          this.moduleName = "accounting";
          break;
        case "investor":
          this.moduleName = "investors";
          break;
        case "processing":
          this.moduleName = "processing";
          break;
        case "workflow":
          this.moduleName = "workflow";
          break;
        case "reconciliation":
          this.moduleName = "reconciliation";
          break;
        case "query":
          this.moduleName = "query";
          break;
        case "batch-tracking":
          this.moduleName = "batch_tracking";
          break;
        case "reports":
          this.moduleName = "seamless_reports";
          break;
        case "setting":
          this.moduleName = "settings";
          break;
        case "manager":
          this.moduleName = "manager";
          break;
        case 'legacy':
          this.moduleName = 'legacy';
          break;
        case 'MIS':
          this.moduleName = 'mis';
          break;
        case 'Entities':
          this.moduleName = 'Entities';
          break;
        default:
          this.commonService.moduleNameObs$.subscribe((moduleName) => {
            this.moduleName = moduleName;
            this.translateService.get(this.moduleName).subscribe((res) => {
              this.moduleName = res;
            });
          });
          break;
      }
      this.translateService.get(this.moduleName).subscribe((res) => {
        this.moduleName = res;
      });
    });
  }

  protected _onDestroy = new Subject<void>();
  public uploadCtrl: FormControl = new FormControl();
  public uploadFilterCtrl: FormControl = new FormControl();
  public filteredSMTypes: ReplaySubject<[]> = new ReplaySubject<[]>(1);

  @ViewChild("singleSelect", { static: true }) singleSelect: MatSelect;

  ngOnInit(): void {
    this.displayBasic2 = false;
    if (this.componentName == "uploadWorkflow") {
      this.displayBasic = false;
      this.displayBasic2 = true;
    }
    this.moduleName = this.router.url.split("/")[1];
    switch (this.moduleName) {
      case "trades":
        this.moduleName = "trades_module";
        break;
      case "portfolio":
        this.moduleName = "portfolio";
        break;
      case "accounting":
        this.moduleName = "accounting";
        break;
      case "investor":
        this.moduleName = "investors";
        break;
      case "processing":
        this.moduleName = "processing";
        break;
      case "workflow":
        this.moduleName = "workflow";
        break;
      case "reconciliation":
        this.moduleName = "reconciliation";
        break;
      case "query":
        this.moduleName = "query";
        break;
      case "batch-tracking":
        this.moduleName = "batch_tracking";
        break;
      case "reports":
        this.moduleName = "seamless_reports";
        break;
      case "setting":
        this.moduleName = "settings";
        break;
      case "manager":
        this.moduleName = "manager";
        break;
      case 'legacy':
        this.moduleName = 'legacy';
        break;
      case 'MIS':
        this.moduleName = 'mis';
        break;
      case 'Entities':
        this.moduleName = 'Entities';
        break;
      default:
        this.commonService.moduleNameObs$.subscribe((moduleName) => {
          this.moduleName = moduleName;
          this.translateService.get(this.moduleName).subscribe((res) => {
            this.moduleName = res;
          });
        });
        break;
    }
    this.translateService.get(this.moduleName).subscribe((res) => {
      this.moduleName = res;
    });

    this.redirectionUrl = apiConstants[this.componentName]["uploadRedirectionUrl"]
    this.redirectionUrl2 = apiConstants[this.componentName]["uploadRedirectionUrl2"]

    if (this.roleAs == "SystemManager") {
      this.card = apiConstants[this.componentName]["systemAdminCard"];
    } else if (this.roleAs == "Manager" || this.roleAs == "enterprise") {
      this.card = apiConstants[this.componentName]["entCard"];
    } else if (this.roleAs == "User") {
      this.card = apiConstants[this.componentName]["userCard"];
    }

    this.currentUser = JSON.parse(this.localService.getItem('fss'));
    this.uploadDetails = apiConstants[this.componentName]["uploadDetails"];
    this.pathName = apiConstants[this.componentName]?.pathName;
    this.uploadDataName = apiConstants[this.componentName]["uploadDetails"].name;
    this.headertitle = "upload_";
    this.uploadTitleName = "upload_" + this.uploadDataName + "_file";
    this.resName = apiConstants[this.componentName]["uploadDetails"].resName;
    this.sampleName = apiConstants[this.componentName]["uploadDetails"].sampleName
      ? apiConstants[this.componentName]["uploadDetails"].sampleName
      : "";
    this.dataStringify = apiConstants[this.componentName]["uploadDetails"].dataStringify || false;

    if (this.componentName != "tradeUploadHV" || this.componentName != 'rapidTradeUploadHV') {
      this.fetchAllCustomTypes();
    }

    this.value = "Client";
    if (this.componentName == "allsmupload") {
      this.gridService.getSMNames(
        "AllSMDownload/getAllSMNames",
        (cbs) => {
          this.smTypes = cbs.allSMNames;
          this.uploadCtrl.setValue(this.smTypes);

          // load the initial sm  list
          this.filteredSMTypes.next(this.smTypes.slice());

          // listen for search field value changes
          this.uploadFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterSMTypes();
            });
        },
        (cbe) => { }
      );
    }
  }

  protected filterSMTypes() {
    if (!this.smTypes) {
      return;
    }
    // get the search keyword
    let search = this.uploadFilterCtrl.value;
    if (!search) {
      this.filteredSMTypes.next(this.smTypes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredSMTypes.next(
      this.smTypes.filter(
        (element) => element.name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  fetchAllCustomTypes() {
    if (this.createDefaultConfigModule.includes(this.componentName)) {
      let reqObj = (this.componentName == 'fschartOfAccounts' || this.componentName == 'fschartOfAccountsRapid') ? 'chartOfAccountUpdate' : (this.componentName == 'fsAllSMupdate') ? 'smupdate' : (this.componentName == 'MISFundList') ? 'internalAttributes' : (this.componentName == 'fSInvestor' || this.componentName == 'fSManagementCompanyInvestor') ? 'fsInvestor' : (this.componentName == 'setupamcIMProgram') ? 'aMC' : (this.componentName == 'investorUpdate') ? 'investorUpdate' : this.componentName

      this.uploadService.createDefaultConfig(this.uploadDetails.createDefaultConfigUrl, reqObj).subscribe((res) => {
        this.uploadService.fetchAllCustomTypes(
          this.uploadDetails.getCustomTypeUrl,
          (response) => {
            // this.domainCustomTypes = response.resultList;
            response.resultList.forEach((element) => {

              if (element) {
                this.domainCustomTypes.push({ name: element });
              }
            })
            if (this.domainCustomTypes.length) {
              this.selectCustomType(this.domainCustomTypes[0].name);
              this.displayBasic = true;
            } else if (this.componentName == "reportDeliveryConfig") {
              this.displayBasic = false;
              this.displayNoCustom = false;
            } else {
              this.displayNoCustom = true;
            }
          })
      }, (cbe) => { })

    }
    else if (this.componentName == 'rapidVendorsUpload') {
      this.uploadService.createCustom(this.componentName).subscribe(res => {
        this.uploadService.fetchAllCustomTypes(
          this.uploadDetails.getCustomTypeUrl,
          (response) => {
            response.resultList.forEach((element) => {
              if (element) {
                this.domainCustomTypes.push({ name: element });
              }
            })
            if (this.domainCustomTypes.length) {
              this.selectCustomType(this.domainCustomTypes[0].name);
              this.displayBasic = true;
            } else if (this.componentName == "reportDeliveryConfig") {
              this.displayBasic = false;
              this.displayNoCustom = false;
            } else {
              this.displayNoCustom = true;
            }
          }
        );
      })
    }
    else if (this.componentName == 'customizationSetupJeTransfer') {
      this.uploadService.createCustomDefault(this.componentName).subscribe(res => {
        this.uploadService.fetchAllCustomTypes(
          this.uploadDetails.getCustomTypeUrl,
          (response) => {
            response.resultList.forEach((element) => {
              if (element) {
                this.domainCustomTypes.push({ name: element });
              }
            })

            if (this.domainCustomTypes.length) {
              this.selectCustomType(this.domainCustomTypes[0].name);
              this.displayBasic = true;
            }
          }
        );
      })
    }
    else if (this.componentName == 'customizationReportApproval') {
      this.uploadService.createCustomDefaults(this.componentName).subscribe(res => {
        this.uploadService.fetchAllCustomTypes(
          this.uploadDetails.getCustomTypeUrl,
          (response) => {
            response.resultList.forEach((element) => {
              if (element) {
                this.domainCustomTypes.push({ name: element });
              }
            })

            if (this.domainCustomTypes.length) {
              this.selectCustomType(this.domainCustomTypes[0].name);
              this.displayBasic = true;
            }
          }
        );
      })
    }
    else if (this.componentName == "disableAutoUpdateViaAllocationUpdate" || this.componentName == "redemptionActivityUpdate" || this.componentName == "subscriptionActivityUpdate") {
      this.uploadService.createDefaultConfig(this.uploadDetails.createDefaultConfigUrl, '').subscribe(res => {
        this.uploadService.fetchAllCustomTypes(this.uploadDetails.getCustomTypeUrl, (response) => {
          response.resultList.forEach((element) => {
            if (element) {
              this.domainCustomTypes.push({ name: element });
            }
          });
          if (this.domainCustomTypes.length) {
            this.selectCustomType(this.domainCustomTypes[0].name);
            this.displayBasic = true;
          }
        });
      });
    }
    else {
      if (this.componentName != 'setupamcIMProgram' && this.componentName != 'investorUpdate') {
        this.uploadService.fetchAllCustomTypes(
          this.uploadDetails.getCustomTypeUrl,
          (response) => {

            response.resultList.forEach((element) => {
              if (element) {
                this.domainCustomTypes.push({ name: element });

              }
            });
            if (this.domainCustomTypes.length) {
              this.selectCustomType(this.domainCustomTypes[0].name);
            }
          }
        );
      }
    }
  }

  openDialog() {
    this.dialog
      .open(UploadDialogComponent, {
        disableClose: false
      })
      .afterClosed()
      .subscribe((result) => { });
  }

  selectCustomType = function (customType) {
    this.selectedOption = customType;
    this.displayBasic = false;
    if (this.domainCustomTypes.length) {
      this.getConfigFields(customType);
    }
  };
  getConfigFields(customType) {
    this.getConfigCheck = true;
    let configUrl = "";
    if ((this.componentName == "disableAutoUpdateViaAllocationUpdate" || this.componentName == "redemptionActivityUpdate" || this.componentName == "subscriptionActivityUpdate") && customType == 'Update') {
      configUrl = this.uploadDetails.getUpdateConfigUrl;
    } else {
      configUrl = this.uploadDetails.getConfigUrl;
    }
    this.gridService.getConfigFields(configUrl, customType, (cbs) => {
      if (cbs.resultList) {
        var list = cbs.resultList;
        this.configFields = {};
        for (var i = 0; i < list.length; i++) {
          this.configFields[list[i].userField] = list[i].serverField;
        }
      }
    },
      (cbe) => { }
    );
  }

  downloadSampleFile() {
    this.translateService.get(this.uploadDataName).subscribe((res) => {
      this.uploadDataName = res;
    });
    var sampletradeData = this.globalService.getFieldsForExcel(this.sampleName, this.selectedOption);

    const selectedCustomType = this.selectedOption

    if (this.componentName == "tradeUploadHV" || this.componentName == 'rapidTradeUploadHV') {
      const zip = new JSZip();
      var data, filename, link;
      var csv = this.convertArrayOfObjectsToCSV({
        data: sampletradeData
      });
      if (csv == null) return;
      filename = this.sampleName + ".csv" || "export.csv";
      data = csv;
      link = document.createElement("a");
      zip.file(filename, data);
      zip.generateAsync({ type: "blob" }).then(function (content) {
        FileSaver(content, "tradeuploadhv.zip");
      });
    } else if (this.componentName == "fSInvestor" || this.componentName == 'fSManagementCompanyInvestor') {
      this.templateFillData.forEach(item => {
        let fsData = {}
        fsData = {
          "Fund Name": item.fundName,
          "Investor Name": item.investorName,
          "Investor Number": item.investorNumber,
          "Share Class": "",
          "Series": "",
          "Investor Type": "",
          "Investor for FIHI": "",
          "Investor Type Ranking": "",
          "Class Ranking": "",
          "Series Ranking": ""
        };
        if (this.componentName == 'fSManagementCompanyInvestor') {
          fsData = {
            "Investor Name": item.investorName,
            "Investor Number": item.investorNumber,
            "Share Class": "",
            "Class Ranking": ""
          }
        }
        sampletradeData.push(fsData);
      });
      alasql(
        'SELECT * INTO XLSX("Sample ' +
        this.uploadDataName +
        '.xlsx",{headers:true}) FROM ?',
        [sampletradeData]
      );
    }
    //------------ TA- 388 || Added Custom format for Investor and Fund in Tax Automation START-----
    else if (this.componentName == "investorUpdate") {
      const modifiedData = sampletradeData.map((row) => ({ ...row }));
      // Create a worksheet
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(modifiedData);
      // Set the worksheet range till 10000 rows A1:P10000
      //If new column is added in Investor update then update the count(A-Z)here last column number is T here 
      ws['!ref'] = 'A1:T10000';
      // Specify the columns for the number format Tax ID1 and Tax ID2
      const columnsToFormat = ['E', 'F'];
      this.customFormatHeaderFundInvestorTAX(columnsToFormat, ws);
    }

    else if (this.componentName == "taxFundList") {
      const modifiedData = sampletradeData.map((row) => ({ ...row }));
      // Create a worksheet
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(modifiedData);
      // Set the worksheet range till 10000 rows A1:O10000
      ws['!ref'] = 'A1:O10000';
      // Specify the columns for the number format Tax ID1 , Tax ID2, Tax ID3
      const columnsToFormat = ['B', 'C', 'D'];
      this.customFormatHeaderFundInvestorTAX(columnsToFormat, ws);
    }
    //-------- TA- 388 || Added Custom format for Investor and Fund in Tax Automation END------------

    else {
      alasql(
        'SELECT * INTO XLSX("Sample ' +
        this.uploadDataName +
        '.xlsx",{headers:true}) FROM ?',
        [sampletradeData]
      );
    }
  }

  clear() {
    this.formValid = true;
    this.fileUpload.clear();
    this.uploadedData = null;
    this.dragDrop = true;
    if (this.componentName == "uploadWorkflow") {
      this.uploadResult.emit("reset");
    }
  }

  onRead(evt) {
    this.userFieldArray = [];
    if (!this.getConfigCheck && this.componentName != "uploadWorkflow") {
      this.clear();
      this.toast("info", "File Upload", "Please Select Custom Type", true);
      return;
    }
    this.formValid = false;
    this.dragDrop = false;
    let workbook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = evt.currentFiles[0];
    this.filename = evt.currentFiles[0];
    reader.onload = (event) => {
      const data = reader.result;
      workbook = XLSX.read(data, { type: "binary" });

      /* DO SOMETHING WITH workbook HERE */
      var fieldArray = [
        "createdDate",
        "pnlDate",
        "issueDate",
        "bondDefaultDate",
        "lastInterestPayDate",
        "firstInterestPayDate",
        "lastTradingDay",
        "expiryDate",
        "expiryDate",
        "effectiveDateTo",
        "dateOfRecordCreation",
        "effectiveDateFrom",
        "taxlotdate",
        "nextPayDate",
        "lastPaydate",
        "date",
        "dateOfIncorporation",
        "dateOfTax",
        "valuationDate",
        "valuationTime",
        "startDate",
        "transferDate",
        "endDate",
        "exDate",
        "payDate",
        "entryDate",
        "effectivedate",
        "tradeDateTime",
        "settlementDate",
        "entryDateTime",
        "maturityDate",
        "updatedOn",
        "dateOfIncorp",
        "tradingStartDate",
        "dateOfDoc",
        "fundStartDate",
        "fiscalYearEnd",
        "taxYearEnd",
        "serviceStartDate",
        "valuationDateAndTime",
        "eventDate",
        "issueStartDate",
        "issueEndDate",
        "exchangeTime",
        "exchangeDate",
        "taxLotDate",
        "tenureEndDate",
        "purchaseDate",
        "vintageDate",
        "customDate2",
        "customDate1",
        "fundEndDate",
        "closedDate",
        "jedate",
        "dateOfEntry",
        "date1",
        "date2",
        "timestamp",
        "expirationDate",
        "effectiveDate",
        "lastPayDate",
        "jeDate",
        "reconciliationDate",
        "holidayCalendar",
        "effectiveDateStart",
        "effectiveDateEnd",
        "createdOn",
        "startdate",
        "enddate",
        "seriesDate",
        "feeResetDate",
        "resetDate",
        "distributionDate",
        "ratioDate",
        "capitalCallDate",
        "ufSubscriptionDate",
        "periodLockDate",
        "reportApprovalDate",
        "ctaManagerStartDate",
        "ctaManagerEndDate",
        "fundVAMICalcSincePeriod",
        "redemptionLockupDate",
        "wsOpenTaxLotDate",
        "fundPeriodLockDate",
        "originalPurchaseDate",
        "balanceDate"
      ];

      var breakCheck = true;
      var sheet_name_list = workbook.SheetNames;
      var resultArray = [];
      var finalResultArray = [];
      var configFields = this.configFields;
      var currentUser = this.currentUser;
      sheet_name_list.forEach((y) => {
        /* iterate through sheets */
        var worksheet = workbook.Sheets[y];
        var cellFieldLinker = {};
        var dateFieldCells = [];
        var custodian = { id: "" };

        for (var z in worksheet) {
          /* all keys that do not begin with "!" correspond to cell addresses */
          if (z[0] === "!") continue;
          var res = z;
          var row = res.replace(/[^0-9]+/gi, "");
          var col = res.replace(row, "");

          if (row == "1") {
            var configIndexCheck =
              configFields[worksheet[z].v] != undefined &&
              configFields[worksheet[z].v] != null &&
              configFields[worksheet[z].v] != "";
            this.userFieldArray.push(worksheet[z].v);
            if (configFields[worksheet[z].v]) {
              cellFieldLinker[col] = configFields[worksheet[z].v];
            } else {
              cellFieldLinker[col] = worksheet[z].v;
            }
            if (fieldArray.indexOf(cellFieldLinker[col]) > -1) {
              dateFieldCells.push(col);
            }
          } else {
            var fieldName = cellFieldLinker[col];

            if (resultArray[Number(row) - 2] == undefined) {
              resultArray[Number(row) - 2] = {};
            }

            resultArray[Number(row) - 2].enterpriseId =
              currentUser.enterpriseId;

            resultArray[Number(row) - 2].brokerAccount = {
              id: custodian.id
            };
            if (this.componentName == "chartOfAccountTaxUpdate") {
              resultArray[Number(row) - 2].taxTeam = true;
            }
            if (
              (this.componentName == "rapidSmEquitiesList" ||
                this.componentName == "rapidCrypto" ||
                this.componentName == "rapidSmFixedIncomeList" ||
                this.componentName == "RapidSmFutureList" ||
                this.componentName == "smFxForwardList" ||
                this.componentName == "rapidSmFxForwardList" ||
                this.componentName == "smOptionsList" ||
                this.componentName == "RapidSmOptionsList" ||
                this.componentName == "RapidSmPrivateFundList" ||
                this.componentName == "setupcustodianList" ||
                this.componentName == "RapidSmPools") &&
              (fieldName == "TimeStamp" || fieldName == "timeStamp" || fieldName == "accountingTime" || fieldName == "Accounting Time")
            ) {
              resultArray[Number(row) - 2][fieldName] = worksheet[z].w;
            } else {
              if (fieldName == "taxId1" || fieldName == "taxId2" || fieldName == "taxId3") {
                resultArray[Number(row) - 2][fieldName] = worksheet[z].w;
              }
              else {
                resultArray[Number(row) - 2][fieldName] = worksheet[z].v;
              }
            }
            if (dateFieldCells.indexOf(col) > -1) {
              if (
                fieldName == "settlementDate" &&
                resultArray[Number(row) - 2][fieldName] == ""
              ) {
                resultArray[Number(row) - 2][fieldName] =
                  resultArray[Number(row) - 2]["tradeDateTime"];
              }
              else if (this.componentName == "setupamcIMProgram" && fieldName == "historicalBlendMonthYear" &&
                resultArray[Number(row) - 2]["historicalBlendMonthYear"]) {

                let isValid = moment(this.setDateFieldsFromDoc(worksheet[z].v)).isValid()
                if (isValid) {
                  resultArray[Number(row) - 2][fieldName] = moment(this.setDateFieldsFromDoc(worksheet[z].v)).format('YYYY-MM-DD');
                } else {
                  resultArray[Number(row) - 2][fieldName] = "";
                }

              }
              else if ((this.componentName == "disableAutoUpdateViaAllocationUpdate" || this.componentName == "redemptionActivityUpdate" || this.componentName == "subscriptionActivityUpdate") && fieldName == "effectiveDate") {
                if (typeof (worksheet[z].v) == "string") {
                  resultArray[Number(row) - 2][fieldName] = this.convertToUTCString(worksheet[z].v);
                } else {
                  resultArray[Number(row) - 2][fieldName] = this.setDateFieldsFromDoc(worksheet[z].v);
                }
              }
              else {
                resultArray[Number(row) - 2][fieldName] =
                  this.setDateFieldsFromDoc(worksheet[z].v);
              }
            }
          }
        }
        if (this.componentName == "taxFundList" || this.componentName == "investorUpdate") {
          resultArray.forEach(el => {
            finalResultArray.push(el)
          })
        }
      });
      if (this.componentName == "taxFundList" || this.componentName == "investorUpdate") {
        this.uploadedData = this.clearNullElementsInArrayInNewFormat(finalResultArray);
      }
      else {
        if (this.componentName == "fSPortfolio") {
          var sampletradeData = ["FS ProductTypes", "FS Country", "FS Sector", "FS Level"]

          resultArray.forEach(ele => {
            for (const item of sampletradeData) {
              if (!ele.hasOwnProperty(configFields[item])) {
                ele[(configFields[item] || item)] = ''
              }
            }
          })

          this.uploadedData = resultArray;
        } else {
          this.uploadedData = this.clearNullElementsInArray(resultArray);
        }

      }
    };
    reader.readAsBinaryString(file);
  }


  convertToUTCString(dateString: string): string {
    // Parse the date string (assuming MM/DD/YYYY format)
    const dateParts = dateString.split('/');
    const date = new Date(Date.UTC(+dateParts[2], +dateParts[0] - 1, +dateParts[1]));

    // Manually construct the date string
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Adjust for India Standard Time (UTC+0530)
    date.setHours(date.getUTCHours() + 5);
    date.setMinutes(date.getUTCMinutes() + 30);

    const dayOfWeek = days[date.getUTCDay()];
    const day = ('0' + date.getUTCDate()).slice(-2);
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    const hours = ('0' + date.getUTCHours()).slice(-2);
    const minutes = ('0' + date.getUTCMinutes()).slice(-2);
    const seconds = ('0' + date.getUTCSeconds()).slice(-2);

    return `${dayOfWeek} ${month} ${day} ${year} ${hours}:${minutes}:${seconds} GMT+0530 (India Standard Time)`;
  }


  setDateFieldsFromDoc(value: any) {
    var numOfDays = Math.floor(value);
    this.daysInMilliSecs = 0;
    if (parseInt(new Date().toString().match(/([-\+][0-9]+)\s/)[1]) < 0) {
      this.daysInMilliSecs = (numOfDays - 25568) * 24 * 60 * 60 * 1000; // (no of days between 1/1/1900 to 1/1/1970) + 1 days = 25568
    } else if (numOfDays == 0) {
      this.daysInMilliSecs = "";
    } else {
      this.daysInMilliSecs = (numOfDays - 25569) * 24 * 60 * 60 * 1000; // (no of days between 1/1/1900 to 1/1/1970) + 2 days = 25569
    }
    var numOfDaysInFraction = value - numOfDays;
    var dayToHours = numOfDaysInFraction * 24;
    var hours = Math.floor(dayToHours);
    var numOfHoursInFraction = dayToHours - hours;
    var hourToMins = numOfHoursInFraction * 60;
    var mins = Math.floor(hourToMins);
    var numOfMinsInFraction = hourToMins - mins;
    var minToSecs = numOfMinsInFraction * 60;
    var secs = Math.round(minToSecs);
    var newDate = new Date(this.daysInMilliSecs);
    newDate.setHours(hours);
    newDate.setMinutes(mins);
    newDate.setSeconds(secs);
    if (this.componentName == "rapidProgramHistoricalData") {
      newDate = value;
    }
    return String(newDate);
  }


  formatDate(dateString: string): string {
    return (dateString.toString());
  }

  clearNullElementsInArray(arr) {
    var result = [];
    for (var c = 0; c < arr.length; c++) {
      if (arr[c] != undefined && arr[c] != null) {
        result.push(arr[c]);
      }
    }
    return result;
  }

  // TA-388 || Created a custom format header for Fund and Investor upload in Tax Setup-----------
  clearNullElementsInArrayInNewFormat(array) {
    var result = [];
    if (this.componentName == "investorUpdate") {
      for (var c = 0; c < array.length; c++) {
        if (array[c].fund && array[c].id && array[c].investorNumber && array[c].name) {
          result.push(array[c]);
        }
      }
    }
    else if (this.componentName == "taxFundList") {
      for (var c = 0; c < array.length; c++) {
        if (array[c].id) {
          result.push(array[c]);
        }
      }
    }
    return result;
  }

  upload() {
    this.formValid = true;
    var fileNameArr = this.filename.name.split(".");
    if (
      fileNameArr[fileNameArr.length - 1] == "xlsx" ||
      fileNameArr[fileNameArr.length - 1] == "xls"
    ) {
      if (this.uploadedData) {
        this.submitDisable = true;
        if (this.componentName == "tradeList" || this.componentName == "rapidTradeList") {
          if (this.uploadedData) {
            this.uploadedData.forEach((element) => {
              if (element.parValue == "" || element.parValue == null) {
                element.parValue = 1;
              }
            });
          }
        }
        if (this.componentName == "smOptionsList" || this.componentName == "RapidSmOptionsList") {
          if (this.uploadedData) {
            this.uploadedData.forEach((element) => {
              if (
                element.usTaxExempt &&
                (element.usTaxExempt == "" || element.usTaxExempt == null)
              ) {
                element.usTaxExempt = "No";
              }
            });
          }
        }
        if (this.componentName == "chartOfAccountTaxUpdate") {
          if (this.uploadedData) {
            this.uploadedData.forEach((el) => {
              delete el['brokerAccount'];
              delete el['enterpriseId'];
              if (el?.["Seamless Tax Code"]) {
                el["seamlessTaxCode"] =
                  el["Seamless Tax Code"];
                delete el["Seamless Tax Code"];
              }
            });
          }
        }
        if (this.componentName == "fSInvestor" || this.componentName == "fSManagementCompanyInvestor" || this.componentName == "fSPortfolio") {
          if (this.uploadedData) {
            this.uploadedData.forEach((el) => {
              delete el['brokerAccount'];
              delete el['enterpriseId'];
            });
          }
        }
        if (this.componentName == "investorUpdate" || this.componentName == "taxFundList") {
          if (this.uploadedData) {
            this.uploadedData.forEach((el) => {
              delete el['brokerAccount'];
              delete el['enterpriseId']
            });
          }
        }
        if (this.componentName == "setupcustodianList") {
          if (this.uploadedData) {
            this.uploadedData.forEach((element) => {
              if (
                element.accountingTime
              ) {
                element.accountingTime = element.accountingTime.split(' ')[0];
              }
            });
          }
        }
        if (this.componentName == "taxAdditionK1Details") {
          if (this.uploadedData) {
            this.uploadedData.forEach((el) => {
              delete el['brokerAccount'];
              delete el['enterpriseId']
              //		TA-541 || Additional K-1 Upload Bugs || Server
              if (el.partnerShareOfNetUnrecognizedSection704CGainOrLoss_Beginning) {
                if (typeof (el.partnerShareOfNetUnrecognizedSection704CGainOrLoss_Beginning) == "string") {
                  el.partnerShareOfNetUnrecognizedSection704CGainOrLoss_Beginning = 0
                }
              } if (el.partnerShareOfNetUnrecognizedSection704CGainOrLoss_Ending) {
                if (typeof (el.partnerShareOfNetUnrecognizedSection704CGainOrLoss_Ending) == "string") {
                  el.partnerShareOfNetUnrecognizedSection704CGainOrLoss_Ending = 0
                }
              } if (el.partnerShareOfLiabilities_Beginning_Nonrecourse) {
                if (typeof (el.partnerShareOfLiabilities_Beginning_Nonrecourse) == "string") {
                  el.partnerShareOfLiabilities_Beginning_Nonrecourse = 0
                }
              } if (el.partnerShareOfLiabilities_Ending_Nonrecourse) {
                if (typeof (el.partnerShareOfLiabilities_Ending_Nonrecourse) == "string") {
                  el.partnerShareOfLiabilities_Ending_Nonrecourse = 0
                }
              } if (el.partnerShareOfLiabilities_Beginning_QualifiedNonrecourseFinancing) {
                if (typeof (el.partnerShareOfLiabilities_Beginning_QualifiedNonrecourseFinancing) == "string") {
                  el.partnerShareOfLiabilities_Beginning_QualifiedNonrecourseFinancing = 0
                }
              } if (el.partnerShareOfLiabilities_Ending_QualifiedNonrecourseFinancing) {
                if (typeof (el.partnerShareOfLiabilities_Ending_QualifiedNonrecourseFinancing) == "string") {
                  el.partnerShareOfLiabilities_Ending_QualifiedNonrecourseFinancing = 0
                }
              } if (el.partnerShareOfLiabilities_Beginning_Recourse) {
                if (typeof (el.partnerShareOfLiabilities_Beginning_Recourse) == "string") {
                  el.partnerShareOfLiabilities_Beginning_Recourse = 0
                }
              } if (el.partnerShareOfLiabilities_Ending_Recourse) {
                if (typeof (el.partnerShareOfLiabilities_Ending_Recourse) == "string") {
                  el.partnerShareOfLiabilities_Ending_Recourse = 0
                }
              } if (el.partnerBeginningPercentageOf_Profit) {
                if (typeof (el.partnerBeginningPercentageOf_Profit) == "string") {
                  el.partnerBeginningPercentageOf_Profit = 0
                }
              } if (el.partnerBeginningPercentageOf_Loss) {
                if (typeof (el.partnerBeginningPercentageOf_Loss) == "string") {
                  el.partnerBeginningPercentageOf_Loss = 0
                }
              } if (el.partnerBeginningPercentageOf_Capital) {
                if (typeof (el.partnerBeginningPercentageOf_Capital) == "string") {
                  el.partnerBeginningPercentageOf_Capital = 0
                }
              }
            });
          }
        }

        if (this.componentName == "RapidTransferTradeRawDataHistory") {
          if (this.uploadedData) {
            this.uploadedData.forEach((element) => {
                  if (element.transferDate) {
                  element.transferDate = moment(element.transferDate).format("YYYY-MM-DD");
                }
              
            });
          }
        }
        if (this.componentName == "chartOfAccounts" || this.componentName == "defaultChartOfAccounts") {
          if (this.uploadedData) {
            this.uploadedData.forEach((element) => {
              if (element?.["Is this a cost account"]) {
                element["customField3"] = element["Is this a cost account"];
                delete element["Is this a cost account"];
              }
              if (element?.["Is this a portfolio account"]) {
                element["customField5"] =
                  element["Is this a portfolio account"];
                delete element["Is this a portfolio account"];
              }
            });
          }
        }
        if (this.componentName == "rapidDividend") {
          if (this.uploadedData) {
            this.uploadedData.forEach((element) => {
              if (
                element.paymentInKind == "" ||
                element.paymentInKind == null
              ) {
                element.paymentInKind = "No";
              }
              if (
                element.optionOfCashPay == "" ||
                element.optionOfCashPay == null
              ) {
                element.optionOfCashPay = "No";
              }
              // delete element['brokerAccount'];
            });
          }
        }
        if (this.componentName == "setupcustodianList" || this.componentName == "custodianList" || this.componentName == 'custodianListLegacy') {
          if (this.uploadedData) {
            this.uploadedData.forEach((element) => {
              if (element?.["Custodian Name"]) {
                element["nameOfCustodian"] = element["Custodian Name"];
                delete element["Custodian Name"];
              }
              if (element?.["Account Number/Name"]) {
                element["accountNumber"] = element["Account Number/Name"];
                delete element["Account Number/Name"];
              }
              if (element?.["Connection ID/Account Number in Source File"]) {
                element["accountNumberInSourceFile"] = element["Connection ID/Account Number in Source File"];
                delete element["Connection ID/Account Number in Source File"];
              }
            });
          }
        }
        if (
          this.componentName == "rapidMarketdata" ||
          this.componentName == "uploadcashBalance" ||
          this.componentName == "uploadPositionBroker" ||
          this.componentName == "sourceDestTradeTransferTaxLotInfo" ||
          this.componentName == "RapidCryptoMarketData" ||
          this.componentName == "rapidNonTradeActivity" ||
          this.componentName == "rapidInvestors" ||
          this.componentName == "rapidUploadallocationhistory" ||
          this.componentName == "externalROR" ||
          this.componentName == "rapidExternalROR" ||
          this.componentName == "rapidSidepocketcreation" ||
          this.componentName == "rapidDividend" ||
          this.componentName == "RapidDividendadjuctment" ||
          this.componentName == "RapidSeamlessmarketdata" ||
          this.componentName == "rapidBondinterestadjustment" ||
          this.componentName == "rapidBenchmark" ||
          this.componentName == "performanceAnalyticsSettings" ||
          this.componentName == "rapidCustomRatio" ||
          this.componentName == "rapidSpecialAllocation" ||
          this.componentName == "rapidTLRegime" ||
          this.componentName == "takeOverPerfBaseFee" ||
          //this.componentName == "RapidTransferTradeRawDataHistory" ||
          this.componentName == "rapidHistoricalwashsaleopenLot" ||
          this.componentName == "setupInvestorsList" ||
          this.componentName == "setupamcIMProgram"
        ) {
          if (this.uploadedData.length != 0) {
            this.uploadedData.forEach((el) => {
              delete el["brokerAccount"];
            });
          }
          for (var key in this.uploadedData[0]) {
            if (this.uploadedData[0].hasOwnProperty(key)) {
              if (this.uploadedData[0][key] == "Invalid Date") {
                this.uploadedData[0][key] = "";
              }
            }
          }
          dataPrep = { data: this.uploadedData };
        }

        if (this.componentName == "smFxForwardList" || this.componentName == "rapidSmFxForwardList") {
          if (this.uploadedData) {
            this.uploadedData.forEach((element) => {
              if (
                element.priceInversion &&
                (element.priceInversion == "" || element.priceInversion == null)
              ) {
                element.priceInversion = "No";
              }
            });
          }
        }
        if (
          this.componentName == "allsmupload" ||
          this.componentName == "custodianList" ||
          this.componentName == 'custodianListLegacy' ||
          this.componentName == "FundList" ||
          this.componentName == "sourceDestTradeTransferTaxLotInfo" ||
          this.componentName == "RapidCryptoMarketData" ||
          this.componentName == "rapidNonTradeActivity" ||
          this.componentName == "rapidInvestors" ||
          this.componentName == "setupInvestorsList" ||
          this.componentName == "rapidUploadallocationhistory" ||
          this.componentName == "externalROR" ||
          this.componentName == "rapidExternalROR" ||
          this.componentName == "rapidSidepocketcreation" ||
          this.componentName == "takeOverPerfBaseFee" ||
          this.componentName == "rapidTLRegime" ||
          this.componentName == "rapidSpecialAllocation" ||
          this.componentName == "rapidCustomRatio" ||
          this.componentName == "performanceAnalyticsSettings" ||
          this.componentName == "rapidBenchmark" ||
          this.componentName == "RapidSeamlessmarketdata" ||
          this.componentName == 'RapidSmRealestateList' ||
          this.componentName == "rapidSmEquitiesList" ||
          this.componentName == "rapidCrypto" ||
          this.componentName == "rapidSmFixedIncomeList" ||
          this.componentName == "RapidSmPrivateFundList" ||
          this.componentName == "RapidSmPools" ||
          this.componentName == "RapidSmFutureList" ||
          this.componentName == "rapidDailyMargin" ||
          this.componentName == "RapidJournalentry" ||
          this.componentName == "rapidCompoundJEListComponent" ||
          this.componentName == "rapidTradeList" ||
          this.componentName == "vendors" ||
          this.componentName == "customer" ||
          this.componentName == "smOptionsList" ||
          this.componentName == "RapidSmOptionsList" ||
          this.componentName == "smFxForwardList" ||
          this.componentName == "rapidSmFxForwardList" ||
          this.componentName == "rapidRecurringTransaction" ||
          this.componentName == "rapidProgramHistoricalData" ||
          this.componentName == "setupamcIMProgram" || 
          this.componentName == "fschartOfAccountsRapid"
        ) {
          this.uploadedData.forEach((element) => {
            for (var key in element) {
              if (element.hasOwnProperty(key)) {
                if (
                  !element[key] ||
                  element[key] == "Invalid Date" ||
                  element[key] == " " ||
                  key == "dateOfTax"
                ) {
                  if (key == 'auaCode') {
                    element[key] = element['auaCode'];
                  }
                  else
                    delete element[key];
                } else if (typeof element[key] == "object") {
                  let keys = Object.keys(element[key]);
                  if (!element[key][keys[0]]) {
                    delete element[key];
                  }
                }
              }
            }
          });
        }
        if (this.componentName == "setupFundList") {
          this.uploadedData.forEach((element) => {
            for (var key in element) {
              if (element.hasOwnProperty(key)) {
                if (
                  !element[key] ||
                  element[key] == "Invalid Date" ||
                  element[key] == " " ||
                  key == "dateOfTax"
                ) {
                  if (key == "defaultInvestorFormat") {
                    element[key] = "Investor Statement Generic";
                  } else if (key == "fundManager") {
                    element[key] = { id: this.currentUser["id"] };
                  } else if (key == "fundManagerId") {
                    element[key] = this.currentUser["firstName"];
                  } else if (key == 'auaCode') {
                    element[key] = element['auaCode'];
                  }
                  else delete element[key];
                } else if (typeof element[key] == "object") {
                  let keys = Object.keys(element[key]);
                  if (!element[key][keys[0]]) {
                    delete element[key];
                  }
                }
              }
            }

            element["defaultInvestorFormat"] =
              element["defaultInvestorFormat"] || "Investor Statement Generic";
            element["fundManagerId"] =
              element["fundManagerId"] || this.currentUser["firstName"];
            element["fundManager"] = element["fundManager"] || {
              id: this.currentUser["id"]
            };
          });
        }
        else if (this.componentName == "disableAutoUpdateViaAllocationUpdate" || this.componentName == "redemptionActivityUpdate" || this.componentName == "subscriptionActivityUpdate") {
          if (this.uploadedData.length != 0) {
            let value = [];
            this.uploadedData.forEach((el) => {
              delete el["brokerAccount"];
              if (!el["series"]) {
                el["seriesName"] = null;
              } else {
                if (typeof (el["series"]) == "string") {
                  el["seriesName"] = (el["series"]);
                } else {
                  el["seriesName"] = el["shareClass"].toString();
                }
              }
              if (!el["shareClass"]) {
                el["shareClassName"] = null;
              } else {
                if (typeof (el["shareClass"]) == "string") {
                  el["shareClassName"] = (el["shareClass"]);
                } else {
                  el["shareClassName"] = el["shareClass"].toString();
                }
              }
              if (el["% Value Of Account"] && !el["perValueOfAc"]) {
                if (typeof (el["% Value Of Account"]) == "string") {
                  el["perValueOfAccount"] = (el["% Value Of Account"]);
                } else {
                  el["perValueOfAccount"] = (el["% Value Of Account"]).toString();
                }
              }
              if (el["perValueOfAc"] && !el["% Value Of Account"]) {
                if (typeof (el["perValueOfAc"]) == "string") {
                  el["perValueOfAccount"] = (el["perValueOfAc"]);
                } else {
                  el["perValueOfAccount"] = (el["perValueOfAc"]).toString();
                }
              }
              if (!el["perValueOfAc"] && !el["% Value Of Account"]) {
                el["perValueOfAccount"] = null
              }
              if (el["disableAutoUpdateViaAllocation"] == true) {
                el["disableAutoUpdateViaAllocation"] = true;
              }
              else if (el["disableAutoUpdateViaAllocation"] == false) {
                el["disableAutoUpdateViaAllocation"] = false;
              }
              else if (el["disableAutoUpdateViaAllocation"] == "true") {
                el["disableAutoUpdateViaAllocation"] = true;
              }
              else if (el["disableAutoUpdateViaAllocation"] == "false") {
                el["disableAutoUpdateViaAllocation"] = false;
              }
              else {
                el["disableAutoUpdateViaAllocation"] = null;
              }
              delete el["series"];
              delete el["shareClass"];
              delete el["% Value Of Account"];
              delete el["perValueOfAc"];
            });
          }
          dataPrep = { data: this.uploadedData };
        }

        var dataPrep = new Object();

        switch (this.componentName) {
          case "tradeList":
          case "rapidTradeList":
            {
              dataPrep = {
                data: this.uploadedData,
                selectedOption: this.selectedOption
              };
              break;
            }
          case "vendors": {
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "customer": {
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "rapidExchangerate": {
            let carryWeekEndDataexchange;
            if (this.carryWeekend) {
              carryWeekEndDataexchange = "Yes";
            } else {
              carryWeekEndDataexchange = "No";
            }
            dataPrep = {
              data: this.uploadedData,
              carryWeekEndData: carryWeekEndDataexchange
            };
            break;
          }
          case "rapidVendorsUpload": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach(el => {
                delete el['brokerAccount'];
                delete el['id'];
                delete el['enterpriseId'];
              })
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "marketdatavar": {
            let carryWeekEndData;
            if (this.carryWeekend) {
              carryWeekEndData = "Yes";
            } else {
              carryWeekEndData = "No";
            }

            dataPrep = {
              data: this.uploadedData,
              selectedOption: this.selectedOptionVar,
              carryWeekEndData: carryWeekEndData
            };
            break;
          }
          case "rapidMarketdata": {
            let carryWeekEndData;
            if (this.carryWeekend) {
              carryWeekEndData = "Yes";
            } else {
              carryWeekEndData = "No";
            }

            dataPrep = {
              data: this.uploadedData,
              selectedOption: this.selectedOption,
              carryWeekEndData: carryWeekEndData
            };
            break;
          }
          case "subsectors": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
              });
            }
            dataPrep = { data: this.uploadedData };
          }
          case "rapidSubSectors": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
              });
            }
            dataPrep = { data: this.uploadedData };
          }
          case "rapidUploadallocationhistory": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
              });
            }
            dataPrep = { data: this.uploadedData };
          }

          case "rapidInvestors":
          case "setupInvestorsList":
          case "investors": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
              });
            }

            dataPrep = { data: this.uploadedData };
          }
          case "rapidProgramHistoricalData": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
                if (el["ytdRor"] == "") {
                  delete el["ytdRor"];
                }
              });
            }

            dataPrep = { data: this.uploadedData };
          }

          case "rapidRecurringTransaction": {
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "chartOfAccounts": {
            delete this.uploadedData[0]["brokerAccount"];
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "identifiers": {
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "performanceAnalyticsSettings": {
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "allsmupload": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
              });
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "rapidSetupbondinterest": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
              });
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "rapidTradingLevelChange": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
              });
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "predefinedJe": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
              });
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "hurdleRate": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
              });
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "RapidJournalentry": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
                delete el["undefined"];
              });
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "rapidCompoundJEListComponent": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
                delete el["undefined"];
              });
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "custodianListLegacy":
          case "setupcustodianList":
          case "custodianList": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
                delete el["undefined"];
              });
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "historicalInvestorCapitalSummary": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
              });
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "uploadWorkflow": {
            this.uploadedData.forEach((el) => {
              delete el["brokerAccount"];
            });
            dataPrep = { data: this.uploadedData, uniqueId: this.uniqueId };
            break;
          }
          case "connectUserFundList": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach(el => {
                delete el['brokerAccount'];
                delete el['enterpriseId'];
              })
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "customizationSetupJeTransfer": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach(el => {
                delete el['brokerAccount'];
                delete el['enterpriseId'];
              })
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "customizationReportApproval": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach(el => {
                if (el['decimal'] != 0) {
                  el['decimal'] = el['decimal'] ? el['decimal'] : 2
                }
                el['dtd'] = el['dtd'] ? el['dtd'] : false
                el['itd'] = el['itd'] ? el['itd'] : false
                el['mtd'] = el['mtd'] ? el['mtd'] : false
                el['qtd'] = el['qtd'] ? el['qtd'] : false
                el['ytd'] = el['ytd'] ? el['ytd'] : false
                delete el['brokerAccount'];
                delete el['enterpriseId'];
              })
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "rapidReBudgets": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach(el => {
                delete el['brokerAccount'];
                delete el['enterpriseId'];
              })
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "setupamcIMProgram": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach(el => {
                delete el['brokerAccount'];
                delete el['enterpriseId'];
              })
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "userCoaList": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach(el => {
                delete el['brokerAccount'];
                delete el['enterpriseId'];
              })
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "connectUserInvestor": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach(el => {
                delete el['brokerAccount'];
              })
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "defaultChartOfAccounts": {
            delete this.uploadedData[0]["brokerAccount"];
            this.uploadedData[0]["userId"] = this.uploadedData[0]["enterpriseId"]
            delete this.uploadedData[0]["enterpriseId"]
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "fSInvestor":
          case "fSManagementCompanyInvestor": {
            if (this.uploadedData.length) {
              this.uploadedData.forEach(el => {
                let shareClass = el['shareClass'] || '';
                let series = el['series'] || '';
                el['shareClass'] = shareClass ? [shareClass] : [];
                el['series'] = series ? [series] : [];
              })
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "fschartOfAccountsRapid": {
            let FsChartOfAccountsData = this.gridService.getFsChartOfAccounts();
            if (this.uploadedData.length) {
              this.uploadedData.forEach((el) => {
                el['chartOfAccountsId'] = el['COA Id']
                delete el["brokerAccount"];
                delete el['COA Id'];
                delete el["undefined"];
              });
            }
            dataPrep = { data: this.uploadedData, configId: FsChartOfAccountsData['configId'] };
            break;
          }
          default: {
            dataPrep = { data: this.uploadedData };
          }
        }
        if (this.uploadedData.length != 0) {
          this.uploadedData.forEach((el) => {
            if (el["dateOfRecordCreation"] == "") {
              this.uploadedData["dateOfRecordCreation"] = new Date();
            }
          });
        }
        if (this.componentName == "allsmupload") {
          if (this.smtype != undefined || this.smtype != null) {
            this.uploadUrl =
              "AllSMDownload/uploadAllSM/" +
              this.smtype +
              "/allSMs?smUploadConfigType=" +
              this.selectedOption;
          } else {
            this.toast(
              "info",
              "Please Select",
              "please select mandatory fields",
              true
            );
            return;
          }
        } else if (this.componentName == "fSInvestor" || this.componentName == "fSManagementCompanyInvestor") {
          let fundId = this.templateFillData.length ? this.templateFillData[0].fundId : '';
          this.uploadUrl = this.uploadDetails.uploadUrl + fundId;
        } else if (this.componentName == "fSPortfolio") {
          let portfolioData = this.gridService.getFSPortFolioData();
          this.uploadUrl = this.uploadDetails.uploadUrl + portfolioData['fundId'] + "/" + portfolioData['endDate'];
        } else {
          this.uploadUrl =
            (this.componentName == "tradeList" || this.componentName == "rapidTradeList")
              ? this.value == "Client"
                ? this.uploadDetails.uploadUrl
                : this.value == "Broker"
                  ? "tradeEntryBrokerV2/upload"
                  : ""
              : this.uploadDetails.uploadUrl;
          if (this.componentName == "rapidNonTradeActivity") {
            this.uploadUrl =
              this.componentName == "rapidNonTradeActivity"
                ? this.value == "Client"
                  ? this.uploadDetails.uploadUrl
                  : this.value == "Third Party Source"
                    ? "nonTradeTransactionTpSourceV2/newUpload"
                    : ""
                : this.uploadDetails.uploadUrl;
          }
        }
        try {
          if (this.componentName == "tradeList" || this.componentName == "rapidTradeList") {
            this.reqObj = btoa(JSON.stringify(dataPrep));
          }
        } catch (error) {
          this.toast(
            "error", 'Trade list could not be uploaded due to special character(s). Please recheck and upload again.',
            '',
            true
          );
          throw error;
        }
        if (this.componentName == "rapidUploadallocationhistory") {
          this.reqObj = dataPrep;
        } else if (this.componentName == "investorsList" || this.componentName == "setupInvestorsList") {
          this.reqObj = dataPrep;
        } else if (this.componentName == 'rapidVendorsUpload') {
          this.reqObj = JSON.stringify(dataPrep);
        } else if (this.componentName == "investorPortalUser") {
          this.reqObj = dataPrep;
        } else if (this.componentName == "reportingPortalUser") {
          this.reqObj = dataPrep;
        } else if (this.componentName == "historicalInvestorCapitalSummary") {
          this.reqObj = dataPrep;
        } else if (this.componentName == "customizationSetupJeTransfer") {
          this.reqObj = dataPrep;
        } else if (this.componentName == "customizationReportApproval") {
          this.reqObj = dataPrep;
        } else if (this.componentName == "chartOfAccountTaxUpdate") {
          this.reqObj = dataPrep;
        } else if (this.componentName == "investorUpdate") {
          this.reqObj = dataPrep;
        } else if (this.componentName == 'taxFundList') {
          this.reqObj = dataPrep;
        } else if (this.componentName == 'setupamcIMProgram') {
          this.reqObj = dataPrep;
        } else if (this.componentName == "rapidReBudgets" || this.componentName == "userCoaList") {
          this.reqObj = this.uploadedData;
        } else if (this.componentName == "RapidSmRealestateList") {
          this.reqObj = window.btoa(unescape(encodeURIComponent(JSON.stringify(dataPrep))));
        } else if (this.componentName == "FundList" || this.componentName == "setupFundList") {
          this.reqObj = dataPrep;
        } else if (this.componentName == "RapidJournalentry" || this.componentName == 'rapidCompoundJEListComponent') {
          this.reqObj = this.dataStringify ? this.isConvert(JSON.stringify(dataPrep)) : dataPrep;
        } else if (this.componentName == "taxAdditionK1Details") {
          this.reqObj = this.uploadedData;
        } else if (this.componentName == "disableAutoUpdateViaAllocationUpdate" || this.componentName == "redemptionActivityUpdate" || this.componentName == "subscriptionActivityUpdate") {
          this.reqObj = dataPrep;
          this.reqObj["userFields"] = this.userFieldArray;
        }
        else {
          this.reqObj = this.dataStringify
            ? btoa(JSON.stringify(dataPrep))
            : dataPrep;
        }
        if (this.componentName == "setupcustodianList") {
          this.fetchtradeAdvisor().subscribe((res) => {
            let tradeAdvisorId =
              res.resultList.length > 0 ? res.resultList[0].nameOfAdvisor : "";
            this.uploadedData.forEach((element) => {
              for (var key in element) {
                if (element.hasOwnProperty(key)) {
                  if (
                    !element[key] ||
                    element[key] == "Invalid Date" ||
                    element[key] == "" ||
                    element[key] == " " ||
                    key == "dateOfTax"
                  ) {
                    if (key == "taxlotclosingRankingMethod") {
                      element[key] = "FIFO";
                    } else if (key == "tradeAdvisorId") {
                      element[key] = tradeAdvisorId;
                    } else {
                      delete element[key];
                    }
                  } else if (typeof element[key] == "object") {
                    let keys = Object.keys(element[key]);
                    if (!element[key][keys[0]]) {
                      delete element[key];
                    }
                  }
                }
                element["taxlotclosingRankingMethod"] =
                  element["taxlotclosingRankingMethod"] || "FIFO";
                element["tradeAdvisorId"] =
                  element["tradeAdvisorId"] || tradeAdvisorId;
              }
            });
            this.gridService.getUploadData(
              this.uploadUrl,
              this.reqObj,
              (response) => {
                this.formValid = false;
                let type: string = 'Zip file';
                var res = response?.data ? response.data : response;
                if (res) {
                  var count = 0;
                  for (var i in res) {
                    if (res[i]) {
                      count++;
                    }
                  }
                  if (res && res.failedObjects != null) {
                    count = res.failedObjects.length;
                  }
                  var alertDataObj = {};
                  var goToListScreen = true;
                  if (
                    count != 0 &&
                    count != this.uploadedData.length &&
                    res.message !== "Data Saved" && res.message !== 'No record(s) uploaded'
                  ) {
                    this.translateService
                      .get(this.uploadDataName)
                      .subscribe((res) => {
                        this.uploadDataName = res;
                      });
                    var name = "Warning!" + this.uploadDataName + " uploaded";
                    var message =
                      count +
                      " " +
                      this.uploadDataName +
                      " uploaded are n't saved due to invalid data. Please check once.";
                    this.toast("warn", name, message, true);
                    if (this.componentName != "uploadWorkflow") {
                      var arg = this.resName ? res[this.resName] : res['failedObjects'];
                      this.downloadFailureTrades(arg);
                    } else {
                      if (res && res['failedObjects']) {
                        let url = environment.api_url + 'alteryxExecuteWorkflow/exportFile/' + this.uniqueId + '/' + type;
                        // this.processScreenService.downloadCSV(url, 'Failed Data.xlsx', 'Failed Data');
                        this.commonService.GenericDownload(url, 'Failed Data', 'Failed Data')

                      }
                    }
                  } else if (
                    count != 0 &&
                    count == this.uploadedData.length &&
                    res.failedObjects != null
                  ) {
                    this.translateService
                      .get(this.uploadDataName)
                      .subscribe((res) => {
                        this.uploadDataName = res;
                      });
                    goToListScreen = false;
                    name = "Uploading aborted!";
                    if (this.uploadDataName == 'Investors') {
                      message = "Investor records were not uploaded. Please check error file.";
                    } else {
                      message = "All " + this.uploadDataName + " aren't saved due to invalid data. Please check once.";
                    }
                    this.toast("error", name, message, true);
                    if (this.componentName != "uploadWorkflow") {
                      var arg = this.resName ? res[this.resName] : res['failedObjects'];
                      this.downloadFailureTrades(arg);
                    } else {
                      if (res && res['failedObjects']) {
                        let url = environment.api_url + 'alteryxExecuteWorkflow/exportFile/' + this.uniqueId + '/' + type;
                        // this.processScreenService.downloadCSV(url, 'Failed Data.xlsx', 'Failed Data');
                        this.commonService.GenericDownload(url, 'Failed Data', 'Failed Data')

                      }
                    }
                  } else if ((count == 0 && count == this.uploadedData.length) || res.message == 'No record(s) uploaded') {
                    this.translateService
                      .get(this.uploadDataName)
                      .subscribe((res) => {
                        this.uploadDataName = res;
                      });
                    var name = "Warning!";
                    var message = "Uploaded file has no data.";
                    this.toast("info", name, message, true);
                    if (this.componentName != "uploadWorkflow") {
                      var args = this.resName ? res[this.resName] : res['failedObjects'];
                      this.downloadFailureTrades(args);
                    } else {
                      if (res && res['failedObjects']) {
                        let url = environment.api_url + 'alteryxExecuteWorkflow/exportFile/' + this.uniqueId + '/' + type;
                        // this.processScreenService.downloadCSV(url, 'Failed Data.xlsx', 'Failed Data');
                        this.commonService.GenericDownload(url, 'Failed Data', 'Failed Data')

                      }
                    }
                  } else if (res.message == "DataNotFound") {
                    this.toast(
                      "error",
                      "No Recods Found",
                      "No records found in the uploaded file",
                      true
                    );
                  } else {
                    this.clear();
                    this.translateService
                      .get(this.uploadDataName)
                      .subscribe((res) => {
                        this.uploadDataName = res;
                      });

                    let message1 =
                      "unable to upload" +
                      " " +
                      this.smName +
                      " " +
                      "please contact System Administrator.";
                    if (
                      this.componentName == "allsmupload" &&
                      res.message == message1
                    ) {
                      this.toast(
                        "error",
                        this.uploadDataName,
                        res.message,
                        true
                      );
                      //  this.toast('error', "", res.message, true);
                    } else {
                      this.setuppercentage.setUploadTrigger(true);
                      if (this.uploadDataName == 'Investors') {
                        this.toast('success', "", "Investor(s)" + " uploaded successfully.", true);
                      } else {
                        this.toast('success', "", this.uploadDataName + " Uploaded Successfully.", true);
                      }
                      if (this.redirectionUrl) {
                        this.router.navigate([this.redirectionUrl])
                      }
                    }
                  }
                  this.submitDisable = false;
                }
              },
              (cbe) => {
                this.formValid = true;
                this.translateService
                  .get(this.uploadDataName)
                  .subscribe((res) => {
                    this.uploadDataName = res;
                  });

                this.toast(
                  "error",
                  "",
                  this.uploadDataName +
                  " records could not be uploaded due to invalid data. Please check the downloaded excel file",
                  true
                );

                this.submitDisable = false;
              }
            );
          });
        }
        else {
          if (this.componentName == "disableAutoUpdateViaAllocationUpdate" || this.componentName == "redemptionActivityUpdate" || this.componentName == "subscriptionActivityUpdate") {
            this.uploadUrl = '';
            if (this.selectedOption == 'Update') {
              this.uploadUrl = this.uploadDetails.updateUploadUrl;
            } else {
              this.uploadUrl = this.uploadDetails.uploadUrl;
            }
          } 
          this.gridService.getUploadData(
            this.uploadUrl,
            this.reqObj,
            (response) => {
              this.formValid = false;
              let type: string = 'Zip file';
              var res = response.data ? response.data : response;
              if (res) {
                var count = 0;
                for (var i in res) {
                  if (res[i]) {
                    count++;
                  }
                }
                if (res && res.failedObjects != null) {
                  count = res.failedObjects.length;
                }
                var alertDataObj = {};
                var goToListScreen = true;

                if (this.componentName == "uploadWorkflow") {
                  this.uploadDataName = "up_workflow";
                }
                if (
                  count != 0 &&
                  count != this.uploadedData.length &&
                  res.message !== "Data Saved" && res.message !== 'No record(s) uploaded'
                ) {
                  this.translateService
                    .get(this.uploadDataName)
                    .subscribe((res) => {
                      this.uploadDataName = res;
                    });
                  var name = "Warning!" + this.uploadDataName + " uploaded";
                  if (this.uploadDataName == 'Investors') {
                    var name = "Warning!" + this.uploadDataName + " not uploaded";
                    var message = count + " " + 'Investor records were not uploaded. Please check error file.';
                  } // TA-541 || Additional K-1 Upload
                  else if (this.uploadDataName == "Additional K-1 Details") {
                    var name = "Warning!" + " " + this.uploadDataName + " not uploaded";
                    this.toast("warn", name, res.failedObjects.length + " " + this.uploadDataName + " uploaded aren’t saved due to invalid data. Please check the error file once.", true);
                  } else {
                    var message = count + " " + this.uploadDataName + " uploaded aren't saved due to invalid data. Please check once.";
                  }
                  if (this.uploadDataName != "Additional K-1 Details") {
                    this.toast("warn", name, message, true);
                  }
                  if (this.componentName != "uploadWorkflow") {
                    var args = this.resName ? res[this.resName] : res['failedObjects'];
                    this.downloadFailureTrades(args);
                  } else {
                    if (res && res['failedObjects']) {
                      let url = environment.api_url + 'alteryxExecuteWorkflow/exportFile/' + this.uniqueId + '/' + type;
                      // this.processScreenService.downloadCSV(url, 'Failed Data.xlsx', 'Failed Data');
                      this.commonService.GenericDownload(url, 'Failed Data', 'Failed Data')

                    }
                  }
                } else if (
                  count != 0 &&
                  count == this.uploadedData.length &&
                  res.failedObjects != null
                ) {
                  this.translateService
                    .get(this.uploadDataName)
                    .subscribe((res) => {
                      this.uploadDataName = res;
                    });
                  goToListScreen = false;
                  name = "Uploading aborted!";
                  if (this.uploadDataName == 'Investors') {
                    message = "Investor records were not uploaded. Please check error file.";
                  } else {
                    message = "All " + this.uploadDataName + " aren't saved due to invalid data. Please check once.";
                  }
                  this.toast("error", name, message, true);
                  if (this.componentName != "uploadWorkflow") {
                    var arg = this.resName ? res[this.resName] : res['failedObjects'];
                    this.downloadFailureTrades(arg);
                  } else {
                    if (res && res['failedObjects']) {
                      let url = environment.api_url + 'alteryxExecuteWorkflow/exportFile/' + this.uniqueId + '/' + type;
                      // this.processScreenService.downloadCSV(url, 'Failed Data.xlsx', 'Failed Data');
                      this.commonService.GenericDownload(url, 'Failed Data', 'Failed Data')

                    }
                  }
                } else if ((count == 0 && count == this.uploadedData.length) || res.message == 'No record(s) uploaded') {
                  this.translateService
                    .get(this.uploadDataName)
                    .subscribe((res) => {
                      this.uploadDataName = res;
                    });
                  var name = "Warning!";
                  var message = "Uploaded file has no data.";
                  this.toast("info", name, message, true);
                  if (this.componentName != "uploadWorkflow") {
                    var args = this.resName ? res[this.resName] : res['failedObjects'];
                    this.downloadFailureTrades(args);
                  } else {
                    if (res && res['failedObjects']) {
                      let url = environment.api_url + 'alteryxExecuteWorkflow/exportFile/' + this.uniqueId + '/' + type;
                      // this.processScreenService.downloadCSV(url, 'Failed Data.xlsx', 'Failed Data');
                      this.commonService.GenericDownload(url, 'Failed Data', 'Failed Data')

                    }
                  }
                } else if (res.message == "DataNotFound") {
                  this.toast(
                    "error",
                    "No Recods Found",
                    "No records found in the uploaded file",
                    true
                  );
                } else {
                  this.clear();
                  this.translateService
                    .get(this.uploadDataName)
                    .subscribe((res) => {
                      this.uploadDataName = res;
                    });

                  let message1 =
                    "unable to upload" +
                    " " +
                    this.smName +
                    " " +
                    "please contact System Administrator.";
                  if (
                    this.componentName == "allsmupload" &&
                    res.message == message1
                  ) {
                    this.toast("error", this.uploadDataName, res.message, true);
                    //  this.toast('error', "", res.message, true);
                  } else {
                    this.setuppercentage.setUploadTrigger(this.componentName);
                    if (this.uploadDataName == 'Investors') {
                      this.toast('success', "", "Investor(s)" + " uploaded successfully.", true);
                    }
                    // TA-541 || Additional K-1 Upload
                    else if (this.uploadDataName == "Additional K-1 Details") {
                      const stringLength = res.message.split(' ')[0].trim();
                      this.toast('success', "Successful", stringLength + " " + this.uploadDataName + " were uploaded successfully.", true);
                    } else {
                      this.toast('success', "", this.uploadDataName + " Uploaded Successfully.", true);
                    }
                    if (this.redirectionUrl2 && ((this.componentName == "rapidTradeList" && this.value == "Broker") ||
                      (this.componentName == "rapidNonTradeActivity" && this.value == "Third Party Source"))) {
                      this.router.navigate([this.redirectionUrl2])
                    } else if (this.redirectionUrl) {
                      this.router.navigate([this.redirectionUrl])
                    }
                  }
                }
                this.submitDisable = false;
              }
            },
            (cbe) => {
              this.formValid = true;
              this.translateService
                .get(this.uploadDataName)
                .subscribe((res) => {
                  this.uploadDataName = res;
                });
              if (this.uploadDataName == 'Investors') {
                this.toast('Investors not uploaded', "", "Investor records were not uploaded. Please check error file.", true);
              } else {
                this.toast('error', "", this.uploadDataName + " records could not be uploaded due to invalid data. Please check the downloaded excel file", true);
              }
              this.submitDisable = false;
            }
          );
        }
      } else {
        this.formValid = true;
        this.toast("error", "", "Please choose file to upload!", true);
      }
    } else {
      this.formValid = true;
      this.toast(
        "error",
        "Unsupported File Format!",
        "Please upload only in excel (.xlsx , .xls) format.",
        true
      );
    }
  }

  downloadFailureTrades = function (failedTradeAllocationConfig) {
    var failName = "failed " + this.uploadDataName + " (Upload errors).xlsx";
    var failUrl =
      'SELECT * INTO XLSX("' + failName + '",{headers:true}) FROM ?';
    alasql(failUrl, [failedTradeAllocationConfig]);
  };

  toast(severity, summary, detail, autoClear) {
    this.messageService.add({ sticky: true, severity: severity, summary: summary, detail: detail, });
  }

  isConvert(data) {
    // Create a TextEncoder instance
    const encoder = new TextEncoder();
    // Encode the string as a Uint8Array
    const encodedData = encoder.encode(data);
    // Convert the Uint8Array to base64
    const binaryArray = Array.from(encodedData, byte => String.fromCharCode(byte));
    const binaryString = binaryArray.join('');
    const base64String = btoa(binaryString);
    return base64String;
  }

  compareCategoryObjects(object1: any, object2: any) {
    if (object1 != null && object2 != null) {
      if (object1.id && object2.id) {
        return object1 && object2 && object1.id == object2.id;
      } else {
        return object1 && object2 && object1 == object2;
      }
    }
  }

  setOnChange(type) {
    this.smTypes.forEach((item, index) => {
      if (item.value == type) {
        this.smName = item.name;
      }
    });
  }

  onFileSelected(evt) {
    this.formValid = false;
    // const file: File = evt.target.files[0];
    this.fileData = evt.files[0];
    this.filename = this.fileData;
    this.dragDrop = false;
    //this.uploadedData = this.fileData
    this.uploadedData = this.fileData.name;
  }

  uploadZipFile() {
    this.formValid = true;
    var fileNameArr = this.filename.name.split(".");
    if (fileNameArr[fileNameArr.length - 1] == "zip") {
      const formData = new FormData();
      formData.append("files", this.fileData);
      this.gridService.uploadFile(formData).subscribe((response) => {
        this.formValid = false;
        var count = JSON.parse(response.message);
        let responseData = JSON.parse(response.message);
        this.successListCount = count.SuccessListCount;
        this.failureListCount = count.FailedListCount;
        this.tradeUploadId = count.tradeUploadId;
        localStorage.setItem("failureListCount", this.failureListCount);
        localStorage.setItem("tradeUploadId", this.tradeUploadId);
        if (this.failureListCount != 0) {
          var name = "Uploading aborted!";
          var message =
            this.failureListCount +
            " Trade aren't saved due to invalid data. Please check once.";
          this.toast("error", name, message, true);
          this.exportTradeUploadFailedList();
        }
        if (this.failureListCount == 0 && this.successListCount == 0) {
          var name = "Warning!";
          var message = "Uploaded file has no data.";
          this.toast("info", name, message, true);
        }

        if (responseData.message) {
          this.messageService.add({
            sticky: true,
            severity: "error",
            summary: "Error",
            detail: responseData.message
          });
        }
        //  this.toast('error', " Trade Upload", this.successListCount + " uploaded Successfully.", true);
        if (this.successListCount > 0) {
          this.selectPreviewAndAggregation = true;
          this.toast(
            "success",
            " Trade Upload",
            this.successListCount + " uploaded Successfully.",
            true
          );
        }
      });
    } else {
      this.toast(
        "error",
        "Unsupported File Format!",
        "Please upload only in csv (.zip) format",
        true
      );
    }
  }

  exportTradeUploadFailedList() {
    let tradeUploadId = localStorage.getItem("tradeUploadId");
    let url =
      apiConstants[this.componentName]["exportTradeUploadFailedURL"].url +
      "/" +
      this.failureListCount +
      "/" +
      tradeUploadId;
    // this.processScreenService.downloadCSV(
    //   url,
    //   "Trade Upload Failed.zip",
    //   "Trade Upload HV"
    // );
    this.commonService.GenericDownload(url, 'Trade Upload Failed', 'Trade Upload HV')

  }

  convertArrayOfObjectsToCSV(args) {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = args.columnDelimiter || ",";
    lineDelimiter = args.lineDelimiter || "\n";

    keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function (item) {
      ctr = 0;
      keys.forEach(function (key) {
        if (ctr > 0) result += columnDelimiter;

        result +=
          item[key] == null
            ? ""
            : Array.isArray(item[key])
              ? item[key].join().replace(",", "-")
              : typeof item[key] === "string"
                ? item[key].replace(",", "-")
                : item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  directUploadTradeEntry() {
    //let tradeUploadId = localStorage.getItem('tradeUploadId');
    if (this.isDoubleClick) return;
    this.isDoubleClick = true;
    let url = "tradeUpload/aggRecordsWithUpload/" + this.tradeUploadId;
    this.gridService.getGridData(
      url,
      (cbs) => {
        this.selectPreviewAndAggregation = false;
        this.isDoubleClick = false;
        this.messageService.add({
          severity: "success",
          summary: "Trade Upload ",
          detail:
            "Group Count" + cbs.groupCount + "\n Total Count" + cbs.totalCount
        });
        this.router.navigate(['/input/view/trade-list'])
      },
      (cbe) => {
        this.isDoubleClick = false;
      }
    );
  }

  fetchtradeAdvisor(): Observable<any> {
    return this.http.get<any>("tradeAdvisorV2/fetchAllByEnterprise");
  }

  dialogClose(evt) {
    if (this.componentName == "uploadWorkflow") {
      this.uploadResult.emit("reset");
    }
  }

  //------------TA-388 || Custom Format for Leading Zeroes Excel Download Tax Automation START---------- 
  customFormatHeaderFundInvestorTAX(columnsToFormat, ws) {

    const range = XLSX.utils.decode_range(ws['!ref']);

    columnsToFormat.forEach((columnLetter) => {
      const columnIndex = XLSX.utils.decode_col(columnLetter);
      for (let rowIndex = range.s.r; rowIndex <= 10000; rowIndex++) {
        const cellAddress = { c: columnIndex, r: rowIndex };
        const cellRef = XLSX.utils.encode_cell(cellAddress);

        // If the cell doesn't exist, create it
        if (!ws[cellRef]) {
          ws[cellRef] = { t: 's', v: '', z: '000000000' };
        }
        // Set the number format
        ws[cellRef].z = '000000000';
      }
    });

    // Create a workbook
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Save the workbook to a file
    XLSX.writeFile(wb, 'Sample ' + this.uploadDataName + '.xlsx');
  }
  //-------------TA-388 || Custom Format for Leading Zeroes Excel Download Tax Automation END----------- 
}

