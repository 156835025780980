/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';
import { LocalStoreService } from 'src/app/shared/services/commonServices/local-store.service';
import { ReportService } from 'src/app/views/seamlessReports/reports.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
const api_url = environment['api_url'];

@Injectable()
export class FSButtonService {
  amcList: any;
  custodian: any;
  investor: any;
  assestBasedFee: any;
  performanceFee: any;
  public reportStartDate: any;
  public reportEndDate: any;
  public currentUser: any;
  public effectiveDate: any;
  public pricingTime: any;
  public fundList = [];
  public fundListAllData = [];
  public templateTypeList = [{ label: "Default Template", value: "Default Template" }];
  public templateList = [];
  public currencyList = [{ label: "USD", value: { "id": 'USD' } }];
  public companyFormedUnderTheLawOfList = [
    { label: "British Virgin Islands Business Company", value: { "id": 'British Virgin Islands Business Company' } },
    { label: "Cayman Islands exempted limited partnership", value: { "id": 'Cayman Islands exempted limited partnership' } },
    { label: "Delaware limited partnership", value: { "id": 'Delaware limited partnership' } },
    { label: "Delaware limited liability company", value: { "id": 'Delaware limited liability company' } },
  ];

  public PPMTermForEntityList = [
    { label: "Partnership", value: { "id": 'Partnership' } },
    { label: "Fund", value: { "id": 'Fund' } },
    { label: "Company", value: { "id": 'Company' } },
    { label: "Master fund", value: { "id": 'Master fund' } },
  ];

  public managementList = [
    { label: "Manager", value: { "id": 'Manager' } },
    { label: "General Partner", value: { "id": 'General Partner' } },
    { label: "Investment Manager", value: { "id": 'Investment Manager' } }
  ];
  public performanceList = [
    { label: "Manager", value: { "id": 'Manager' } },
    { label: "General Partner", value: { "id": 'General Partner' } },
    { label: "Investment Manager", value: { "id": 'Investment Manager' } },
    { label: "Managing Member", value: { "id": 'Managing Member' } }
  ];
  public performanceFeesFrequencyList = [
    { label: "Monthly", value: { "id": 'monthly' } },
    { label: "Quarterly", value: { "id": 'quarterly' } },
    { label: "Annually", value: { "id": 'annually' } }
  ];
  public distributionPeriodList = [
    { label: "Monthly", value: { "id": 'monthly' } },
    { label: "Quarterly", value: { "id": 'quarterly' } },
    { label: "Annually", value: { "id": 'annually' } }
  ];
  public ppmTermList = [
    { label: "Incentive Allocation", value: { "id": 'Incentive Allocation' } },
    { label: "Performance Allocation", value: { "id": 'Performance Allocation' } },
    { label: "Performance Fee", value: { "id": 'Performance Fee' } },
  ];
  public capitalClauseList = [
    { label: "Partners' capital", value: { "id": "Partners' capital" } },
    { label: "Net assets", value: { "id": "Net assets" } },
    { label: "Members' equity", value: { "id": "Members' equity" } },
    { label: "Shareholders' capital", value: { "id": "Shareholders' capital" } }
  ];
  public investorClauseList = [
    { label: "Limited partners", value: { "id": "Limited partners" } },
    { label: "Non-managing members", value: { "id": "Non-managing members" } },
    { label: "Members", value: { "id": "Members" } },
    { label: "Shareholders", value: { "id": "Shareholders" } }
  ];

  public sooGroupList = [
    { label: "Investment Expenses", value: { "id": 'Investment Expenses' } },
    { label: "Investment Income", value: { "id": 'Investment Income' } },
    { label: "Trading Gain/(Loss)", value: { "id": 'Trading Gain/(Loss)' } },
  ];

  public ppmTermAdditionList = [
    { label: "Addition", value: { "id": 'addition' } },
    { label: "Subscription", value: { "id": 'subscription' } },
    { label: "Contribution", value: { "id": 'contribution' } }
  ];
  public ppmTermRedeptionList = [
    { label: "Redemption", value: { "id": 'redemption' } },
    { label: "Withdrawal", value: { "id": 'withdrawal' } }
  ];
  public ppmTermDistributionList = [
    { label: "Redemption", value: { "id": 'redemption' } },
    { label: "Distribution", value: { "id": 'distribution' } }
  ];
  public ppmTermManagementList = [
    { label: "Investment Management Fee", value: { "id": 'Investment Management Fee' } },
    { label: "Management Fee", value: { "id": 'Management Fee' } }
  ];
  public closingMethodeList = [
    { label: "first-in-first-out (FIFO)", value: { "id": "first-in-first-out (FIFO)" } },
    { label: "last-in-first-out (LIFO)", value: { "id": "last-in-first-out (LIFO)" } },
    { label: "highest-cost-first-out (HCFO)", value: { "id": "highest-cost-first-out (HCFO)" } },
    { label: "lowest-cost-first-out (LCFO)", value: { "id": "lowest-cost-first-out (LCFO)" } },
    { label: "manual-first-in-first-out (MFIFO)", value: { "id": "manual-first-in-first-out (MFIFO)" } },
    { label: "STMAXLOSS", value: { "id": "STMAXLOSS" } }
  ];

  public basisOfPresentationList = [
    { label: "GAAP", value: { "id": "GAAP" } },
    { label: "IFRS", value: { "id": "IFRS" } },
    { label: "IND AS", value: { "id": "IND AS" } }
  ];
  public fundStructureList = [
    { label: "Master", value: { "id": "Master" } },
    { label: "Feeder", value: { "id": "Feeder" } }
  ];
  public dateFormatList = [
    { label: "December 31, 1900", value: "MMMM D, YYYY" },
    { label: "DECEMBER 31, 1900", value: "MMMM D, YYYY.toUpperCase()" },
    { label: "december 31, 1900", value: "MMMM D, YYYY.toLowerCase()" },
    { label: "1900-12-31", value: "YYYY-MM-DD" },
    { label: "1900-31-12", value: "YYYY-DD-MM" },
    { label: "12-31-1900", value: "MM-DD-YYYY" },
    { label: "31-12-1900", value: "DD-MM-YYYY" },
    { label: "1900/12/31", value: "YYYY/MM/DD" },
    { label: "1900/31/12", value: "YYYY/DD/MM" },
    { label: "12/31/1900", value: "MM/DD/YYYY" },
    { label: "31/12/1900", value: "DD/MM/YYYY" },
    { label: "31 December 1900", value: "DD MMMM YYYY" },
    { label: "December 31, 1900", value: "MMMM DD, YYYY" },
    { label: "December 31st 1900", value: "MMMM Do YYYY" },
    { label: "Monday, December 31, 1900", value: "dddd, MMMM DD, YYYY" },
  ];

  public periodType = [
    { label: "Monthly", value: 'monthly' },
    { label: "Quarterly", value: 'quarterly' },
    { label: "Semi-Annual", value: 'semiAnnual' },
    { label: "Annually", value: 'annually' }
  ];
  public selectionList = [
    { label: "Yes", value: 'Yes' },
    { label: "No", value: 'No' }
  ];
  public scheduleList = [
    { label: "All", value: 'all' },
    { label: "Specific", value: 'specific' }
  ];
  public noteModuleList = [];
  public additionalNotesList = [];
  public fundTypeList = [];
  public assetClassList = [];
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  // FS-Notes config FSA-108 autosuggestion keywords
  autoSuggetionsKeyword: any = [
    { name: "fundName", description: "Name of Fund" },
    { name: "entityType", description: "What the entity is called." },
    { name: "fundJurisdiction", description: "Juridiction under which the Fund is organized." },
    { name: "incorporationDate", description: "Date at which the fund was incorporated." },
    { name: "dateOfCommencedOperations", description: "Date on which the fund started its operations." },
    { name: "generalPartner", description: "Name of the General Partner." },
    { name: "performanceFeeReceiver", description: "The receiver of Performance Fee." },
    { name: "gpJurisdiction", description: "Law authority of the country in which the company is formed." },
    { name: "investmentManager", description: "Name of the Investment Manager." },
    { name: "investmentObjective", description: "The Objective on which the fund started its operations." },
    { name: "startDate", description: "Start Date of the financial as selected by user." },
    { name: "endDate", description: "End Date as per user selection." },
    { name: "cash", description: "Total Cash for the period." },
    { name: "managementFeeReceiver", description: "The receiver of Management Fee." },
    { name: "warrants", description: "Total Warrants in the period." },
    { name: "realisationMethod", description: "The accounting process of assigning value to a fund's inventory." },
    { name: "securitiesShort", description: "Securities sold in the open market." },
    { name: "dueFromBroker", description: "Total Amount due from Broker for the selected  period." },
    { name: "subscriptionYear", description: "Year of Subscription." },
    { name: "subscription", description: "Total Subscription received for the period." },
    { name: "effectiveDate", description: "Effective Date for Subscription." },
    { name: "managementFeesAnnualRate", description: "Annual rate of Management fee." },
    { name: "managementFeesFrequency", description: "Frequency on which the management fee will be charged." },
    { name: "monthlyQuarterlyRate", description: "Rate at which management fee will be paid on monthly or quarterly basis." },
    { name: "managementFees", description: "Total Management Fee for the selected period." },
    { name: "performanceClause", description: "How Performance is expressed in the fund." },
    { name: "performanceFeesRate", description: "Rate at which Performance Fee will be charged." },
    { name: "affiliatedMembersCapital", description: "Capital of Minority Share Holders of the fund." },
    { name: "administrator", description: "Name of Fund Administrator." },
    { name: "subsequentEventsPeriodThrough", description: "Effective Date for Subsequent Event." },
    { name: "subsequentEventsPeriodFrom", description: "Date of Entry of Subsequent Event." },
    { name: "additionalCapitalContributions", description: "Additional capital contribution for the year." },
    { name: "additionClause", description: "How Addition is expressed in the fund." },
    { name: "redemptionClause", description: "How Redemption is expressed in the fund." },
    { name: "minimumRedemptionAmount", description: "Total Minimum Amount that can be redeemed by the investors." },
    { name: "managementFeesPayable", description: "Total Management Fee payable for the period." },
    { name: "performanceFees", description: "Total Performance Fee for the selected  period." },
    { name: "performanceFeesPayable", description: "Total Performance Fee payable for the period." },
    { name: "pricingSource", description: "Source of Financial Information." },
    { name: "pricingSourceSecondery", description: "Source of Financial Information." },
    { name: "redemptions", description: "Total Redemptions in the period." },
    { name: "minimumInitialInvestmentAmount", description: "Minimum Investment needed to be invested." },
    { name: "minimumAdditionalContributionAmount", description: "Minimum Additional Contribution that needed to be contributed in the fund." },
    { name: "inKindContributions", description: "Total non-cash contribution the fund received." },
    { name: "stakedAssets", description: "Income thorugh Rewards." },
    { name: "stakingIncome", description: "Staking income." },
    { name: "sidePocket", description: "Side pocket." },
    { name: "pricingTime", description: "Pricing strategy linking prices to a particular time." },
    { name: "performanceFeesFrequency", description: "Frequency on which the performance fee will be charged." },
    { name: "bankName", description: "Name of the bank." },
    { name: "cashLimit", description: "Cash Limit." },
    { name: "endDatePY", description: "End Date for PY" },
    { name: "endDateCY", description: "End Date for CY" },
    { name: "previousYear", description: "Previous Year for comparative FS" },
    { name: "currentYear", description: "Current Year" },
    { name: "fundSubsidiary1", description: "Subsidiary of the fund." },
    { name: "fundSubsidiary2", description: "Subsidiary of the fund." },
    { name: "subsidiary1FormationDate", description: "Date of Formation of the Subsidiary." },
    { name: "subsdiary1Juridiction", description: "Juridiction under which the subsidiary is organized." },
    { name: "subsidiary2FormationDate", description: "Date of Formation of the Subsidiary." },
    { name: "subsdiary2Juridiction", description: "Juridiction under which the subsidiary is organized." },
    { name: "imFormationDate", description: "Date of Formation of the Investment Manager" },
    { name: "gpFormationDate", description: "Date of Formation of the General Partner" },
    { name: "imJuridiction", description: "Juridiction under which the IM is organized." },
    { name: "managementFeeClause", description: "How Management fee is expressed in the fund." },
    { name: "contributionClause", description: "How contribution is expressed in the fund." },
    { name: "distributionClause", description: "How Distribution is expressed in the fund." },
    { name: "equitySubsciption", description: "Equity subsciption" },
    { name: "slpCapitalCommitment", description: "" },
    { name: "slpCommitmentPercentage", description: "" },
    { name: "totalCommitedCapital", description: "" },
    { name: "accruedIncentiveCY", description: "" },
    { name: "accruedIncentivePY", description: "" },
    { name: "securityDepositsCY", description: "" },
    { name: "securityDepositsPY", description: "" },
    { name: "securityDepositsLiabilityCY", description: "" },
    { name: "securityDepositsLiabilityPY", description: "" },
    { name: "interestExpenseCY", description: "" },
    { name: "interestExpensePY", description: "" },
    { name: "loanFeesCY", description: "" },
    { name: "loanFeesPY", description: "" },
    { name: "organizationalAndOfferingExpensesLimit", description: "" },
    { name: "organizationalAndOfferingExpensesCY", description: "" },
    { name: "organizationalAndOfferingExpensesPY", description: "" },
    { name: "defferedTaxAssetCY", description: "" },
    { name: "defferedTaxAssetPY", description: "" },
    { name: "unrealizedGainCY", description: "" },
    { name: "unrealizedGainPY", description: "" },
    { name: "unrealizedLossCY", description: "" },
    { name: "unrealizedLossPY", description: "" },
    { name: "mortgageNotesCY", description: "" },
    { name: "mortgageNotesPY", description: "" },
    { name: "followOnDebtCY", description: "" },
    { name: "followOnDebtPY", description: "" },
    { name: "managementFeesCY", description: "" },
    { name: "managementFeesPY", description: "" },
    { name: "projectManagementFeeCY", description: "" },
    { name: "projectManagementFeePY", description: "" },
    { name: "investmentAccountingFeeCY", description: "" },
    { name: "investmentAccountingFeePY", description: "" },
    { name: "leasingCommissionsCY", description: "" },
    { name: "leasingCommissionsPY", description: "" },
    { name: "totalContributionsCY", description: "" },
    { name: "totalContributionsPY", description: "" },
    { name: "totalDistributionCY", description: "" },
    { name: "totalDistributionPY", description: "" },
    { name: "outstandingCommitments", description: "" },
    { name: "incometaxYear", description: "current year less 3 years" },
    { name: "distributionPeriod", description: "Frequency at which distribution is done." },
    { name: "relatedParty1", description: "Related Party of the fund." },
    { name: "relatedParty2", description: "Related Party of the fund." },
    { name: "currencySymbol", description: "Fund currency." },
    { name: "minimumCapitalAmount", description: "Minimum Capital to be invested." },
    { name: "expenseBorneByGP", description: "Expenses borne by the General Partner." },
    { name: "additionalCapitalWithdraw", description: "Subsequent note update for capital withdrwal during subsequent period." },
    { name: "additionalCapitalContributionsCY", description: "Additional capital contribution for the selected year." },
    { name: "noticePeriodForWithdrawal", description: "Limited partner is required to give notice before withdrawing amount from its capital account." },
    { name: "lockupPeriodForWithdrawal", description: "Limited partner cannot make withdrawal for certain period from their initial contribution." },
    { name: "periodDate", description: "Financial reporting period date." },
    { name: "period", description: "Financial reporting period/year." },
    { name: "fundStructure", description: "Structure of fund." },
    { name: "masterFundName", description: "Name of Master Fund." },
    { name: "masterFundEntityType", description: "What the Master Fund entity is called." },
    { name: "masterFundJuridiction", description: "Juridiction under which the Master Fund is organized." },
    { name: "capitalClause", description: "How capital is expressed on the basis of entity type." },
    { name: "investorClause", description: "How investor is expressed on the basis of entity type." },
    { name: "sofc_table_image", description: "", plainText: true },
    { name: "soo_table_image", description: "", plainText: true },
    { name: "scna_table_image", description: "", plainText: true },
    { name: "soi_table_image", description: "", plainText: true },
    { name: "cashflow_table_image", description: "", plainText: true },
    { name: "asc_820_table_image", description: "", plainText: true },
    { name: "asc_820_level_3_table_image", description: "", plainText: true },
    { name: "asc_815_table_image", description: "", plainText: true },
    { name: "asc_815_ii_table_image", description: "", plainText: true },
    { name: "fihi_table_image", description: "", plainText: true },
    { name: "fihi_xirr_table_image", description: "", plainText: true },
    { name: "custody_note_table_image", description: "", plainText: true },
  ];

  //suggestion keywords config FS-Notes config FSA-108
  mentionConfig: any = {}
  fsTemplate: any;

  constructor(
    private http: HttpClient,
    private reportService: ReportService,
    private localService: LocalStoreService,
    private messageService: GlobalMessageService,
    private translateService: TranslateService,
    private cookieService: CookieService,
  ) {
    this.mentionConfig = {
      items: this.autoSuggetionsKeyword,
      labelKey: 'name',
      triggerChar: "@",
    }
  }

  setupManagementTabs = [
    // { id: 1, aliasName: "manualFeed", name: 'Manual Feed', isSubmit: false, isDisabled: false, description: 'Primary Setup for Financial Statement', viewRouterlink: "", createRouterlink: '', uploadRouterlink: '', authView: '', authCreate: '', authUpload: '' },
    { id: 1, aliasName: "fundBasic", name: 'Fund Basics', isSubmit: false, isDisabled: false, description: 'About Basic Information relating to the fund', viewRouterlink: "", createRouterlink: '', uploadRouterlink: '', authView: '', authCreate: '', authUpload: '' },
    { id: 2, aliasName: "standingData", name: 'Standing Data', isSubmit: false, isDisabled: true, description: 'Check and Update the details relating to PPM', authView: '', createRouterlink: '', viewRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '' },
    // { id: 3, aliasName: "currentPeriodInformation", name: 'Current Period Information', isSubmit: false, isDisabled: true, description: 'Check and Up date the financial details of the fund', authView: '', createRouterlink: '', viewRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '', },
    { id: 3, aliasName: "scheduleConfiguration", name: 'Schedule Configuration', isSubmit: false, isDisabled: true, description: 'Configure the scheduled Financial Statement', authView: '', createRouterlink: '', viewRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '' },
    { id: 4, aliasName: "notesToAccount", name: 'Notes To Account', isSubmit: false, isDisabled: true, description: 'Configure, Edit Add the notes to account', authView: '', createRouterlink: '', viewRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '' },
    { id: 5, aliasName: "templateConfiguration", name: 'Template Configuration', isSubmit: false, isDisabled: true, description: 'Configure the scheduled Financial Statement', authView: '', createRouterlink: 'investor-entry', viewRouterlink: '', uploadRouterlink: 'yes', authUpload: '', authCreate: '' },
    { id: 6, aliasName: "generateFinancialStatement", name: 'Generate Financial Statement', isSubmit: false, isDisabled: true, description: 'Configure the scheduled Financial Statement', authView: '', createRouterlink: '', viewRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '' },

    // { id:5, aliasName: "investManager", name: 'Preview', isSubmit: false, isDisabled: true, description: 'Preview of the Financial Statement', authView: '', viewRouterlink: '', createRouterlink: 'asset-based-fee', uploadRouterlink: '', authCreate: '', authUplaod: '' },
    // { id:6, aliasName: "managerPerfBasedFee", name: 'Performance Based Fee', isSubmit: false, isDisabled: true, description: 'About Performance Based Fee or anything in details', authView: '', viewRouterlink: '', createRouterlink: 'performance-based-fee', uploadRouterlink: '', authCreate: '', authUplaod: '' },
  ];

  getPrecentageProgress(username: any): Observable<any> {
    return this.http.get('userPerferenceV2/searchV2?view=ProgressPercentage&userName=' + username);
  }

  checkSetupProgress(): Observable<any> {
    return this.http.get('enterpriseV2/checkSetupProgress');
  }

  basicDetails() {
    return {
      basicForm1: [
        { id: 'fundId', type: [], controlType: 2, label: 'fund', viewname: 'fundId', name: 'fundId', order: 1, value: '', required: true, appearance: "fill", fill: this.fundList, readonly: false, class: "col-lg-6 mb-c" },
        { id: 'fundType', type: [], controlType: 2, label: 'type_of_fund', viewname: 'fundType', name: 'fundType', order: 2, value: '', required: true, appearance: "fill", fill: this.fundTypeList, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
        { id: 'startDate', type: [], controlType: 3, label: 'start_date', viewname: 'startDate', name: 'startDate', order: 3, value: this.reportStartDate, required: true, appearance: "fill", class: "col-lg-6 mb-c" },
        { id: 'endDate', type: ['Management Company', 'Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 3, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 4, value: this.reportEndDate, required: true, appearance: "fill", class: "col-lg-6 mb-c" },

      ],

      basicForm2: [
        { id: 'basisOfPresentation', type: [], controlType: 2, label: 'presentation_type', viewname: 'basisOfPresentation', name: 'basisOfPresentation', order: 1, value: '', required: true, appearance: "fill", fill: this.basisOfPresentationList, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
        { id: 'fundStructure', type: ['Feeder Fund'], controlType: 2, label: 'fund_structure', viewname: 'fundStructure', name: 'fundStructure', order: 1, value: '', required: false, appearance: "fill", fill: this.fundStructureList, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
        { id: 'masterFund', type: ['Feeder Fund'], controlType: 2, label: 'master_fund_fs', viewname: 'masterFund', name: 'masterFund', order: 1, value: '', required: false, appearance: "fill", fill: this.fundList, readonly: false, class: "col-lg-6 mb-c" },
        //RE
        { id: 'endDateCY', type: ['Real Estate Fund'], controlType: 3, label: 'end_date_cy', viewname: 'endDateCY', name: 'endDateCY', order: 3, value: this.reportEndDate, required: true, appearance: "fill", class: "col-lg-6 mb-c" },
        { id: 'endDatePY', type: ['Real Estate Fund'], controlType: 3, label: 'end_date_py', viewname: 'endDatePY', name: 'endDatePY', order: 3, value: null, required: false, appearance: "fill", class: "col-lg-6 mb-c" },
        { id: 'currentYear', type: ['Real Estate Fund'], controlType: 17, label: 'current_year', viewname: 'currentYear', name: 'currentYear', order: 3, value: null, required: false, appearance: "fill", class: "col-lg-6 fs-datepicker" },
        { id: 'previousYear', type: ['Real Estate Fund'], controlType: 17, label: 'previous_year', viewname: 'previousYear', name: 'previousYear', order: 3, value: null, required: false, appearance: "fill", class: "col-lg-6 fs-datepicker" },
        { id: 'incometaxYear', type: ['Real Estate Fund'], controlType: 17, label: 'incometax_ear', viewname: 'incometaxYear', name: 'incometaxYear', order: 3, value: null, required: false, appearance: "fill", class: "col-lg-6 fs-datepicker" },
        //RE
        { id: 'dateOfCommencedOperations', type: [], controlType: 3, label: 'date_of_commenced_operations', viewname: 'dateOfCommencedOperations', name: 'dateOfCommencedOperations', order: 3, value: null, required: false, appearance: "fill", class: "col-lg-6 mb-c" },
        { id: 'incorporationDate', type: [], controlType: 3, label: 'date_of_formation', viewname: 'incorporationDate', name: 'incorporationDate', order: 3, value: null, required: false, appearance: "fill", class: "col-lg-6 mb-c" },
        { id: 'generalPartner', type: [], controlType: 1, label: 'general_partner', viewname: 'generalPartner', name: 'generalPartner', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
        { id: 'investmentManager', type: [], controlType: 1, label: 'management_company', viewname: 'investmentManager', name: 'investmentManager', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
        { id: 'reportingCurrency', type: [], controlType: 1, label: 'fund_currency', viewname: 'reportingCurrency', name: 'reportingCurrency', order: 1, value: '', required: false, readonly: false, disabled: true, class: "col-lg-6 mb-c" },
        { id: 'currencySymbol', type: [], controlType: 1, label: 'currency_symbol', viewname: 'currencySymbol', name: 'currencySymbol', order: 1, value: '', required: false, readonly: false, disabled: true, class: "col-lg-6 mb-c" },
        { id: 'currencySymbolAlias', type: [], controlType: 1, label: 'currency_symbol_alias', viewname: 'currencySymbolAlias', name: 'currencySymbolAlias', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
        { id: 'administrator', type: [], controlType: 1, label: 'fund_administrator', viewname: 'administrator', name: 'administrator', order: 1, value: 'Formidium Fund Services (formerly Sudrania Fund Services)', required: false, readonly: false, disabled: false, class: "col-lg-12 mb-c" },
        { id: 'periodType', type: [], controlType: 2, label: 'period_type', viewname: 'periodType', name: 'periodType', order: 1, value: '', required: false, appearance: "fill", fill: this.periodType, readonly: false, disabled: false, class: "col-lg-6 mb-c", style: "display:block;"},
        { id: 'comparitiveFS', type: [], controlType: 2, label: 'comparative_fs', viewname: 'comparitiveFS', name: 'comparitiveFS', order: 1, value: '', required: false, appearance: "fill", fill: this.selectionList, readonly: false, disabled: false, class: "col-lg-6 mb-c",style: "display:block;" },
        { id: 'comparitiveFsScheduleApplicability', type: [], controlType: 2, label: 'comparative_fs_schedule_applicability', viewname: 'comparitiveFsScheduleApplicability', name: 'comparitiveFsScheduleApplicability', order: 1, value: '', required: false, appearance: "fill", fill: this.scheduleList, showVisibility: false, readonly: false, disabled: false, class: "col-lg-6 mb-c",style: "display:block;" },
        { id: 'comparitiveStartDate', type: [], controlType: 3, label: 'comparative_start_date', viewname: 'comparitiveStartDate', name: 'comparitiveStartDate', order: 3, value: null, required: false, appearance: "fill", class: "col-lg-6 mb-c", style: "display:block;" },
        { id: 'comparitiveEndDate', type: [], controlType: 3, label: 'comparative_end_date', viewname: 'comparitiveEndDate', name: 'comparitiveEndDate', order: 3, value: null, required: false, appearance: "fill", class: "col-lg-6 mb-c", style: "display:block;" },
      ]
    }
  }

  ppmDetails() {
    return [
      { id: 'fundJurisdiction', type: [], controlType: 31, label: 'company_formed_under_the_law_of', viewname: 'fundJurisdiction', name: 'fundJurisdiction', order: 1, value: this.companyFormedUnderTheLawOfList[0].value, required: false, appearance: "fill", fill: this.companyFormedUnderTheLawOfList, readonly: false, disabled: false, showAddButton: true, addType: 'selection', class: "col-lg-6 mb-c" },
      { id: 'masterFundJuridiction', type: ['Feeder Fund'], controlType: 31, label: 'master_fund_company_formed_under_the_law_of', viewname: 'masterFundJuridiction', name: 'masterFundJuridiction', order: 1, value: this.companyFormedUnderTheLawOfList[0].value, required: false, appearance: "fill", fill: this.companyFormedUnderTheLawOfList, readonly: false, disabled: false, showAddButton: true, addType: 'selection', class: "col-lg-6 mb-c" },
      { id: 'gpJurisdiction', type: [], controlType: 1, label: 'gp_Jurisdiction', viewname: 'gpJurisdiction', name: 'gpJurisdiction', order: 2, value: '', required: false, disabled: false, readonly: false, class: "col-lg-6 mb-c" },
      //RE
      { id: 'gpFormationDate', type: [], controlType: 3, label: 'gp_formation_date', viewname: 'gpFormationDate', name: 'gpFormationDate', order: 3, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'imJuridiction', type: [], controlType: 31, label: 'im_juridiction', viewname: 'imJuridiction', name: 'imJuridiction', order: 1, value: this.companyFormedUnderTheLawOfList[0].value, required: false, appearance: "fill", fill: this.companyFormedUnderTheLawOfList, readonly: false, disabled: false, showAddButton: true, addType: 'selection', class: "col-lg-6 mb-c" },
      { id: 'imFormationDate', type: [], controlType: 3, label: 'im_formation_date', viewname: 'imFormationDate', name: 'imFormationDate', order: 5, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'fundSubsidiary1', type: ['Real Estate Fund'], controlType: 1, label: 'fund_subsidiary1', viewname: 'fundSubsidiary1', name: 'fundSubsidiary1', order: 4, value: '', required: false, disabled: false, readonly: false, class: "col-lg-6 mb-c" },
      { id: 'subsidiary1FormationDate', type: ['Real Estate Fund'], controlType: 3, label: 'subsidiary1_formation_date', viewname: 'subsidiary1FormationDate', name: 'subsidiary1FormationDate', order: 3, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'subsdiary1Juridiction', type: ['Real Estate Fund'], controlType: 31, label: 'subsdiary1_juridiction', viewname: 'subsdiary1Juridiction', name: 'subsdiary1Juridiction', order: 1, value: this.companyFormedUnderTheLawOfList[0].value, required: false, appearance: "fill", fill: this.companyFormedUnderTheLawOfList, readonly: false, disabled: false, showAddButton: true, addType: 'selection', class: "col-lg-6 mb-c" },
      { id: 'fundSubsidiary2', type: ['Real Estate Fund'], controlType: 1, label: 'fund_subsidiary2', viewname: 'fundSubsidiary2', name: 'fundSubsidiary2', order: 4, value: '', required: false, disabled: false, readonly: false, class: "col-lg-6 mb-c" },
      { id: 'subsidiary2FormationDate', type: ['Real Estate Fund'], controlType: 3, label: 'subsidiary2_formation_date', viewname: 'subsidiary2FormationDate', name: 'subsidiary2FormationDate', order: 3, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'subsdiary2Juridiction', type: ['Real Estate Fund'], controlType: 31, label: 'subsdiary2_juridiction', viewname: 'subsdiary2Juridiction', name: 'subsdiary2Juridiction', order: 1, value: this.companyFormedUnderTheLawOfList[0].value, required: false, appearance: "fill", fill: this.companyFormedUnderTheLawOfList, readonly: false, disabled: false, showAddButton: true, addType: 'selection', class: "col-lg-6 mb-c" },
      //RE
      { id: 'entityType', type: [], controlType: 31, label: 'ppm_term_for_entity', viewname: 'entityType', name: 'entityType', order: 1, value: this.PPMTermForEntityList[0].value, required: false, appearance: "fill", fill: this.PPMTermForEntityList, readonly: false, disabled: false, showAddButton: true, addType: 'selection', class: "col-lg-6 mb-c" },
      { id: 'masterFundEntityType', type: ['Feeder Fund'], controlType: 31, label: 'master_fund_entity_type', viewname: 'masterFundEntityType', name: 'masterFundEntityType', order: 1, value: this.PPMTermForEntityList[0].value, required: false, appearance: "fill", fill: this.PPMTermForEntityList, readonly: false, disabled: false, showAddButton: true, addType: 'selection', class: "col-lg-6 mb-c" },
      { id: 'investmentObjective', type: [], controlType: 1, label: 'investment_objective', viewname: 'investmentObjective', name: 'investmentObjective', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'managementFeeReceiver', type: [], controlType: 31, label: 'who_will_receive_management_fees', viewname: 'managementFeeReceiver', name: 'managementFeeReceiver', order: 1, value: this.managementList[0].value, required: false, appearance: "fill", fill: this.managementList, readonly: false, disabled: false, showAddButton: true, addType: 'selection', class: "col-lg-6 mb-c" },
      { id: 'managementFeesAnnualRate', type: [], controlType: 5, label: 'management_fee_annual_rate', viewname: 'managementFeesAnnualRate', name: 'managementFeesAnnualRate', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'monthlyQuarterlyRate', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 1, label: 'management_monthly_quarterly_rate', viewname: 'monthlyQuarterlyRate', name: 'monthlyQuarterlyRate', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'managementFeesFrequency', type: [], controlType: 31, label: 'management_fees_frequency', viewname: 'managementFeesFrequency', name: 'managementFeesFrequency', order: 1, value: this.performanceFeesFrequencyList[0].value, required: false, appearance: "fill", fill: this.performanceFeesFrequencyList, readonly: false, disabled: false, showAddButton: true, addType: 'selection', class: "col-lg-6 mb-c" },
      { id: 'performanceFeeReceiver', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 31, label: 'who_will_receive_performance_fees', viewname: 'performanceFeeReceiver', name: 'performanceFeeReceiver', order: 1, value: this.performanceList[0].value, required: false, appearance: "fill", fill: this.performanceList, readonly: false, disabled: false, showAddButton: true, addType: 'selection', class: "col-lg-6 mb-c" },
      { id: 'performanceFeesRate', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 5, label: 'performance_fees_rate', viewname: 'performanceFeesRate', name: 'performanceFeesRate', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'performanceFeesFrequency', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 31, label: 'performance_fees_frequency', viewname: 'performanceFeesFrequency', name: 'performanceFeesFrequency', order: 1, value: this.performanceFeesFrequencyList[0].value, required: false, appearance: "fill", fill: this.performanceFeesFrequencyList, readonly: false, disabled: false, showAddButton: true, addType: 'selection', class: "col-lg-6 mb-c" },
      { id: 'performanceClause', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 31, label: 'ppm_term', viewname: 'performanceClause', name: 'performanceClause', order: 1, value: { id: 'Incentive Allocation' }, required: false, appearance: "fill", fill: this.ppmTermList, readonly: false, disabled: false, showAddButton: true, addType: 'selection', class: "col-lg-6 mb-c" },
      { id: 'additionClause', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 31, label: 'ppm_term_for_addition', viewname: 'additionClause', name: 'additionClause', order: 1, value: { id: 'Subscription' }, required: false, appearance: "fill", fill: this.ppmTermAdditionList, readonly: false, disabled: false, showAddButton: true, addType: 'selection', class: "col-lg-6 mb-c" },
      { id: 'redemptionClause', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 31, label: 'ppm_term_for_redemption', viewname: 'redemptionClause', name: 'redemptionClause', order: 1, value: { id: 'Redemption' }, required: false, appearance: "fill", fill: this.ppmTermRedeptionList, readonly: false, disabled: false, showAddButton: true, addType: 'selection', class: "col-lg-6 mb-c" },
      { id: 'capitalClause', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 31, label: 'capital_clause', viewname: 'capitalClause', name: 'capitalClause', order: 1, value: { id: "Partners' capital" }, required: false, appearance: "fill", fill: this.capitalClauseList, readonly: false, disabled: false, showAddButton: true, addType: 'selection', class: "col-lg-6 mb-c" },
      { id: 'investorClause', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 31, label: 'investor_clause', viewname: 'investorClause', name: 'investorClause', order: 1, value: { id: "Limited partners" }, required: false, appearance: "fill", fill: this.investorClauseList, readonly: false, disabled: false, showAddButton: true, addType: 'selection', class: "col-lg-6 mb-c" },
      { id: 'minimumInitialInvestmentAmount', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 5, label: 'minimum_initial_investment_amount', viewname: 'minimumInitialInvestmentAmount', name: 'minimumInitialInvestmentAmount', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'minimumAdditionalContributionAmount', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 5, label: 'minimum_additional_contribution_amount', viewname: 'minimumAdditionalContributionAmount', name: 'minimumAdditionalContributionAmount', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'minimumRedemptionAmount', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 5, label: 'minimum_withdrawal_amount', viewname: 'minimumRedemptionAmount', name: 'minimumRedemptionAmount', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'minimumCapitalAmount', type: ['Crypto Fund'], controlType: 5, label: 'minimum_capital_amount', viewname: 'minimumCapitalAmount', name: 'minimumCapitalAmount', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      //{ id: 'sooGroup', type: ['Private Equity Fund', 'Hedge Fund','Management Company',, 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 31, label: 'soo_group', viewname: 'sooGroup', name: 'sooGroup', order: 1, value: null, required: false, appearance: "fill", fill: this.sooGroupList, readonly: false, disabled: false, showAddButton: true, addType: 'selection', class: "col-lg-6 mb-c" },
      // { id: 'expenseBorneByGP', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund','Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 5, label: 'expense_borne_by_gp', viewname: 'expenseBorneByGP', name: 'expenseBorneByGP', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'additionalCapitalWithdraw', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 5, label: 'additional_capital_withdraw', viewname: 'additionalCapitalWithdraw', name: 'additionalCapitalWithdraw', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'additionalCapitalContributionsCY', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 5, label: 'additional_capital_contribution_cy', viewname: 'additionalCapitalContributionsCY', name: 'additionalCapitalContributionsCY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'noticePeriodForWithdrawal', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 1, label: 'notice_period_for_withdrawal', viewname: 'noticePeriodForWithdrawal', name: 'noticePeriodForWithdrawal', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'lockupPeriodForWithdrawal', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 1, label: 'lockup_period_for_withdrawal', viewname: 'lockupPeriodForWithdrawal', name: 'lockupPeriodForWithdrawal', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'realisationMethod', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 31, label: 'realisation_method', viewname: 'realisationMethod', name: 'realisationMethod', order: 1, value: { id: 'FIFO' }, required: false, appearance: "fill", fill: this.closingMethodeList, readonly: false, disabled: false, showAddButton: true, addType: 'selection', class: "col-lg-6 mb-c" },
      // { id: 'subsequentEventsPeriodFrom', type: [], controlType: 3, label: 'subsequent_events_period_from', viewname: 'subsequentEventsPeriodFrom', name: 'subsequentEventsPeriodFrom', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'subsequentEventsPeriodThrough', type: [], controlType: 3, label: 'subsequent_events_period_through', viewname: 'subsequentEventsPeriodThrough', name: 'subsequentEventsPeriodThrough', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      //RE
      { id: 'contributionClause', type: ['Real Estate Fund'], controlType: 31, label: 'contribution_clause', viewname: 'contributionClause', name: 'contributionClause', order: 1, value: { id: 'Subscription' }, required: false, appearance: "fill", fill: this.ppmTermAdditionList, readonly: false, disabled: false, showAddButton: true, addType: 'selection', class: "col-lg-6 mb-c" },
      { id: 'managementFeeClause', type: ['Real Estate Fund'], controlType: 31, label: 'management_fee_clause', viewname: 'managementFeeClause', name: 'managementFeeClause', order: 1, value: { id: 'Management Fee' }, required: false, appearance: "fill", fill: this.ppmTermManagementList, readonly: false, disabled: false, showAddButton: true, addType: 'selection', class: "col-lg-6 mb-c" },
      { id: 'distributionClause', type: ['Real Estate Fund'], controlType: 31, label: 'distribution_clause', viewname: 'distributionClause', name: 'distributionClause', order: 1, value: { id: 'Redemption' }, required: false, appearance: "fill", fill: this.ppmTermDistributionList, readonly: false, disabled: false, showAddButton: true, addType: 'selection', class: "col-lg-6 mb-c" },
      { id: 'distributionPeriod', type: ['Real Estate Fund'], controlType: 31, label: 'distribution_period', viewname: 'distributionPeriod', name: 'distributionPeriod', order: 1, value: { id: 'Monthly' }, required: false, appearance: "fill", fill: this.distributionPeriodList, readonly: false, disabled: false, showAddButton: true, addType: 'selection', class: "col-lg-6 mb-c" },
      { id: 'relatedParty1', type: ['Real Estate Fund'], controlType: 1, label: 'related_party1', viewname: 'relatedParty1', name: 'relatedParty1', order: 2, value: '', required: false, disabled: false, readonly: false, class: "col-lg-6 mb-c" },
      { id: 'relatedParty2', type: ['Real Estate Fund'], controlType: 1, label: 'related_party2', viewname: 'relatedParty2', name: 'relatedParty2', order: 2, value: '', required: false, disabled: false, readonly: false, class: "col-lg-6 mb-c" },
      //RE
      { id: 'bankName', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 1, label: 'bank_name', viewname: 'bankName', name: 'bankName', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'pricingSource', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 1, label: 'pricing_source_primary', viewname: 'pricingSource', name: 'pricingSource', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'pricingSourceSecondery', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 1, label: 'pricing_source_secondery', viewname: 'pricingSourceSecondery', name: 'pricingSourceSecondery', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'pricingTime', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 24, label: 'pricing_time', viewname: 'pricingTime', name: 'pricingTime', order: 1, value: this.pricingTime, required: false, readonly: false, disabled: false, appearance: "fill", class: "col-lg-6 timestamp", style: "padding-left: 5px;" },
      // { id: 'assetClass', type: [], controlType: 7, label: 'Asset Class', name: 'assetClass', viewName: 'assetClass', order: 2, value: [], required: false, fill: this.assetClassList, appearance: "fill", readonly: false, disabled: false, class: "col-lg-6 pl-5 pt-0 pr-1" }
    ];
  }

  financialDetails() {
    return [
      { id: 'affiliatedMembersCapital', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund', 'Real Estate Fund'], controlType: 5, label: 'aggregate_value_of_affiliated_partners_equity', viewname: 'affiliatedMembersCapital', name: 'affiliatedMembersCapital', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'inKindContributions', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund', 'Real Estate Fund'], controlType: 5, label: 'in_kind_contributions', viewname: 'inKindContributions', name: 'inKindContributions', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'cashLimit', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund', 'Real Estate Fund'], controlType: 5, label: 'cash_limit', viewname: 'cashLimit', name: 'cashLimit', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'sidePocket', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund', 'Real Estate Fund'], controlType: 5, label: 'side_pocket_capital_balance_p_end', viewname: 'sidePocket', name: 'sidePocket', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'stakingIncome', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund', 'Real Estate Fund'], controlType: 5, label: 'staked_income_amount_P_end', viewname: 'stakingIncome', name: 'stakingIncome', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'stakedAssets', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund', 'Real Estate Fund'], controlType: 5, label: 'staked_assets', viewname: 'stakedAssets', name: 'stakedAssets', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      //RE
      // { id: 'equitySubsciption', type: ['Real Estate Fund'], controlType: 5, label: 'equity_subsciption', viewname: 'equitySubsciption', name: 'equitySubsciption', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'slpCapitalCommitment', type: ['Real Estate Fund'], controlType: 5, label: 'slp_capital_commitment', viewname: 'slpCapitalCommitment', name: 'slpCapitalCommitment', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'slpCommitmentPercentage', type: ['Real Estate Fund'], controlType: 5, label: 'slp_commitment_percentage', viewname: 'slpCommitmentPercentage', name: 'slpCommitmentPercentage', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'totalCommitedCapital', type: ['Real Estate Fund'], controlType: 5, label: 'total_commited_capital', viewname: 'totalCommitedCapital', name: 'totalCommitedCapital', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'accruedIncentiveCY', type: ['Real Estate Fund'], controlType: 5, label: 'accrued_incentive_CY', viewname: 'accruedIncentiveCY', name: 'accruedIncentiveCY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'accruedIncentivePY', type: ['Real Estate Fund'], controlType: 5, label: 'accrued_incentive_PY', viewname: 'accruedIncentivePY', name: 'accruedIncentivePY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'securityDepositsCY', type: ['Real Estate Fund'], controlType: 5, label: 'security_deposits_CY', viewname: 'securityDepositsCY', name: 'securityDepositsCY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'securityDepositsPY', type: ['Real Estate Fund'], controlType: 5, label: 'security_deposits_PY', viewname: 'securityDepositsPY', name: 'securityDepositsPY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'securityDepositsLiabilityCY', type: ['Real Estate Fund'], controlType: 5, label: 'security_deposits_liability_CY', viewname: 'securityDepositsLiabilityCY', name: 'securityDepositsLiabilityCY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'securityDepositsLiabilityPY', type: ['Real Estate Fund'], controlType: 5, label: 'security_deposits_liability_PY', viewname: 'securityDepositsLiabilityPY', name: 'securityDepositsLiabilityPY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'interestExpenseCY', type: ['Real Estate Fund'], controlType: 5, label: 'interest_expense_CY', viewname: 'interestExpenseCY', name: 'interestExpenseCY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'interestExpensePY', type: ['Real Estate Fund'], controlType: 5, label: 'interest_expense_PY', viewname: 'interestExpensePY', name: 'interestExpensePY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'loanFeesCY', type: ['Real Estate Fund'], controlType: 5, label: 'loan_fees_CY', viewname: 'loanFeesCY', name: 'loanFeesCY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'loanFeesPY', type: ['Real Estate Fund'], controlType: 5, label: 'loan_fees_PY', viewname: 'loanFeesPY', name: 'loanFeesPY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'organizationalAndOfferingExpensesLimit', type: ['Real Estate Fund'], controlType: 5, label: 'organizational_and_offering_expenses_limit', viewname: 'organizationalAndOfferingExpensesLimit', name: 'organizationalAndOfferingExpensesLimit', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'organizationalAndOfferingExpensesCY', type: ['Real Estate Fund'], controlType: 5, label: 'organizational_and_offering_expenses_CY', viewname: 'organizationalAndOfferingExpensesCY', name: 'organizationalAndOfferingExpensesCY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'organizationalAndOfferingExpensesPY', type: ['Real Estate Fund'], controlType: 5, label: 'organizational_and_offering_expenses_PY', viewname: 'organizationalAndOfferingExpensesPY', name: 'organizationalAndOfferingExpensesPY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'defferedTaxAssetCY', type: ['Real Estate Fund'], controlType: 5, label: 'deffered_tax_asset_CY', viewname: 'defferedTaxAssetCY', name: 'defferedTaxAssetCY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'defferedTaxAssetPY', type: ['Real Estate Fund'], controlType: 5, label: 'deffered_tax_asset_PY', viewname: 'defferedTaxAssetPY', name: 'defferedTaxAssetPY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'unrealizedGainCY', type: ['Real Estate Fund'], controlType: 5, label: 'unrealized_gain_CY', viewname: 'unrealizedGainCY', name: 'unrealizedGainCY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'unrealizedGainPY', type: ['Real Estate Fund'], controlType: 5, label: 'unrealized_gain_PY', viewname: 'unrealizedGainPY', name: 'unrealizedGainPY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'unrealizedLossCY', type: ['Real Estate Fund'], controlType: 5, label: 'unrealized_loss_CY', viewname: 'unrealizedLossCY', name: 'unrealizedLossCY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'unrealizedLossPY', type: ['Real Estate Fund'], controlType: 5, label: 'unrealized_loss_PY', viewname: 'unrealizedLossPY', name: 'unrealizedLossPY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'mortgageNotesCY', type: ['Real Estate Fund'], controlType: 5, label: 'mortgage_notes_CY', viewname: 'mortgageNotesCY', name: 'mortgageNotesCY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'mortgageNotesPY', type: ['Real Estate Fund'], controlType: 5, label: 'mortgage_notes_PY', viewname: 'mortgageNotesPY', name: 'mortgageNotesPY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'followOnDebtCY', type: ['Real Estate Fund'], controlType: 5, label: 'follow_on_debt_CY', viewname: 'followOnDebtCY', name: 'followOnDebtCY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'followOnDebtPY', type: ['Real Estate Fund'], controlType: 5, label: 'follow_on_debt_PY', viewname: 'followOnDebtPY', name: 'followOnDebtPY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'managementFeeCY', type: ['Real Estate Fund'], controlType: 5, label: 'management_fee_CY', viewname: 'managementFeeCY', name: 'managementFeeCY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'managementFeePY', type: ['Real Estate Fund'], controlType: 5, label: 'management_fee_PY', viewname: 'managementFeePY', name: 'managementFeePY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'projectManagementFeeCY', type: ['Real Estate Fund'], controlType: 5, label: 'project_management_fee_CY', viewname: 'projectManagementFeeCY', name: 'projectManagementFeeCY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'projectManagementFeePY', type: ['Real Estate Fund'], controlType: 5, label: 'project_management_fee_PY', viewname: 'projectManagementFeePY', name: 'projectManagementFeePY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'investmentAccountingFeeCY', type: ['Real Estate Fund'], controlType: 5, label: 'investment_accounting_fee_CY', viewname: 'investmentAccountingFeeCY', name: 'investmentAccountingFeeCY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'investmentAccountingFeePY', type: ['Real Estate Fund'], controlType: 5, label: 'investment_accounting_fee_PY', viewname: 'investmentAccountingFeePY', name: 'investmentAccountingFeePY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'leasingCommissionsCY', type: ['Real Estate Fund'], controlType: 5, label: 'leasing_commissions_CY', viewname: 'leasingCommissionsCY', name: 'leasingCommissionsCY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'leasingCommissionsPY', type: ['Real Estate Fund'], controlType: 5, label: 'leasing_commissions_PY', viewname: 'leasingCommissionsPY', name: 'leasingCommissionsPY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'totalContributionsCY', type: ['Real Estate Fund'], controlType: 5, label: 'total_contributions_CY', viewname: 'totalContributionsCY', name: 'totalContributionsCY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'totalContributionsPY', type: ['Real Estate Fund'], controlType: 5, label: 'total_contributions_PY', viewname: 'totalContributionsPY', name: 'totalContributionsPY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'totalDistributionCY', type: ['Real Estate Fund'], controlType: 5, label: 'total_distribution_CY', viewname: 'totalDistributionCY', name: 'totalDistributionCY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'totalDistributionPY', type: ['Real Estate Fund'], controlType: 5, label: 'total_distribution_PY', viewname: 'totalDistributionPY', name: 'totalDistributionPY', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // { id: 'outstandingCommitments', type: ['Real Estate Fund'], controlType: 5, label: 'outstanding_commitments', viewname: 'outstandingCommitments', name: 'outstandingCommitments', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      // //RE
    ];
  }

  noteDetails() {
    return [
      { id: 'notemodule', controlType: 2, label: 'group', viewname: 'notemodule', name: 'notemodule', order: 1, value: null, required: false, appearance: "fill", fill: this.noteModuleList, readonly: false, style: "display:none;", class: "col-lg-12" },
      { id: 'noteTitle', controlType: 1, label: 'title', viewname: 'noteTitle', name: 'noteTitle', order: 2, value: '', required: false, readonly: false, class: "col-lg-12", style: "display:none;" },
      { id: 'noteTitleHtml', controlType: 1, label: 'title', viewname: 'noteTitleHtml', name: 'noteTitleHtml', order: 2, value: '', required: false, readonly: false, class: "col-lg-12", style: "display:none;" },
      { id: 'noteContent', controlType: 15, label: 'description', viewname: 'noteContent', name: 'noteContent', order: 3, value: '', required: false, mention: true, mentionConfig: this.mentionConfig, readonly: false, style: "display:none;", class: "col-lg-12 text-area" }
    ];
  }

  noteBasicDetails() {
    return [
      { id: 'notesHeaderName', controlType: 1, label: 'notes_to_accoount_alias', viewname: 'notesHeaderName', name: 'notesHeaderName', order: 2, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6" },
      { id: 'dateformat', controlType: 2, label: 'date_format', viewname: 'dateformat', name: 'dateformat', order: 1, value: null, required: false, appearance: "fill", fill: this.dateFormatList, readonly: false, class: "col-lg-6" },
      { id: 'templateName', type: [], controlType: 2, label: 'template', viewname: 'templateName', name: 'templateName', order: 5, value: 'Default Template', fill: this.templateTypeList, required: false, template: null, readonly: true, disabled: false, showDelButton: false, showDownloadButton: true, class: "col-lg-6 mb-c", style: "display:none;" },
      { id: 'template', type: [], controlType: 53, label: '', viewname: 'template', name: 'template', order: 6, value: '', required: false, readonly: false, upload: true, accept: ".docx", appearance: "fill", class: "col-lg-6 Rightwriting_attachment", style: "display:none; padding-bottom: 26px;" },
      { id: 'affiliatedMembersCapital', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund', 'Real Estate Fund'], controlType: 5, label: 'aggregate_value_of_affiliated_partners_equity', viewname: 'affiliatedMembersCapital', name: 'affiliatedMembersCapital', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'inKindContributions', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund', 'Real Estate Fund'], controlType: 5, label: 'in_kind_contributions', viewname: 'inKindContributions', name: 'inKindContributions', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'cashLimit', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund', 'Real Estate Fund'], controlType: 5, label: 'cash_limit', viewname: 'cashLimit', name: 'cashLimit', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'sidePocket', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund', 'Real Estate Fund'], controlType: 5, label: 'side_pocket_capital_balance_p_end', viewname: 'sidePocket', name: 'sidePocket', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'stakingIncome', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund', 'Real Estate Fund'], controlType: 5, label: 'staked_income_amount_P_end', viewname: 'stakingIncome', name: 'stakingIncome', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'stakedAssets', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Management Company', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund', 'Real Estate Fund'], controlType: 5, label: 'staked_assets', viewname: 'stakedAssets', name: 'stakedAssets', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'addNotesToAccount', controlType: 9, label: 'Show Notes', viewname: 'addNotesToAccount', name: 'addNotesToAccount', order: 1, value: true, required: false, disabled: false, readonly: false, class: "col-lg-6 mb-c" },
    ];
  }


  comparativeFsScheduleDetails() {
    return [
      { id: 'specificStartDate', controlType: 3, label: 'start_date', viewname: 'specificStartDate', name: 'specificStartDate', order: 3, value: null, required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },
      { id: 'specificEndDate', controlType: 3, label: 'end_date', viewname: 'specificEndDate', name: 'specificEndDate', order: 3, value: null, required: false, readonly: false, disabled: false, class: "col-lg-6 mb-c" },

      { id: 'sofc', controlType: 9, label: 'sofc_tile', viewname: 'sofc', name: 'sofc', order: 1, value: false, required: false, disabled: false, readonly: false, class: "col-lg-4 mb-c" },

      { id: 'soi', controlType: 9, label: 'schedule_of_investments', viewname: 'soi', name: 'soi', order: 1, value: false, required: false, disabled: false, readonly: false, class: "col-lg-4 mb-c" },

      { id: 'soo', controlType: 9, label: 'soo_tile', viewname: 'soo', name: 'soo', order: 1, value: false, required: false, disabled: false, readonly: false, class: "col-lg-4 mb-c" },

      { id: 'scna', controlType: 9, label: 'scna_tile', viewname: 'scna', name: 'scna', order: 1, value: false, required: false, disabled: false, readonly: false, class: "col-lg-4 mb-c" },

      { id: 'cashFlow', controlType: 9, label: 'cashflow_tile', viewname: 'cashFlow', name: 'cashFlow', order: 1, value: false, required: false, disabled: false, readonly: false, class: "col-lg-4 mb-c" },

      { id: 'asc820', controlType: 9, label: 'asc820', viewname: 'asc820', name: 'asc820', order: 1, value: false, required: false, disabled: false, readonly: false, class: "col-lg-4 mb-c" },

      { id: 'asc820Level3', controlType: 9, label: 'asc820Level3', viewname: 'asc820Level3', name: 'asc820Level3', order: 1, value: false, required: false, disabled: false, readonly: false, class: "col-lg-4 mb-c" },

      { id: 'asc815', controlType: 9, label: 'asc815', viewname: 'asc815', name: 'asc815', order: 1, value: false, required: false, disabled: false, readonly: false, class: "col-lg-4 mb-c" },

      { id: 'asc815_II', controlType: 9, label: 'asc815_II', viewname: 'asc815_II', name: 'asc815_II', order: 1, value: false, required: false, disabled: false, readonly: false, class: "col-lg-4 mb-c" },

      { id: 'investmentMaster', controlType: 9, label: 'investment_in_master_title', viewname: 'investmentMaster', name: 'investmentMaster', order: 1, value: false, required: false, disabled: false, readonly: false, class: "col-lg-4 mb-c" },

      { id: 'shareRegister', controlType: 9, label: 'share_register_tile', viewname: 'shareRegister', name: 'shareRegister', order: 1, value: false, required: false, disabled: false, readonly: false, class: "col-lg-4 mb-c" },

      { id: 'shareCapital', controlType: 9, label: 'capital_roll_forward_title', viewname: 'shareCapital', name: 'shareCapital', order: 1, value: false, required: false, disabled: false, readonly: false, class: "col-lg-4 mb-c" },

      { id: 'custodyNote', controlType: 9, label: 'custody_note', viewname: 'custodyNote', name: 'custodyNote', order: 1, value: false, required: false, disabled: false, readonly: false, class: "col-lg-4 mb-c" },

      { id: 'fihi', controlType: 9, label: 'fihi_tile', viewname: 'fihi', name: 'fihi', order: 1, value: false, required: false, disabled: false, readonly: false, class: "col-lg-4 mb-c" },
    ]
  }

  getformControlsDetail(flag, gridData) {
    return new Promise(resolve => {
      this.currentUser = JSON.parse(this.localService.getItem('fss'));
      this.reportStartDate = this.reportService.getFilterDateStart(this.currentUser.reportFrequency);
      this.reportStartDate = this.reportStartDate ? this.reportStartDate : new Date(new Date().getFullYear(), 0, 1);
      this.reportEndDate = this.reportService.getFilterDate(this.currentUser.reportFrequency);
      this.reportEndDate = this.reportEndDate ? this.reportEndDate : new Date(new Date().getFullYear(), 11, 31);
      this.effectiveDate = new Date(new Date(this.reportEndDate).valueOf() + 1000 * 60 * 60 * 24);
      if (flag === "create" || flag === "edit") {
        this.fetchMultipleApiBasic(gridData).subscribe((response) => {
          let basicDetailsData = this.basicDetails();
          this.fundListAllData = response[0].resultList;
          //map fund 
          let fund = response[0].resultList && response[0].resultList.length ? response[0].resultList : [];
          this.fundList = fund.map(element => ({ label: element.fundAlias != null ? element.fundAlias : element.fundName, value: { "id": element.id } }));
          basicDetailsData.basicForm1[0].fill = this.fundList;
          basicDetailsData.basicForm2[2].fill = this.fundList;
          //map fundType
          let result = response[1].resultList && response[1].resultList.length ? response[1].resultList : [];
          this.fundTypeList = result.map(element => ({ label: element.fundType, value: { "id": element.id } }));
          basicDetailsData.basicForm1[1].fill = this.fundTypeList;
          if (this.fundTypeList.length) {
            basicDetailsData.basicForm1[1].value = this.fundTypeList[0].value;
            basicDetailsData.basicForm2[0].value = this.basisOfPresentationList[0].value;
          }
          // if (this.fundList.length) {
          //   basicDetailsData[0].value = this.fundList[0].value;
          // }

          //map service
          // basicDetailsData[1].value = new Date(this.reportStartDate);
          if (flag === "edit") {
            basicDetailsData.basicForm1.forEach(element => {
              switch (element.controlType) {
                case (2):
                  if (gridData[element.id] != null) {
                    element.value = { id: gridData[element.id] };
                    if (element.id == 'fundId' || element.id == 'fundType') {
                      element.disabled = true;
                    }
                  } else {
                    element.value = null
                  }
                  break;
                  case (3):
                    element.value = gridData[element.viewname];
                    if (gridData[element.id] != null) {
                      element.value = new Date(element.value)
                      if (element.id == 'startDate' || element.id == 'endDate') {
                        element.disabled = true;
                      }
                    }
                    else {
                      element.value = null
                    }
                    break;
                case (31):
                  let fillData = element.fill || [];
                  if (gridData[element.id] != null) {
                    let selectedData = fillData.filter(data => { return data.value.id == gridData[element.id] });
                    if (!selectedData.length) {
                      let newItem = { label: gridData[element.id], value: { "id": gridData[element.id] } };
                      element.fill.push(newItem);
                    }
                    element.value = { id: gridData[element.id] } as any;
                  } else {
                    element.value = null
                  }
                  break;
                default:
                  element.value = gridData[element.id];
              }
            });
            basicDetailsData.basicForm2.forEach(element => {
              switch (element.controlType) {
                case (2):
                  if (gridData[element.id] != null) {
                    element.value = { id: gridData[element.id] };
                    if (element.id == 'fundId') {
                      element.disabled = true;
                    }
                  } else {
                    element.value = null
                  }
                  break;
                case (3):
                  element.value = gridData[element.id];
                  if (gridData[element.id] != null)
                    element.value = moment(element.value,['ddd MMM DD HH:mm:ss zzz YYYY','ddd MMM DD HH:mm:ss UTC YYYY','YYYY-MM-DD']).format('YYYY-MM-DD')
                  else element.value = null
                  break;
                case (31):
                  let fillData = element.fill || [];
                  if (gridData[element.id] != null) {
                    let selectedData = fillData.filter(data => { return data.value.id == gridData[element.id] });
                    if (!selectedData.length) {
                      let newItem = { label: gridData[element.id], value: { "id": gridData[element.id] } };
                      element.fill.push(newItem);
                    }
                    element.value = { id: gridData[element.id] } as any;
                  } else {
                    element.value = null
                  }
                  break;
                default:
                  element.value = gridData[element.id];
              }
              if (element.id === "periodType") {
                element.value = gridData['periodType']
              } else if (element.id === "comparitiveFS") {
                if (!gridData['comparitiveFS']) {
                  basicDetailsData.basicForm2[18].style = "display:none;"
                  basicDetailsData.basicForm2[19].style = "display:none;"
                  basicDetailsData.basicForm2[20].style = "display:none;"
                }
                element.value =  gridData['comparitiveFS'] ? "Yes" : "No"
              } else if (element.id === "comparitiveFsScheduleApplicability") {
                element.value = gridData['comparitiveFsScheduleApplicability']
                if (gridData['comparitiveFsScheduleApplicability'] == "specific") {
                  basicDetailsData.basicForm2[18].showVisibility = true;
                  basicDetailsData.basicForm2[19].style = "display:none;"
                  basicDetailsData.basicForm2[20].style = "display:none;"
                }
              } else if (element.id === "comparitiveStartDate") {
                element.value = gridData['comparitiveStartDate']
              } else if (element.id === "comparitiveEndDate") {
                element.value = gridData['comparitiveEndDate']
              }
            });

            if (gridData['isnew']) {
              basicDetailsData.basicForm2[0].value = this.basisOfPresentationList[0].value;
            }
            resolve(basicDetailsData);
          } else {
            resolve(basicDetailsData);
          }
        });
      } else {
        this.fetchMultipleApiBasic(gridData).subscribe((response) => {
          let basicDetailsData = this.basicDetails();
          //map fund
          let fund = response[0].resultList && response[0].resultList.length ? response[0].resultList : [];
          this.fundList = fund.map(element => ({ label: element.fundAlias != null ? element.fundAlias : element.fundName, value: { "id": element.id } }));
          //map template
          // let template = response[1] || [];
          // this.templateTypeList = template.map(element => ({ label: element.fileName, value: { "id": element.fileUrl } }));
          basicDetailsData.basicForm1.forEach(element => {
            if (element.id == 'fundId') {
              let fund = this.fundList.filter(fund => fund.value.id == gridData[element.id])[0];
              let fundName = fund?.label || '';
              element.value = fundName;
              // } else if (element.id == 'template') {
              //   let template = this.templateTypeList.filter(template => template.value.id == gridData[element.id])[0];
              //   let templateName = template.label || '';
              //   element.value = templateName;
            } else if (element.id == "fundType") {
              element.value = gridData['fundTypeName'];
            } else {
              element.value = gridData[element.id];
            }

            element.controlType = 1;
            element.disabled = true;
          });
          basicDetailsData.basicForm2.forEach(element => {
            if (element.id == 'fundId') {
              let fund = this.fundList.filter(fund => fund.value.id == gridData[element.id])[0];
              let fundName = fund?.label || '';
              element.value = fundName;
            } else if (element.id == "fundType") {
              element.value = gridData['fundTypeName'];
            } else if (element.id === "periodType") {
              element.value = gridData['periodType']
            } else if (element.id === "comparitiveFS") {
              if (!gridData['comparitiveFS']) {
                basicDetailsData.basicForm2[18].style = "display:none;"
                basicDetailsData.basicForm2[19].style = "display:none;"
                basicDetailsData.basicForm2[20].style = "display:none;"
              }
              element.value =  gridData['comparitiveFS'] ? "Yes" : "No"
            } else if (element.id === "comparitiveFsScheduleApplicability") {
              element.value = gridData['comparitiveFsScheduleApplicability']
              if (gridData['comparitiveFsScheduleApplicability'] == "specific") {
                basicDetailsData.basicForm2[19].style = "display:none;"
                basicDetailsData.basicForm2[20].style = "display:none;"
              }
            } else if (element.id === "comparitiveStartDate") {
              element.value = gridData['comparitiveStartDate']
            } else if (element.id === "comparitiveEndDate") {
              element.value = gridData['comparitiveEndDate']
            }
            else {
              element.value = gridData[element.id];
            }

            element.controlType = 1;
            element.disabled = true;
          });
          resolve(basicDetailsData);
        });
      }
    })
  }

  getformControlsPpmDetail(flag, gridData) {
    return new Promise(resolve => {
      flag = gridData['isnew'] ? 'create' : flag
      if (flag === "create" || flag === "edit") {
        let ppmDetailsData = this.ppmDetails();
        if (flag === 'edit') {
          // this.fetchAssetApi().subscribe((response) => {
          // let assetClass = response[0].resultList && response[0].resultList.length ? response[0].resultList : [];
          // this.assetClassList = assetClass.map(element => ({ label: element.name != null ? element.name : "", value: { "id": element.id } }));
          ppmDetailsData.forEach((element: any) => {
            switch (element.controlType) {
              case (2):
                if (gridData[element.id] != null)
                  element.value = { id: gridData[element.id] };
                else element.value = null
                break;
              case (3):
                element.value = gridData[element.viewname];
                if (gridData[element.id] != null)
                  element.value = new Date(element.value)
                else element.value = null
                break;
              // case (7):
              //   if (element.id == 'assetClass' && gridData['assetClass'] != null) {
              //     var result1 = this.assetClassList.filter(function (o1) {
              //       return gridData['assetClass'].some(function (o2) {
              //         return o1.label === o2;
              //       });
              //     });
              //     element.value = result1;
              //   }
              //   else element.value = null
              //   element.fill = this.assetClassList;
              //   break;
              case (31):
                let fillData = element.fill || [];
                if (gridData[element.id] != null) {
                  let selectedData = fillData.filter(data => { return data.value.id == gridData[element.id] });
                  if (!selectedData.length) {
                    let newItem = { label: gridData[element.id], value: { "id": gridData[element.id] } };
                    element.fill.push(newItem);
                  }
                  element.value = { id: gridData[element.id] };
                } else {
                  element.value = null
                }
                break;
              default:
                element.value = gridData[element.id];
            }
          })
          resolve(ppmDetailsData);
          // })
        } else {
          // this.fetchAssetApi().subscribe((response) => {
          //   let assetClass = response[0].resultList && response[0].resultList.length ? response[0].resultList : [];
          //   this.assetClassList = assetClass.map(element => ({ label: element.name != null ? element.name : "", value: { "id": element.id } }));
          //   ppmDetailsData[46] = { id: 'assetClass', type: [], controlType: 7, label: 'Asset Class', name: 'assetClass', viewName: 'assetClass', order: 2, value: [], required: false, fill: this.assetClassList, appearance: "fill", readonly: false, disabled: false, class: "col-lg-6 pl-5 pt-0 pr-1" }
          //   resolve(ppmDetailsData);
          // })
          resolve(ppmDetailsData);
        }
      } else {
        let ppmDetailsData = this.ppmDetails();
        ppmDetailsData.forEach(element => {
          element.value = gridData[element.id];
          element.controlType = 1;
          element.disabled = true;
        });
        resolve(ppmDetailsData);
      }
    });
  }

  getformControlsFinancialDetail(flag, gridData) {
    return new Promise(resolve => {
      if (flag === "create" || flag === "edit") {
        let financialDetailsData = this.financialDetails();
        if (flag === 'edit') {
          financialDetailsData.forEach((element: any) => {
            switch (element.controlType) {
              case (2):
                if (gridData[element.id] != null)
                  element.value = { id: gridData[element.id] };
                else element.value = null
                break;
              case (3):
                element.value = gridData[element.viewname];
                if (gridData[element.id] != null)
                  element.value = new Date(element.value)
                else element.value = null
                break;
              case (24):
                if (gridData[element.id] != null) {
                  let timeStamp = moment(new Date()).format('MM-DD-YYYY') + " " + gridData[element.id];
                  element.value = new Date(timeStamp);
                } else {
                  element.value = null
                }
                break;
              case (31):
                let fillData = element.fill || [];
                if (gridData[element.id] != null) {
                  let selectedData = fillData.filter(data => { return data.value.id == gridData[element.id] });
                  if (!selectedData.length) {
                    let newItem = { label: gridData[element.id], value: { "id": gridData[element.id] } };
                    element.fill.push(newItem);
                  }
                  element.value = { id: gridData[element.id] };
                } else {
                  element.value = null
                }
                break;
              default:
                element.value = gridData[element.id];
            }
          })
        } else {
          let timeStamp = moment(new Date()).format('MM-DD-YYYY') + " " + '23:59:59';
          financialDetailsData.forEach((element: any) => {
            if (element.controlType == 24) {
              element.value = new Date(timeStamp);
            }
          });
        }
        resolve(financialDetailsData);
      } else {
        let financialDetailsData = this.financialDetails();
        financialDetailsData.forEach(element => {
          element.value = gridData[element.id];
          element.controlType = 1;
          element.disabled = true;
        });
        resolve(financialDetailsData);
      }
    });
  }

  getAddNoteFormControlsDetail(node?) {
    return new Promise(resolve => {
      if (node) {
        let noteDetailsData = this.noteDetails();
        noteDetailsData[0].style = node['parentId'] ? "display:block;" : "display:none;";
        noteDetailsData[0].value = node['parentId'] ? node['parentId'] : null;
        noteDetailsData[0].required = node['parentId'] ? true : false;
        noteDetailsData[1].value = node['title'];
        noteDetailsData[2].value = node['content'];
        resolve(noteDetailsData);
      } else {
        let noteDetailsData = this.noteDetails();
        resolve(noteDetailsData);
      }
    })
  }
  getAddAdditionalNoteFormControlsDetail() {
    return new Promise(resolve => {
      let noteDetailsData: any = this.noteDetails();
      noteDetailsData[1] = { id: 'noteTitle', controlType: 2, label: 'title', viewname: 'noteTitle', name: 'noteTitle', order: 2, value: null, required: false, readonly: false, appearance: "fill", fill: this.additionalNotesList, class: "col-lg-12" };
      resolve(noteDetailsData);
    })
  }

  getNotesBasicFormControlsDetails(flag, gridData) {
    return new Promise(resolve => {
      let basicDetailsData = this.noteBasicDetails();
      this.fetchNotesApis(gridData).subscribe((response: any) => {
        basicDetailsData.forEach(element => {
          if (flag === "create" || flag === "edit") {
            if (gridData[element.id]) {
              element.value = gridData[element.id];
            }
            if (element.id == 'templateName') {
              if (gridData.configId) {
                this.templateList = response[0] || [];
                this.fsTemplate = this.templateList.length ? this.templateList[0] : {};
                if (this.templateList.length) {
                  basicDetailsData[2].fill.push({ label: this.fsTemplate.fileName, value: this.fsTemplate.fileName })
                  basicDetailsData[2].value = this.fsTemplate.fileName;
                  basicDetailsData[2].style = "display:block;";
                  basicDetailsData[3].style = "display:block; padding-bottom: 26px;";
                } else {
                  basicDetailsData[2].style = "display:block;";
                  basicDetailsData[2].value = "Default Template";
                  basicDetailsData[3].style = "display:block; padding-bottom: 26px;";
                }
              }
            }
            else if(element.id == 'template'){
              element.value = ''
            } else if (element.id == 'addNotesToAccount') {
              element.value = gridData['addNotesToAccount'] ? gridData['addNotesToAccount'] : true;
            }
          } else {
            element.value = gridData[element.id] || '';
            element.controlType = 1;
            element.disabled = true;
          }
        })

        resolve(basicDetailsData);
      })
    })
  }


  getComparativeFsScheduleDetails(flag, gridData) {
    return new Promise(resolve => {
      gridData['comparitiveSpecificMap'] = gridData['comparitiveSpecificMap'] || {}
      if (flag === 'create' || flag === 'edit') {
        let comparativeFsScheduleDetails = this.comparativeFsScheduleDetails();
        if (flag === "edit" && Object.keys(gridData['comparitiveSpecificMap']).length) {
          comparativeFsScheduleDetails.forEach(el => {
            el.value = gridData['comparitiveSpecificMap'][el.id];
          })
        }
        resolve(comparativeFsScheduleDetails);
      } else {
        let comparativeFsScheduleDetails = this.comparativeFsScheduleDetails();
        comparativeFsScheduleDetails.forEach(el => {
          if (flag === "view" && Object.keys(gridData['comparitiveSpecificMap']).length) {
            el.value = gridData['comparitiveSpecificMap'][el.id] || '';
          }
          el.disabled = true;
        })
        resolve(comparativeFsScheduleDetails)
      }
    })
  }

  fetchNotesApis(gridData) {
    let reqData = {
      "tableId": gridData.configId,
      "category": "fsdocument"
    };
    let response1;
    if (gridData) {
      response1 = this.http.post<any>('commonDocument/getDocumentList', reqData);
    } else {
      response1 = this.http.get<any>('fSConfig/sFDocumentList', this.httpOptions);
    }
    return forkJoin([response1]);
  }

  fetchMultipleApiBasic(gridData) {
    let response1 = this.http.get<any>('fundV2/fetchAllByEnterprise', this.httpOptions);
    let response3 = this.http.get<any>('fundType/getRecords', this.httpOptions);
    return forkJoin([response1, response3]);
  }

  fetchAssetApi() {
    let response = this.http.get<any>('assetClass/fetchAllByEnterprise', this.httpOptions);
    return forkJoin([response]);
  }

  fetchFundTypeBasic() {
    return this.http.get<any>('fundType/getRecords', this.httpOptions);
  }

  fetchDefaultNotes(fundType, basisOfPresentation) {
    this.fetchAdditionalNotes(fundType, basisOfPresentation);
    return this.http.get<any>('defaultNodes/getDefaultNodesByFundType?fundType=' + fundType + "&basisOfPresentation=" + basisOfPresentation, this.httpOptions);
  }

  fetchAdditionalNotes(fundType, basisOfPresentation) {
    this.http.get<any>('defaultNodes/getFSAdditionalNotesByFundType?fundType=' + fundType + "&basisOfPresentation=" + basisOfPresentation, this.httpOptions).subscribe((response) => {
      let notes = response && response.data && response.data.data ? response.data.data : [];
      this.additionalNotesList = notes.map(element => ({ label: element.title, value: element._id, content: element.content, keyword: element.keyword }));
    });
  }

  fetchFundDetails(url) {
    return this.http.get<any>(url, this.httpOptions);
  }

  setFsconfig(data) {
    return this.http.post<any>('fSConfig/create', data);
  }

  updateFsconfig(data) {
    return this.http.post<any>('fSConfig/update', data);
  }

  getTemplateRecords(url) {
    return this.http.get<any>(url);
  }

  getTemplateList(gridData) {
    let reqData = {
      "tableId": gridData.configId,
      "category": "fsdocument"
    };
    return this.http.post<any>('commonDocument/getDocumentList', reqData);
  }

  deleteTemplate(template) {
    return this.http.post<any>('commonDocument/deleteDocument', template);
  }

  uploadTemplate(file) {
    return this.http.post<any>('commonDocument/addDocument', file);
  }

  downloadTemplate(element) {
    let url = environment.api_url + 'commonDocument/downloadDocument'
    var reqObj = element;
    var access_token = this.cookieService.get('access_token');
    var request = new XMLHttpRequest();
    request.open('POST', url, true);
    request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    request.setRequestHeader('Authorization', 'Bearer ' + access_token);
    request.responseType = 'blob';
    request.onload = function () { };
    request.addEventListener("loadend", () => {
      if (request.status === 200) {
        if (!request.response || !request.response.size) {
          this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: "confirmed", detail: 'Something went wrong with download, please try again.' });
        } else {
          var fileName: any = element.fileName
          var disposition: any = request.getResponseHeader('Content-Disposition');
          if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex: any = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches: any = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, '');
            }
          }
          var blob: any = request.response;
          var downloadLink: any = window.document.createElement('a');
          var contentTypeHeader: any = request.getResponseHeader("Content-Type");
          downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: contentTypeHeader }));
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } else {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('something_went_wrong_please_try_again') });
      }
    })
    this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: 'Confirmed', detail: 'Download Request taken. Your download will start soon.' });
    request.send(JSON.stringify(reqObj));
  }

  previewTemplate(data) {
    const formData = new FormData();
    var access_token = this.cookieService.get('access_token');
    formData.append('data', JSON.stringify(data));
    let url = api_url + 'financialStatement1/previewReport';
    var request = new XMLHttpRequest();
    request.open('POST', url, true);
    request.setRequestHeader('Authorization', 'Bearer ' + access_token);
    request.responseType = 'arraybuffer';
    request.onload = function () { };
    request.addEventListener("loadend", () => {
      if (request.status === 200) {
        if (!request.response) {
          this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: "confirmed", detail: 'Something went wrong with download, please try again.' });
        } else {
          var fileName = name + ".zip";
          var disposition = request.getResponseHeader("Content-Disposition");
          if (disposition && disposition.indexOf("filename") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          var blob = request.response;
          var downloadLink = window.document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: 'application/zip' }));
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } else {
        this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: "Error", detail: 'Something went wrong with download, please try again.' });
      }
    })
    this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: 'Confirmed', detail: 'Download Request taken. Your download will start soon.' });
    request.send(formData);
  }
}
