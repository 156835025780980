/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { apiConstants } from 'src/app/constants/apiConstants';
import { GridService } from '../../services/gridServices/grid.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../services/commonServices/common.service';
import { MatDialog } from '@angular/material/dialog';
import { GlobalMessageService } from '../../services/commonServices/global-message.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationDialog } from '../../dialogs/confirmation-dialog.component';
import _ from 'lodash';
import { LocalStoreService } from '../../services/commonServices/local-store.service';
import moment from 'moment';
import { GlobalService } from '../../services/commonServices/global.service';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { BatchTrackingEntryService } from '../../../../../src/app/views/batch-tracking/batch-tracking-entry.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { PendingInterceptorService } from '../../../../@fury/shared/loading-indicator/pending-interceptor.service';
import { SetupPercentageService } from 'src/app/shared/services/commonServices/setup-percentage.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-button-renderer',
    templateUrl: './edit-cell-button.component.html'
})

export class ButtonRendererComponent implements ICellRendererAngularComp {
    public params;
    public data: any = {};
    public getViewFunctionName: any;
    public getEditFunctionName: any;
    public getOnlyEditFunctionName: any;
    public getDeleteFunctionName: any;
    public moduleName: any;
    public gridDeleteUrl: any;
    public viewUrl: any;
    public componentName: any;
    public msg: any;
    public count: number;
    public result: any;
    public oldParamsNode: any;
    public periodLockDate: any = JSON.parse(this.localService.getItem('fss'))['periodLockDate'];
    public csdEnterpriseId: any;
    periodLockDateAccessor: any;
    public httpOptions = {
        headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*'
        }), responseType: 'arraybuffer'//for xls download
    };
    public gridDataUrl: string;
    public msgs: any[];
    public omsEdit: boolean;
    public omsCancel: boolean;
    public omsApproveList: boolean;
    public omsEditList: boolean;
    public gridOMSAllocatedUrl: string;
    public dataTobeShownInList: any;
    public display: boolean = true;
    public displayDeleteButton: boolean = true;
    public omsValidation: any;
    public omsDeleteAllo: any;
    public getDownloadFunctionName: boolean = false;
    public gridDownloadUrl: string;
    public userAction: boolean = true;
    public rowToggle: boolean;
    public exicuteBtn: boolean;
    public refreshDisabled: boolean = false;
    public rowDisabled: boolean = false;
    public tradeDeleteView: boolean = true;
    public playPauseButtonDis: boolean;
    public deleteButtonClick: boolean;
    public isTrade: boolean = false;
    public isSM: boolean = false;
    @Input() subComponent: any;
    omsData: { selectedData: any; dataTobeShownInList: any; };
    excluiddeleteCondtion: any;
    public updateUserCheck = true;
    public updateClientCheck = true;
    fundPeriodLockDate: any;
    isActive: boolean;
    viewDeleteHideUser: boolean = true;
    @Output() addnewSetupItem = new EventEmitter<any>();
    isMemoEntryCheck: any;
    status: any;
    public isPaymentActive: string;
    currentUser = JSON.parse(this.localService.getItem('fss'));
    authList: any = this.currentUser['authList'];
    ref: DynamicDialogRef;
    public addOnTaxUpdateCheck: boolean = false;
    formData: any;
    isShowEditBtn: boolean = false;
    transactionTypes = ["Subscription - First Time", "Subscription - Additional", "Subscription - Transfer", "Redemption - Full", "Redemption - Partial - by share or Unit", "Redemption - Partial by Amount", "Redemption - Partial - by % value of Account", "Redemption - Transfer", "MFee Update", "PFee Update", "Share Class Update", "Series Update"]

    constructor(private router: Router, private gridService: GridService, private transalteService: TranslateService, private commonService: CommonService,
        private dialog: MatDialog, public batchTrackingEntryService: BatchTrackingEntryService, private messageService: GlobalMessageService,
        public dialogService: DialogService, private http: HttpClient, private globalService: GlobalService,
        private localService: LocalStoreService, public translate: TranslateService, private spinner: SpinnerVisibilityService, private pendingRequestInterceptorService: PendingInterceptorService, private setuppercentage: SetupPercentageService) {

    }
    agInit(params): void {
        this.params = params;
        this.isActive = params['data'].isActive;
        this.status = params['data'].status;
        this.periodLockDateAccessor = this.params.api.periodLockDateAccessor || 'date';
        this.data = this.params.node.data;
        this.oldParamsNode = _.cloneDeep(this.data);
        this.csdEnterpriseId = params['data'].csdEnterpriseId;
        this.globalService.getNewUserInfo().subscribe(info => {
            if (this.params.api.componentName == undefined) {
                this.params.api.componentName = info.componentName;
                this.params.api.subComponent = info.subComponent;
            }
        })

        this.moduleName = apiConstants[this.params.api.componentName][this.params.api.subComponent] ? apiConstants[this.params.api.componentName][this.params.api.subComponent]['moduleName'] : (apiConstants[this.params.api.componentName] != (undefined || null) ? apiConstants[this.params.api.componentName]['moduleName'] : this.params.api.componentName)
        this.gridDeleteUrl = apiConstants[this.params.api.componentName][this.params.api.subComponent] ? apiConstants[this.params.api.componentName][this.params.api.subComponent]['gridDeleteUrl'] : apiConstants[this.params.api.componentName]['gridDeleteUrl'];
        this.getViewFunctionName = this.params.api.getViewFunctionName;
        this.getEditFunctionName = this.params.api.getEditFunctionName;
        this.getDeleteFunctionName = this.params.api.getDeleteFunctionName;
        this.omsValidation = this.params.api.omsValidation;
        this.omsEdit = apiConstants[this.params.api.componentName][this.params.api.subComponent] ? apiConstants[this.params.api.componentName][this.params.api.subComponent]['isOmsEdit'] : false;
        this.omsCancel = apiConstants[this.params.api.componentName][this.params.api.subComponent] ? apiConstants[this.params.api.componentName][this.params.api.subComponent]['isOmsCancel'] : false;
        this.omsApproveList = apiConstants[this.params.api.componentName][this.params.api.subComponent] ? apiConstants[this.params.api.componentName][this.params.api.subComponent]['isomsApproveList'] : false;
        this.omsEditList = apiConstants[this.params.api.componentName][this.params.api.subComponent] ? apiConstants[this.params.api.componentName][this.params.api.subComponent]['isomsEditList'] : false;
        this.gridOMSAllocatedUrl = apiConstants[this.params.api.componentName][this.params.api.subComponent] ? apiConstants[this.params.api.componentName][this.params.api.subComponent]['gridOMSAllocatedUrl'] : '';
        this.viewUrl = apiConstants[this.params.api.componentName][this.params.api.subComponent] ? apiConstants[this.params.api.componentName][this.params.api.subComponent]['viewUrl'] : apiConstants[this.params.api.componentName]['viewUrl'];
        this.omsDeleteAllo = apiConstants[this.params.api.componentName][this.params.api.subComponent] ? apiConstants[this.params.api.componentName][this.params.api.subComponent]['omsCancelApprove'] : false;
        this.exicuteBtn = apiConstants[this.params.api.componentName][this.params.api.subComponent] ? apiConstants[this.params.api.componentName][this.params.api.subComponent]['exicuteBtn'] : false;
        this.excluiddeleteCondtion = this.params.api.componentName ? this.params.api.componentName == 'excludeComposite' : false;
        this.getDownloadFunctionName = apiConstants[this.params.api.componentName]['downloadButtonFunction'];
        this.gridDownloadUrl = apiConstants[this.params.api.componentName]['gridDownloadUrl'];
        if (this.omsValidation) {
            this.getViewFunctionName = false;
            this.getDeleteFunctionName = false;
        }
        if (this.params.api.componentName == "fSInvestor" || this.params.api.componentName == "fSPortfolio" || this.params.api.componentName == "fschartOfAccountsRapid") {
            this.getViewFunctionName = false;
            this.getDeleteFunctionName = false;
            this.getOnlyEditFunctionName = true;
        }
        if ((this.omsValidation && this.params.api.componentName === 'usermanagementlist') || this.params.api.componentName === 'usermappinglist' || this.params.api.componentName === 'automate-process') {
            this.getViewFunctionName = this.params.api.getViewFunctionName
            this.getDeleteFunctionName = this.params.api.getDeleteFunctionName
        }
        if (this.params.api.componentName == "journalentry" || this.params.api.componentName == "RapidJournalentry" || this.params.api.componentName == "rapidCompoundJEListComponent") {
            this.getViewFunctionName = (this.getViewFunctionName && (this.params.data && (!this.params.data.referenceModule || this.params.data.referenceModule == 'Rollup-Consolidation')));
            this.getDeleteFunctionName = (this.getDeleteFunctionName && (this.params.data && (!this.params.data.referenceModule || this.params.data.referenceModule == "findata" || this.params.data.referenceModule == 'Rollup-Consolidation')));
        }
        if (this.params.api.componentName == "rapidInvestors") {
            this.addOnTaxUpdateCheck = this.currentUser.authList.indexOf('SEAMLESS_INPUT_VIEW_INVESTORS_INVESTORS_TAX_UPDATE') > -1 ? true : false;
        }
        if (this.params.api.componentName == "tradeList" || this.params.api.componentName == 'rapidTradeList') {
            this.tradeDeleteView = this.params.node.data.transferTradeInputIdList != null && this.params.node.data.sourceTradeId != null ? false : true;
        }
        if (this.params.api.componentName == "workflowStatus") {
            this.rowDisabled = this.params.node.data.successCount === 0;
        }
        if (this.params.api.componentName == "manageJobSchedule") {
            this.playPauseButtonDis = this.params.node.data.state == 'PAUSED' ? true : false;
        }
        if (this.params.api.componentName == "usersList" && this.params.api.roleAs != 'SystemManager') {
            this.viewDeleteHideUser = (this.params.data.userType == 'investor portal' || this.params.data.userType == 'reporting portal') ? false : true;
        }
        let systemAdminView = ["custodianList", "FundList", "investorsList", "amcIMProgram"]
        if (systemAdminView.includes(this.params.api.componentName) && this.params.api.issystemAdmin) {
            this.viewUrl = apiConstants[this.params.api.componentName][this.params.api.subComponent] ? apiConstants[this.params.api.componentName][this.params.api.subComponent]['systemAdminViewUrl'] : apiConstants[this.params.api.componentName]['systemAdminViewUrl'];
        }
        let systemEntitiesView = ["custodianList", "FundList", "investorsList", "amcIMProgram", "enterprises", "allUsers"]
        if (systemEntitiesView.includes(this.params.api.componentName) && this.authList.includes("SEAMLESS_SIDEMENU_ENTITIES")) {
            this.viewUrl = apiConstants[this.params.api.componentName]['viewEntitiesUrl'];
        }
        if ((this.params.api.componentName == 'userCoaList' || this.params.api.componentName == 'reviewChartOfAccounts') && this.params.node.data.coaStatus == "Mapped") {
            this.displayDeleteButton = false;
        }
        if ((this.params.api.componentName == 'reReportConfig')) {
            if ((!this.params.api.isEnterprise) && (this.params.node.data.userId != this.params.api.currentUser.id)) {
                this.displayDeleteButton = false;
                this.updateClientCheck = false;
            }
        }
        if (this.params.api.componentName == 'userCoaList') {
            this.updateUserCheck = this.params.api.updateUserCheck
            this.updateClientCheck = this.params.api.updateClientCheck
        }
        if (this.params.api.componentName == 'reviewChartOfAccounts') {
            this.updateClientCheck = this.params.api.updateClientCheck
        }
        let cstId = this.params.data.id;
        let cstSts = this.params.data.disabled;
        if (!cstId) {
            this.userAction = false;
        } else {
            if (!cstSts) {
                this.rowToggle = true;
            }
        }
        if (this.params.api.componentName === 'rapidOmslist') {
            this.getDeleteFunctionName = apiConstants[this.params.api.componentName][this.params.api.subComponent]?.authFunctions?.deleteButtonFunction ? this.authList.indexOf(apiConstants[this.params.api.componentName][this.params.api.subComponent]?.authFunctions?.deleteButtonFunction) > -1 : true;
        }
        this.componentName = this.params.api.componentName

        if (this.params.api.componentName == "accountSummaryReconcile" || this.params.api.componentName == "rapidAccountSummaryReconcile") {
            this.isMemoEntryCheck = this.params.node.data.isMemoEntry;
        }

        if (this.moduleName == "paymentsPayable") {
            this.isPaymentActive = this.params.node.data.reversedEntry
        }

        if (this.componentName == "disableAutoUpdateViaAllocationUpdate" || this.componentName == 'redemptionActivityUpdate' || this.componentName == 'subscriptionActivityUpdate') {
            this.formData = this.params.api['caFormData'];
            let transactionType = this.params.api['transactionType'];
            if (this.formData['transactionType'] != null) {
                this.isShowEditBtn = true;
            } else if (transactionType != null) {
                this.isShowEditBtn = true;
            } else {
                this.isShowEditBtn = false;
            }
        }
        if (this.componentName == 'rapidCapitalActivities') {
            let capActivityNode = this.params.node.data;
            if (this.transactionTypes.includes(capActivityNode.transactionType)) {
                this.isShowEditBtn = false;
            } else {
                this.isShowEditBtn = true;
            }
        }
    }

    // gets called whenever the cell refreshes
    viewRowData(event) {
        if (this.viewUrl) {
            if (this.viewUrl == 'None') {
                this.gridService.setViewData(this.params.data);
                if (this.componentName == 'paymentPayableData' || this.componentName == 'rapidPaymentPayableData' || this.componentName == "reBudgets" || this.componentName == "rapidReBudgets" || this.componentName == "fundPropertyList" || this.componentName == "reviewChartOfAccounts" || this.componentName == "calculateTaxAllocationStatus" || this.componentName == "chartofAccountTaxMappingDetails") {
                    this.gridService.seteditBtnMethod(this.params.data);
                } else {
                    this.gridService.seteditBtnMethod(true);
                }
            } else {
                this.router.navigate([this.viewUrl]);
                this.gridService.setViewData(this.params.data);
            }
        }
    }

    msgTrans(pmsg) {
        this.transalteService.get(pmsg).subscribe((traval) => {
            if (traval != undefined || traval != null) {
                this.msg = traval;
            }
        });
    }

    updateSchdule(event) {
        let selectedNode = this.params.node;
        let selectedData = selectedNode.data;
        if (this.params.updateSchdule instanceof Function) {
            const params = {
                event: event,
                rowData: selectedData
            }
            this.params.updateSchdule(params);
        }
    }

    deleteUploadData(event) {
        let selectedNode = this.params.node;
        let selectedData = selectedNode.data;
        let processName = this.params.node.data.processName;
        let uploadCount = this.params.node.data.successCount;
        if (processName == "Trade Client" || processName == "Trade Broker") {
            this.isTrade = true;
        }
        if (processName == "SM Equities Option" || processName == "SM Fixed Income" || processName == "SM FXForward" || processName == "SM Private Fund" || processName == "SM Futures" || processName == "SM Equities") {
            this.isSM = true;
        }
        const dialogRef = this.dialog.open(ConfirmationDialog, {
            data: {
                header: 'Confirm Delete',
                message: 'Are you sure you want to delete the record ?',
                buttonText: {
                    ok: 'Delete',
                    cancel: 'Cancel'
                }
            }
        });
        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.deleteButtonClick = true;
                this.gridService.deleteGridData("alteryxExecuteWorkflowV2/deleteProcessedData/" + this.params.node.data.uniqueNumber, (cbs) => {
                    if (cbs && cbs.success) {
                        var row = cbs.data;
                        if (row && this.params.api.componentName == "workflowStatus") {
                            if (row['runDate']) {
                                row["runDate"] = moment(new Date(row['runDate'])).format("MM/DD/YYYY hh:mm:ss A");
                            }
                            selectedNode.setData(row);
                            this.rowDisabled = row.successCount === 0;
                            if (!this.isTrade && !this.params.data.dataDeletionStarted) {
                                this.messageService.add({ sticky: true, severity: 'success', summary: processName, detail: uploadCount + " " + cbs.message });
                            }
                        }
                    } else if (this.isSM && !cbs.success) {
                        if (cbs && cbs.status === 'Failed') {
                            this.deleteButtonClick = false;
                            this.messageService.add({ sticky: true, severity: 'error', summary: processName, detail: cbs.message });
                            this.messageService.add({ sticky: true, severity: 'error', summary: processName, detail: "SM could not be deleted" });
                        }
                        if (cbs && cbs.status === 'started') {
                            this.messageService.add({ sticky: true, severity: 'error', summary: processName, detail: cbs.message });
                        }
                    } else {
                        this.messageService.add({ sticky: true, severity: 'error', summary: processName, detail: cbs.message });
                    }
                }, (cbe) => { })

                if (this.isTrade && !this.params.data.dataDeletionStarted) {
                    setTimeout(() => {
                        this.spinner.hide();
                        this.pendingRequestInterceptorService.hideLoading();
                        this.messageService.add({ sticky: true, severity: 'success', summary: processName, detail: 'This process is running in backend and it might take some time .' });
                    }, 1500);
                }
            } else {
                if (confirmed != undefined) {
                    this.messageService.add({ sticky: true, severity: 'error', summary: processName, detail: ' not Deleted ' });
                }
            }
        });
    }

    batchScduleDetail(event) {
        let selectedNode = this.params.node;
        let selectedData = selectedNode.data;
        if (this.params.batchScduleDetail instanceof Function) {
            const params = {
                event: event,
                rowData: selectedData
            }
            this.params.batchScduleDetail(params);
        }
    }

    updateBatch(event) {
        let selectedNode = this.params.node;
        let selectedData = selectedNode.data;
        if (this.params.updateBatch instanceof Function) {
            const params = {
                event: event,
                rowData: selectedData
            }
            this.params.updateBatch(params);
        }
    }

    updatePayment(event) {
        let selectedNode = this.params.node;
        let selectedData = selectedNode.data;
        if (this.params.updatePayment instanceof Function) {
            const params = {
                event: event,
                rowData: selectedData
            }
            this.params.updatePayment(params);
        }
    }

    batchScduleDetail2(event) {
        let selectedNode = this.params.node;
        let selectedData = selectedNode.data;
        if (this.params.batchScduleDetail2 instanceof Function) {
            const params = {
                event: event,
                rowData: selectedData
            }
            this.params.batchScduleDetail2(params);
        }
    }

    downloadRowDoc(event) {
        if (this.params.data) {
            if (this.params.data.id) {
                var usrId = this.params.data.id;
                if (this.params.data.fileUrl) {
                    var flUri = this.params.data.fileUrl;
                    var flNm = this.params.data.fileName;
                }
            }
        }
        this.downloadFileFromAWS(flUri).subscribe(res => {
            var a = document.createElement('a');
            var blob = new Blob([res], { type: res.ContentType });
            a.href = URL.createObjectURL(blob);
            a.download = flNm;
            a.click();
            this.messageService.add({ sticky: true, severity: 'success', summary: this.transalteService.instant('success'), detail: this.transalteService.instant('File Download successful') });
        })
    }

    downloadFileFromAWS(fileUrl): Observable<any> {
        var filePath = fileUrl.split('/');
        var fileName = filePath[filePath.length - 3] + "/" + filePath[filePath.length - 2] + "/" + filePath[filePath.length - 1];
        return this.http.post<any>('documentManagementV2/download', fileName, { responseType: 'blob' as 'json' });
    }

    userActionfun(event) {
        let parId = this.params.data.id;
        let payload = '"id":' + '"' + parId + '"';
        let url = apiConstants[this.params.api.componentName]['configUrl'] + "?id=" + parId;
        payload = "{" + payload + "}";
        payload = JSON.parse(payload);
        if (event.checked == false) {
            this.gridService.getGridDataPost(url, (cbs) => {
            }, (cbe) => {
            }, payload)
            this.userAction = false;
        }
    }

    deleteSingleRow(event) {
        this.result = this.commonService.getPeriodLockmessagefordelete(this.params.node.data, this.params.api.componentName, 'singleRowDelete');
        if ((this.params.api.componentName == 'tradeList' || this.params.api.componentName == 'rapidTradeList') && this.result != false) {
            var tradeDate: any = this.params.node.data.tradeDate ? new Date(this.params.node.data.tradeDate) : "";
            var openTaxLotDate: any = this.params.node.data.openTaxLotDate ? new Date(this.params.node.data.openTaxLotDate) : "";
            if (this.params.node.data.taxLotApplied || (openTaxLotDate && tradeDate && openTaxLotDate.getTime() >= tradeDate.getTime())) {
                let date = new Date((this.params.node.data.tradeDate)).getTime().valueOf() - 1000 * 60 * 60 * 24;
                var msg = "Trade can`t be deleted. Please reset open lots as on " + moment(date).format('MM-DD-YYYY');
                this.messageService.add({ sticky: true, severity: "warn", summary: "Trade Entry", detail: msg });
                return false;
            }
        }

        if (this.result != false) {
            this.msgTrans('del_sure');
            let msg2 = this.msg;
            this.msgTrans('yes');
            let msg3 = this.msg;
            this.msgTrans('no');
            let msg4 = this.msg;
            const dialogRef = this.dialog.open(ConfirmationDialog, {
                data: {
                    message: msg2,
                    buttonText: {
                        ok: msg3,
                        cancel: msg4
                    }
                }
            });
            dialogRef.afterClosed().subscribe((confirmed: boolean) => {
                if (confirmed) {
                    let selectedNode = this.params.node;
                    let selectedData = selectedNode.data;
                    if ((this.params.api.componentName == "setupcustodianList") && (this.params.node.data.accountType == 'Exchange' || this.params.node.data.accountType == 'Wallet')) {
                        var url = 'api/clients/' + this.params.api.enterpriseId + '/users/deleteAccount/' + this.params.node.data.accountNumberInSourceFileEnc + '/' + this.params.node.data.sourceExchangeId;
                        this.gridService.deleteGridData(url, (cbs) => {
                            if (cbs.message == 'Record deleted successfully.' || cbs.message == 'Could not find User in DB.') {
                                var gridDeleteUrlcustodian = "deleteController/delete?id=" + this.params.node.data.id + "&moduleName=" + this.moduleName
                                this.gridService.deleteGridData(gridDeleteUrlcustodian, (cbs) => {
                                    let selectedNode = this.params.node
                                    let selectedData = selectedNode.data;
                                    if (cbs.code) {
                                        let locMsg = cbs.message.toLowerCase();
                                        if (!locMsg.includes('not') && !locMsg.includes('mapped')
                                            && !locMsg.includes('failed') && !locMsg.includes("can't")) {
                                            this.params.api.updateRowData({ remove: [selectedData] });
                                        }
                                    }
                                    if (cbs.message == 'Record Deleted' || cbs.message == 'Record deleted' || cbs.message == 'Record deleted.' || cbs.message == 'Record Deleted.') {
                                        if (this.params.updateCount instanceof Function) {
                                            const count = 1;
                                            this.params.updateCount(count);
                                        }
                                        this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translate.instant('success'), detail: this.transalteService.instant(cbs.message) });
                                    } else {
                                        this.messageService.add({ closable: true, sticky: true, severity: 'warn', detail: cbs.message });
                                    }
                                }, (cbe) => {
                                })
                                //this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translate.instant('success'), detail: this.transalteService.instant(cbs.message) });
                            } else {
                                this.messageService.add({ closable: true, sticky: true, severity: 'warn', detail: cbs.message });
                            }
                        }, (cbe) => {
                        })
                    } else {
                        if (this.moduleName == "capitalActivity") {
                            this.gridDeleteUrl = "deleteController/delete?id=" + this.params.node.data.transactionID + "&moduleName=" + this.moduleName
                        } else if (this.moduleName == "CompoundJournalEntryRevised") {
                            this.gridDeleteUrl = this.gridDeleteUrl + "delete?jeId=" + this.params.node.data.jeId + "&moduleName=" + this.moduleName
                        } else if (this.params.api.componentName == "journalentry" || this.params.api.componentName == "RapidJournalentry") {
                            if (this.params.data.referenceModule == "findata") {
                                this.gridDeleteUrl = this.gridDeleteUrl + "delete?jeId=" + this.params.node.data.jeId + "&moduleName=findata";
                            } else {
                                this.gridDeleteUrl = this.gridDeleteUrl + "delete?jeId=" + this.params.node.data.jeId + "&moduleName=" + this.params.api.lookups;
                            }
                        } else if (this.params.api.componentName == "accountSummaryReconcile" || this.params.api.componentName == "rapidAccountSummaryReconcile") {
                            if (this.params.node.data.isReconcile == true) {
                                this.messageService.add({ closable: true, sticky: true, severity: 'error', detail: this.transalteService.instant('Reconcile not deleted') });
                            } else {
                                this.gridDeleteUrl = "deleteController/delete?id=" + this.params.node.data._id + "&moduleName=" + this.moduleName;
                            }
                        } else if (this.params.api.componentName == "tradehv") {
                            this.gridDeleteUrl = "deleteController/delete?id=" + this.params.node.data.sid + "&moduleName=" + this.moduleName;
                        } else if (this.params.api.componentName == "rapidhighVolume" && this.params.api.subComponent == "tradeUploadHV") {
                            this.gridDeleteUrl = "deleteController/delete?id=" + this.params.node.data.sid + "&moduleName=" + this.moduleName;
                        } else if (this.params.api.componentName == "investors" || this.params.api.componentName == "rapidInvestors") {
                            this.gridDeleteUrl = "deleteController/delete?id=" + this.params.node.data.id + "&moduleName=" + 'investor';
                        } else if (this.params.api.componentName == "bill" || this.params.api.componentName == "unpaidBills" || this.params.api.componentName == "paidBills"
                            || this.params.api.componentName == "rapidBill" || this.params.api.componentName == "rapidUnpaidBills" || this.params.api.componentName == "rapidPaidBills") {
                            this.gridDeleteUrl = "reBillDetails/delete?id=" + this.params.node.data.id;
                        } else if (this.params.api.componentName == "reBudgets" || this.params.api.componentName == "rapidReBudgets") {
                            this.gridDeleteUrl = "reBudgetDetails/deleteBudget?fundId=" + this.params.node.data.fundId + "&custodianId=" + this.params.node.data.custodianId + "&chartOfAccId=" + this.params.node.data.chartofAccId + "&year=" + this.params.node.data.year;
                        } else if (this.params.api.componentName == "apiCreation") {
                            this.gridDeleteUrl = "investorCapitalSummaryAPI/deleteRestAPI/" + this.params.node.data.id
                        } else if (this.params.api.componentName == "apiModule") {
                            this.gridDeleteUrl = "investorCapitalSummaryAPI/deleteUsagePlan/" + this.params.node.data.id
                        } else if (this.params.api.componentName == "apiKeyModule") {
                            this.gridDeleteUrl = "investorCapitalSummaryAPI/disableAPIKey/" + this.params.node.data.apiKeyId
                        } else if (this.params.api.componentName == "defaultChartOfAccounts") {
                            this.gridDeleteUrl = "defaultChartOfAccounts/delete?id=" + this.params.node.data.id;
                        } else if (this.params.api.componentName == "FSAutomation") {
                            this.gridDeleteUrl = "fSConfig/deleteByConfigId";
                        } else {
                            this.gridDeleteUrl = "deleteController/delete?id=" + this.params.node.data.id + "&moduleName=" + this.moduleName
                        }
                        /**
                         * delete request update for FS-config FSA-386
                         * @author   Ashok Kumar
                         */
                        if (this.params.api.componentName == "FSAutomation") {
                            let fsScheduleConfigId = this.params.node.data.scheduleData ? this.params.node.data.scheduleData.id : '';
                            let requestData = {
                                "fsConfigId": this.params.node.data.id,
                                "fsScheduleConfigId": fsScheduleConfigId
                            }
                            this.gridService.deleteSelectedGridData(this.gridDeleteUrl, requestData, (cbs) => {
                                if (cbs.code) {
                                    let selectedNode = this.params.node
                                    let selectedData = selectedNode.data;
                                    if (cbs.code) {
                                        let locMsg = cbs.message.toLowerCase();
                                        if (!locMsg.includes('not') && !locMsg.includes('mapped')
                                            && !locMsg.includes('failed') && !locMsg.includes("can't")) {
                                            this.params.api.updateRowData({ remove: [selectedData] });
                                        }
                                    }
                                }
                                let message = cbs.message.includes('Record(s) deleted') || cbs.message.includes('Record deleted.') || cbs.message.includes('Record Deleted.') || cbs.message.includes('Record Deleted') || cbs.message.includes('Selected Record(s) deleted');
                                if (message && cbs.code == 1) {
                                    if (this.params.updateCount instanceof Function) {
                                        const count = 1;
                                        this.params.updateCount(count);
                                    }
                                    this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translate.instant('success'), detail: this.transalteService.instant(cbs.message) });
                                } else {
                                    this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: cbs.message });
                                }
                            }, (cbe) => { });
                        } else {
                            this.gridService.deleteGridData(this.gridDeleteUrl, (cbs) => {
                                if (cbs && cbs.code) {
                                    let selectedNode = this.params.node
                                    let selectedData = selectedNode.data;
                                    if (cbs.code) {
                                        let locMsg = cbs.message.toLowerCase();
                                        if (!locMsg.includes('not') && !locMsg.includes('mapped')
                                            && !locMsg.includes('failed') && !locMsg.includes("can't")) {
                                            this.params.api.updateRowData({ remove: [selectedData] });
                                        }
                                    }

                                    if (cbs.message == 'Record Deleted' || cbs.message == 'Record deleted' || cbs.message == 'Record deleted.' || cbs.message == 'Record Deleted.') {
                                        if (this.params.updateCount instanceof Function) {
                                            const count = 1;
                                            this.params.updateCount(count);
                                        }
                                        if (this.params.api.componentName == "investors" || this.params.api.componentName == "rapidInvestors") {
                                            this.messageService.add({ closable: true, sticky: true, severity: 'success', detail: this.transalteService.instant(cbs.message + ' successfully') });
                                        }
                                        if (this.componentName == 'bill' || this.componentName == "unpaidBills" || this.componentName == "paidBills"
                                            || this.componentName == 'rapidBill' || this.componentName == "rapidUnpaidBills" || this.componentName == "rapidPaidBills") {
                                            if (this.params.refershGrid instanceof Function) {
                                                const params = {
                                                    event: event
                                                }
                                                this.params.refershGrid();
                                            }
                                            this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translate.instant('success'), detail: this.transalteService.instant(cbs.message) });
                                        }
                                        if (this.params.api.componentName == "accountSummaryReconcile" || this.params.api.componentName == "rapidAccountSummaryReconcile") {
                                            if (this.params.refershGrid instanceof Function) {
                                                const params = {
                                                    event: event
                                                }
                                                if (this.moduleName == "reconciliationAccountSummary") {
                                                    this.router.navigate(["/reconciliation/account-summary"]);
                                                } else {
                                                    this.params.refershGrid();
                                                }
                                            }
                                        } else {
                                            this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translate.instant('success'), detail: this.transalteService.instant(cbs.message) });
                                        }
                                    } else if (this.params.api.componentName == "investors" || this.params.api.componentName == "rapidInvestors") {
                                        this.messageService.add({ closable: true, sticky: true, severity: 'error', detail: cbs.message });
                                    } else {
                                        this.messageService.add({ closable: true, sticky: true, severity: 'warn', detail: cbs.message });
                                    }
                                } else {
                                    if (this.componentName == 'bill' || this.componentName == "unpaidBills" || this.componentName == "paidBills"
                                        || this.componentName == 'rapidBill' || this.componentName == "rapidUnpaidBills" || this.componentName == "rapidPaidBills"
                                        || this.componentName == "defaultChartOfAccounts") {

                                        this.messageService.add({ closable: true, sticky: true, severity: 'error', detail: cbs.message });
                                    }
                                    this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: cbs.message });
                                }
                            }, (cbe) => {
                            })
                        }
                    }
                }
            });
        }
    }

    findByIdAllocated(event) {
        let selectedNode = this.params.node;
        let selectedData = selectedNode.data;
        this.gridService.getOMSAllocatedData(this.gridOMSAllocatedUrl, selectedData.orderId, (cbs) => {
            if (cbs) {
                this.dataTobeShownInList = cbs.resultList;
                this.omsData = { 'selectedData': selectedData, 'dataTobeShownInList': this.dataTobeShownInList }
                if (this.params.findByIdAllocated instanceof Function) {
                    const params = {
                        event: event,
                        rowData: this.omsData
                    }
                    this.params.findByIdAllocated(params);
                }
            }
        }, (cbe) => { })
        reject: () => { }
    }

    cancelApproveOrder(event) {
        let selectedNode = this.params.node;
        let selectedData = selectedNode.data;
        const dialogRef = this.dialog.open(ConfirmationDialog, {
            data: {
                message: 'Do you want to cancel the original order? This will create a cancel entry in OMS (Approved Orders).',
                header: 'Confirm Approved Order Cancellation?',
                buttonText: {
                    ok: 'Yes',
                    cancel: 'No'
                }
            }
        });
        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                let selectedNode = this.params.node;
                let selectedData = selectedNode.data;
                let id = { docIds: [this.params.node.data.id] }
                this.gridService.getOmsApprovedDeleteUpdate('omsEntry/cancelApproveOrder', { orderId: selectedData.orderId }, (cbs) => {
                    if (cbs) {
                        if (this.params.refershGrid instanceof Function) {
                            const params = {
                                event: event
                            }
                            this.params.refershGrid();
                        }
                    }
                }, (cbe) => { })
            }
        });
    }

    updateApproveOrder(event) {
        let selectedNode = this.params.node;
        let selectedData = selectedNode.data;
        const dialogRef = this.dialog.open(ConfirmationDialog, {
            data: {
                message: 'Do you want to update the original order? This will create a cancel entry in OMS (Approved Orders) and order will be available for updation in OMS (Unallocated Orders).',
                header: 'Confirm Approved Order Updation?',
                buttonText: {
                    ok: 'Update',
                    cancel: 'Cancel'
                }
            }
        });
        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                let selectedNode = this.params.node;
                let selectedData = selectedNode.data;
                this.gridService.getOmsApprovedDeleteUpdate('omsEntryV2/updateApproveOrder', { orderId: selectedData.orderId }, (cbs) => {
                    if (cbs) {
                        if (this.params.refershGrid instanceof Function) {
                            const params = {
                                event: event
                            }
                            this.params.refershGrid();
                        }
                    }
                }, (cbe) => { })
            }
        });
    }

    downloadFile() {
        let selectedNode = this.params.node;
        var filePath = selectedNode.data.fileUrl.split('/');
        var fileName = filePath[filePath.length - 3] + "/" + filePath[filePath.length - 2] + "/" + filePath[filePath.length - 1];
        this.gridService.downloadFile(this.gridDownloadUrl, fileName, (cbs) => {
            let a = document.createElement('a');
            let blob = new Blob([cbs], { type: cbs.ContentType });
            a.href = URL.createObjectURL(blob);
            var arr = fileName.split('/')
            a.download = arr[2];
            a.click();
        }, (cbe) => { })
    }

    deleteData(details) {
        this.msgTrans('del_sure');
        let msg2 = this.msg;
        this.msgTrans('yes');
        let msg3 = this.msg;
        this.msgTrans('no');
        let msg4 = this.msg;
        const dialogRef = this.dialog.open(ConfirmationDialog, {
            width: '250px',
            data: {
                message: msg2,
                buttonText: {
                    ok: msg3,
                    cancel: msg4
                }
            }
        });
        dialogRef.afterClosed().subscribe(isdeleted => {
            let selectedNode = this.params.node;
            let selectedData = selectedNode.data;
            let getParams = selectedData.jobName.split("_");
            let clientId = getParams[1];
            let routeId = getParams[2];
            let userId = getParams[3];
            if (isdeleted) {
                let deleteUrl = 'schedule/delete?clientId=' + clientId + '&routeId=' + routeId + '&userId=' + userId + "&enterpriseId=" + this.params.api.enterpriseId;
                this.http.delete(deleteUrl,)
                    .subscribe((response) => {
                        if (this.params.updateCount instanceof Function) {
                            this.count = 1;
                            this.params.updateCount(this.count);
                            this.params.api.updateRowData({ remove: [selectedData] });
                        }
                        this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.transalteService.instant('success'), detail: this.transalteService.instant('Cron has been Deleted Successfully') });
                    }, (error) => {
                        if (error.error.message) {
                            this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.transalteService.instant('error'), detail: this.transalteService.instant(error.error.message) });
                        } else {
                            this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.transalteService.instant('error'), detail: this.transalteService.instant('Something went wrong, Please try again.') });
                        }
                    });
            }
        });
    }

    goToReconcile(res) {
        this.setuppercentage.setAccountSummaryReconcileGrid(res.data)
        this.router.navigate([this.viewUrl]);
    }

    setMemoEntry() {
        this.gridService.setViewData(this.params.data);
        if (this.params.node.data.isReconcile == true) {
            this.messageService.add({ closable: true, sticky: true, severity: 'error', detail: this.transalteService.instant('Reconcile not Updated') });
        } else {
            console.log("3")
            this.gridService.seteditBtnMethod({ type: "edit", check: true });
        }
    }

    playPauseSchedule(type) {
        let messageDisply = type == 'pause' ? 'Are you sure you want to Pause ?' : 'Are you sure you want to Resume ?';
        this.msgTrans(messageDisply);
        let msg2 = this.msg;
        this.msgTrans('yes');
        let msg3 = this.msg;
        this.msgTrans('no');
        let msg4 = this.msg;
        const dialogRef = this.dialog.open(ConfirmationDialog, {
            data: {
                message: msg2,
                buttonText: {
                    ok: msg3,
                    cancel: msg4
                }
            }
        });
        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            let selectedNode = this.params.node;
            let selectedData = selectedNode.data;
            let getParams = selectedData.jobName.split("_");
            let clientId = getParams[1];
            let routeId = getParams[2];
            let userId = getParams[3];
            if (confirmed) {
                let Url = type == 'pause' ? 'schedule/pause/?clientId=' : 'schedule/resume/?clientId=';
                this.http.get(Url + clientId + '&routeId=' + routeId + '&userId=' + userId + "&enterpriseId=" + this.params.api.enterpriseId)
                    .subscribe((response) => {
                        this.params.updateCount(this.count, 'refreshGrid');
                        let message = type == 'pause' ? "scheduler_has_been_paused" : "scheduler_has_been_resumed";
                        this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translate.instant('success'), detail: this.transalteService.instant(message) });
                    }, (error) => {
                        console.log(error);
                        if (error.error.message) {
                            this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.transalteService.instant('error'), detail: this.transalteService.instant(error.error.message) });
                        } else {
                            this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.transalteService.instant('error'), detail: this.transalteService.instant('Something went wrong, Please try again.') });
                        }
                    })
            }
        })
    }

    runDailyProcessingCompliance(event) {
        let selectedNode = this.params.node;
        let selectedData = selectedNode.data;
        if (this.params.runDailyProcessingCompliance instanceof Function) {
            const params = {
                event: event,
                rowData: selectedData
            }
            this.params.runDailyProcessingCompliance(params);
        }
    }

    refreshGLBalance(type) {
        var result = this.getFundPeriodLockDate(this.params.node.data)
        if (result == "No locks found") {
            if (this.refreshDisabled) {
                return
            }
            this.refreshDisabled = true
            let selectedNode = this.params.node;
            let req = { "checkedList": [], "bankConfig": selectedNode.data, "unCheckedList": [], "reconcileProcess": "doRefresh" }
            this.gridService.saveAccountSummaryReconcileData(req).subscribe(res => {
                if (res.message && type == 'refresh') {
                    this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translate.instant('success'), detail: this.transalteService.instant(res.message) });
                    if (this.params.refershGrid instanceof Function) {
                        const params = {
                            event: event
                        }
                        this.params.refershGrid();
                    }
                } else {
                    this.goToReconcile(res)
                }
            })
        }
    }

    getFundPeriodLockDate(accountSummaryData) {
        let data = this.params.api
        let fundPeriodLockDate;
        let fundListAll = data.fundListAll
        let currentUser = data.periodLockDate
        for (var v = 0; v < fundListAll.length; v++) {
            if (fundListAll[v].id == accountSummaryData.fundId) {
                fundPeriodLockDate = fundListAll[v].fundPeriodLockDate;
            }
        }
        let newDateFundLock = (moment(new Date(fundPeriodLockDate))).format('YYYY-MMM-DD hh:mm:ss:ssss');
        const fundLockyear = (new Date(newDateFundLock)).getFullYear();
        const fundLockmonth = newDateFundLock.split("-")[1]
        const formattedFundDate = `${fundLockmonth}-${fundLockyear}`;

        let newDatePeriodLock = (moment(new Date(currentUser))).format('YYYY-MMM-DD hh:mm:ss:ssss');
        const periodLockyear = (new Date(newDatePeriodLock)).getFullYear();
        const periodLockmonth = newDatePeriodLock.split("-")[1]

        const formattedPeriodLockDate = `${periodLockmonth}-${periodLockyear}`;
        const monthsIndex = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        // Fund Lock Date
        const [monthStrF, yearStrF] = formattedFundDate.split('-');
        const monthIndexF = monthsIndex.indexOf(monthStrF);
        const yearF = parseInt(yearStrF, 10);

        // Period Lock Date
        const [monthStrP, yearStrP] = formattedPeriodLockDate.split('-');
        const monthIndexP = monthsIndex.indexOf(monthStrP);
        const yearP = parseInt(yearStrP, 10);

        // Configuration date 
        const [monthStrConfig, yearStrConfig] = accountSummaryData.monthYear.split('-');
        const monthIndexConfig = monthsIndex.indexOf(monthStrConfig);
        const yearConfig = parseInt(yearStrConfig, 10);

        const fundDate = new Date(yearF, monthIndexF, 1);
        const periodDate = new Date(yearP, monthIndexP, 1);
        const configDate = new Date(yearConfig, monthIndexConfig, 1);
        var result = this.getLockMsgOld(configDate, fundDate, periodDate, newDateFundLock, newDatePeriodLock);
        return result;
    }

    getLockMsgOld(configDate, compFundLockDate, UserLockDate, dateFundLock, datePeriodLock) {
        let data = this.params.api
        let currentUser = data.periodLockDate

        if ((compFundLockDate != null) && (UserLockDate != null)) {
            if (((new Date(compFundLockDate)) >= (new Date(UserLockDate))) && ((new Date(configDate)) <= (new Date(compFundLockDate)))) {
                this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('config_month_should_be_greater_than_fund_lock_date') + `(` + moment(dateFundLock).format('MM-DD-YYYY') + ` )`, });
                return "Fund Lock"
            } else if (((new Date(compFundLockDate)) <= (new Date(UserLockDate))) && ((new Date(configDate)) <= (new Date(UserLockDate)))) {
                this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('config_month_should_be_greater_than_lock_date') + `(` + moment(datePeriodLock).format('MM-DD-YYYY') + ` )`, });
                return "Period Lock"
            } else {
                return "No locks found";
            }
        } else if ((currentUser != null)) {
            if (((new Date(configDate)) <= (new Date(UserLockDate)))) {
                this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('config_month_should_be_greater_than_lock_date') + `(` + moment(datePeriodLock).format('MM-DD-YYYY') + ` )`, });
                return "Period Lock"
            }
        } else if ((compFundLockDate != null)) {
            if (((new Date(configDate)) <= (new Date(compFundLockDate)))) {
                this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('config_month_should_be_greater_than_fund_lock_date') + `(` + moment(dateFundLock).format('MM-DD-YYYY') + ` )`, });
                return "Fund Lock"
            } else {
                return "No locks found";
            }
        } else {
            return "No locks found";
        }
    }

    calculateExportDoc() {
        let selectedNode = this.params.node;
        let selectedData = selectedNode.data;
        if (selectedData.configId) {
            let fundId = selectedData.fundId;
            let configId = selectedData.configId;
            let url = environment.api_url + 'taxReturnDetails/downloadTaxAllocationRecords/' + configId
            // this.commonService.downloadCSV(url, 'Tax Allocation Records');
            // this.commonService.GenericDownload(url,'Tax Allocation Records');
        }
    }

    refresh(params?: any): boolean {
        this.params = params;
        this.data = this.params.node.data;
        return true;
    }

    getLockMsg(date, FundLockDate, UserLockDate) {
        if ((FundLockDate != null) && (UserLockDate != null)) {
            if (((new Date(FundLockDate)) >= (new Date(UserLockDate))) &&
                ((new Date(date)) <= (new Date(FundLockDate)))) {
                this.messageService.add({ sticky: true, severity: 'error', summary: this.transalteService.instant('error'), detail: this.transalteService.instant('date_should_be_greater_than_fund_lock_date') + `(` + moment(FundLockDate).format('MM-DD-YYYY') + ` )`, });
                return false;
            } else if (((new Date(FundLockDate)) <= (new Date(UserLockDate))) &&
                ((new Date(date)) <= (new Date(UserLockDate)))) {
                this.messageService.add({ sticky: true, severity: 'error', summary: this.transalteService.instant('error'), detail: this.transalteService.instant('date_should_be_greater_than_lock_date') + `(` + moment(UserLockDate).format('MM-DD-YYYY') + ` )`, });
                return false;
            }
        } else if ((this.periodLockDate != null)) {
            if (((new Date(date)) <= (new Date(UserLockDate)))) {
                this.messageService.add({ sticky: true, severity: 'error', summary: this.transalteService.instant('error'), detail: this.transalteService.instant('date_should_be_greater_than_lock_date') + `(` + moment(UserLockDate).format('MM-DD-YYYY') + ` )`, });
                return false;
            }
        } else if ((FundLockDate != null)) {
            if (((new Date(date)) <= (new Date(FundLockDate)))) {
                this.messageService.add({ sticky: true, severity: 'error', summary: this.transalteService.instant('error'), detail: this.transalteService.instant('date_should_be_greater_than_fund_lock_date') + `(` + moment(FundLockDate).format('MM-DD-YYYY') + ` )`, });
                return false;
            }
        }
    }

    onEdit($event) {
        let result;
        if (this.periodLockDateAccessor != "no" && ((this.data['fund'] && this.data['fund'].id) || (this.data['fundName'] && this.data['fundName'].id))) {
            var effectiveDate = new Date(this.data[this.periodLockDateAccessor]);
            let fundId = (this.data['fund'] && this.data['fund'].id) ? this.data['fund'].id : (this.data['fundName'] && this.data['fundName'].id) ? this.data['fundName'].id : '';
            var fundPeriodLockDate = this.commonService.getCellFundPeriodLockDate(fundId);
            result = this.getLockMsg(effectiveDate, fundPeriodLockDate, this.periodLockDate);
        }
        if(this.moduleName == 'marketData' && this.data['fund'] == null) {
            var effectiveDate = new Date(this.data[this.periodLockDateAccessor]);
            result = this.getLockMsg(effectiveDate, fundPeriodLockDate, this.periodLockDate);
        }
        if ((this.moduleName == 'dividend' || this.moduleName == 'bondInterestAccrual') && this.data['fund'] == null) {
            var effectiveDate = new Date(this.data[this.periodLockDateAccessor]);
            result = this.getLockMsg(effectiveDate, fundPeriodLockDate, this.periodLockDate);
        }
        if (this.moduleName == 'positionBroker' && this.data['fundId'] != null) {
            let fundId = this.data['fundId'] ;
            var effectiveDate = new Date(this.data[this.periodLockDateAccessor]);

            var fundPeriodLockDate = this.commonService.getCellFundPeriodLockDate(fundId);
            result = this.getLockMsg(effectiveDate, fundPeriodLockDate, this.periodLockDate);
           }
        if (result != false || this.periodLockDateAccessor == "no") {
            if (this.params.onRowEdit instanceof Function) {
                // this.params.node.data.isEditing = true;
                // this.data.isEditing = true;
                this.params.onRowEdit(this.params);
            }
        }
    }

    onSave($event) {
        if (this.params.onRowSave instanceof Function) {
            this.params.node.data.isEditing = false;
            this.data.isEditing = false;
            this.params.onRowSave(this.params);
        }
    }

    onCancel($event) {
        if (this.params.onRowCancel instanceof Function) {
            this.params.node.data = this.oldParamsNode;
            this.params.node.data.isEditing = false;
            this.data.isEditing = false;
            this.params.onRowCancel(this.params);
        }
    }

    // gets called whenever the cell refreshes
    viewRowDatarun(event) {
        this.gridService.seteditBtnMethod(this.params);
        this.gridService.setViewData(this.params);
    }
}