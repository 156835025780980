

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CellEditingStoppedEvent, CellValueChangedEvent, GridOptions } from 'ag-grid-community';
import { DynamicFormComponent } from '../../dynamic-form/dynamic-form.component';
import { GridFilterService } from './gridFilter.service';
import { fadeInRightAnimation } from '../../../../../src/@fury/animations/fade-in-right.animation';
import { fadeInUpAnimation } from '../../../../../src/@fury/animations/fade-in-up.animation';
import { GridFilterDropdownService } from './gridFilterDropdown.service';
import { Router } from '@angular/router';
import { HttpBackend, HttpClient } from '@angular/common/http';
import 'ag-grid-enterprise';
import { ViewBtnCellRenderer } from '../../buttons/view-button-cell-renderer.component';
import { GlobalService } from '../../services/commonServices/global.service';
import { apiConstants } from '../../../../../src/app/constants/apiConstants';
import moment from 'moment';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';
import { GridService } from '../../services/gridServices/grid.service';
import { CommonService } from '../../services/commonServices/common.service';
import { SetupPercentageService } from 'src/app/shared/services/commonServices/setup-percentage.service';
import {
  ColDef,
  FirstDataRenderedEvent,
  GridReadyEvent,
} from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from '../../dialogs/confirmation-dialog.component';
import _ from 'lodash';

import { LocalStoreService } from '../../services/commonServices/local-store.service';
import { AttachmentDownloadButton } from '../../buttons/attachment-download-button/attachment-download-button.component';
import { FundItem } from '../../services/commonServices/FundItem.interface';
import { EditCellDropDownEditor } from '../../buttons/edit-cell-dropdown/edit-cell-dropdown.component';
import { EditCellDateEditor } from '../../buttons/edit-cell-date/edit-cell-date-editor.compoent';
import { ButtonRendererComponent } from '../../buttons/edit-cell-button/edit-cell-button.component';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { EditCellDropDownRenderer } from '../../buttons/edit-cell-dropdown/edit-cell-dropdown-renderer.component';
import { EditCellDateRenderer } from '../../buttons/edit-cell-date/edit-cell-date-editor-renderer.component';
import { CookieService } from 'ngx-cookie-service';
import { EditCellNumberEditor } from '../../buttons/edit-cell-number/edit-cell-number-editor.compoent';
import { EditCellNumberRenderer } from '../../buttons/edit-cell-number/edit-cell-number-renderer.component';
import { environment } from 'src/environments/environment';
const api_url = environment['api_url'];
@Component({
  selector: 'app-grid-filter',
  templateUrl: './grid-filter.component.html',
  styleUrls: ['./grid-filter.component.scss'],
  providers: [GridFilterService],
  animations: [fadeInUpAnimation, fadeInRightAnimation]
})
export class GridFilterComponent implements OnInit {
  public formBasic: boolean = false;
  public formValid: boolean = false;
  @ViewChild(DynamicFormComponent) formEl: DynamicFormComponent;


  // Input Variables

  @Input() componentName: any;
  @Input() heading: String;
  @Input() subComponent: any;
  @Input() isNewBtn: Boolean
  @Input() isDeleteBtn: Boolean
  @Input() isExportBtn: Boolean
  @Input() isUploadBtn: Boolean
  @Input() modalHeading: String;
  @ViewChild('formBasic') formBasicInfo;
  @Input() isNotShowBreadCrum: Boolean
  @Input() isRapid: Boolean
  @Input() editableAPIList: any = [];
  @Input() afterEditableRowAPIList: any = [];
  @Input() updateRequestArray: any = {};
  @Input() editable: Boolean;
  @Input() periodLockDateAccessor: string;
  @Input() isPostPending: any;
  // Grid Properties 
  public modules: any[] = [ClientSideRowModelModule];

  public gridOptions: GridOptions;
  public gridProperties: any;
  public statusBar: any;
  public defaultColDef: any;
  public sideBar: any;
  public paginationPageSize: Number = 100;
  public cacheBlockSize: Number = 100;
  public gridApi: any;
  public gridColumnApi: any;
  public columnDefs: any = [];
  public rowData: any = [];
  rowHeight = 30;
  public frameworkComponents: any
  // Other Required Variables 
  reqObj1: any;
  public moduleNameGrid: any;
  public lookups: any = [];
  public gridShow: boolean = false;
  public basicDetails: any;
  public formData: any = {};
  public reqObj: any;
  public getFilteredReq: void;
  public totalFltRecords: any = 0;
  public routeUrl: any;
  public selectedRows: any = [];
  public lastSelectedNode: any;
  rowSelection: string;
  filteredData: any = [];
  public page: number = 0;
  public pageNumber: number = 1;
  public totalPages: number;
  public totalRecords: number;
  public selectedBroker: String
  public selectedBrokerDelete: String;
  public selectedTicker: String = "";
  public display: boolean = false;
  public brokerList: any = [];
  public custodianList: any = [];
  public tickerList: any = [];
  arrFunds: any = [];
  public istotalFilterCount: boolean = false;
  public displayForm: boolean = true;
  public transferTradeHave: boolean = false;
  deleteObjData: any;
  result: boolean;
  isFryFilterAct: any;
  isEmpty: boolean;
  topSearchClick: boolean;
  serchClickAfterFilter: boolean = false;
  public getNewFunctionName: boolean;
  public getuploadFunctionName: boolean;
  public getdeleteButtonFunctionName: boolean;
  public gridHeaders: any;
  public roleAs: any = JSON.parse(this.localService.getItem('fss'))['ROLE']
  card: any;
  collectionName: any;
  enterpriseId: any;
  currentUser: any = JSON.parse(this.localService.getItem('fss'));
  gridFilteredUrl: string;
  genericdata: any;
  authList = JSON.parse(this.localService.getItem('fss'))['authList'];
  clientId: any;
  monthNames: string[];
  public tempInstanceDataValue: any = [];
  fundname: any;
  exchangeName: any;
  clientfundId: any;
  public clientFundId: any = [];
  public updatedColumnData: any = {};
  fundId: any;
  subCategory: any;
  getEditFunctionName: boolean;
  JournalEnt: boolean;
  commentpopup: any;
  accountList: any;
  clientList:any;
  exchangeNameList: any;
  fundlist:any
  public iscommentpopup: boolean = false;
  constructor(private gridFilterService: GridFilterService, private dialog: MatDialog, private commonService: CommonService, public gridService: GridService, private messageService: GlobalMessageService, private globalService: GlobalService, handler: HttpBackend, private httpClient: HttpClient, private gridFilterDropdownService: GridFilterDropdownService, private translateService: TranslateService, private router: Router, private setupPercentageService: SetupPercentageService, private localService: LocalStoreService, private cookieService: CookieService) {

    this.httpClient = new HttpClient(handler);
    this.gridProperties = this.gridFilterService.gridProperties;
    ({ sideBar: this.sideBar, statusBar: this.statusBar, defaultColDef: this.defaultColDef } = this.gridProperties);
    // console.log(x);
    this.frameworkComponents = {
      viewBtnCellRenderer: ViewBtnCellRenderer,
      attachmentDownloadButton: AttachmentDownloadButton,
      DropDownEditor: EditCellDropDownEditor,
      DropDownCellRenderer: EditCellDropDownRenderer,
      DateEditor: EditCellDateEditor,
      DateCellRenderer: EditCellDateRenderer,
      NumberCellEditor: EditCellNumberEditor,
      NumberCellRenderer: EditCellNumberRenderer,
      editBtnCellRenderer: ButtonRendererComponent,
      periodLockDateAccessor: this.periodLockDateAccessor
    }
    this.componentName = this.gridFilterService.getComponentName();
    this.translateService.onLangChange.subscribe(() => {
      this.getGridHeader();
      setTimeout(() => {
        if (this.gridApi) {
          this.gridApi.refreshCells({ force: true });
        }
      }, 1000);
      // this.commonService.moduleNameObs$.subscribe((moduleName) => {
      //   this.moduleNameGrid = moduleName;
      //   this.translateService.get(this.moduleNameGrid).subscribe((res) => {
      //     this.moduleNameGrid = res;
      //   });
      // });
      this.moduleNameGrid = this.router.url.split('/')[1];
      switch (this.moduleNameGrid) {
        case 'trades':
          this.moduleNameGrid = 'trades_module';
          break;
        case 'portfolio':
          this.moduleNameGrid = 'portfolio';
          break;
        case 'accounting':
          this.moduleNameGrid = 'accounting';
          break;
        case 'investor':
          this.moduleNameGrid = 'investors';
          break;
        case 'processing':
          this.moduleNameGrid = 'processing';
          break;
        case 'workflow':
          this.moduleNameGrid = 'workflow';
          break;
        case 'reconciliation':
          this.moduleNameGrid = 'reconciliation';
          break;
        case 'query':
          this.moduleNameGrid = 'query';
          break;
        case 'batch-tracking':
          this.moduleNameGrid = 'batch_tracking';
          break;
        case 'reports':
          this.moduleNameGrid = 'seamless_reports';
          break;
        case 'setting':
          this.moduleNameGrid = 'settings';
          break;
        case 'manager':
          this.moduleNameGrid = 'manager';
          break;
        case 'MIS':
          this.moduleNameGrid = 'mis';
          break;
        case 'Entities':
          this.moduleNameGrid = 'Entities';
          break;
        case 'global-security-master':
          this.moduleNameGrid = 'Global Security Master';
          break;
        case 'api-management':
          this.moduleNameGrid = 'api_management';
          break;
        default:
          this.commonService.moduleNameObs$.subscribe((moduleName) => {
            this.moduleNameGrid = moduleName;
            this.translateService.get(this.moduleNameGrid).subscribe((res) => {
              this.moduleNameGrid = res;
            });
          });
          break;
      }
      this.translateService.get(this.moduleNameGrid).subscribe((res) => {
        this.moduleNameGrid = res;
      });
    })
    // this.columnDefs = this.gridFilterService.getColumns();
    // this.getformControls();
  }

  ngOnInit(): void {
    this.moduleNameGrid = this.router.url.split('/')[1];
    switch (this.moduleNameGrid) {
      case 'trades':
        this.moduleNameGrid = 'trades_module';
        break;
      case 'portfolio':
        this.moduleNameGrid = 'portfolio';
        break;
      case 'accounting':
        this.moduleNameGrid = 'accounting';
        break;
      case 'investor':
        this.moduleNameGrid = 'investors';
        break;
      case 'processing':
        this.moduleNameGrid = 'processing';
        break;
      case 'workflow':
        this.moduleNameGrid = 'workflow';
        break;
      case 'reconciliation':
        this.moduleNameGrid = 'reconciliation';
        break;
      case 'query':
        this.moduleNameGrid = 'query';
        break;
      case 'batch-tracking':
        this.moduleNameGrid = 'batch_tracking';
        break;
      case 'reports':
        this.moduleNameGrid = 'seamless_reports';
        break;
      case 'setting':
        this.moduleNameGrid = 'settings';
        break;
      case 'manager':
        this.moduleNameGrid = 'manager';
        break;
      case 'MIS':
        this.moduleNameGrid = 'mis';
        break;
      case 'global-security-master':
        this.moduleNameGrid = 'Global Security Master';
        break;
      case 'api-management':
        this.moduleNameGrid = 'api_management';
        break;
      default:
        this.commonService.moduleNameObs$.subscribe((moduleName) => {
          this.moduleNameGrid = moduleName;
          this.translateService.get(this.moduleNameGrid).subscribe((res) => {
            this.moduleNameGrid = res;
          });
        });
        break;
    }
    this.translateService.get(this.moduleNameGrid).subscribe((res) => {
      this.moduleNameGrid = res;
    });

    if (this.roleAs == "SystemManager") {
      this.card = apiConstants[this.componentName]['systemAdminCard']
    } else if (this.roleAs == "Manager" || this.roleAs == "enterprise") {
      this.card = apiConstants[this.componentName]['entCard']
    } else if (this.roleAs == "User") {
      this.card = apiConstants[this.componentName]['userCard']
    } else { undefined }

    this.getformControls();
    this.routeUrl = apiConstants[this.componentName]['routeUrl'] ? apiConstants[this.componentName]['routeUrl'] : {};
    this.getNewFunctionName = apiConstants[this.componentName]?.authFunctions?.newButtonFunction ? this.authList.indexOf(apiConstants[this.componentName]?.authFunctions.newButtonFunction) > -1 : true;
    this.getdeleteButtonFunctionName = apiConstants[this.componentName]?.authFunctions?.deleteButtonFunction ? this.gridService.checkMultiAuth(apiConstants[this.componentName]?.authFunctions.deleteButtonFunction, this.authList) : true;
    this.getuploadFunctionName = apiConstants[this.componentName]?.authFunctions?.uploadButtonFunction ? this.authList.indexOf(apiConstants[this.componentName]?.authFunctions.uploadButtonFunction) > -1 : true;
    this.gridHeaders = apiConstants[this.componentName]['headers'];
    // this.commonService.moduleNameObs$.subscribe((moduleName) => {
    //   this.moduleNameGrid = moduleName;
    // });
    if (this.componentName != 'viewDataCollection') {
      this.getGridHeader();
    }
  }
  getGridHeader() {
    let generic = this.genericdata;
    if (this.editable) {
      this.gridFilterService.getEditableAPI(this.editableAPIList, this.rowData).subscribe((response: any) => {
        this.gridFilterService.getGridHeader(this.gridHeaders, generic, (cbs) => {
          this.columnDefs = cbs;
          this.columnDefs.forEach(element => {
            let heder = element.headerName;
            this.translateService.get([heder]).subscribe((traval) => {
              if (traval[heder] != undefined || traval[heder] != null) {
                element.headerName = traval[heder];
              }
            });
            if (element.filter == 'agNumberColumnFilter') {
              element['cellStyle'] = { "text-align": "right" };
              element['type'] = 'rightAligned';
            }

            if (element.cellRenderer == 'editBtnCellRenderer') {
              element['cellRendererParams'] = {
                onRowEdit: this.onRowEdit.bind(this),
                onRowSave: this.onRowEditingSave.bind(this),
                onRowCancel: this.onRowEditingCancel.bind(this)
              }
            }
            if (element.editable && element.renderType == 'dropdown') {
              let apiDetails = this.editableAPIList.filter(item => { return item.updateField.includes(element.updateField) })[0];
              if (apiDetails) {
                let index = apiDetails["key"];
                let label = apiDetails["label"];
                let value = apiDetails["value"];
                let fields = apiDetails["fields"];
                let resultList = (response[index] && Array.isArray(response[index])) ? response[index] : (response[index] && response[index].resultList) ? response[index].resultList : [];
                let result = [];
                if (fields) {
                  resultList.forEach(element => {
                    element.fields.forEach(item => {
                      result.push({ label: item.value, value: item.value })
                    })
                  });
                  element["cellEditorParams"].values = result;
                } else {
                  result = resultList;
                  element["cellEditorParams"].values = resultList.map(item => ({ label: item[label], value: value ? item[value] : item.id }));
                }
              }
            }
          });
        }, (cbe) => {
        });
      });
    } else {
      this.gridFilterService.getGridHeader(this.gridHeaders, generic, (cbs) => {
        this.columnDefs = cbs;
        this.columnDefs.forEach(element => {
          let heder = element.headerName;
          this.translateService.get([heder]).subscribe((traval) => {
            if (traval[heder] != undefined || traval[heder] != null) {
              element.headerName = traval[heder];
            }
          });
          if (element.filter == 'agNumberColumnFilter') {
            element['cellStyle'] = { "text-align": "right" };
            element['type'] = 'rightAligned';
          }
          if ((this.componentName == 'tradeList' || this.componentName == "rapidTradeList") && element.field == "baseAmount" || element.field == "quantity" || element.field == "nfaFees" || element.field == "localAmount" || element.field == "ticketCharges" || element.field == "commission" || element.field == "exchangeFees" || element.field == "clearingFee" || element.field == "parValue" || element.field == "purchaseInterest" || element.field == "sellInterest") {
            element['valueFormatter'] = this.commonService.numFormat;
          }
        })
      }, (cbe) => {
      });
    }
  }

  onRowEdit(params) {
    params.node.data.isEditingCancel = false;
    params.node.data.isEditingSave = false;
    params.api.isEditing = true;
    params.api['isEditingLoad'] = true;
    let data = params.node;
    this.gridFilterService.getEditableAPI(this.afterEditableRowAPIList, data).subscribe((response: any) => {
      this.columnDefs.forEach(element => {
        if (element.editable && element.renderType == 'dropdown') {
          let apiDetails = this.afterEditableRowAPIList.filter(item => { return item.updateField.includes(element.updateField) })[0];
          if (apiDetails) {
            let index = apiDetails["key"];
            let label = apiDetails["label"];
            let resultList = (response[index] && Array.isArray(response[index])) ? response[index] : (response[index] && response[index].resultList) ? response[index].resultList : [];
            element["cellEditorParams"].values = resultList.map(item => ({ label: item[label], value: item.id }));
          }
        }
      });
      this.gridApi.setColumnDefs(this.columnDefs);
      params.api.startEditingCell({
        rowIndex: params.node.rowIndex,
        // gets the first columnKey
        colKey: params.columnApi.getDisplayedCenterColumns()[0].colId
      });
    });
  }

  onRowEditingSave(params) {
    params.node.data.isEditingCancel = false;
    params.node.data.isEditingSave = true;
    params.api.stopEditing(false);

  }

  onRowEditingCancel(params) {
    params.node.data.isEditingCancel = true;
    params.node.data.isEditingSave = false;
    params.api.stopEditing(true);
  }

  onRowEditingStarted(params) {
    // this.gridFilterService.getEditableAPI(this.afterEditableRowAPIList, params).subscribe((response: any) => {
    //   params.api.refreshCells({
    //     columns: ["action"],
    //     rowNodes: [params.node],
    //     force: true
    //   });
    //   this.gridFilterService.getGridHeader(this.gridHeaders, this.genericdata, (cbs) => {
    //     this.columnDefs = cbs;
    //     this.columnDefs.forEach(element => {
    //       let heder = element.headerName;
    //       if (element.editable && element.renderType == 'dropdown') {
    //         // if (element.cellRenderer == 'actionCellRenderer') {
    //         //   element.cellRenderer = actionCellRenderer
    //         // }
    //         let apiDetails = this.afterEditableRowAPIList.filter(item => { return item.updateField == element.updateField })[0];
    //         if (apiDetails) {
    //           let index = apiDetails["key"];
    //           let label = apiDetails["label"];
    //           let resultList = response[index] && response[index].resultList ? response[index].resultList : response[index] && response[index].length ? response[index] : [];
    //           element["cellEditorParams"].values = resultList.map(item => ({ label: item[label], value: item.id }));
    //           params.api.refreshCells({
    //             columns: [apiDetails["columns"]],
    //             rowNodes: [params.node],
    //             force: true
    //           });
    //         }
    //       }
    //       this.translateService.get([heder]).subscribe((traval) => {
    //         if (traval[heder] != undefined || traval[heder] != null) {
    //           element.headerName = traval[heder];
    //         }
    //       });
    //     });
    //   }, (cbe) => {
    //   });
    //   this.columnDefs[0]['cellRendererParams'] = {
    //     onRowEdit: this.onRowEdit.bind(this),
    //     onRowSave: this.onRowEditingSave.bind(this),
    //     onRowCancel: this.onRowEditingCancel.bind(this)
    //   }
    // });
    // this.gridApi.refreshCells({
    //   rowNodes: [params.node]
    // });
    params.node.data.isEditing = true;
    params.node.data.isEditingCancel = false;
    params.node.data.isEditingSave = false;
    params.api['isEditingLoad'] = false;
    params.api.refreshCells({
      columns: ["action"],
      rowNodes: [params.node],
      force: true
    });
  }

  onRowEditingStopped(params: CellEditingStoppedEvent) {
    //handle outside click on edit progress
    if (params.api['isEditingLoad']) {
      params.api.startEditingCell({
        rowIndex: params.node.rowIndex,
        colKey: params.columnApi.getDisplayedCenterColumns()[0]["colId"]
      });
      return;
    }
    //make sure stop editing
    if (!params.node.data.isEditingCancel && !params.node.data.isEditingSave) {
      params.node.data.isEditing = false;
      params.api['isEditing'] = false;
      return;
    }
    //set data
    params.node.data.isEditing = false;
    let componentRequest = this.createReqObj(this.updateRequestArray, params);
    let requestData = componentRequest["data"];
    if (componentRequest.formValid && params.node.data.isEditingSave) {
      if (this.periodLockDateAccessor != "no") {
        let dateAccessor = this.periodLockDateAccessor || 'date';
        let date = requestData[dateAccessor];
        let dateArray = date.split("-");
        var effectiveDate = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
        let fundId: string;
        if (requestData['fund'] && requestData['fund'].id) {
          fundId = requestData['fund'].id
        } else if (requestData['fundName'] && requestData['fundName'].id) {
          fundId = requestData['fundName'].id
        }
        var fundPeriodLockDate = this.commonService.getCellFundPeriodLockDate(fundId);
        this.result = this.getLockMsg(effectiveDate, fundPeriodLockDate, this.currentUser.periodLockDate);
      }
      if (this.result != false || this.periodLockDateAccessor == "no") {
        this.updateEditableRow(requestData);
      } else {
        params.api['isEditing'] = false;
        this.fetchGridData();
        this.gridApi.setFilterModel(null);
      }
    } else if (params.node.data.isEditingSave) {
      params.api['isEditing'] = false;
      if (this.componentName == "RapidJournalentry" && componentRequest.differenceAmount) {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('dr_amount_equal_cr_amount') });
      } else {
        this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: "Error", detail: "Please fill required fields" });
      }
    } else {
      params.api['isEditing'] = false;
    }
    //update columns
    this.columnDefs.forEach(element => {
      if (element.editable || element.colId == "action") {
        params.api.refreshCells({
          rowNodes: [params.node],
          columns: [element.field],
          suppressFlash: true,
          force: true
        });
      }
    });
  }

  onCellClicked(params) {
    this.componentName = this.gridFilterService.getComponentName();
    if (params?.value && params?.colDef?.field == 'details' && (this.componentName == 'dataMonitoring' || this.componentName == 'dataMonitoringinput')) {
      this.commentpopup = params.value;
      this.iscommentpopup = true;
    }
    // Handle click event for action cells
    // if (
    //   params.column.colId === "action" &&
    //   params.event.target.dataset.action
    // ) {
    //   let action = params.event.target.dataset.action;

    //   if (action === "edit") {
    //     params.api.startEditingCell({
    //       rowIndex: params.node.rowIndex,
    //       // gets the first columnKey
    //       colKey: params.columnApi.getDisplayedCenterColumns()[0].colId
    //     });
    //   }

    //   if (action === "delete") {
    //     params.api.applyTransaction({
    //       remove: [params.node.data]
    //     });
    //   }

    //   if (action === "update") {
    //     params.api.stopEditing(false);
    //   }

    //   if (action === "cancel") {
    //     params.api.stopEditing(true);
    //   }
    // }
  }

  onCellValueChanged(params: CellValueChangedEvent) {
    var colId = params.column.getId();
    let userProvidedColDef = params.column['userProvidedColDef'];
    let updateField = userProvidedColDef.updateField;
    let dropdownField = userProvidedColDef.dropdownField;
    if (dropdownField) {
      this.updatedColumnData[updateField] = params.data[dropdownField];
    } else {
      this.updatedColumnData[updateField] = params.data[colId];
    }
  }

  updateEditableRow(requestData) {
    let updateUrl = apiConstants[this.componentName].updateUrl;
    let dataToSave;
    if (this.componentName == "rapidNonTradeActivity" || this.componentName == 'fetchBankTransaction') {
      dataToSave = btoa(JSON.stringify({ data: [requestData] }))
    } else if (this.componentName == "RapidJournalentry") {
      dataToSave = this.isConvert(JSON.stringify(requestData))
    } else if (this.componentName == 'disableAutoUpdateViaAllocationUpdate' || this.componentName == 'redemptionActivityUpdate' || this.componentName == 'subscriptionActivityUpdate' || this.componentName == 'fetchCsdBankTransactionPendingReview') {
      dataToSave = requestData
    }
    this.gridFilterService.updateData(updateUrl, dataToSave).subscribe((response: any) => {
      this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: this.translateService.instant('record_updated') });
      this.gridApi['isEditing'] = false;
      this.gridApi.setFilterModel(null);
      this.fetchGridData();
    }, (error) => {
      this.gridApi['isEditing'] = false;
      this.gridApi.setFilterModel(null);
      this.fetchGridData();
    });
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  fetchGridData() {
    if (this.componentName == 'disableAutoUpdateViaAllocationUpdate' || this.componentName == 'redemptionActivityUpdate' || this.componentName == 'subscriptionActivityUpdate') {
      this.commonService.getSubCategory().subscribe((response: any) => {
        this.subCategory = response;
        this.removeActions();
        this.getGridData(this.subCategory);
        const filtredModel = this.gridApi.getFilterModel();
        this.isEmptyObject(filtredModel)
        if (!this.isEmptyObject && !filtredModel.hasOwnProperty('transactionType')) {
          this.gridApi['transactionType'] = null;
          this.formData['transactionType'] = null;
          this.gridApi['caFormData'] = this.formData;
        }
      });
    }
    else if (this.componentName == 'fetchCsdBankTransactionPendingReview') {
      this.getFilteredData();
      this.displayForm = true;

    }
    else {
      this.getGridData();
    }
  }

  createReqObj(updateRequestArray, params) {
    let nodeData = params.node.data;
    let editReqData = {};
    let formValid = true;
    let gridReqData = {};
    let errorMsg = "Please fill required fields";
    //get editable field request data 
    let gridRequestParams: any = {};
    let gridExcludeParams: any;
    updateRequestArray.forEach(requestData => {
      if (requestData["type"] == "gridExclude") {
        gridExcludeParams = requestData["fields"];
      } else if (requestData["type"] == "editReqObj") {
        gridRequestParams = requestData["fields"];
      } else if (requestData["type"] == "array") {
        if (nodeData[requestData["dataAccessor"]]) {
          editReqData[requestData["updateField"]] = Array.isArray(nodeData[requestData["dataAccessor"]]) ? nodeData[requestData["dataAccessor"]] : [nodeData[requestData["dataAccessor"]]];
        } else {
          formValid = requestData["required"] ? false : formValid;
          if (requestData["emptyAccessor"] != "none")
            editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
        }
      } else if (requestData["type"] == "object") {
        if (nodeData[requestData["dataAccessor"]]) {
          editReqData[requestData["updateField"]] = { id: nodeData[requestData["dataAccessor"]] };
        } else {
          formValid = requestData["required"] ? false : formValid;
          editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
        }
        if (requestData["dataAccessor"] == "fund" && nodeData.fund) {
          editReqData[requestData["updateField"]] = { id: nodeData.fund.id };
        }
        if (requestData["dataAccessor"] == "fundName" && nodeData.fundName) {
          editReqData[requestData["updateField"]] = { id: nodeData.fundName.id };
        }
      } else if (requestData["type"] == "file") {
        editReqData[requestData["updateField"]] = nodeData[requestData["dataAccessor"]] || null;
      } else if (requestData["type"] == "date") {
        if (nodeData[requestData["dataAccessor"]]) {
          let date = nodeData[requestData["dataAccessor"]];
          editReqData[requestData["updateField"]] = moment(date).format('YYYY-MM-DD');
        } else {
          formValid = requestData["required"] ? false : formValid;
          editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
        }
      }
      if (this.componentName == 'fetchBankTransaction' || this.componentName == 'rapidNonTradeActivity') {
        if (requestData["type"] == "number") {
          if (nodeData.hasOwnProperty(requestData["dataAccessor"])) {
            editReqData[requestData["updateField"]] = Number(nodeData[requestData["dataAccessor"]]);
          } else {
            formValid = requestData["required"] ? false : formValid;
            if (requestData["emptyAccessor"] !== "none")
              editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
            else
              editReqData[requestData["updateField"]] = 0; // Set to 0 if not "none"
          }

        }
        if (requestData["type"] == "string") {
          if (nodeData[requestData["dataAccessor"]]) {
            editReqData[requestData["updateField"]] = nodeData[requestData["dataAccessor"]];
          }
          else {
            formValid = requestData["required"] ? false : formValid;
            editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          }
        }
        if (requestData["type"] == "boolean") {
          if (nodeData[requestData["dataAccessor"]]) {
            editReqData[requestData["updateField"]] = nodeData[requestData["dataAccessor"]];
          }
          else {
            formValid = requestData["required"] ? false : formValid;
            editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          }
        }
      }
      else {
        if (requestData["type"] == "number") {
          if (nodeData[requestData["dataAccessor"]]) {
            editReqData[requestData["updateField"]] = Number(nodeData[requestData["dataAccessor"]]);
          } else {
            formValid = requestData["required"] ? false : formValid;
            if (requestData["emptyAccessor"] != "none")
              editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          }
        }
        else {
          if (nodeData[requestData["dataAccessor"]]) {
            editReqData[requestData["updateField"]] = nodeData[requestData["dataAccessor"]];
          } else {
            formValid = requestData["required"] ? false : formValid;
            editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          }
        }
      }
    });
    if (gridExcludeParams) {
      for (var key in gridExcludeParams) {
        delete editReqData[gridExcludeParams[key]];
      }
    }
    if (gridRequestParams) {
      //merge editable data and default request obj data
      Object.assign(editReqData, gridRequestParams);
    }
    let requestData;
    if (this.componentName == "rapidNonTradeActivity" || this.componentName == 'fetchBankTransaction') {
      requestData = {
        formValid: formValid,
        data: editReqData
      }
    } else if (this.componentName == "RapidJournalentry") {
      editReqData['drAmount1Calculation'] = nodeData['drAmount1Calculation'];
      editReqData['crAmount1Calculation'] = nodeData['crAmount1Calculation'];
      editReqData['jeId'] = nodeData['jeId'];
      editReqData['insertedBy'] = nodeData['insertedBy'];
      if (nodeData['insertedOn']) {
        let strDate = nodeData['insertedOn'].substring(0, nodeData['insertedOn'].length - 1);
        editReqData['insertedOn'] = Date.parse(strDate);
      }
      if (nodeData['updatedOn']) {
        let strDate = nodeData['updatedOn'].substring(0, nodeData['insertedOn'].length - 1);
        editReqData['updatedOn'] = Date.parse(strDate);
      }
      let differenceAmount;
      var drAmt = editReqData['drAmount1'] * editReqData['fXRateDrAmount1'];
      var crAmt = editReqData['crAmount1'] * editReqData['fXRateCrAmount1'];
      if ((drAmt.toFixed(8)) != (crAmt.toFixed(8))) {
        differenceAmount = true;
        formValid = false;
      }
      requestData = {
        formValid: formValid,
        differenceAmount: differenceAmount,
        data: editReqData
      }
    }
    else if (this.componentName == 'disableAutoUpdateViaAllocationUpdate' || this.componentName == 'redemptionActivityUpdate' || this.componentName == 'subscriptionActivityUpdate') {
      let tansactionGroup1 = ['Transfer - Taxable', 'Assignment - taxable', 'Assignment - tax free', 'Transfer - Tax free'];
      //pre define grid data as request data
      Object.keys(nodeData).forEach(x => {
        if (!nodeData[x]) {
          gridReqData[x] = "";
        } else if (Array.isArray(nodeData[x])) {
          if (nodeData[x].length) {
            // let data = [];
            // if (typeof nodeData[x][0] == 'object' && nodeData[x][0] != null && !Array.isArray(nodeData[x][0])) {
            //   nodeData[x].forEach(element => {
            //     data.push({ id: element.id });
            //   });
            // } else {
            //   nodeData[x].forEach(element => {
            //     data.push(element);
            //   });
            // }
            gridReqData[x] = nodeData[x];
          } else {
            gridReqData[x] = [];
          }
        } else if (typeof nodeData[x] === 'object') {
          let id = nodeData[x].id || ''
          gridReqData[x] = { id: id };
        } else {
          gridReqData[x] = nodeData[x];
        }
      });
      for (var key in gridReqData) {
        if (gridReqData.hasOwnProperty(key)) {
          if (!gridReqData[key])
            delete gridReqData[key];
        }
      }
      //get editable field request data 
      let gridExcludeParams: any;
      updateRequestArray.forEach(requestData => {
        if (requestData["type"] == "gridExclude") {
          gridExcludeParams = requestData["fields"];
          // requestData["fields"].forEach(element => {
          //   if (nodeData[element]) {
          //     editReqData[element] = nodeData[element] || "";
          //   }
          // });
        } else if (requestData["type"] == "array") {
          if (nodeData[requestData["dataAccessor"]]) {
            editReqData[requestData["updateField"]] = Array.isArray(nodeData[requestData["dataAccessor"]]) ? nodeData[requestData["dataAccessor"]] : [nodeData[requestData["dataAccessor"]]];
          } else {
            formValid = requestData["required"] ? false : formValid;
            if (requestData["emptyAccessor"] != "none")
              editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          }
        } else if (requestData["type"] == "object") {
          if (nodeData[requestData["dataAccessor"]]) {
            editReqData[requestData["updateField"]] = { id: nodeData[requestData["dataAccessor"]] };
          } else {
            formValid = requestData["required"] ? false : formValid;
            if (requestData["emptyAccessor"] != "none")
              editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          }
          if (requestData["dataAccessor"] == "fund" && nodeData.fund) {
            editReqData[requestData["updateField"]] = { id: nodeData.fund.id };
          }
          if (requestData["dataAccessor"] == "fundName" && nodeData.fundName) {
            editReqData[requestData["updateField"]] = { id: nodeData.fundName.id };
          }
        } else if (requestData["type"] == "file") {
          editReqData[requestData["updateField"]] = nodeData[requestData["dataAccessor"]] || null;
        } else if (requestData["type"] == "date") {
          if (nodeData[requestData["dataAccessor"]] && nodeData[requestData["dataAccessor"]] != "Invalid date") {
            let date = nodeData[requestData["dataAccessor"]];
            editReqData[requestData["updateField"]] = moment(date).format('YYYY-MM-DD');
          } else {
            formValid = requestData["required"] ? false : formValid;
            if (requestData["emptyAccessor"] != "none")
              editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          }
        } else if (requestData["type"] == "number") {
          if (nodeData[requestData["dataAccessor"]]) {
            let min = requestData["min"];
            if ((min || min === 0) && nodeData[requestData["dataAccessor"]] < min) {
              formValid = false;
              errorMsg = requestData["updateField"] + " value can't be less than " + min;
            } else {
              editReqData[requestData["updateField"]] = Number(nodeData[requestData["dataAccessor"]]);
            }
          } else {
            formValid = requestData["required"] ? false : formValid;
            if (requestData["emptyAccessor"] != "none")
              editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          }
        } else {
          if (nodeData[requestData["dataAccessor"]]) {
            editReqData[requestData["updateField"]] = nodeData[requestData["dataAccessor"]];
          } else {
            formValid = requestData["required"] ? false : formValid;
            if (requestData["emptyAccessor"] != "none")
              editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          }
        }
      });
      if (gridExcludeParams) {
        for (var key in gridExcludeParams) {
          delete gridReqData[gridExcludeParams[key]];
        }
      }
      //merge editable data and grid data
      Object.assign(gridReqData, editReqData);
      Object.assign(this.formData, this.formEl.form.value);
      delete gridReqData['fetch'];
      delete gridReqData['otherInfoSubLabel'];
      delete gridReqData['transaactionLabel'];
      delete gridReqData['chooseInvestor'];
      delete gridReqData['supportFiles'];
      gridReqData['updatedBy'] = this.currentUser['id'];
      gridReqData['transferor'] = gridReqData['transferor'] ? { id: gridReqData['transferor'].id } : null;
      gridReqData['transferee'] = gridReqData['transferee'] ? { id: gridReqData['transferee'].id } : null;
      gridReqData['investor'] = gridReqData['investor'] || null;
      gridReqData['transactionType'] = gridReqData['transactionType'];
      gridReqData['fundingFactor'] = gridReqData['fundingFactor'] || 1;
      if (gridReqData['dateOfEntry']) {
        gridReqData['dateOfEntry'] = moment(gridReqData['dateOfEntry'], 'YYYY-MM-DD').format('YYYY-MM-DD');
      } else {
        gridReqData['dateOfEntry'] = '';
      }
      if (gridReqData['effectivedate']) {
        gridReqData['effectivedate'] = moment(gridReqData['effectivedate'], 'YYYY-MM-DD').format('YYYY-MM-DD');
      } else {
        gridReqData['effectivedate'] = "";
      }
      gridReqData['amount'] = gridReqData['amount'] || gridReqData['amountValue'];
      gridReqData['amount'] = Number(gridReqData['amount']);
      if (gridReqData['shares'] > 0 || gridReqData['transferShares'] > 0) {
        gridReqData['shares'] = gridReqData['shares'] || gridReqData['transferShares'];
      }
      gridReqData['transferstatus'] = gridReqData['transferstatus'] || "No";
      // for testing
      gridReqData['accountNumber'] = gridReqData['accountNumber'] || [];
      gridReqData['performanceFeeCrystalized'] = gridReqData['performanceFeeCrystalized'] || gridReqData['tperformanceFeeCrystalized'];
      if (!tansactionGroup1.includes(gridReqData['transactionType'])) {
        gridReqData['investorName'] = gridReqData['investorName'];
      }
      if (tansactionGroup1.includes(gridReqData['transactionType'])) {
        gridReqData['perValueOfAc'] = [];
      }
      delete gridReqData['transferSeries'];
      delete gridReqData['transfereeSeries'];
      delete gridReqData['transferShareClass'];
      delete gridReqData['transfereeShareClass'];
      delete gridReqData['transferShareClass'];
      delete gridReqData['transfereeShareClass'];
      delete gridReqData['investorNumber'];
      delete gridReqData['transferShares']
      delete gridReqData['transfereeAccountNumber']
      delete gridReqData['transfereeAmount']
      delete gridReqData['transfereeDistributionBase']
      delete gridReqData['transfereeHurdlebase']
      delete gridReqData['transfereeManagementFeeBase']
      delete gridReqData['transfereeNumber']
      delete gridReqData['transfereePerValueOfAc']
      delete gridReqData['transfereeRorinception']
      delete gridReqData['transfereeRormonth']
      delete gridReqData['transfereeRorquarter']
      delete gridReqData['transfereeRorweek']
      delete gridReqData['transfereeRoryear']
      delete gridReqData['transfereeShares']
      delete gridReqData['transfereeTransferHWM']
      delete gridReqData['transfereeperPriorDayPerformancefee']
      delete gridReqData['transfereeperformanceFeeBase']
      delete gridReqData['tperformanceFeeCrystalized']
      delete gridReqData['TransferorInvestorNumber']
      delete gridReqData['transferPerValueOfAc']
      if (!tansactionGroup1.includes(gridReqData['transactionType'])) {
        delete gridReqData['transferPrecentage']
      }
      delete gridReqData['amountValue'];
      delete gridReqData['fetchTransfer'];
      delete gridReqData['Transferee'];
      delete gridReqData['transfereeassetBaseFeeOnIncome'];
      delete gridReqData['transfereecumulativeIncome'];
      delete gridReqData['transfereehurdleAmount'];
      delete gridReqData['Transferor'];
      delete gridReqData['isDisabled'];
      for (var key in gridReqData) {
        if (gridReqData.hasOwnProperty(key)) {
          if (!gridReqData[key])
            delete gridReqData[key];
        }
      }
      gridReqData['investor'] = gridReqData['investor'] || null;
      gridReqData['fundingMethod'] = gridReqData['fundingMethod'] || "";
      gridReqData['assetBasedFee'] = gridReqData['assetBasedFee'] || "";
      gridReqData['eitherORMethod'] = gridReqData['eitherORMethod'] || "";
      gridReqData['oldAccNumber'] = gridReqData['oldAccNumber'] || "";
      if (gridReqData['shares'] != 0) {
        gridReqData['shares'] = gridReqData['shares'] || null;
      }
      gridReqData['performanceFee'] = gridReqData['performanceFee'] || "";
      gridReqData['redemptionFee'] = gridReqData['redemptionFee'] || "";
      gridReqData['reductionInLCF'] = gridReqData['reductionInLCF'] || "";
      gridReqData['fundedCommitmentApplicable'] = gridReqData['fundedCommitmentApplicable'] || "No";
      gridReqData['custodianAccNumber'] = gridReqData['custodianAccNumber'] || null;
      gridReqData['custodianAccId'] = gridReqData['custodianAccId'] || null;
      gridReqData['perValueOfAc'] = gridReqData['perValueOfAc'] ? [gridReqData['perValueOfAc']] : null;

      let transactionType = this.formData?.['transactionType']?.["id"] ? this.formData['transactionType']['id'] : null;
      let reqObjTransType = gridReqData?.['transactionType'];
      if (transactionType == "Subscription - Transfer" && reqObjTransType == "Subscription - Transfer") {
        gridReqData['transferActivity'] = true;
        gridReqData['transactionType'] = "Subscription - First Time";
      } else if (transactionType == "Redemption - Transfer" && reqObjTransType == "Redemption - Transfer") {
        gridReqData['transferActivity'] = true;
        gridReqData['transactionType'] = "Redemption - Partial by Amount";
      }

      requestData = {
        formValid: formValid,
        data: gridReqData
      }
    }
    else if (this.componentName == "fetchCsdBankTransactionPendingReview") {

      updateRequestArray.forEach(requestData => {
        if (requestData["type"] == "gridExclude") {
          gridExcludeParams = requestData["fields"];
        } else if (requestData["type"] == "object") {
          if (nodeData[requestData["dataAccessor"]]) {
            editReqData[requestData["updateField"]] = { id: nodeData[requestData["dataAccessor"]] };
          } else {
            formValid = requestData["required"] ? false : formValid;
            editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          }
          if (requestData["dataAccessor"] == "transactionId" && nodeData.transactionType) {
            editReqData[requestData["updateField"]] = nodeData.transactionType;
          }
          if (requestData["dataAccessor"] == "investorId" && nodeData.investorNo) {
            editReqData[requestData["updateField"]] = nodeData.investorNo;
          }
          if (requestData["dataAccessor"] == "vendorId" && nodeData.vendorName) {
            editReqData[requestData["updateField"]] = nodeData.vendorName;
          }
        } else if (requestData["type"] == "date") {
          if (nodeData[requestData["dataAccessor"]]) {
            let date = nodeData[requestData["dataAccessor"]];
            editReqData[requestData["updateField"]] = moment(date).format('YYYY-MM-DD');
          } else {
            formValid = requestData["required"] ? false : formValid;
            editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          }
        }
        if (requestData["type"] == "number") {
          if (nodeData.hasOwnProperty(requestData["dataAccessor"])) {
            editReqData[requestData["updateField"]] = Number(nodeData[requestData["dataAccessor"]]);
          } else {
            formValid = requestData["required"] ? false : formValid;
            if (requestData["emptyAccessor"] !== "none")
              editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
            else
              editReqData[requestData["updateField"]] = 0; // Set to 0 if not "none"
          }

        }
        if (requestData["type"] == "string") {
          if (nodeData[requestData["dataAccessor"]]) {
            editReqData[requestData["updateField"]] = nodeData[requestData["dataAccessor"]];
          }
          else {
            formValid = requestData["required"] ? false : formValid;
            editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          }
        }
        if (requestData["type"] == "boolean") {
          if (nodeData[requestData["dataAccessor"]]) {
            editReqData[requestData["updateField"]] = nodeData[requestData["dataAccessor"]];
          }
          else {
            formValid = requestData["required"] ? false : formValid;
            editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          }
        }

      });
      editReqData['id'] = nodeData['id'];
      if (gridExcludeParams) {
        for (var key in gridExcludeParams) {
          delete editReqData[gridExcludeParams[key]];
        }
      }
      if (gridRequestParams) {
        //merge editable data and default request obj data
        Object.assign(editReqData, gridRequestParams);
      }

      requestData = {
        formValid: formValid,
        data: editReqData
      }
    }

    return requestData;
  }

  isConvert(data) {
    // Create a TextEncoder instance
    const encoder = new TextEncoder();
    // Encode the string as a Uint8Array
    const encodedData = encoder.encode(data);
    // Convert the Uint8Array to base64
    const base64 = btoa(String.fromCharCode.apply(null, encodedData));
    return base64;
  }

  getLockMsg(date, FundLockDate, UserLockDate) {
    if ((FundLockDate != null) && (UserLockDate != null)) {
      if (((new Date(FundLockDate)) >= (new Date(UserLockDate))) &&
        ((new Date(date)) <= (new Date(FundLockDate)))) {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('date_should_be_greater_than_fund_lock_date') + `(` + moment(FundLockDate).format('MM-DD-YYYY') + ` )`, });
        return false;
      } else if (((new Date(FundLockDate)) <= (new Date(UserLockDate))) &&
        ((new Date(date)) <= (new Date(UserLockDate)))) {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('date_should_be_greater_than_lock_date') + `(` + moment(UserLockDate).format('MM-DD-YYYY') + ` )`, });
        return false;
      }
    } else if ((this.currentUser.periodLockDate != null)) {
      if (((new Date(date)) <= (new Date(UserLockDate)))) {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('date_should_be_greater_than_lock_date') + `(` + moment(UserLockDate).format('MM-DD-YYYY') + ` )`, });
        return false;
      }
    } else if ((FundLockDate != null)) {
      if (((new Date(date)) <= (new Date(FundLockDate)))) {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('date_should_be_greater_than_fund_lock_date') + `(` + moment(FundLockDate).format('MM-DD-YYYY') + ` )`, });
        return false;
      }
    }
  }

  getformControls() {
    if (this.componentName != 'dataMonitoring' || this.componentName != 'dataMonitoringinput') {
      this.commonService.fetchFundList().subscribe((response) => {
        this.arrFunds = response.resultList?.map((item: FundItem) => ({
          fundPeriodLockDate: item.fundPeriodLockDate,
          fundName: item.fundName,
          fundAlias: item.fundAlias,
          id: item.id
        }));
        let val = JSON.parse(this.localService.getItem('fss'));
        if (val) {
          val['fundlist'] = this.arrFunds
          this.localService.setItem('fss', JSON.stringify(val));
        }
        // localStorage.setItem('fundlist', JSON.stringify(this.arrFunds))
      });
    }
    if (this.componentName != 'reBudgets' && this.componentName != "rapidReBudgets" && this.componentName != 'dataMonitoring' && this.componentName!='dataMonitoringinput' && this.componentName != 'disableAutoUpdateViaAllocationUpdate' && this.componentName != 'redemptionActivityUpdate' && this.componentName != 'subscriptionActivityUpdate' && this.componentName != 'fetchCsdBankTransactionPendingReview') {
      this.gridFilterService.getformControlsDetail(this.componentName).then(response => {
        this.basicDetails = response;
        this.formBasic = true;
        if (this.componentName == "globalSMEquitiesList" || this.componentName == "globalSmOptionsList" || this.componentName == "globalSmFixedIncomeList" || this.componentName == "globalSmFxForwardList" || this.componentName == "globalSmPrivateFundList" || this.componentName == "globalSmFutureList" || this.componentName == "globalSmRealestateList" || this.componentName == "globalSmPools" || this.componentName == "globalCrypto") {
          this.Search();
        }
      });
    }
    if (this.componentName == 'viewDataCollection') {
      this.gridFilterService.cryptoformControlsDetail(this.componentName).then(response => {
        this.basicDetails = response;
        this.formBasic = true;
      });
    }
    if (this.componentName == 'reBudgets' || this.componentName == "rapidReBudgets") {
      this.gridFilterService.budgetformControlsDetail(this.componentName).then(response => {
        this.basicDetails = response;
        this.formBasic = true;
      });
    }
    if (this.componentName == 'dataMonitoring' || this.componentName=='dataMonitoringinput') {
      this.gridFilterService.dataMonitoringFormControlsDetail(this.componentName).then(response => {
        this.basicDetails = response;
        this.formBasic = true;
      });
    }
    if (this.componentName == 'disableAutoUpdateViaAllocationUpdate') {
      this.gridFilterService.capitalActivityformControlsDetail(this.componentName).then(response => {
        this.basicDetails = response;
        this.formBasic = true;
      });
    }
    if (this.componentName == 'redemptionActivityUpdate') {
      this.gridFilterService.redemptionActivityformControlsDetail(this.componentName).then(response => {
        this.basicDetails = response;
        this.formBasic = true;
      });
    }
    if (this.componentName == 'subscriptionActivityUpdate') {
      this.gridFilterService.subscriptionActivityformControlsDetail(this.componentName).then(response => {
        this.basicDetails = response;
        this.formBasic = true;
      });
    }

    if (this.componentName == 'fetchCsdBankTransactionPendingReview') {
      this.formValid = true;
      this.gridFilterService.csdBankTransactionFormControls(this.componentName).then(response => {
        this.basicDetails = response;
        this.formBasic = true;
      });
    }
  }
  onClear() {
    this.formEl.form.reset();
  }
  formValidation(event: boolean) {
    this.formValid = !event;
    if (!this.formValid) {
      this.formValid = this.formEl.form.status === "INVALID" ? true : false;
    }
  }
  /* Name:onGridReady
 param:null
 purpose: Grid Api Setup
 author: Akshar
*/
  onGridReady(params: any) {
    params.api.componentName = this.componentName;
    params.api.subComponent = this.subComponent;
    params.api.periodLockDateAccessor = this.periodLockDateAccessor;
    params.api.lookups = this.lookups;
    //view-button-cell-renderer
    params.api.enterpriseId = this.currentUser.enterpriseId;
    params.api.periodLockDate = this.currentUser.periodLockDate;
    params.api.roleAs = this.roleAs;
    params.api.issystemAdmin = (this.currentUser.authList.indexOf('FUNCTION_CREATE') > -1 && this.currentUser.authList.indexOf('ENTERPRISE_CREATE') > -1) ? true : false;
    //view-button-cell-renderer    
    this.gridApi = params.api;
    this.setGridApiData();
    this.gridColumnApi = params.columnApi;
    this.gridApi.closeToolPanel();
    this.gridApi.hideOverlay();
  }
  getFilterData(e) {
    const nodeTextandNum = e?.filterInstance?.eGui?.children[0]?.children[1]?.children[0]?.children[1]?.children[0];
    const nodeDate = e?.filterInstance?.eGui?.children[0]?.children[1]?.children[0]?.children[0]?.children[0]?.children[1]?.children[0];
    const nodeDateRange = e?.filterInstance?.eGui?.children[0]?.children[1]?.children[1]?.children[0]?.children[0]?.children[1]?.children[0];
    const applyButton = e?.filterInstance?.eGui?.children[1]?.children[1];
    let isRange = e?.filterInstance?.eGui?.innerText.includes("range");
    let node = nodeTextandNum != undefined ? nodeTextandNum : (isRange ? nodeDateRange : nodeDate);
    node.addEventListener("keyup", (event) => {
      if (event.key === "Enter") {
        let ele: HTMLElement = document.getElementsByClassName(applyButton)[1] as HTMLElement;
        applyButton.click();
      }
    });
  }

  setGridApiData() {
    this.gridApi.getViewFunctionName = apiConstants[this.componentName]?.authFunctions?.viewButtonFunction ? this.authList.indexOf(apiConstants[this.componentName]?.authFunctions?.viewButtonFunction) > -1 : true;
    this.gridApi.getEditFunctionName = apiConstants[this.componentName]?.authFunctions?.updateButtonFunction ? this.authList.indexOf(apiConstants[this.componentName]?.authFunctions?.updateButtonFunction) > -1 : true;
    this.gridApi.getDeleteFunctionName = apiConstants[this.componentName]?.authFunctions?.deleteButtonFunction ? this.gridService.checkMultiAuth(apiConstants[this.componentName]?.authFunctions?.deleteButtonFunction, this.authList) : true;
    this.gridApi.omsValidation = apiConstants[this.componentName][this.subComponent] ? true : false;
    this.getEditFunctionName = apiConstants[this.componentName]?.authFunctions?.updateButtonFunction ? this.authList.indexOf(apiConstants[this.componentName]?.authFunctions?.updateButtonFunction) > -1 : true;
    if ((this.gridApi.omsValidation && this.componentName === 'usermanagementlist') || this.componentName === 'usermappinglist' || this.componentName === 'automate-process') {
      this.gridApi.getViewFunctionName = apiConstants[this.componentName][this.subComponent]?.authFunctions?.viewButtonFunction ? this.authList.indexOf(apiConstants[this.componentName][this.subComponent]?.authFunctions?.viewButtonFunction) > -1 : true;
      this.gridApi.getDeleteFunctionName = apiConstants[this.componentName][this.subComponent]?.authFunctions?.deleteButtonFunction ? this.gridService.checkMultiAuth(apiConstants[this.componentName][this.subComponent]?.authFunctions?.deleteButtonFunction, this.authList) : true;
    }
    if (this.componentName == 'userCoaList') {
      this.gridApi.updateUserCheck = this.authList.indexOf('SEAMLESS_SETUP_CHARTOFACCOUNT_APPROVER_UPDATE') > -1 ? true : false;
      this.gridApi.updateClientCheck = this.authList.indexOf('SEAMLESS_SETUP_CHARTOFACCOUNT_CLIENT_UPDATE') > -1 ? true : false;
    }
    if (this.componentName == 'reviewChartOfAccounts') {
      this.gridApi.updateClientCheck = this.authList.indexOf('SEAMLESS_SETTINGS_REVIEW_CHARTOFACCOUNTS_UPDATE') > -1 ? true : false;
    }
  }

  removeActions() {
    if (!this.formData?.["transactionType"]?.["id"]) {
      this.columnDefs.forEach(element => {
        if (element.colId && element.colId == 'action') {
          let columns = this.columnDefs.slice(1);
          this.columnDefs = columns;
        }
      });
    } else {
      this.getGridHeader();
    }
  }

  Search() {
    this.gridApi['isEditing'] = false;
    Object.assign(this.formData, this.formEl.form.value);
    let flagN = 1;
    let flagJ = 1;
    this.page = 0
  if (this.componentName == 'journalentry' ) {
            this.lookups = _.map(this.formData['moduleName'], function (element, key) { return element.value });
      delete this.formData['moduleName'];
      this.isEmpty = Object.values(this.formData).every(x => x === null || x === '');
      this.formData['moduleName'] = this.lookups;

    }
    else if (this.componentName == 'RapidJournalentry' || this.componentName == 'rapidCompoundJEListComponent') {
      if (_.isEmpty(this.formData['custodian'])) {
        flagJ = 0;
      }
      this.lookups = _.map(this.formData['moduleName'], function (element, key) { return element.value });
      this.isEmpty = false;
      delete this.formData['moduleName'];
      Object.values(this.formData).forEach(element => {
        if (element != null && element != '') {
          this.isEmpty = true;
        }
      });
      this.formData['moduleName'] = this.lookups;
    }
    else if (this.componentName == 'rapidNonTradeActivity' || this.componentName == 'fetchBankTransaction' || this.componentName == 'fetchCsdBankTransactionPendingReview') {
      if (_.isEmpty(this.formData['custodian'])) {
        flagN = 0;
      }
      this.isEmpty = false;
      Object.values(this.formData).forEach(element => {
        if (element != null && element != '') {
          this.isEmpty = true;
        }
      });
    }
    else if (this.componentName == "disableAutoUpdateViaAllocationUpdate" || this.componentName == 'redemptionActivityUpdate' || this.componentName == 'subscriptionActivityUpdate') {
      this.gridApi['caFormData'] = this.formData;
      this.removeActions();
      this.commonService.getSubCategory().subscribe((res) => {
        if (res) {
          this.subCategory = res;
        }
      })
    }
    if (this.isEmpty == false && flagN == 0) {
      this.isEmpty = true;
    }
    else if (this.isEmpty == false && flagJ == 0) {
      this.isEmpty = true;
    } else {
      // this.isEmpty = false;
      this.isEmpty = Object.values(this.formData).every(x => x === null || x === '');
    }
    if (this.isEmpty) {
      if (this.componentName != "reBudgets" && this.componentName != "rapidReBudgets" && this.componentName != "disableAutoUpdateViaAllocationUpdate" && this.componentName != 'redemptionActivityUpdate' && this.componentName != 'subscriptionActivityUpdate') {
        if (!this.serchClickAfterFilter) {
          this.getGridData();
        }
        this.gridApi.setFilterModel(null);
      }
      else if (this.componentName == "disableAutoUpdateViaAllocationUpdate" || this.componentName == 'redemptionActivityUpdate' || this.componentName == 'subscriptionActivityUpdate') {
        this.getGridData(this.subCategory);

        if (this.formData != null && this.formData != undefined) {
          Object.keys(this.formData).forEach(x => {
            if (this.formData[x] == null || this.formData[x] == '') {
              this.gridApi.setFilterModel(null);
            }
          });
        }
      }
    }
    else {
      var checkExecuteEndDate = moment(this.formData['endDate']).clone().endOf('month').format('YYYY-MM-DD');
      var checkExecuteStartDate = moment(this.formData['startDate']).clone().endOf('month').format('YYYY-MM-DD');
      var daysDiff = moment(checkExecuteEndDate).diff(moment(checkExecuteStartDate), 'days') + 1;
      if ((this.componentName == "reBudgets" || this.componentName == "rapidReBudgets") && daysDiff > 365) {
        this.messageService.add({
          closable: true, sticky: true, severity: 'error', summary: this.translateService.instant('Error'),
          detail: this.translateService.instant('diff_between_start_date_and_end_date_should_not_be_greater_than_one_year')
        });
      }
      else {
        this.serchClickAfterFilter = true;
        let formData = this.formData
        this.reqObj = this.gridFilterService.getFilteredReq(formData);
        this.gridShow = true;
        let tempReqObj = this.reqObj;

        if (this.componentName == 'rapidCompoundJEListComponent') {
          tempReqObj['moduleName']
          tempReqObj['page'];
        } else {
          delete tempReqObj['moduleName'];
          delete tempReqObj['page'];
        }

        delete tempReqObj['custodian'];
        let tempValueInstance = {};
        Object.entries(tempReqObj).forEach((value, index) => {
          let key;
          if (((this.componentName == 'tradeList' || this.componentName == "rapidTradeList") && value[0] == 'TransientFund') || ((this.componentName == 'nonTradeActivity' || this.componentName == 'rapidNonTradeActivity' || this.componentName == 'fetchBankTransaction') && value[0] == 'fund') || (this.componentName == 'journalentry' && value[0] == 'fund') || (this.componentName == 'RapidJournalentry' && value[0] == 'fund') || (this.componentName == 'disableAutoUpdateViaAllocationUpdate' && value[0] == 'fund') || (this.componentName == 'redemptionActivityUpdate' && value[0] == 'fund') || (this.componentName == 'subscriptionActivityUpdate' && value[0] == 'fund')) {
            key = 'fund.fundName';
            tempValueInstance[key] = { type: 'contains', filter: value[1] }
          }
          else if ((this.componentName == 'rapidCompoundJEListComponent' && value[0] == 'fund')) {
            key = 'fundName';
            tempValueInstance[key] = { type: 'contains', filter: value[1] }
          }
          else if ((this.componentName == 'tradeList' || this.componentName == "rapidTradeList") && value[0] == 'TransientBrokerAccount') {
            key = 'brokerAccount.accountNumber';
            tempValueInstance[key] = { type: 'contains', filter: value[1] }
          }
          else if (value[0] == 'tradeDateTime' || value[0] == 'pnlDate' || value[0] == 'closeTrade.tradeDateTime' || value[0] == 'date' || value[0] == 'jedate' || value[0] == 'valuationDateAndTime' || value[0] == 'effectivedate') {
            key = value[0];
            tempValueInstance[key] = this.gridFilterService.createdateSet(value[1]);
          }
          else if (value[0] == 'startDate' || value[0] == 'endDate') {
            key = value[0];
            tempValueInstance[key] = { type: 'contains', filter: value[1] }
          }
          else if (((this.componentName == 'reBudgets' || this.componentName == "rapidReBudgets") && value[0] == 'fundName')) {
            key = value[0];
            tempValueInstance[key] = { type: 'contains', filter: value[1], "filterType": "text" }
          }
          else if (((this.componentName == 'reBudgets' || this.componentName == "rapidReBudgets") && value[0] == 'custodianAccNumber')) {
            key = value[0];
            tempValueInstance[key] = { type: 'contains', filter: value[1], filterType: 'text' }
          }
          else if (this.componentName == 'fetchCsdBankTransactionPendingReview' && value[0] == 'fund') {
            key = "fundName";
            tempValueInstance[key] = { type: 'contains', filter: value[1] }
          }
          else {
            key = value[0];
            tempValueInstance[key] = { type: 'contains', filter: value[1] }
          }
        });

        this.topSearchClick = true;
        this.tempInstanceDataValue = JSON.parse(JSON.stringify(tempValueInstance));
        if ((this.componentName == "reBudgets" || this.componentName == "rapidReBudgets")) {
          delete tempValueInstance['endDate']
          delete tempValueInstance['startDate']
          if (this.tempInstanceDataValue['startDate']) {
            this.tempInstanceDataValue['startDate'] = moment(this.formData['startDate']).clone().endOf('month').format('YYYY-MM-DD')
          }
          if (this.tempInstanceDataValue['endDate']) {
            this.tempInstanceDataValue['endDate'] = moment(this.formData['endDate']).clone().endOf('month').format('YYYY-MM-DD')
          }
        }
        if ((this.componentName == "reBudgets" || this.componentName == "rapidReBudgets") && !(tempValueInstance['fundName'] || tempValueInstance['custodianAccNumber'])) {
          this.gridApi.onFilterChanged()
        } else {

          if (this.componentName != 'fetchCsdBankTransactionPendingReview') {
            if (tempValueInstance['custodianAccNo']) {
              tempValueInstance['custodianAccNo'] = "";
            }
            if (tempValueInstance['custodianName']) {
              tempValueInstance['custodianName'] = "";
            }
          }

          if (this.componentName == "dataMonitoring" || this.componentName == 'dataMonitoringinput' || this.componentName == "globalSMEquitiesList" || this.componentName == "globalSmOptionsList" || this.componentName == "globalSmFixedIncomeList" || this.componentName == "globalSmFxForwardList" || this.componentName == "globalSmPrivateFundList" || this.componentName == "globalSmFutureList" || this.componentName == "globalSmRealestateList" || this.componentName == "globalSmPools" || this.componentName == "globalCrypto") {
            this.gridApi.onFilterChanged();
          }
          else if (this.componentName == 'fetchCsdBankTransactionPendingReview') {
            this.gridApi.setFilterModel(null);
            if ((tempValueInstance["fundName"] && tempValueInstance["fundName"]["filter"] && tempValueInstance["fundName"]["filter"].length) && !tempValueInstance.hasOwnProperty("custodianAccNo") && !tempValueInstance.hasOwnProperty("date")) {
              this.getFilteredData();
            } else {
              tempValueInstance['fundName'] = "";
              this.gridApi.setFilterModel(tempValueInstance);
            }
          }
          else {
            this.gridApi.setFilterModel(tempValueInstance);
          }
        }
      }
    }
  }
  viewDataCollectionSubmit() {
    this.serchClickAfterFilter = true;
    let tempValueInstance = {};
    let formData: any = {};
    this.fundname = '';
    this.clientId = '';
    this.clientFundId = [];
    Object.assign(formData, this.formEl.form.value);
    this.gridFilterService.exchangeList?.forEach(item => {
      if (item.value == this.formData['exchangeName']) {
        this.clientId = item.clientId;
        this.exchangeName = this.formData['exchangeName'];
      }
    })
    this.gridFilterService.clientList?.forEach(item => {
      if (item.value == this.formEl.form.value.clientName) {
        this.fundname = item.label;
        this.clientFundId = [this.formEl.form.value.clientName];
      } if (!this.formEl.form.value.clientName) {
        var fundid = item.value;
        this.clientFundId.push(fundid)
      }
    })
    this.currentUser = JSON.parse(this.localService.getItem('fss'));
    this.collectionName = formData.genericdata
    this.enterpriseId = this.currentUser.enterpriseId
    tempValueInstance['exchangeName'] = { type: 'contains', filter: formData['exchangeName'] }
    let startDate = moment(formData['startDate']).format('YYYY-MM-DD');
    let endDate = moment(formData['endDate']).format('YYYY-MM-DD');
    if (startDate > endDate) {
      this.formEl.form.controls.endDate.setValue(null)
      this.messageService.add({ sticky: true, severity: "error", summary: this.translateService.instant("error"), detail: this.translateService.instant("end_date_should_be_greater_than_start_date") });
    }
    else {
      tempValueInstance['processISODate'] = { type: 'inRange', dateFrom: startDate, dateTo: endDate };
      this.gridApi.setFilterModel(tempValueInstance);
    }
  }
  getGridData(subCategory?) {
    this.gridFilterService.getGridData(this.lookups, (cbs) => {
      if (cbs.resultList != null) {

        cbs.resultList.forEach(element => {
          if (element.updatedOn) {
            let resMnt = moment(new Date(element.updatedOn)).isValid()
            if (resMnt) {
              element.updatedOn = moment(new Date(element.updatedOn)).format('YYYY-MM-DD hh:mm:ss:ssss');
            } else {
              element.updatedOn = element.updatedOn;
            }
          }
          if (element?.createdOn) {
            let resMnt1 = moment(new Date(element.createdOn)).isValid()
            if (resMnt1) {
              element.createdOn = moment(new Date(element.createdOn)).format('YYYY-MM-DD hh:mm:ss:ssss');
            } else {
              element.createdOn = element.createdOn;
            }
          }
          if (element?.insertedOn) {
            let resMnt1 = moment(new Date(element.insertedOn)).isValid()
            if (resMnt1) {
              element.insertedOn = moment(new Date(element.insertedOn)).format('YYYY-MM-DD hh:mm:ss:ssss');
            } else {
              element.insertedOn = element.insertedOn;
            }
          }
          if (this.componentName == "journalentry" || this.componentName == 'RapidJournalentry' || this.componentName == 'rapidCompoundJEListComponent') {
            if (element['investerACNumberDr1']) {
              element['investerACNumberDr1'] = element.investerACNumberDr1.investorNumber;
            }
            if (element['custodianACNumberCr1']) {
              element['custodianACNumberCr1'] = element.custodianACNumberCr1.accountNumber;
            }
            if (element['investerACNumberCr1']) {
              element['investerACNumberCr1'] = element.investerACNumberCr1.investorNumber;
            }
            if (element['custodianACNumberDr1']) {
              element['custodianACNumberDr1'] = element.custodianACNumberDr1.accountNumber;
            }
            if (element['bankACNumberDr1']) {
              element['bankACNumberDr1'] = element.bankACNumberDr1.vendor;
            }
            if (element['bankACNumberCr1']) {
              element['bankACNumberCr1'] = element.bankACNumberCr1.vendor;
            }
          }
          if (this.componentName == "reBudgets" || this.componentName == "rapidReBudgets") {
            this.monthNames = ["jan", "feb", "march", "april", "may", "june", "july", "aug", "sept", "oct", "nov", "dec"];
            if (element['yearDetails']) {
              element.yearDetails.forEach(yeararray => {
                var mDate = new Date(yeararray.monthEndDate);
                var month = mDate.getMonth();
                var monthName = this.monthNames[month];
                var amount = yeararray.amount;
                element[monthName + "Amount"] = amount;
              })
            }
          }
          if (this.componentName == 'disableAutoUpdateViaAllocationUpdate' || this.componentName == 'redemptionActivityUpdate' || this.componentName == 'subscriptionActivityUpdate') {
            if (element["createdOn"]) {
              element["createdOn"] = moment(new Date(element['createdOn'])).format("MM-DD-YYYY");
            }
            element["investorNumber"] = element.investor && element.investor.investorNumber ? element.investor.investorNumber : null;
            element["investorId"] = element.investor && element.investorId ? element.investorId : null;

            let shares = element.shares ? Number(element.shares) : 0;
            if (!Number.isNaN(shares) && shares > 0) {
              element.shares = element.shares ? element.shares : '';
            }
            if (element.transferActivity == true && element.transactionType == 'Subscription - First Time') {
              element.transactionType = 'Subscription - Transfer';
            }
            if (element.transferActivity == true && element.transactionType == 'Redemption - Partial by Amount') {
              element.transactionType = 'Redemption - Transfer';
            }

            element.effectiveAmount = element.effectiveAmount ? element.effectiveAmount.toFixed(2) : '';
          }
        })
        this.rowData = _.cloneDeep(cbs.resultList);
      }
      else {
        this.rowData = [];
      }

      this.displayForm = false;
      this.totalRecords = cbs.totalCount;
      this.istotalFilterCount = false;
      this.totalPages = Math.ceil(cbs.totalCount / 100) || 1;
      this.formData = {};
      this.filteredData = [];
      this.gridShow = true;
    }, (cbe) => {
    }, subCategory);
  }
  getFilteredData(event?) {
    Object.assign(this.formData, this.formEl.form.value);
    if (event) {
      let gridApi = this.gridApi;
      this.reqObj = this.gridFilterService.getFilteredDataGridReq(event, gridApi, this.topSearchClick, this.clientFundId);
      if ((this.componentName == "dataMonitoring" || this.componentName=='dataMonitoringinput') && this.formData['status'] == 'All') {
        delete this.formData['status']
      }
      if (this.componentName == "dataMonitoring" || this.componentName=='dataMonitoringinput') {
        this.reqObj = this.reqObj || {}
        this.reqObj['dataPeriod'] = this.formData['datePeriod']
        this.reqObj['filterList'] = this.reqObj['filterList'] || []
        if (this.formData['startDate'] || this.formData['endDate']) {
          let date = {}
          if (this.formData['endDate']) {
            let utc = moment.utc(this.formData['endDate']).local().format("MM-DD-YYYY HH:mm:ss");
            date['endDate'] = Date.parse(utc)
          }
          if (this.formData['startDate']) {
            let utc1 = moment.utc(this.formData['startDate']).local().format("MM-DD-YYYY HH:mm:ss");
            date['startDate'] = Date.parse(utc1)
          }
          this.reqObj['filterList'].push({startTime:date})
        }
        if (this.formData['status']) {
          this.reqObj['filterList'].push({ status: this.formData['status'] })
        }
        if (this.formData['clientName']) {
          this.reqObj['filterList'].push({ clientId: this.formData['clientName'].map(x => x.value) })
        }
        if (this.formData['api']) {
          this.reqObj['filterList'].push({ exchangeId: this.formData['api'] })
        }
        if (this.formData['fundName']) {
          this.reqObj['filterList'].push({ fundId: this.formData['fundName'] })
        }
        if (this.currentUser['enterpriseId']) {
          this.reqObj['filterList'].push({ enterpriseId: this.currentUser['enterpriseId'] })
        }
        this.reqObj['page'] = this.page

      }
      if (this.componentName == "reBudgets" || this.componentName == "rapidReBudgets") {
        this.reqObj = this.reqObj || {}
        this.reqObj['endDate'] = this.tempInstanceDataValue['endDate']
        this.reqObj['startDate'] = this.tempInstanceDataValue['startDate']
        this.reqObj['page'] = this.page;
      }
      if (this.componentName == "globalSMEquitiesList" || this.componentName == "globalSmOptionsList" || this.componentName == "globalSmFixedIncomeList" || this.componentName == "globalSmFxForwardList" || this.componentName == "globalSmPrivateFundList" || this.componentName == "globalSmFutureList" || this.componentName == "globalSmRealestateList" || this.componentName == "globalSmPools" || this.componentName == "globalCrypto") {
        let enterpriseStatusGlobal = []
        let enterpriseType = []
        this.reqObj = this.reqObj || {}
        //  this.reqObj['dateOfRecordCreation'] = this.formData['dateOfRecordCreation'];
        this.reqObj['updatedOn'] = this.commonService.getStartDate(this.formData['endDate']) + 'LHRXJ10EWRdateZRHXJ10JFK' + this.commonService.getEndDate(this.formData['endDate'])
        this.formData['enterpriseStatusGlobal']?.forEach(el => {
          enterpriseStatusGlobal.push(el.value);
        })
        this.reqObj['enterpriseStatusGlobal'] = enterpriseStatusGlobal;
        if (this.formData['enterpriseTypeGlobal']) {
          this.formData['enterpriseTypeGlobal']?.forEach(el => {
            enterpriseType.push(el.value);
          });
        }

        this.reqObj['enterpriseTypeGlobal'] = enterpriseType;
        this.reqObj['missingFields'] = this.formData['missingFields']?.toString();
        this.reqObj['page'] = this.page;
        delete this.reqObj['endDate'];
      }
      if (this.componentName == "globalSmRealestateList") {
        delete this.reqObj['missingFields']
      }
      if (this.reqObj == undefined && !this.formData['custodianAccNo'] && !this.formData['custodianName']) {
        this.page = 0;
        this.pageNumber = 1;
        this.filteredData = [];
        this.fetchGridData();
        return;
      }
      else if (this.componentName == 'RapidJournalentry' || this.componentName == 'rapidNonTradeActivity' || this.componentName == 'rapidCompoundJEListComponent' || this.componentName == 'fetchBankTransaction') {
        this.reqObj = this.reqObj || {}
        if (this.componentName == 'RapidJournalentry' || this.componentName == 'rapidCompoundJEListComponent') {
          this.reqObj['moduleName'] = this.lookups;
        }
        if (this.componentName == 'fetchBankTransaction') {
          this.reqObj['viewCSDBankTransaction'] = true;
        }
        if(this.componentName == 'rapidNonTradeActivity'){
          this.reqObj['viewCSDBankTransaction'] = false;
        }
        if (this.reqObj.hasOwnProperty('endDate')) {
          this.reqObj['endDate'] = this.tempInstanceDataValue['endDate']
        }
        if (this.reqObj.hasOwnProperty('startDate')) {
          this.reqObj['startDate'] = this.tempInstanceDataValue['startDate']
        }
        this.reqObj['page'] = this.page;
      }
      else if (this.componentName == 'journalentry' || this.componentName == 'RapidJournalentry' || this.componentName == 'rapidCompoundJEListComponent') {
        this.reqObj['moduleName'] = this.lookups;
      } else if (this.componentName == 'viewDataCollection') {
        this.reqObj['collectionName'] = this.collectionName;
        this.reqObj['enterpriseId'] = this.enterpriseId
        this.reqObj['clientId'] = this.clientId
        this.gridShow = true;
      }
    }
    else if (this.componentName != 'RapidSeamlessmarketdata' && this.componentName != 'dataMonitoring' && this.componentName != 'rapidViewtotalgl' && this.componentName != 'viewclosedtaxLots' && this.componentName != 'fetchBankTransaction' && this.componentName != "rapidNonTradeActivity" && this.componentName != "rapidTradeList" && this.componentName != "globalSMEquitiesList" && this.componentName != "globalSmOptionsList" && this.componentName != "globalSmFixedIncomeList" && this.componentName != "globalSmFxForwardList" && this.componentName != "globalSmPrivateFundList" && this.componentName != "globalSmFutureList" && this.componentName != "globalSmRealestateList" && this.componentName != "globalSmPools" && this.componentName != "globalCrypto" && this.componentName != "dataMonitoringinput" && this.componentName == 'disableAutoUpdateViaAllocationUpdate' && this.componentName == 'redemptionActivityUpdate' && this.componentName == 'subscriptionActivityUpdate') {
      this.reqObj['moduleName'] = this.lookups;
    }
    else if (this.componentName == "dataMonitoring" || this.componentName=='dataMonitoringinput') {
      this.reqObj = this.reqObj || {}
      this.reqObj['dataPeriod'] = this.reqObj['dataPeriod']
      this.reqObj['filterList'] = this.reqObj['filterList'] || []
      this.reqObj['page'] = this.page
    }
    if (this.formData['custodianAccNo']) {
      this.reqObj['custodianAccNo'] = this.formData['custodianAccNo'];
    }
    if (this.formData['custodianName']) {
      this.reqObj['custodianName'] = this.formData['custodianName'];
    }
    if (this.componentName == 'disableAutoUpdateViaAllocationUpdate' || this.componentName == 'redemptionActivityUpdate' || this.componentName == 'subscriptionActivityUpdate') {
      if (this.formData['activityType'] && this.formData['activityType']['id'] != "" && this.formData['activityType']['id'] != null && this.formData['startDate'] == null && this.formData['endDate'] == null && this.formData['fund'] == null && this.formData['transactionType'] == null) {
        this.reqObj['activityType'] = this.formData['activityType']['id'];
        delete this.reqObj['startDate'];
        delete this.reqObj['endDate'];
        delete this.reqObj['fund'];
        delete this.reqObj['transactionType'];
      } else {
        this.reqObj['activityType'] = this.formData && this.formData['activityType'] && this.formData['activityType']['id'] ? this.formData['activityType']['id'] : null;
        delete this.reqObj['startDate'];
        delete this.reqObj['endDate'];
        if (this.reqObj['activityType'] == null) {
          delete this.reqObj['activityType'];
        }
        if (this.reqObj['transactionType'] == null) {
          delete this.reqObj['transactionType'];
        }
        if (this.reqObj['fund'] == null) {
          delete this.reqObj['fund'];
        }
      }
      const filtredModel = this.gridApi.getFilterModel();
      this.isEmptyObject(filtredModel)
      if (!this.isEmptyObject && !filtredModel.hasOwnProperty('transactionType')) {
        this.gridApi['transactionType'] = null;
        this.formData['transactionType'] = null;
        this.gridApi['caFormData'] = this.formData;
      }

      this.reqObj['subCategory'] = this.subCategory;
      let transactionType = this.formData?.['transactionType']?.["id"] ? this.formData['transactionType']['id'] : null;
      let reqObjTransType = this.reqObj?.['transactionType'];
      if (transactionType == "Subscription - Transfer" && reqObjTransType == "Subscription - Transfer") {
        this.reqObj['transferActivity'] = true;
        this.reqObj['transactionType'] = "Subscription - First Time";
      } else if (transactionType == "Redemption - Transfer" && reqObjTransType == "Redemption - Transfer") {
        this.reqObj['transferActivity'] = true;
        this.reqObj['transactionType'] = "Redemption - Partial by Amount";
      } else {
        this.reqObj['transferActivity'] = false;
      }
    }

    if (this.componentName == "fetchCsdBankTransactionPendingReview") {
      let csdIds = [];
      let fundId = [];
      let fundArr = [];
      let funds = this.tempInstanceDataValue['fundName']['filter'];
      fundArr = this.gridFilterService.csdBankTransFundList.filter(item => funds.includes(item.value));
      fundArr.forEach(item => {
        if (item.csdFundId) {
          csdIds.push(item.csdFundId);
        }
        if (item.value) {
          fundId.push(item.value);
        }
      });
      this.reqObj['fundIds'] = fundId;
      this.reqObj['csdFundIds'] = csdIds;
      if (this.reqObj['fund']) {
        delete this.reqObj['fund'];
      }
      if (!this.reqObj['startDate']) {
        delete this.reqObj['startDate'];
      }
      if (!this.reqObj['endDate']) {
        delete this.reqObj['endDate'];
      }
      if (this.reqObj['custodian'] && !this.reqObj['custodian'].length) {
        delete this.reqObj['custodian'];
      }
      if (this.reqObj['custodian']) {
        delete this.reqObj['custodian'];
      }
      this.reqObj['page'] = this.page;
    }
    this.gridFilterService.getFilteredData((cbs) => {

      if (this.componentName == 'dataMonitoring' || this.componentName=='dataMonitoringinput') {
        cbs.resultList.forEach(element => {

          element['dataCollectionStatus'] = element?.events?.dataCollection?.event_status;
          element['dataTransformationStatus'] = element?.events?.dataTranformation?.event_status;
          element['dataReconcilationStatus'] = element?.events?.dataReconciliation?.event_status;
        });
      }
      if (this.componentName == "viewclosedtaxLots") {
        cbs.resultList.forEach(element => {
          element.closeTrade.tradeDateTime = moment(element.closeTrade.tradeDateTime).format('YYYY-MM-DD');
        });
      }
      this.rowData = cbs.resultList;
      this.setupPercentageService.setShowGridMsg(true);
      if (this.componentName != "globalSMEquitiesList" && this.componentName != "globalSmOptionsList" && this.componentName != "globalSmFixedIncomeList" && this.componentName != "globalSmFxForwardList" && this.componentName != "globalSmPrivateFundList" && this.componentName != "globalSmFutureList" && this.componentName != "globalSmRealestateList" && this.componentName != "globalSmPools" && this.componentName != "globalCrypto") {
        this.displayForm = false;
      }

      if (cbs.resultList != null) {
        cbs.resultList.forEach(element => {
          if (this.componentName == "viewDataCollection") {
            if (element.jsonData) {
              element.jsonData = JSON.stringify(element.jsonData);
            }
          }
          if (element.updatedOn) {
            let resMnt = moment(new Date(element.updatedOn)).isValid()
            if (resMnt) {
              element.updatedOn = moment(new Date(element.updatedOn)).format('YYYY-MM-DD hh:mm:ss:ssss');
            } else {
              element.updatedOn = element.updatedOn;
            }
          }
          if (element?.createdOn) {
            let resMnt1 = moment(new Date(element.createdOn)).isValid()
            if (resMnt1) {
              element.createdOn = moment(new Date(element.createdOn)).format('YYYY-MM-DD hh:mm:ss:ssss');
            } else {
              element.createdOn = element.createdOn;
            }
          }
          if (element?.insertedOn) {
            let resMnt1 = moment(new Date(element.insertedOn)).isValid()
            if (resMnt1) {
              element.insertedOn = moment(new Date(element.insertedOn)).format('YYYY-MM-DD hh:mm:ss:ssss');
            } else {
              element.insertedOn = element.insertedOn;
            }
          }
          if (this.componentName == "journalentry" || this.componentName == 'RapidJournalentry' || this.componentName == 'rapidCompoundJEListComponent') {
            if (element['investerACNumberDr1']) {
              element['investerACNumberDr1'] = element.investerACNumberDr1.investorNumber;
            }
            if (element['custodianACNumberCr1']) {
              element['custodianACNumberCr1'] = element.custodianACNumberCr1.accountNumber;
            }
            if (element['investerACNumberCr1']) {
              element['investerACNumberCr1'] = element.investerACNumberCr1.investorNumber;
            }
            if (element['custodianACNumberDr1']) {
              element['custodianACNumberDr1'] = element.custodianACNumberDr1.accountNumber;
            }
            if (element['bankACNumberDr1']) {
              element['bankACNumberDr1'] = element.bankACNumberDr1.vendor;
            }
            if (element['bankACNumberCr1']) {
              element['bankACNumberCr1'] = element.bankACNumberCr1.vendor;
            }
          }
          if (this.componentName == "reBudgets" || this.componentName == "rapidReBudgets") {
            this.monthNames = ["jan", "feb", "march", "april", "may", "june", "july", "aug", "sept", "oct", "nov", "dec"];
            if (element['yearDetails']) {
              element.yearDetails.forEach(yeararray => {
                var mDate = new Date(yeararray.monthEndDate);
                var month = mDate.getMonth();
                var monthName = this.monthNames[month];
                var amount = yeararray.amount;
                element[monthName + "Amount"] = amount;
              })
            }
          }
          if (this.componentName == 'disableAutoUpdateViaAllocationUpdate' || this.componentName == 'redemptionActivityUpdate' || this.componentName == 'subscriptionActivityUpdate') {
            if (element["createdOn"]) {
              element["createdOn"] = moment(new Date(element['createdOn'])).format("MM-DD-YYYY");
            }
            element["investorNumber"] = element.investor && element.investor.investorNumber ? element.investor.investorNumber : null;
            element["investorId"] = element.investor && element.investorId ? element.investorId : null;
            let shares = element.shares ? Number(element.shares) : 0;
            if (!Number.isNaN(shares) && shares > 0) {
              element.shares = element.shares ? element.shares : '';
            }
            if (element.transferActivity == true && element.transactionType == 'Subscription - First Time') {
              element.transactionType = 'Subscription - Transfer';
            }
            if (element.transferActivity == true && element.transactionType == 'Redemption - Partial by Amount') {
              element.transactionType = 'Redemption - Transfer';
            }
            element.effectiveAmount = element.effectiveAmount ? element.effectiveAmount.toFixed(2) : '';
          }
        })
        this.rowData = _.cloneDeep(cbs.resultList);
      }
      else {
        this.rowData = [];
      }
      this.istotalFilterCount = true;
      this.formData = {};
      this.topSearchClick = false;
      if ((this.rowData != null || this.rowData != undefined) && cbs.content == null) {
        if (this.totalFltRecords > cbs.totalCount || !this.filteredData.length) {
          this.page = 0;
          this.pageNumber = 1;
        }
        if (this.componentName == 'fetchCsdBankTransactionPendingReview') {
          this.filteredData.length = cbs.resultList.length;
        }
        this.totalFltRecords = cbs.totalCount;
        this.totalPages = Math.ceil(cbs.totalCount / 100) || 1;
        Number(this.pageNumber) > 1 ? this.pageNumber : this.pageNumber = 1;
        this.totalRecords = 0;
        this.gridApi.setRowData(this.rowData);
        if (!this.filteredData.length) {
          if (this.componentName != "fetchCsdBankTransactionPendingReview") {
            this.filteredData = event.api.rowModel.rowsToDisplay;
          }
        }
      }
    }, (cbe) => {
    }, this.reqObj);
  }
  pageShift(type, pageNumberEnter?) {
    let tempObj = this.gridFilterService.pageShift(this.totalPages, this.page, type, pageNumberEnter);
    this.page = tempObj != undefined ? tempObj.page : 0;
    this.pageNumber = tempObj != undefined ? tempObj.pageNumber : 1;

    if (this.componentName == "dataMonitoring" || this.componentName=='dataMonitoringinput' || this.componentName == "globalSMEquitiesList" || this.componentName == "globalSmOptionsList" || this.componentName == "globalSmFixedIncomeList" || this.componentName == "globalSmFxForwardList" || this.componentName == "globalSmPrivateFundList" || this.componentName == "globalSmFutureList" || this.componentName == "globalSmRealestateList" || this.componentName == "globalSmPools" || this.componentName == "globalCrypto" || this.componentName == 'fetchCsdBankTransactionPendingReview') {
      tempObj.isFilter = true;
    }
    tempObj.isFilter == true ? this.reqObj['page'] = this.page : this.page = this.page;
    tempObj.isFilter == true ? this.getFilteredData() : this.fetchGridData();
    this.gridApi.paginationGoToPage(this.page);
  }
  onSelectionChanged(evt) {
    this.selectedRows = evt.api.getSelectedRows();
    this.lastSelectedNode = evt.api.selectionController.lastSelectedNode;
    if (this.componentName == "journalentry" || this.componentName == 'RapidJournalentry'  || this.componentName == 'rapidCompoundJEListComponent') {
      this.rowSelection = 'multiple';
      this.gridApi.forEachNode((node) => {
        if (node.selected) {
          let curtType = node.data ? node.data.referenceModule : null;
          if (curtType == null || curtType == "findata" || curtType == "Rollup-Consolidation") {
            node.setSelected(true);
          } else {
            node.setSelected(false);
          }
        }
      })
    } else if (this.componentName == "tradeList" || this.componentName == "rapidTradeList") {
      let isExist = this.selectedRows.filter(data => data.transferTradeInputIdList != null && data.sourceTradeId != null)
      isExist.length > 0 ? this.transferTradeHave = true : this.transferTradeHave = false;
    }
  }
  downloadCSV() {
    // var name = "confirmed";
    // var message = "download_request_taken_soon";
    // this.toast('success', this.translateService.instant(name), this.translateService.instant(message), true);
    // this.componentName != "journalentry" || this.componentName != "RapidJournalentry" ? this.gridFilterService.downloadCSV(this.totalRecords) : this.gridFilterService.downloadCSV(this.totalRecords, this.lookups);
    if (this.componentName != 'RapidJournalentry') {
      (this.componentName != "journalentry" && this.componentName != "rapidCompoundJEListComponent") ? this.gridFilterService.downloadCSV(this.totalRecords) : this.gridFilterService.downloadCSV(this.totalRecords, this.lookups);
    }
    else if (this.componentName == 'RapidJournalentry') {
      this.componentName != "RapidJournalentry" ? this.gridFilterService.downloadCSV(this.totalRecords) : this.gridFilterService.downloadCSV(this.totalRecords, this.lookups);
    }

  }
  downloaddatasetData() {
    const filtredModel = this.gridApi.getFilterModel();
    let x = Object.entries(filtredModel);
    var key = x[0][0];
    let value = x[0][1]['filter'];
    let gridOptions = { api: this.gridApi };
    let searchObject = this.commonService.getDataToFilter(gridOptions, this.gridApi);
    searchObject['filterList'] = [];
    Object.keys(searchObject).forEach(x => {
      switch (x) {

        case "processISODate": {
          let date = this.gridService.getStartEndDate(searchObject.processISODate);
          searchObject['filterList'].push(date);
          delete searchObject[x];
          x = "processISODate"
          break;
        }
        case "exchangeName": {
          if (this.componentName == 'viewDataCollection') {
            searchObject['filterList'].push({ "exchangeName": searchObject.exchangeName });
            delete searchObject[x];
          }
          break;
        }
        case "clientRouteName": {
          if (this.componentName == 'viewDataCollection') {
            searchObject['filterList'].push({ "clientRouteName": searchObject.clientRouteName });
            delete searchObject[x];
          }
          break;
        }
        case "clientName": {
          if (this.componentName == 'viewDataCollection') {
            searchObject['filterList'].push({ "clientName": searchObject.clientName });
            delete searchObject[x];
          }
          break;
        }
        default: {
          x = key
          break;
        }
      }
    });

    if (this.componentName == 'viewDataCollection' && this.clientFundId) {
      searchObject['filterList'].push({ "fundId": this.clientFundId });
    }
    var res = searchObject;
    var request = new XMLHttpRequest();
    var access_token = this.cookieService.get('access_token');
    this.gridFilteredUrl = apiConstants[this.componentName][this.collectionName]['gridFilteredUrl'].url + this.totalFltRecords + '/' + this.collectionName;
    searchObject['collectionName'] = this.collectionName;
    searchObject['enterpriseId'] = this.currentUser['enterpriseId'];
    searchObject['clientId'] = this.clientId
    searchObject['exchangeName'] = this.exchangeName
    searchObject['clientName'] = this.fundname
    if (this.fundname == '') {
      delete searchObject['clientName'];
    }
    request.open('POST', this.gridFilteredUrl, true);
    request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    request.setRequestHeader('Authorization', 'Bearer ' + access_token);
    request.responseType = 'blob';
    request.onload = function () { };
    request.addEventListener("loadend", () => {
      if (request.status === 200) {
        if (request.response && request.response.size) {
          var fileName = this.collectionName + "Filtered.zip";
          var disposition = request.getResponseHeader("Content-Disposition");
          if (disposition && disposition.indexOf("attachment") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          var blob = request.response;
          var downloadLink = window.document.createElement("a");
          var contentTypeHeader = request.getResponseHeader("Content-Type");
          downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: contentTypeHeader }));
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } else {
          this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('export_incomplete_contact_support') });
        }
      } else {
        this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translateService.instant('confirmed'), detail: this.translateService.instant('download_request_taken_soon') });
      }
    })
    request.send(JSON.stringify(res));
  }
  getDownloadCsvFilteredData() {
    let gridApi = this.gridApi;
    var name = "confirmed";
    var message = "download_request_taken_soon";
    // this.toast('success', this.translateService.instant(name), this.translateService.instant(message), true);
    // if(this.componentName != "journalentry"){
    // this.gridFilterService.getDownloadCsvFilteredData(gridApi, this.totalFltRecords, this.lookups);
    //  }
    // else{
    this.gridFilterService.downloadCsvFilteredData(this.lookups, gridApi, this.page, this.totalFltRecords, this.heading, this.tempInstanceDataValue);
    // } 
  }

  setOnChange(evt) {
    Object.assign(this.formData, this.formEl.form.value);
    if (this.componentName == 'viewDataCollection') {
      this.genericdata = this.formData['genericdata']
      if (this.genericdata != "") {
        this.gridHeaders = apiConstants[this.componentName][this.genericdata]['headers'];
        this.gridHeaders = this.formData;
        this.getGridHeader()
      }
    }
    if (evt.id == "fund") {
      if (this.componentName == "RapidJournalentry" || this.componentName == "rapidNonTradeActivity" || this.componentName == 'rapidCompoundJEListComponent' || this.componentName == 'fetchBankTransaction') {
        var id = this.formEl.form.controls['fund'].value;
        let custodianList = [];
        if (!id || !id.length) {
          this.gridFilterDropdownService.fetchFundList().subscribe((response) => {
            response.resultList = response.resultList || [];
            let tempFundList = response.resultList.length ? response.resultList.map(element => ({ label: element.fundName == null ? element.fundAlias : element.fundName, value: element.id, alias: element.fundAlias })) : [];
            let fundList1 = [];
            tempFundList.forEach(element => {
              fundList1.push(element.value);
            });
            var data = {
              "fundlist": _.map(fundList1, function (element, key) { return element }),
            }
            this.gridFilterDropdownService.fetchCustodianList(data).subscribe(res => {
              this.formEl.form.controls['custodian'].setValue(null);
              custodianList = res.length != 0 ? res.map(element => ({ label: element.accountNumber != null ? element.accountNumber : '', value: { "id": element.id } })) : [];
              this.basicDetails[3] = { id: 'custodian', controlType: 7, label: 'custodian', viewname: 'custodianNumber', name: 'custodianNumber', value: {}, order: 4, required: false, fill: custodianList, readonly: false, appearance: "fill", class: "col-lg-6 pl-5 pt-0 pr-1" };
            });
          });
        }
        else {
          let data = { "id": id };
          this.gridFilterDropdownService.fetchBrokerList(data).subscribe(res => {

            this.formEl.form.controls['custodian'].setValue(null);
            custodianList = res.map(element => ({ label: element.accountNumber, value: { "id": element.id } }));
            this.basicDetails[3] = { id: 'custodian', controlType: 7, label: 'custodian', viewname: 'custodianNumber', name: 'custodianNumber', value: {}, order: 4, required: false, fill: custodianList, readonly: false, appearance: "fill", class: "col-lg-6 pl-5 pt-0 pr-1" };
          })
        }
      }
    }
    if (evt.id == this.basicDetails[2].id &&  this.componentName != 'dataMonitoring' && this.componentName != 'rapidCompoundJEListComponent' && this.componentName != 'globalSMEquitiesList' && this.componentName != "globalSmOptionsList" && this.componentName != "globalSmFixedIncomeList" && this.componentName != "globalSmFxForwardList" && this.componentName != "globalSmPrivateFundList" && this.componentName != "globalSmFutureList" && this.componentName != "globalSmRealestateList" && this.componentName != "globalSmPools" && this.componentName != "globalCrypto" && this.componentName != 'journalentry' && this.componentName != 'RapidJournalentry' && this.componentName != 'nonTradeActivity' && this.componentName != 'rapidNonTradeActivity'&& this.componentName != 'fetchBankTransaction'  && this.componentName != 'viewDataCollection' && this.componentName != 'seamlessmarketdata' && this.componentName != 'RapidSeamlessmarketdata' && this.componentName != 'reBudgets' && this.componentName != "rapidReBudgets" && this.componentName != 'dataMonitoringinput' && this.componentName != 'disableAutoUpdateViaAllocationUpdate' && this.componentName != 'redemptionActivityUpdate' && this.componentName != 'subscriptionActivityUpdate' && this.componentName != 'fetchCsdBankTransactionPendingReview') {
      var data = {
        fundlist: [this.formEl.form.controls[this.basicDetails[2].id].value],
      };
      this.formEl.form.controls[this.basicDetails[3].id].setValue(null);
      if (this.formEl.form.controls[this.basicDetails[2].id].value == "" || this.formEl.form.controls[this.basicDetails[2].id].value == null) {
        let custodianAccounts = this.gridFilterService.custodianAccounts;
        this.basicDetails[3] = { id: this.basicDetails[3].id, controlType: 2, label: 'broker_account', viewname: 'custodianBroker', name: 'custodianBroker', order: 6, value: null, required: false, fill: custodianAccounts, class: "col-lg-6" };
      }
      else {
        this.gridFilterDropdownService.getBrokerDetails(data).subscribe((response) => {
          let custodianAccounts = response.map(element => ({ label: element.accountNumber, value: element.accountNumber }));
          this.basicDetails[3] = { id: this.basicDetails[3].id, controlType: 2, label: 'broker_account', viewname: 'custodianBroker', name: 'custodianBroker', order: 6, value: null, required: false, fill: custodianAccounts, class: "col-lg-6" };
        })
      }
    }
    else if (evt.id == this.basicDetails[2].id && (this.componentName == 'reBudgets' || this.componentName == 'rapidReBudgets')) {
      var data = {
        fundlist: [this.formEl.form.controls[this.basicDetails[2].id].value],
      };
      this.formEl.form.controls[this.basicDetails[3].id].setValue(null);
      if (this.formEl.form.controls[this.basicDetails[2].id].value == "" || this.formEl.form.controls[this.basicDetails[2].id].value == null) {
        let custodianAccounts = this.gridFilterService.custodianList;
        this.basicDetails[3] = { id: this.basicDetails[3].id, controlType: 2, label: 'book_no', viewname: 'bookNo', name: 'bookNo', order: 4, value: null, required: false, fill: custodianAccounts, class: "col-lg-6" };
      }
      else {
        this.gridFilterDropdownService.getBrokerDetails(data).subscribe((response) => {
          let custodianAccounts = response.map(element => ({ label: element.accountNumber, value: element.id }));
          this.basicDetails[3] = { id: this.basicDetails[3].id, controlType: 2, label: 'book_no', viewname: 'bookNo', name: 'bookNo', order: 4, value: null, required: false, fill: custodianAccounts, class: "col-lg-6" };
        })
      }
    }
    else if (evt.id == 'fundName') {
      let fundId = this.formEl.form.value?.fundName
      if(fundId){
      this.exchangeNameList=[];
      this.accountList=[];
      this.formEl.form.controls.clientName.setValue(null)
      this.formEl.form.controls.api.setValue(null)
      this.gridFilterDropdownService.onchangeFundName(fundId).subscribe((response) => {
        this.exchangeNameList = response[0]?.length ? response[0].map(element => ({ label: element.name, value: element._id })) : [];
        this.accountList =   response[1]?.map(element => ({ label: element.name, value: element._id }))
        this.basicDetails[1] = { id: 'api', controlType: 2, label: 'exchange', viewname: 'api', name: 'api', order: 2, value: '', required: false, appearance: "fill", fill: this.exchangeNameList, readonly: false, class: "col-lg-6  custominput" }
        this.basicDetails[3] =   { id: 'clientName', controlType: 7, label: 'client_name', viewname: 'clientName', name: 'clientName', order: 2, value: [], required: false, appearance: "fill", fill: this.accountList, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" }
      
      })
    }else{
    this.exchangeNameList=[];
    this.accountList=[];
    this.fundlist=[];
    this.formEl.form.controls.fundName.setValue(null)
    this.formEl.form.controls.clientName.setValue(null)
    this.formEl.form.controls.api.setValue(null)
    this.gridFilterDropdownService.dataMonitoringMultipleApiBasic().subscribe((response) => {
      this.fundlist = response[0].resultList.length ? response[0].resultList.map(element => ({ label: element.fundName == null ? element.fundAlias : element.fundName, value: element.id, alias: element.fundAlias })) : [];
      this.exchangeNameList = response[1]?.length ? response[1].map(element => ({ label: element.name, value: element._id })) : [];
      this.accountList =   response[2]?.map(element => ({ label: element.name, value: element._id }))
      this.basicDetails[0] =  { id: 'fundName', controlType: 2, label: 'fund_name', name: 'fundName', viewName: 'fundName', order: 3, value: '', required: false, appearance: "fill", fill: this.fundlist, disabled: false, class: "col-lg-6  custominput", style: "display:block;" },
      this.basicDetails[1] = { id: 'api', controlType: 2, label: 'exchange', viewname: 'api', name: 'api', order: 2, value: '', required: false, appearance: "fill", fill: this.exchangeNameList, readonly: false, class: "col-lg-6  custominput" }
      this.basicDetails[3] =   { id: 'clientName', controlType: 7, label: 'client_name', viewname: 'clientName', name: 'clientName', order: 2, value: [], required: false, appearance: "fill", fill: this.accountList, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" }
    })
    }
    }
   
    else if (this.formEl.form.value?.api && this.formEl.form.value?.fundName) {
      let clientId = this.formEl.form.value?.api
      let fundId = this.formEl.form.value?.fundName
      this.accountList=[];
      this.gridFilterDropdownService.onchangeExchangeName(fundId,clientId).subscribe((response) => {
        this.accountList =   response[0].map(element => ({ label: element.name, value: element._id }))
        this.basicDetails[3] =   { id: 'clientName', controlType: 7, label: 'client_name', viewname: 'clientName', name: 'clientName', order: 2, value: [], required: false, appearance: "fill", fill: this.accountList, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" }
      
      })
    }
    else if (evt.id == 'api') {
      let clientId = this.formEl.form.value?.api
      this.accountList=[];
      if(this.formEl.form.value?.api){
         this.formEl.form.controls.clientName.setValue(null)
      this.gridFilterDropdownService.getclientDetails(clientId).subscribe((response) => {
        this.accountList =   response?.map(element => ({ label: element.name, value: element._id }))
        this.basicDetails[3] =   { id: 'clientName', controlType: 7, label: 'client_name', viewname: 'clientName', name: 'clientName', order: 2, value: [], required: false, appearance: "fill", fill: this.accountList, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" }
      
      })
    }else{
      this.exchangeNameList=[];
      this.accountList=[];
      this.fundlist=[];
      this.formEl.form.controls.clientName.setValue(null)
      let fundId = this.formEl.form.value?.fundName
      this.gridFilterDropdownService.getclientDetailbyfund(fundId).subscribe((response) => {
        this.accountList =   response?.map(element => ({ label: element.name, value: element._id }))
        this.basicDetails[3] =   { id: 'clientName', controlType: 7, label: 'client_name', viewname: 'clientName', name: 'clientName', order: 2, value: [], required: false, appearance: "fill", fill: this.accountList, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" }
      })

    }
  }
  }
  deleteByCriteriaModal() {
    this.selectedBrokerDelete = null;
    this.display = true;
    this.getCustodianList();
  }

  getCustodianList() {
    this.gridService.getCustodianList("tradeEntry/fetchAllDistinctCustodiansByEnterprise", (cbs) => {
      cbs.forEach(element => {
        this.custodianList.push({ name: element });
      });
    }, (cbe) => {
    })
  }
  getTickerList(selectedCustodian) {
    this.gridService.getTickerList('tradeEntry/getDitinctTicker?accountNo=' + selectedCustodian, (cbs) => {
      cbs.forEach(element => {
        this.tickerList.push({ name: element });
      });
    }, (cbe) => {
    })
  }

  deleteModal(deleteType) {
    let gridApi = this.gridApi;
    if (deleteType == "deleteFiltered" || deleteType == "deleteAll") {
      this.selectedRows = this.rowData;
    }

    this.result = this.commonService.getPeriodLockmessagefordelete(this.selectedRows, this.componentName, deleteType);
    if (deleteType == "deleteSelected" && (this.componentName == 'tradeList' || this.componentName == "rapidTradeList") && this.result != false) {
      let tradeNotDelete = false;
      for (let element = 0; element < this.selectedRows.length; element++) {
        var tradeDate: any = this.selectedRows[element].tradeDate ? new Date(this.selectedRows[element].tradeDate) : "";
        var openTaxLotDate: any = this.selectedRows[element].openTaxLotDate ? new Date(this.selectedRows[element].openTaxLotDate) : "";
        if (this.selectedRows[element].taxLotApplied || (openTaxLotDate && tradeDate && openTaxLotDate.getTime() >= tradeDate.getTime())) {
          tradeNotDelete = true;
          this.messageService.add({ sticky: true, severity: "error", summary: this.translateService.instant("trade_entry"), detail: this.translateService.instant('trade_can`t_be_deleted') });
          break;
        }
      }
      if (tradeNotDelete) {
        return false;
      }
    }
    if (this.result != false) {
      this.display = false;
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        data: {
          message: deleteType == "deleteByCriteria" ? "Are you sure You want to delete all records for selected Custodian Account?" : deleteType == "deleteSelected" ? 'Are you sure you want to delete ' + this.selectedRows.length + " selected records?" : deleteType == "deleteFiltered" ? 'Are you sure you want to delete ' + this.totalFltRecords + " Filtered records?" : 'Are you sure you want to delete ' + this.totalRecords + " records?",
          buttonText: {
            ok: 'Yes',
            cancel: 'No'
          }
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          if (deleteType == "deleteSelected") {
            this.deleteObjData = this.gridFilterService.deleteSelected(gridApi, this.selectedRows);
          } else if (deleteType == "deleteAll") {
            this.deleteObjData = this.gridFilterService.deleteAll();
          }
          else if (deleteType == "deleteByCriteria") {
            let custodianTickerData = { selectedBrokerDelete: this.selectedBrokerDelete, selectedTicker: this.selectedTicker }
            this.deleteByCriteria(custodianTickerData);
          }
          else if (deleteType == "deleteFiltered") {
            this.deleteObjData = this.gridFilterService.deleteFilter(this.rowData, this.tempInstanceDataValue);
          }
          this.deleteDataFunction(deleteType);
        }
      });
    }
  }

  deleteDataFunction(deleteType) {
    let focusedNode = this.gridApi.getSelectedRows();
    if (deleteType != "deleteByCriteria") {
      this.gridService.deleteSelectedGridData(this.deleteObjData.gridDeleteUrl, this.deleteObjData.deldata, (cbs) => {
        if (cbs.code) {
          let locMsg = cbs.message.toLowerCase();
          if (!locMsg.includes('not') && !locMsg.includes('mapped') && !locMsg.includes('failed') && !locMsg.includes("can't")) {
            if (deleteType == "deleteSelected") {
              if (this.totalRecords && !this.istotalFilterCount) {
                this.totalRecords = Number(this.totalRecords) - focusedNode.length;
              } else {
                this.totalFltRecords = Number(this.totalFltRecords) - focusedNode.length;
              }
              focusedNode.forEach(selectedNode => {
                this.gridApi.updateRowData({ remove: [selectedNode] })
              });
              let message = cbs.message.includes('Record(s) deleted') || cbs.message.includes('Record deleted.') || cbs.message.includes('Record Deleted.') || cbs.message.includes('Record Deleted');
              if (message && cbs.code == 1) {


                this.messageService.add({ sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: this.translateService.instant(cbs.message) });
              }
              else {
                this.messageService.add({ closable: true, sticky: true, severity: 'error', detail: cbs.message });
              }
            }
            else if (deleteType == "deleteFiltered") {
              this.rowData = [];
              if (this.gridApi != null || this.gridApi != undefined) {
                if (this.rowData != null || this.rowData != undefined) {
                  this.gridApi.setRowData(this.rowData);
                }
              }
              let message = cbs.message.includes('Record(s) deleted') || cbs.message.includes('Record deleted.') || cbs.message.includes('Record Deleted.') || cbs.message.includes('Record Deleted');
              if (message && cbs.code == 1) {
                let message = (this.componentName == "nonTradeActivity" || this.componentName == 'rapidNonTradeActivity' || this.componentName == 'fetchBankTransaction') ? this.totalFltRecords + ' :Record(s) deleted' : cbs.message;
                this.messageService.add({ sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: this.translateService.instant(message) });
              }
              else {
                this.messageService.add({ closable: true, sticky: true, severity: 'error', detail: cbs.message });
              }
              this.totalFltRecords = 0;
            }
            else if (deleteType == "deleteAll") {
              let message = cbs.message.includes('All Records Deleted');
              if (message && cbs.code == 1) {


                this.messageService.add({ sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: this.translateService.instant(cbs.message) });
              }
              else {
                this.messageService.add({ closable: true, sticky: true, severity: 'success', detail: cbs.message });
              }

              this.rowData = [];
              this.totalRecords = this.totalFltRecords = 0;
              if (this.gridApi != null || this.gridApi != undefined) {
                if (this.rowData != null || this.rowData != undefined) {
                  this.gridApi.setRowData(this.rowData);
                }
              }
              this.totalRecords = 0;
            }
          }
        }
        // this.messageService.add({ closable: true, sticky: true, severity: 'error', detail: cbs.message });

      }, (cbe) => { });
    }
    else {
      this.selectedBrokerDelete = null;
      this.custodianList = [];
      this.selectedTicker = "";
    }
  }
  /*
    Auth : Vg
    Params:null
    Close Grid SideBar for onmouseleave event
     */
  mouseOutOfGrid(evt) {
    if (evt) {
      let sideBarFlg = this.gridApi.isSideBarVisible()
      if (sideBarFlg) {
        // this.gridApi.setSideBarVisible(false);
        this.gridApi.closeToolPanel();
      }
    }
  }

  deleteByCriteria(custodianTickerData) {
    var reqObj = {
      "custodianBroker": custodianTickerData.selectedBrokerDelete['name'],
      "ticker": custodianTickerData.selectedTicker ? custodianTickerData.selectedTicker['name'] : null
    };
    let currentDate = moment(new Date()).format('YYYY-MM-DD h:mm:ss');
    this.gridService.deleteByCriteria("tradeEntryV2/deleteByCriteria/" + currentDate, reqObj, (cbs) => {
      if (cbs.message.includes('Lock Date')) {
        this.messageService.add({ sticky: true, severity: "error", summary: "Run Fx G/L", detail: cbs.message })
      }
      else {
        this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: this.translateService.instant('trade_entry_delete_successfully') });
      }
    }, (cbe) => {
      this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('data_cannot_be_deleted') });
    })
  }
  toast(severity, summary, detail, autoClear) {
    this.messageService.add({ closable: true, sticky: true, severity: severity, summary: summary, detail: detail });
  }

  setExpendView() {
    setTimeout(() => {
      this.setupPercentageService.setShowGridMsg(this.displayForm ? false : true);

    }, 50)

  }
  onFirstDataRendered(params: FirstDataRenderedEvent) {
    // arbitrarily expand a row for presentational purposes

    if (this.componentName == 'rapidCompoundJEListComponent') {
      setTimeout(function () {
        params.api.getDisplayedRowAtIndex(1)!.setExpanded(false);
      }, 0);
    }
  }
  public detailCellRendererParams: any = {
    detailGridOptions: {
      columnDefs: [
        { field: 'nameOfGl', "headerName": "Account", minWidth: 150, "resizable": true, "suppressMenu": true },
        { field: 'dr', "headerName": "Dr Amount", minWidth: 150, "resizable": true, "suppressMenu": true },
        { field: 'cr', "headerName": "Cr Amount", minWidth: 150, "resizable": true, "suppressMenu": true },
        { field: 'fXRateAmount', "headerName": "Fx Rate", minWidth: 150, "resizable": true, "suppressMenu": true },
        { field: 'currency', "headerName": "Currency",minWidth: 150, "resizable": true, "suppressMenu": true },
        { field: 'bankACName', "headerName": "Vendor", minWidth: 150,"resizable": true, "suppressMenu": true },
        { field: 'bankACNameCustomer', "headerName": "Customer", minWidth: 150,"resizable": true, "suppressMenu": true },
        { field: 'investorName', "headerName": "Investor No.",minWidth: 150, "resizable": true, "suppressMenu": true },
        { field: 'tickerSymbol', "headerName": "Ticker",minWidth: 150, "resizable": true, "suppressMenu": true },
        { field: 'custodianACNumber', "headerName": "Custodian",minWidth: 150, "resizable": true, "suppressMenu": true },
        { field: 'departmentName', "headerName": "Department",minWidth: 150, "resizable": true, "suppressMenu": true },
        { field: 'customField1', "headerName": "Custom Field 1",minWidth: 150, "resizable": true, "suppressMenu": true },
        { field: 'customField2', "headerName": "Custom Field 2",minWidth: 150, "resizable": true, "suppressMenu": true },
        { field: 'customField3', "headerName": "Custom Field 3",minWidth: 150, "resizable": true, "suppressMenu": true },

        { field: 'description', "headerName": "Description",minWidth: 150, "resizable": true, "suppressMenu": true },
      ],
      defaultColDef: {
        flex: 1,
      },
    },

    getDetailRowData: (params) => {
      this.gridApi.forEachNode((node) => {
        if (node.rowIndex === params.node.rowIndex - 1) {
          node.setExpanded(true);
        } else {
          node.setExpanded(false);
        }
      });
      params.successCallback(params.data.cJe);
    },
  }
  isinputui() {
    return (this.componentName == 'rapidCompoundJEListComponent' ||this.componentName == 'fetchBankTransaction' ||this.componentName == 'dataMonitoringinput' || this.componentName == 'rapidNonTradeActivity' || this.componentName == "rapidTradeList" || this.componentName == "seamlessmarketdata" || this.componentName == 'RapidSeamlessmarketdata' || this.componentName == 'RapidJournalentry' || this.componentName == 'viewclosedtaxLots' || this.componentName == 'rapidViewtotalgl' || this.componentName == 'transferTradeStatus' || this.componentName == 'rapidReBudgets'|| this.componentName == 'disableAutoUpdateViaAllocationUpdate' || this.componentName == 'redemptionActivityUpdate' || this.componentName == 'subscriptionActivityUpdate' || this.componentName == 'fetchCsdBankTransactionPendingReview')
  }
  downloadExcel() {
    let url = environment.api_url + apiConstants[this.componentName]['gridExportUrl'].url;
    this.commonService.GenericDownload(url)
  }

  getdownloadExcelFilteredData() {
    const activityType = this.formData?.activityType?.id || '';
    let data = {}
    data = { "activityType": activityType }
    this.exportFilteredExcel(data)
  }
  exportFilteredExcel(type, type1?) {
    Object.assign(this.formData, this.formEl.form.value);
    const filtredModel = this.gridApi.getFilterModel();
    let x = Object.entries(filtredModel);
    var key = x[0][0];
    let value = x[0][1]['filter'];
    let gridOptions = { api: this.gridApi };
    let searchObject = this.commonService.getDataToFilter(gridOptions, this.gridApi);
    Object.keys(searchObject).forEach(x => {
      switch (x) {
        case "country.name": {
          searchObject["country"] = searchObject[x];
          delete searchObject[x];
          x = "country"
          break;
        } case "paymentInstruction": {
          if (this.componentName == "paymentList") {
            searchObject["paymentModeName"] = searchObject[x];
            delete searchObject[x];
            x = "paymentModeName"
          }
          break;
        } case "status": {
          if (this.componentName == "erpinvoicecreation") {
            searchObject["invoiceStatus"] = searchObject[x];
            delete searchObject[x];
            x = "invoiceStatus"
          }
          break;
        }
        case "paymentModesList": {
          if (this.componentName == 'customerList' || this.componentName == 'allCustomerList') {
            searchObject["paymentModes"] = searchObject[x];
            delete searchObject[x];
            x = "paymentModes"
          }
          break;
        }
        default: {
          x = key
          break;
        }
      }
    });

    if (this.componentName == 'disableAutoUpdateViaAllocationUpdate' || this.componentName == 'redemptionActivityUpdate' || this.componentName == 'subscriptionActivityUpdate') {
      let transactionType = this.formData?.['transactionType']?.["id"] ? this.formData['transactionType']['id'] : null;
      let reqObjTransType = searchObject?.['transactionType'];
      if (transactionType == "Subscription - Transfer" && reqObjTransType == "Subscription - Transfer") {
        searchObject['transferActivity'] = true;
        searchObject['transactionType'] = "Subscription - First Time";
      } else if (transactionType == "Redemption - Transfer" && reqObjTransType == "Redemption - Transfer") {
        searchObject['transferActivity'] = true;
        searchObject['transactionType'] = "Redemption - Partial by Amount";
      } else {
        searchObject['transferActivity'] = false;
      }
      searchObject['subCategory'] = this.subCategory;
    }
    let url = environment.api_url + apiConstants[this.componentName]['gridFilteredUrl'].url;
    this.commonService.GenericPostDownload(searchObject, url);
  }

  postPending() {
    this.gridFilterService.postToCSDBankTransactions(this.selectedRows).subscribe((response) => {
      if (response && response.code == 1) {
        this.getFilteredData();
        this.displayForm = true;
        this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: this.translateService.instant(response.message) });
      }
      else {
        this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant(response.message) });
      }
    });
  }
  dataDownload() {
    let url = environment.api_url + 'api/eventMonitoring/export';
    if (this.componentName == "dataMonitoring" || this.componentName == 'dataMonitoringinput') {
      this.reqObj = this.reqObj || {}
      this.reqObj['dataPeriod'] = this.reqObj['dataPeriod']
      this.reqObj['filterList'] = this.reqObj['filterList'] || []
      this.reqObj['page'] = this.page

    }
    this.messageService.add({
      sticky: true,
      severity: "success",
      summary: this.translateService.instant("confirmed"),
      detail: this.translateService.instant("download_request_taken_soon"),
    });
    this.commonService.GenericPostDownload(this.reqObj, url);
  }
  hideDialogBox() {
    this.iscommentpopup = false;
  }
}
