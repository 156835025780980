
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import {
  ColDef,
  GridOptions,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { apiConstants } from 'src/app/constants/apiConstants';
import { CommonService } from '../../services/commonServices/common.service';
import moment from 'moment';
import { ViewBtnCellRenderer } from '../../buttons/view-button-cell-renderer.component';
import { OverAllStatusButton } from '../../buttons/over-all-status-button/over-all-status-button.component';
import { LocalStoreService } from '../../services/commonServices/local-store.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { fadeInRightAnimation } from 'src/@fury/animations/fade-in-right.animation';
import { fadeInUpAnimation } from 'src/@fury/animations/fade-in-up.animation';
import { GridApi } from 'ag-grid';
import { ClientGridService } from './client-grid.service';

@Component({
  selector: 'client-grid',
  templateUrl: './client-grid.component.html',
  styleUrls: ['./client-grid.component.scss'],
  animations: [fadeInRightAnimation, fadeInUpAnimation,]
})


export class ClientGridComponent {
  public columnDefs: ColDef[];
  public defaultColDef: ColDef = {
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    menuTabs: ["filterMenuTab", "generalMenuTab", "columnsMenuTab"]
  };
  public defaultsideBar: any;
  public sideBar: any;
  currentUser = JSON.parse(this.localService.getItem('fss'));
  authList: any = this.currentUser['authList'];
  public roleAs: any = this.currentUser['ROLE']
  currentRole = this.authList.indexOf('ORDER_INITIATOR') > -1 ? 'initiator' : 'approver';
  isEnterprise = ((this.authList.indexOf('USER_CREATE') > -1 && this.authList.indexOf('USER_DELETE') > -1) ? true : false)
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public rowGroupPanelShow: 'always' | 'onlyWhenGrouping' | 'never' = 'always';
  public pivotPanelShow: 'always' | 'onlyWhenPivoting' | 'never' = 'always';
  public rowData: any[];
  public frameworkComponents: any;
  public totalRecords: any;
  @Input() componentName: any;
  @Input() isSmartTable: any;
  @Input() heading: String;
  @Input() isShow: any;
  public parentHeading: any;
  public gridHeaders: any;
  public card: any;
  public gridCrumb: any;
  public moduleNameGrid: any;
  public subscription1: any;
  public selectedRows: any = [];
  private gridApi!: GridApi;
  public gridOptions: GridOptions;
  selectedLanguage: any;
  public isFiltered: boolean = false;

  constructor(private http: HttpClient,private commonService: CommonService,private localService: LocalStoreService,
    private translateService: TranslateService, private router: Router, private httpClient: HttpClient,handler: HttpBackend,
    private clientGridService: ClientGridService) {
      this.gridOptions = <GridOptions>{};
      this.gridOptions.onFilterChanged = this.customFilterEventHandler.bind(this);
      this.httpClient = new HttpClient(handler);

      this.selectedLanguage = this.currentUser['switchLanguage']
      this.translateService.onLangChange.subscribe((res) => {
        this.selectedLanguage = res.lang
        this.getGridHeader();
        setTimeout(() => {
          if (this.gridApi) {
            this.gridApi.refreshCells({ force: true });
          }
        }, 1000);
      })

    this.frameworkComponents = {
      viewBtnCellRenderer: ViewBtnCellRenderer,
      overAllStatusButton: OverAllStatusButton,
      componentName: this.componentName,
    }
  }

  public customDateFilter = (filterLocalDateAtMidnight: Date, cellValue: string) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    try{
    var dateParts = dateAsString.split('-');
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[0])-1 ,
      Number(dateParts[1])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  } catch(e){
    console.log(e.message);
  }
    return 0;
  };

  customFilterEventHandler() {
    this.gridApi.deselectAll();
    this.selectedRows = [];
    this.clientGridService.filterHeaders = [];
    this.isFiltered = false;
    this.columnDefs.forEach((colDef) => {
      var filterInstance = this.gridApi.getFilterInstance(colDef.field);
  
      // Check if a filter is applied
      if (filterInstance && filterInstance.isFilterActive()) {
        this.clientGridService.filterHeaders.push([colDef.field,filterInstance.getModel()]);
        this.isFiltered = true;
      }
    });
  }
//this.commonService.getDataToFilter(evt, gridOptions);
  getFilterData(e: any) {
    const nodeTextandNum = e?.filterInstance?.eGui?.children[0]?.children[1]?.children[0]?.children[1]?.children[0];
    const nodeDate = e?.filterInstance?.eGui?.children[0]?.children[1]?.children[0]?.children[0]?.children[0]?.children[1]?.children[0];
    const nodeDateRange = e?.filterInstance?.eGui?.children[0]?.children[1]?.children[1]?.children[0]?.children[0]?.children[1]?.children[0];
    const applyButton = e?.filterInstance?.eGui?.children[1]?.children[1];
    let isRange = e?.filterInstance?.eGui?.innerText.includes("range");
    let node = nodeTextandNum != undefined ? nodeTextandNum : (isRange ? nodeDateRange : nodeDate);
    node.addEventListener("keyup", (event: any) => {
      if (event.key === "Enter") {
        let ele: HTMLElement = document.getElementsByClassName(applyButton)[1] as HTMLElement;
        applyButton.click();
      }
    });
  }

  ngOnInit(): void {
    this.moduleNameGrid = this.router.url.split('/')[1];
    switch (this.moduleNameGrid) {
      case 'systemmanager':
        this.moduleNameGrid = 'admin_module';
        break;
    }
    this.translateService.get(this.moduleNameGrid).subscribe((res) => {
      this.moduleNameGrid = res;
    });

    this.parentHeading = apiConstants[this.componentName]['parentHeading']
    if (this.roleAs == "SystemManager") {
      this.card = apiConstants[this.componentName]['systemAdminCard']
    }
    this.defaultsideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressSideButtons: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true,
          },
        },
      ],
      defaultToolPanel: 'filters',
    };
    this.setDetailedDefinition();
  }

  ngOnChanges(changes: any) {
    this.getGridHeaderUrl();
  }
  setDetailedDefinition() {
    this.columnDefs = new Array;
    this.sideBar = this.defaultsideBar;
  }
  getGridHeaderUrl() {
    
    this.gridHeaders = apiConstants[this.componentName]['headers'];
    this.getGridHeader();
  }

  async onGridReady(params: any) {
    params.api.componentName = this.componentName;
    params.api.subComponent = "";
    params.api.lookups = "";
    
    params.api.isdViewBtnCheck = this.authList.indexOf('SEAMLESS_ISD_INVESTOR_STATEMENT_DISTRIBUTION_VIEW') > -1 ? true : false;
    params.api.isdEnableViewBtnCheck = this.authList.indexOf('SEAMLESS_ISD_REDISTRIBUTION_VIEW') > -1 ? true : false;
    
    params.api.enterpriseId = this.currentUser.enterpriseId;
    params.api.periodLockDate = this.currentUser.periodLockDate;
    params.api.roleAs = this.roleAs;
    params.api.issystemAdmin = (this.currentUser.authList.indexOf('FUNCTION_CREATE') > -1 && this.currentUser.authList.indexOf('ENTERPRISE_CREATE') > -1) ? true : false;
    this.gridApi = params.api;
    await this.getGridData();
    this.clientGridService.filterHeaders.forEach((filterHeader)=>{
      const filterInstance = this.gridApi.getFilterInstance(filterHeader[0]);
      if (filterInstance) {
        filterInstance.setModel(filterHeader[1]);
      }
    });
  }

  async getGridData(): Promise<void>{
    this.selectedRows = [];
    var data = await this.http
      .get(
        apiConstants[this.componentName]['gridDataUrl']
      ).toPromise();
    this.rowData = data["resultList"];
    this.totalRecords = data['resultList'].length;
    this.rowData.forEach((doc)=>{
      if(doc["status"]==null){
        doc["status"]=" ";
      }
      else if(doc["status"]=='inactive'){
        doc["status"]='deactive';
      }
      if(doc['updatedOn']){
        doc['updatedOn'] = moment(new Date(doc['updatedOn'])).format("MM-DD-YYYY");
      }
      if(doc['lastLogin']){
        doc['lastLogin'] = moment(new Date(doc['lastLogin'])).format("MM-DD-YYYY");
      }
      if(doc['periodLockDate']){
        doc['periodLockDate'] = moment(new Date(doc['periodLockDate'])).format("MM-DD-YYYY");
      }
      if(doc['createdOn'])
      doc['createdOn'] = moment(new Date(doc['createdOn'])).format("MM-DD-YYYY");
    });
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  exportExcel(){
    var date = new Date();
    var fileName = "AllUsers"+this.gridApi.getDisplayedRowCount()+" "+ date.getFullYear()+this.padTo2Digits(date.getMonth())+this.padTo2Digits(date.getDate())
                  + "-" + this.padTo2Digits(date.getHours()) + this.padTo2Digits(date.getMinutes()) ;
    var headerList = [];
    this.columnDefs.forEach((element)=>{
      headerList.push(element.field);
    });
    this.gridApi.exportDataAsCsv({"columnKeys":headerList.slice(1), "fileName":fileName});
  }

  onSelectionChanged(evt) {
    this.selectedRows = evt.api.getSelectedRows();
  }

  getGridHeader() {
    this.subscription1 = this.httpClient.get(this.gridHeaders).subscribe((headers: any) => {
      headers[1]['cellRendererParams'] = {
        refershGrid: this.getGridData.bind(this)
      }
      headers[7]['filterParams']["comparator"] = this.customDateFilter;
      headers[8]['filterParams']["comparator"] = this.customDateFilter;
      headers[10]['filterParams']["comparator"] = this.customDateFilter;
      headers[9]['filterParams']["comparator"] = this.customDateFilter;
      this.columnDefs = this.commonService.formatDataUsingGridHeaders(headers);

      this.columnDefs.forEach((element: any) => {
        let heder = element.headerName;
        this.translateService.get([heder]).subscribe((traval) => {
          if (traval[heder] != undefined || traval[heder] != null) {
            element.headerName = traval[heder];
          }
        });
      });
    });
  }


  ngOnDestroy(): void {
    this.subscription1.unsubscribe()
    
  }

}
