/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class GlobalService {
    displayGrid: boolean;


    constructor() { }

    changeTableView(type) {
        alert(type)
        if (type == true) {
            this.displayGrid = false;
        }
        else {
            this.displayGrid = true;
        } return (this.displayGrid)


    }
    private newUser = new BehaviorSubject<any>({
        componentName: '', subComponent: ''
    });

    setNewUserInfo(user: any) {
        this.newUser.next(user);
    }

    getNewUserInfo() {
        return this.newUser.asObservable();
    }



    getFieldsForExcel(type: any, templateType?) {
        var pageType = type.toLowerCase();
        var fields = [];
        switch (pageType) {
            case "marketdata":
                fields = [{
                    lastTradePrice: "",
                    ticker: "",
                    valuationDateAndTime: "",
                    askPrice: "",
                    beta: "",
                    bidPrice: "",
                    closePrice: "",
                    convexity: "",
                    currency: "",
                    delta: "",
                    exchangeRate: "",
                    fundName: "",
                    gamma: "",
                    highPrice: "",
                    impliedVolatility: "",
                    lowPrice: "",
                    macaulayDuration: "",
                    marketCapitalization: "",
                    mid: "",
                    modifiedDuration: "",
                    openPrice: "",
                    pAndE: "",
                    productType: "",
                    realizedVolatility: "",
                    securityName: "",
                    theta: "",
                    tradingVolume: "",
                    vega: "",
                    ytm: "",

                }];
                break;
            case "rapidMarketdata":
                fields = [{
                    valuationDateAndTime: "",
                    securityName: "",
                    ticker: "",
                    productType: "",
                    currency: "",
                    bidPrice: "",
                    askPrice: "",
                    mid: "",
                    openPrice: "",
                    highPrice: "",
                    lowPrice: "",
                    lastTradePrice: "",
                    closePrice: "",
                    tradingVolume: "",
                    delta: "",
                    gamma: "",
                    vega: "",
                    theta: "",
                    impliedVolatility: "",
                    realizedVolatility: "",
                    beta: "",
                    marketCapitalization: "",
                    pAndE: "",
                    ytm: "",
                    macaulayDuration: "",
                    modifiedDuration: "",
                    convexity: "",
                    exchangeRate: "",
                    fundName: ""
                }];
                break;
            case "aumdetailsdemo":
                fields = [{
                    'date': "",
                    'fund': "",
                    'managerName': "",
                    'morningstarTicker': "",
                    'dateFundMorningstarTicker': "",
                    'aUMFundNAV': "",
                    'aUMFundNAVBOD': "",
                    'fundMorningstarNet': "",
                    'aUMManagerNAV': "",
                    'aUMManagerNAVBOD': "",
                    'morningstarGross': "",
                    'morningstarNet': ""
                }];
                break;

            case "feedetailsdemo":
                fields = [{
                    'fund': "",
                    'date': "",
                    'investorId': "",
                    'investorNo': "",
                    'investorName': "",
                    'managementFees': "",
                    'poolOperatorsFees': "",
                    'performanceFees': ""
                }];
                break;
                case "noteslist":
                    fields = [{
                        "Fund Level Note": "",
                        "Investor Level Note": "",
                        "Investor Account Number Level Notes": "",
                        "Disable Notes Label": "",
                        "Date Range": "",
                        "Start Date": "",
                        "End Date": "",
                        "Fund": "",
                        "Investor Name List": "",
                        "Investor Number List": "",
                        "Investor Account Number List": "",
                        "Notes": ""
                    }];
                    break;
            case "allsmupload":
                fields = [{
                    'Ticker': "",
                    'id': "",
                    // 'Coin Market Cap ID': "",
                    // 'Coingecko ID': "",
                    // 'Coinbase Id': "",
                    // 'MEXC Id': "",
                    // 'Bitfinex Id': "",
                    // 'DYDX Id': "",
                    // 'Gate IO Id': "",
                    // 'Bitmex ID': "",
                    // 'KuCoin Id': "",
                    // 'Bitget Id': "",
                    // 'Crypto.com Id': "",
                    // 'Vinter Id': "",
                    // 'Binance Futures ID': "",
                    // 'Okex Futures ID': "",
                    'Custom Sector': "",
                    'Custom Sub Sector': "",
                    'Primary Exchange': "",
                    'Period of Option': "",
                    'OSI Code': "",
                    'Option Expiry': "",
                    'Call/Put': "",
                    'FX Type': "",
                    'Currency 1': "",
                    'Currency 2': "",
                    'Expiry Date': "",
                    'Price Inversion': "",
                    'Custom Market': "",
                    'Currency Pair': "",
                    'Option Style': "",
                    'Effective Date From': "",
                    'Effective Date To': "",
                    'Date of Record Creation': "",
                    'ISIN': "",
                    'CUSIP': "",
                    'SEDOL': "",
                    // 'Bloomberg ID': "",
                    'RIC Code': "",
                    'IDC ID': "",
                    'Name of Issuer': "",
                    'Coupon': "",
                    'Name of Security': "",
                    'Day Count Method': "",
                    'Compounding Frequency': "",
                    'Interest Payment Frequency': "",
                    'Issue Date': "",
                    'Exchange': "",
                    'Currency': "",
                    'Asset Class': "",
                    'Regulator': "",
                    'Bond Rating': "",
                    'Inv. Grade (Y/N)': "",
                    'Bond Default Date': "",
                    'Zero Coupon Bond (Y/N)': "",
                    'Sector': "",
                    'Sub Sector': "",
                    'Maturity Date': "",
                    'Country': "",
                    'US Tax Exempt (Y/N)': "",
                    'Voting Rights': "",
                    'Cumulative': "",
                    'Convertible': "",
                    'Multiplier': "",
                    'Market Cap': "",
                    'Bond Type': "",
                    'Custom Field 1': "",
                    'Market': "",
                    'Custom Field 3': "",
                    'Custom Field 4': "",
                    'Custom Field 5': "",
                    'Custom Field 6': "",
                    'Custom Field 7': "",
                    'Custom Field 8': "",
                    'Custom Field 9': "",
                    'Custom Field 10': "",
                    'Coupon Type': "",
                    'Factor': "",
                    'Interest Deferrment Period': "",
                    'Lookup Days': "",
                    'Holiday Calendar': "",
                    'Custom Sub Market': "",
                    'Custom Asset Class': "",
                    'Custom Maturity': "",
                    'Bloomberg Security Short Name': "",
                    'Pricing Source Priority': "",
                    'Data Source Priority': "",
                    // 'Reuters Id': "",
                    // 'nyse Id': "",
                    // 'Kraken Id': "",
                    'Market Price Multiplier': "",
                    '1256 Contract': "",
                    'Contract Size': "",
                    'Type of Future': "",
                    'Underlying': "",
                    'Product Master ID of Underlying': "",
                    'Last Trading Day': "",
                    'Quandl Ticker': "",
                    'Bar Chart Ticker': "",
                    'Custom Exposure Attribute': "",
                    'Month Year': "",
                    // 'CME ID': "",
                    'LME ID': "",
                    'Trading Strategy': "",
                    'Accounting Method': "",
                    'Redemption Time': "",
                    'Notice Period Days': "",
                    'Lock-Up Period (Months)': "",
                    'Early Redemption Fee': "",
                    'Fund Name': "",
                    'Holding Name': "",
                    'Portfolio Company Name': "",
                    'Strategy 2': "",
                    'Sub Strategy': "",
                    'Sector1': "",
                    'Sub Sector1': "",
                    'Sector 2': "",
                    'Sub Sector 2': "",
                    'Sub Strategy 2': "",
                    // 'Custom Sector': "",
                    'Region': "",
                    'Investment Type': "",
                    // 'Asset Class': "",
                    'Asset Structure': "",
                    'Asset Type': "",
                    'Distribution Frequency': "",
                    'Target Return': "",
                    'MOIC': "",
                    'IRR': "",
                    'Vintage Date': "",
                    'Purchase Date': "",
                    'Tenure End Date': "",
                    'Custom Date 1': "",
                    'Custom Date 2': "",
                    'Publicly Traded': "",
                    'Commitment Amount': "",
                    'Fund ID': "",
                    'Holding ID': "",
                    'Port Co. ID': "",
                    'Fund Region': "",
                    'Project': "",
                    'Tick Size': "",
                    // 'quodd Id': "",
                    // 'Huobi Id': "",
                    // 'Bybit Id': "",
                    // 'Deribit Id': "",
                    'FS ProductTypes': "",
                    'FS Sector': "",
                    'FS Investment Type for SOI & SOFC': "",
                    'FS Investment Type for Cash Flow': "",
                    'FS Ranking for Cash Flow': "",
                    'FS Country': "",
                    'FS Level': "",
                    'Primary Underlying Risk': "",
                    'Valuation Techniques': "",
                    'Unobservable inputs': "",
                    'Range of inputs': "",
                    // 'Ftx Id': "",
                    // 'Copper Id': "",
                    'Entity #': "",
                    'Entity Name': "",
                    'Entity Type': "",
                    'Acquisition Date': "",
                    'Disposition Date': "",
                    'Joint Venture': "",
                    'Contract Share': "",
                    'Cash Flow Share': "",
                    'Effective Share': "",
                    'Legal Entity': "",
                    'Property Address': "",
                    'City': "",
                    'State': "",
                    'Neighborhood (MSA)': "",
                    'Gross Square Feet': "",
                    'Net Rentable Area': "",
                    'Leased Square Footage': "",
                    'Number of Properties': "",
                    'Number Of Buildings': "",
                    'Number Of Units': "",
                    'Number Of Floors': "",
                    'Appraisal Type': "",
                    'Gross Price': "",
                    'Net Price': "",
                    'Acquisition Cost': "",
                    'Property Type': "",
                    'Property Sub-Type': "",
                    'Lifecycle': "",
                    'PricingSource Id': "",
                    "Ref Rate":"",
                    "Spread":""
                }];
                break;
            case "allsmupload2":
                fields = [{
                    'First Name': "",
                    'Last Name': "",
                    'User Name': "",
                    'Email': "",
                    'Fund Name': "",
                }];
                break;

            case "marketdatavar":
                fields = [{
                    valuationDateAndTime: "",
                    ticker: "",
                    productType: "",
                    currency: "",
                    bidPrice: "",
                    askPrice: "",
                    openPrice: "",
                    highPrice: "",
                    lowPrice: "",
                    lastTradePrice: "",
                    closePrice: "",
                    tradingVolume: "",
                    delta: "",
                    gamma: "",
                    vega: "",
                    theta: "",
                    impliedVolatility: "",
                    realizedVolatility: "",
                    beta: "",
                    marketCapitalization: "",
                    pAndE: "",
                    ytm: "",
                    macaulayDuration: "",
                    modifiedDuration: "",
                    convexity: "",
                    exchangeRate: "",
                    fundName: ""
                }];
                break;


            case "trade":
                fields = [{
                    asstClass: "",
                    custodianBroker: "",
                    sides: "",
                    ticker: "",
                    tradeDateTime: "",
                    tradeStatus: "",
                    currencyCode: "",
                    exchangeRateTrade: "",
                    quantity: "",
                    baseAmount: "",
                    buyAmount: "",
                    buyCurrencyCode: "",
                    capitalContribution: "",
                    clearingBrokerNumber: "",
                    clearingFee: "",
                    clearingFee1: "",
                    clearingFeeCurrencyCode: "",
                    clearingFeeCurrencyCode1: "",
                    commission: "",
                    commissionCurrencyCode: "",
                    commitmentAmount: "",
                    coupon: "",
                    cryptoFutureCommissionCurrency: "",
                    cryptofuturescommission: "",
                    description: "",
                    effectiveDate: "",
                    entryDateTime: "",
                    exchange: "",
                    exchangeFees: "",
                    exchangeFees1: "",
                    exchangeFeesCurrencyCode: "",
                    exchangeFeesCurrencyCode1: "",
                    exchangeRate: "",
                    executingBrokerNumber: "",
                    investorNumber: "",
                    localAmount: "",
                    maturityDate: "",
                    nfaFees: "",
                    nfaFees1: "",
                    nfaFeesCurrencyCode: "",
                    nfaFeesCurrencyCode1: "",
                    occFee: "",
                    occFee1: "",
                    occFeeCurrencyCode: "",
                    occFeeCurrencyCode1: "",
                    optionType: "",
                    orderId: "",
                    originalPurchaseDate: "",
                    originalPurchasePrice: "",
                    osiFees: "",
                    osiFees1: "",
                    osiFeesCurrencyCode: "",
                    osiFeesCurrencyCode1: "",
                    otherCommission: "",
                    otherCommission2: "",
                    otherCommission3: "",
                    otherCommissionCurrencyCode: "",
                    otherCommissionCurrencyCode2: "",
                    otherCommissionCurrencyCode3: "",
                    pair: "",
                    parValue: "",
                    principal: "",
                    purchaseInterest: "",
                    referenceId: "",
                    rollOverCost: "",
                    securityName: "",
                    sellAmount: "",
                    sellCurrencyCode: "",
                    sellInterest: "",
                    settlementDate: "",
                    strategy: "",
                    strikePrice: "",
                    taxable: "",
                    taxClosingMethod: "",
                    taxLotDate: "",
                    thirdPartySource: "",
                    ticketCharges: "",
                    ticketCharges1: "",
                    ticketChargesCurrencyCode: "",
                    ticketChargesCurrencyCode1: "",
                    tradePrice: "",
                    tradeType: "",
                    transactionHash: "",
                    fsTransactionCode:""

                }];
                break;
            case "transfertrade":
                fields = [{
                    tradeId: "",
                    pnlDate: "",
                    sourceAccount: "",
                    targetAccount: "",
                    ticker: "",
                    tradeDateTime: "",
                    taxLotDate: "",
                    quantity: "",
                    balance: "",
                    quantityToBeTransfer: "",
                    tradePrice: ""
                }];
                break;
                case "compoundje":
                    fields = [{
                        'Upload Reference ID':"",
                        'JE Date':"",
                        'Entity':"",
                        'Transaction Type':"",
                        'Account':"",
                        'Dr Amount':"",
                        'Cr Amount':"",
                        'Currency':"",
                        'Fx Rate':"",
                        'Description Notes':"",
                        'Investor No.':"",
                        'Vendor':"",
                        'Customer':"",
                        'Custodian A/C':"",
                        'Ticker Symbol':"",
                        'Department':"",
                        'Custom Field 1':"",
                        'Custom Field 2':"",
                        'Custom Field 3':""
                    }];
                    break;
                    case "reportapprovalperiodrule":
                    fields = [{
                        'Report Approval Date': "",
                        'Fund Name': "",
                        'Investor Capital Reporting Type': "",
                        'Decimal': "",
                        "DTD Frequency for Investor Statement": "",
                        'MTD Frequency for Investor Statement': "",
                        'QTD Frequency for Investor Statement': "",
                        "YTD Frequency for Investor Statement": "",
                        'ITD Frequency for Investor Statement': "",
                    }];
                    break;
            case "cashbalance":
                fields = [{

                    transactionCurrency: "",
                    localClosingBalance: "",
                    exchangeRate: "",
                    baseClosingBalance: "",
                    custodianBroker: "",
                    fundName: "",
                    date: "",
                    thirdPartySource: ""

                }];
                break;

            case "mgtandincentivefeeadj":
                fields = [{

                    investorNumber: "",
                    fundName: "",
                    date: "",
                    managementFeeAmount1: "",
                    managementFeeAmount2: "",
                    managementFeeAmount3: "",
                    managementFeeAmount4: "",
                    managementFeeAmount5: "",
                    managementFeeAmount6: "",
                    managementFeeAmount7: "",
                    managementFeeAmount8: "",
                    performanceFeeAmount1: "",
                    performanceFeeAmount2: "",
                    performanceFeeAmount3: "",
                    notes: ""

                }];
                break;

            case "position":
                fields = [{
                    ticker: "",
                    pnlDate: "",
                    custodianBroker: "",
                    quantity: "",
                    localAmount: "",
                    baseAmount: "",
                    securityName: "",
                    fundName: "",
                    marketPrice: "",
                    marketValueTotalLocal: "",
                    currency: "",
                    totalCostBase: "",
                    totalCostLocal: "",
                    marketValueTotalBase: "",
                    positionDirection: "",
                    assetClassName: "",
                    thirdPartySource: "",

                }];
                break;

            case "privatefundlookthruinput":
                fields = [{
                    fundName: "",
                    effectiveDateFrom: "",
                    effectiveDateTo: "",
                    ticker: "",
                    sector: "",
                    sectorPercentage: "",
                    assetClass: "",
                    assetClassPercentage: ""
                }];
                break;
            case "specilallocation":
                fields = [{
                    fundName: "",
                    investoNumber: "",
                    nameOfGLAccount: "",
                    startDate: "",
                    endDate: "",
                    allocationRatio: ""
                }];
                break;

            case "tradeuploadhv":
                fields = [{
                    tradeDateTime: "",
                    ticker: "",
                    securityName: "",
                    tradeType: "",
                    sides: "",
                    quantity: "",
                    currencyCode: "",
                    exchangeRateTrade: "",
                    tradePrice: "",
                    asstClass: "",
                    custodianBroker: "",
                    taxLotDate: "",
                    commission: "",
                    commissionCurrencyCode: "",
                    description: "",
                }];
                break;


            case "topbfee":
                fields = [{
                    'Fund': "",
                    'Date': "",
                    'Investor': "",
                    'Cumulative Performance Fees Until Prior Day': "",
                    'Adjustment In Carry Forward P&L': "",
                    'Frequency': "",
                }];
                break;
            case "mgtandincentivefeeadj":
                fields = [{
                    'Fund': "",
                    'Investor': "",
                    'Date': "",
                    'Performance Fee Amount 1': "",
                    'Performance Fee Amount 2': "",
                    'Performance Fee Amount 3': "",
                    'Management Fee Amount 1': "",
                    'Management Fee Amount 2': "",
                    'Management Fee Amount 3': "",
                    'Management Fee Amount 4': "",
                    'Management Fee Amount 5': "",
                    'Management Fee Amount 6': "",
                    'Management Fee Amount 7': "",
                    'Management Fee Amount 8': "",
                    'Notes': ""
                }];
                break;

            case "capsuledata":
                fields = [{
                    numberofLosingAccountsThatHaveOpenedandClosed: "",
                    nameOfCTA: "",
                    nameOfTradingProgram: "",
                    rangeofReturnExperiencedbyProfitableAccounts: "",
                    rangeOfReturnExperiencedByUnprofitableAccounts: "",
                    effectiveDateFrom: "",
                    effectiveDateTo: "",
                    vAMI: "",
                    netROR: "",
                    eNAV: "",
                    netPerformance: "",
                    incentiveFees: "",
                    managementFees: "",
                    interest: "",
                    grossRealizedGainAndLoss: "",
                    netRealizedGainAndLoss: "",
                    commission: "",
                    withdrawals: "",
                    additions: "",
                    bNAV: "",
                    numberOfProfitableAccountsThatHaveOpenedandClosed: "",
                    worstPeakToValleyDrawDown: "",
                    largestMonthlyDrawDown: "",
                    totalNominalAssetTradedPursuantToTheProgram: "",
                    totalNominalAssetUnderManagement: "",
                    accountsCurrentlyTradedPursuantToTheProgram: ""

                }];
                break;

            case "targetsector":
                fields = [{
                    fundName: "",
                    targetSector: "",
                    targetSubSector: "",
                    targetAllocationPercentage: ""

                }];
                break;

            case "dailymargin":
                fields = [{
                    date: "",
                    brokerAccountNo: "",
                    initialMargin: "",
                    maintenanceMargin: "",
                    volatilityMargin: "",
                    convertedBrokerEquity: "",
                    convertedBrokerEndingBalance: "",
                    convertedBrokerOTE: "",
                    marginExcessDeficit: "",
                    tradingLevel: ""
                }];
                break;




            case "feesharing":
                fields = [{
                    'Name of Fee Share': "",
                    'Agent / Sub-Agent': "",
                    'Rate Type': "",
                    'Share in Asset Based Fees': "",
                    'Share in Performance Based Fees': "",
                    'Amount of Flat Fee': "",
                    'Frequency of Flat Fee': "",
                    'Effective Date Start': "",
                    'Effective Date End': "",
                    'Attach Agreement': ""
                }];
                break;

            case "investor":
                fields = [{
                    'Investor Number': "",
                    'Name': "",
                    'FundName': "",
                    'usPersonStatus': "",
                    'FundCountry': "",
                    'InvestorGroup': "",
                    'PhoneNumber': "",
                    'Email': "",
                    'Website': "",
                    'EntryDate': "",
                    'PrimaryContact': "",
                    'Zip code': "",
                    'Address': "",
                    'City': "",
                    'State': "",
                    'Other': "",
                    'Country': "",
                    'Country of Citizenship': "",
                    'Country of Birth': "",
                    'ERISA Status': "",
                    'ERISA Ratio': "",
                    'swiftCode': "",
                    'Password Preference': "",
                    'Type of Investor': "",
                    'Writing Intruction': "",
                    'New Issue Participation': "",
                    'Form PF Classification': "",
                    'Communication Preference': "",
                    'Statement Delivery': "",
                    'Tax Schedules Delivery': "",
                    'Taxability': "",
                    'Related Party': "",
                    'Comments': "",
                    'Tax Entity': "",
                    'Disregarded': "",
                    'Alternate Contact': "",
                    'Alternative Zip code': "",
                    'Alternate Address': "",
                    'Alternate City': "",
                    'Alternate State': "",
                    'Alternate Country': "",
                    'Alternate Wiring Instruction': "",
                    'Account Number': "",
                    'ABA Number': "",
                    'Intermediary Bank': "",
                    'Intermediary Swift code': "",
                    'Intermediary ABA': "",
                    'Intermediary Account Number': "",
                    'SEC CIK NUMBER': "",
                    'SEC 801': "",
                    'SEC Large Trader ID': "",
                    'SEC Private Fund ID': "",
                    'NFA ID': "",
                    'SEC LEI': "",
                    'SEC 13F File No.': "",
                    'Feeder Fund': "",
                    'Master Funds Name': "",
                    'GIIN': "",
                    "Beneficial Owner's Name": "",
                    'Beneficial Street Address': "",
                    'Beneficial Tax ID': "",
                    'Beneficial Tax Entity': "",
                    'Beneficial City': "",
                    'Beneficial State': "",
                    'Beneficial Country': "",
                    'Beneficial ZIP Code': "",
                }];
                break;

            case "investoraddress":
                fields = [{
                    'addressID': "",
                    'typeOfAddress': "",
                    'address': "",
                    'city': "",
                    'state': "",
                    'zip': "",
                    'country': "",
                    'investor': ""

                }];
                break;

            case "chartofaccounts":
                fields = [{
                    'Code': "",
                    'Name of GL Account': "",
                    'Short/Reporting Name of GL A/c': "",
                    'Primary/Sub?': "",
                    'Name of Primary A/c': "",
                    'Type of GL Account': "",
                    'Balance Sheet Group': "",
                    'Income Statement Group': "",
                    'Cash Flow Statement Category': "",
                    'Custom GL Group': "",
                    'accountGroupForPerformanceTable': "",
                    'customField1': "",
                    'customField2': "",
                    'customField4': "",
                    'NFA Table Group': "",
                    'Reporting Group': "",
                    'IRR Classification': "",
                    'Connected Payable Account': "",
                    'Connected Book-To-Tax': "",
                    'FIHI/SOO Report Category': "",
                    'Reporting Group for Investor Statement': "",
                    'COA Ranking': "",
                    // 'Seamless Tax Code': "",
                    'Book to tax': "",
                }];
                break;

            case "predefinedjournalentry":
                fields = [{
                    'Activity Type': "",
                    'Asset Class': "",
                    'Transaction Type': "",
                    'Transaction': "",
                    'Account 1 (Dr.)': "",
                    'Account 1 (Cr.)': "",
                    'Account 2 (Dr.)': "",
                    'Account 2 (Cr.)': "",
                    'Position / Direction': "",
                    'Direction of Close Lot': ""
                }];
                break;

            case "incomedistributionbase":
                fields = [{
                    'Name of Distribution Base': "",
                    'Fund': "",
                    'Add Back Undue Perf. Fee': "",
                    'Effective Date Start': "",
                    'Effective Date End': "",
                    'Reset Frequency': ""
                }];
                break;

            case "itdsubscriptionandredemption":
                fields = [{
                    "Date": "",
                    "Fund Name": "",
                    "Investor Number": "",
                    "Transaction Type": "",
                    "Amount": ""
                }]
                break;

            case "recurringtransaction":
                fields = [{
                    'Recurring Transaction': "",
                    'Frequency': "",
                    'Dr. Account': "",
                    'Dr. Amount': "",
                    'Cr. Account': "",
                    'Cr. Amount': "",
                    'Fund Name': "",
                    'Start Date': "",
                    'End Date': "",
                    'Currency': "",
                    'investorNumberDr': "",
                    'investorNumberCr': "",
                    'custodianNumberDr': "",
                    'custodianNumberCr': "",
                    'vendorNumberDr': "",
                    'vendorNumberCr': ""
                }];
                break;
            case "rapidRecurringTransaction":
                fields = [{
                    'Recurring Transaction': "",
                    'Frequency': "",
                    'Dr. Account': "",
                    'Dr. Amount': "",
                    'Cr. Account': "",
                    'Cr. Amount': "",
                    'Fund Name': "",
                    'Start Date': "",
                    'End Date': "",
                    'Currency': "",
                    'investorNumberDr': "",
                    'investorNumberCr': "",
                    'custodianNumberDr': "",
                    'custodianNumberCr': "",
                    'vendorNumberDr': "",
                    'vendorNumberCr': ""
                }];
                break;
            case "chartofaccounttaxupdate":
                fields = [{
                    'Id': "",
                    'Name of GL Account': "",
                 //   'Seamless Tax Code': "",
                    'Connected Book-To-Tax': "",
                    'Book to tax': ""
                }];
                break;
            case "RapidJournalentry":
                fields = [{
                    'Date': "",
                    'Fund': "",
                    'Transaction Type': "",
                    'Dr. Account 1': "",
                    'Dr. Amount 1': "",
                    'Currency Dr. Amount 1': "",
                    'FX Rate Dr. 1': "",
                    'Investor No. (Dr1)': "",
                    'Custodian No. (Dr1)': "",
                    'Vendor (Dr1)': "",
                    'Ticker (Dr1)': "",
                    'Cr. Account 1': "",
                    'Cr. Amount 1': "",
                    'Currency Cr. Amount 1': "",
                    'FX Rate Cr. 1': "",
                    'Investor No. (Cr1)': "",
                    'Custodian No. (Cr1)': "",
                    'Vendor (Cr1)': "",
                    'Ticker (Cr1)': "",
                    'Description / Notes': ""
                }];
                break;

            case "journalentry":
                fields = [{
                    'Date': "",
                    'Fund': "",
                    'Transaction Type': "",
                    'Dr. Account 1': "",
                    'Dr. Amount 1': "",
                    'Currency Dr. Amount 1': "",
                    'FX Rate Dr. 1': "",
                    'Investor No. (Dr1)': "",
                    'Custodian No. (Dr1)': "",
                    'Vendor (Dr1)': "",
                    'Ticker (Dr1)': "",
                    'Cr. Account 1': "",
                    'Cr. Amount 1': "",
                    'Currency Cr. Amount 1': "",
                    'FX Rate Cr. 1': "",
                    'Investor No. (Cr1)': "",
                    'Custodian No. (Cr1)': "",
                    'Vendor (Cr1)': "",
                    'Ticker (Cr1)': "",
                    'Description / Notes': ""
                }];
                break;

            case "caspinoff":
                fields = [{
                    'Entry Date': "",
                    'Effective Date': "",
                    'Source Symbol': "",
                    'Target Symbol 1': "",
                    'Target Symbol 2': "",
                    'Target Symbol 3': "",
                    'Split to Symbol 1': "",
                    'Split to Symbol 2': "",
                    'Split to Symbol 3': "",
                    'Carry Original Trade Date': ""
                }];
                break;

            case "camerger":
                fields = [{
                    'Entry Date': "",
                    'Effective Date': "",
                    'Source Symbol 1': "",
                    'Source Symbol 2': "",
                    'Source Symbol 3': "",
                    'Qty Symbol 1': "",
                    'Qty Symbol 2': "",
                    'Qty Symbol 3': "",
                    'Target Symbol': "",
                    'Qty Target': ""
                }];
                break;

            case "caspecialdividend":
                fields = [{
                    'Entry Date': "",
                    'Symbol': "",
                    'Ex Date': "",
                    'Pay Date': "",
                    'Dividend Rate': "",
                    'Reduce Cost Basis': "",
                    'Tax WithHolding': ""

                }];
                break;

            case "casplit":
                fields = [{
                    'Entry Date': "",
                    'Effective Date': "",
                    'Source Symbol': "",
                    'Pre Split Qty': "",
                    'Post Split Qty': "",
                    'Target Symbol 3': "",
                    'Split to Symbol 1': "",
                    'Split to Symbol 2': "",
                    'Split to Symbol 3': "",
                    'Carry Original Trade Date': "",
                    'Close Original Quantity Cost': ""

                }];
                break;

            case "capitalactivity":
                fields = [{

                    'Entity': "",
                    'Transaction Type': "",
                    'Timing': "",
                    'Effective Date': "",
                    'Investor Number': "",
                    'Amount': "",
                    'Share Units For Investor': "",
                    'Funding Method For Investor': "",
                    'Currency': "",
                    'Date of Entry / Notice': "",
                    'Class For Investor': "",
                    'Series For Investor': "",
                    'Asset Based Fee For Investor': "",
                    'Performance Fee For Investor': "",
                    '% Value of Account For Investor': "",
                    'Redemption Fee For Investor': "",
                    'Reduction in LCF For Investor': "",
                    'Either OR Method For Investor': "",
                    'Transferor / Assignor Number': "",
                    'Share Units For Transferor / Assignor': "",
                    '% Value of Account For Transferor / Assignor': "",
                    'Transfer HWM For Transferor / Assignor': "",
                    'Class For Transferor / Assignor': "",
                    'Series For Transferor / Assignor': "",
                    'Transferee / Assignee Number': "",
                    '% Value of Account For Transferee / Assignee': "",
                    'Transfer HWM For Transferee / Assignee': "",
                    'Class For Transferee / Assignee': "",
                    'Series For Transferee / Assignee': "",
                    'Funding Factor': "",
                    'descriptionNotes': "",
                    'custodianAccNumber': "",
                    'Funded Commitment Applicable': "",
                    'Disable Auto Update Via Allocation':""
                }];
                break;

            case "disableautoupdateviaallocationupdate":
                fields = [{
                    'Unique Id': "",
                    'Fund': "",
                    'Transaction Type': "",
                    'Timing': "",
                    'Effective Date': "",
                    'Investor': "",
                    'Investor Number': "",
                    'Disable Auto Update Via Allocation': ""
                }];
                break;

            case "redemptionactivityupdate":
                fields = [{
                    'Unique Id': "",
                    'Fund': "",
                    'Transaction Type': "",
                    'Timing': "",
                    'Effective Date': "",
                    'Investor': "",
                    'Investor Number': "",
                    'Amount': "",
                    'Shares': "",
                    'Share Class': "",
                    'Series': "",
                    '% Value of Account': "",
                    'Reduction In LCF': "",
                    'Funding Factor': "",
                    'Description Notes': ""
                }];
                break;

            case "subscriptionactivityupdate":
                fields = [{
                    'Unique Id': "",
                    'Fund': "",
                    'Transaction Type': "",
                    'Timing': "",
                    'Effective Date': "",
                    'Investor': "",
                    'Investor Number': "",
                    'Amount': "",
                    'Shares': "",
                    'Share Class': "",
                    'Series': "",
                    'Funding Method': "",
                    'Asset Based Fee': "",
                    'Performance Fee': "",
                    'Funding Factor': "",
                    'Description Notes': ""
                }];
                break;
            case "dividend":
                fields = [{
                    'Entry Date': "",
                    'Symbol': "",
                    'Name': "",
                    'Ex Date': "",
                    'Pay Date': "",
                    'Dividend Per Share': "",
                    'Tax Withholding': "",
                    'Payment in Kind': "",
                    'Option Of Cash Pay': "",
                    'Currency': "",
                    'Country': ""
                }];
                break;

            case "exchangerate":
                fields = [{
                    'Exchange Date': "",
                    'Exchange Rate': "",
                    'Fund': "",
                    'Exchange Time': "",
                    'Quotation': "",
                    'Source Currency': "",
                    'Destination Currency': ""

                }];
                break;
            case "rapidExchangerate":
                fields = [{
                    'Exchange Date': "",
                    'Exchange Rate': "",
                    'Fund': "",
                    'Exchange Time': "",
                    'Quotation': "",
                    'Source Currency': "",
                    'Destination Currency': ""

                }];
                break;

            case "bondinterestaccrual":
                fields = [{
                    'Ticker': "",
                    'Security Name': "",
                    'Coupon Rate %': "",
                    'Tax Withholding %': "",
                    'Currency': "",
                    'Accrual Start Date': "",
                    'Accrual End Date': "",
                    'Day Count Method': "",
                    'Interest Payment Frequency': "",
                    'Maturity Date': "",
                    'Lag Days': "",
                    'Asset Class Name': "",


                }];
                break;

            /*case "nontradetransactions":
             fields = [{
             'Date': "",
             'Amount': "",
             'Exchange Rate': "",
             'Ticker / Symbol': "",
             'Fund': "",
             'Transaction Type': "",
             'Currency': "",
             'Custodian Number': "",
             'Investor Number': "",
             'Vendor': ""
             
             }];
             break;*/

            case "restrictedstock":
                fields = [{
                    'Symbol': "",
                    'Start Date': "",
                    'End Date': "",
                    'Liquidity Discount': "",
                    'Amortize Liquidity Discount': ""

                }];
                break;


            case "nontradetransactions":
                fields = [{
                    'amount': "",
                    'custodianAccNo': "",
                    'date': "",
                    'entityName': "",
                    'transactionTypeName': "",
                    'currencyName': "",
                    'exchangeRate': "",
                    'description': "",
                    'investorNo': "",
                    'thirdPartySource': "",
                    'tickerSymbol': "",
                    'vendorName': "",

                }];
                break;

            case "cryptomarketdata":
                fields = [{
                    "Source": "",
                    "Slug": "",
                    "Coin_id": "",
                    "Symbol": "",
                    "Currency": ""
                }];
                break;

            case "transfertradetaxlot":
                fields = [{
                    'transferDate': "",
                    'sourceFundName': "",
                    'sourceCustodianBroker': "",
                    'sourceTicker': "",
                    'destFundName': "",
                    'destCustodianBroker': "",
                    'transferBalance': "",
                    'transferPositions': ""
                }];
                break;



            case "smequities":
                fields = [{
                    'Ticker': "",
                    'Name of Security': "",
                    'Effective Date From': "",
                    'Effective Date To': "",
                    'Date of Record Creation': "",
                    'ISIN': "",
                    'CUSIP': "",
                    'SEDOL': "",
                    // 'Bloomberg ID': "",
                     'RIC Code': "",
                     'IDC ID': "",
                    'Name of Issuer': "",
                    'Exchange': "",
                    'Primary Exchange': "",
                    'Currency': "",
                    'Asset Class': "",
                    'US Tax Exempt (Y/N)': "",
                    'Regulator': "",
                    'Sector': "",
                    'Custom Sector': "",
                    'Sub Sector': "",
                    'Custom Sub Sector': "",
                    'Country': "",
                    'Voting Rights': "",
                    'Market Cap': "",
                    'Multiplier': "",
                    'Quandl Ticker': "",
                    'Custom Field 1': "",
                    'Market': "",
                    'Custom Field 3': "",
                    'Custom Field 4': "",
                    'Custom Field 5': "",
                    'Bar Chart Ticker': "",
                    'Bloomberg Security Short Name': "",
                    'Custom Asset Class': "",
                    'Custom Maturity': "",
                    'Custom Sub Market': "",
                    'Pricing Source Priority': "",
                    'Data Source Priority': "",
                    'TimeStamp': "",
                    // 'Reuters Id': "",
                    // 'nyse Id': "",
                    // 'Kraken Id': "",
                    'Market Price Multiplier ': "",
                    // 'Coin Market Cap Id': "",
                    // 'Coingecko Id': "",
                    // 'Coinbase Id': "",
                    // 'MEXC Id': "",
                    // 'Bitfinex Id': "",
                    // 'DYDX Id': "",
                    // 'Gate IO Id': "",
                    // 'Bitmex Id': "",
                    // 'KuCoin Id': "",
                    // 'Bitget Id': "",
                    // 'Crypto.com Id': "",
                    // 'Vinter Id': "",
                    // 'Okex Futures Id': "",
                    // 'Binance Futures Id': "",
                    // 'quodd Id': "",
                    // 'Huobi Id': "",
                    // 'Bybit Id': "",
                    // 'Deribit Id': "",
                    'FS ProductTypes': "",
                    'FS Sector': "",
                    'FS Investment Type for SOI & SOFC': "",
                    'FS Investment Type for Cash Flow': "",
                    'FS Ranking for Cash Flow': "",
                    'FS Country': "",
                    'FS Level': "",
                    'Primary Underlying Risk': "",
                    'Valuation Techniques': "",
                    'Unobservable inputs': "",
                    'Range of inputs': "",
                    // 'Ftx Id': "",
                    // 'Copper Id': "",
                    'PricingSource Id': ""
                }];
                break;
            case "rapidcrypto":
                fields = [{
                    'Ticker': "",
                    'Effective Date From': "",
                    'Effective Date To': "",
                    'Date of Record Creation': "",
                    'ISIN': "",
                    'CUSIP': "",
                    'SEDOL': "",
                    'Bloomberg ID': "",
                    'RIC Code': "",
                    'IDC ID': "",
                    'Name of Issuer': "",
                    'Name of Security': "",
                    'Exchange': "",
                    'Primary Exchange': "",
                    'Currency': "",
                    'Asset Class': "",
                    'US Tax Exempt (Y/N)': "",
                    'Regulator': "",
                    'Sector': "",
                    'Custom Sector': "",
                    'Sub Sector': "",
                    'Custom Sub Sector': "",
                    'Country': "",
                    'Voting Rights': "",
                    'Market Cap': "",
                    'Multiplier': "",
                    'Quandl Ticker': "",
                    'Custom Field 1': "",
                    'Market': "",
                    'Custom Field 3': "",
                    'Custom Field 4': "",
                    'Custom Field 5': "",
                    'Bar Chart Ticker': "",
                    'Bloomberg Security Short Name': "",
                    'Custom Asset Class': "",
                    'Custom Maturity': "",
                    'Custom Sub Market': "",
                    'Pricing Source Priority': "",
                    'Data Source Priority': "",
                    'TimeStamp': "",
                    'Reuters Id': "",
                    'nyse Id': "",
                    'Kraken Id': "",
                    'Market Price Multiplier ': "",
                    'Coin Market Cap Id': "",
                    'Coingecko Id': "",
                    'Coinbase Id': "",
                    'Bitmex Id': "",
                    'KuCoin Id': "",
                    'DYDX Id': "",
                    'Gate IO Id': "",
                    'Bitget Id': "",
                    'Crypto.com Id': "",
                    'Vinter Id': "",
                    'Okex Futures Id': "",
                    'Binance Futures Id': "",
                    'quodd Id': "",
                    'Huobi Id': "",
                    'Bybit Id': "",
                    'Deribit Id': "",
                    'FS ProductTypes': "",
                    'FS Sector': "",
                    'FS Investment Type for SOI & SOFC': "",
                    'FS Investment Type for Cash Flow': "",
                    'FS Ranking for Cash Flow': "",
                    'FS Country': "",
                    'FS Level': "",
                    'Primary Underlying Risk': "",
                    'Valuation Techniques': "",
                    'Unobservable inputs': "",
                    'Range of inputs': "",
                    'Ftx Id': "",
                    'Copper Id': ""
                }];
                break;

            case "smequitiesoptions":
                fields = [{
                    'Ticker': "",
                    'Name of Security': "",
                    'Multiplier': "",
                    'Effective Date From': "",
                    'Effective Date To': "",
                    'Date of Record Creation': "",
                    'Option Style': "",
                    'Call/Put': "",
                    'Contract Size': "",
                    'Period of Option': "",
                    'Option Expiry': "",
                    'Sector': "",
                    'ISIN': "",
                    'CUSIP': "",
                    'SEDOL': "",
                    // 'Bloomberg ID': "",
                    'RIC Code': "",
                    'IDC ID': "",
                    'OSI Code': "",
                    'Product Master ID of Underlying': "",
                    'Underlying': "",
                    'Primary Exchange': "",
                    'Currency': "",
                    'Asset Class': "",
                    'Sub Sector': "",
                    'US Tax Exempt (Y/N)': "",
                    'Regulator': "",
                    'Country': "",
                    'Last Trading Day': "",
                    'Bar Chart Ticker': "",
                    'Custom Field 1': "",
                    'Market': "",
                    'Custom Field 3': "",
                    'Custom Field 4': "",
                    'Custom Field 5': "",
                    'Quandl Ticker': "",
                    'Bloomberg Security Short Name': "",
                    'Custom Asset Class': "",
                    'Custom Maturity': "",
                    'Custom Sub Market': "",
                    'Month Year': "",
                    'Expiry Date': "",
                    // 'CME ID': "",
                    // 'LME ID': "",
                    'Pricing Source Priority': "",
                    'Data Source Priority': "",
                    'TimeStamp': "",
                    // 'Reuters Id': "",
                    // 'nyse Id': "",
                    // 'Kraken Id': "",
                    'Market Price Multiplier ': "",
                    // 'Coin Market Cap Id': "",
                    // 'Coingecko Id': "",
                    // 'Coinbase Id': "",
                    // 'MEXC Id': "",
                    // 'Bitfinex Id': "",
                    // 'DYDX Id': "",
                    // 'Gate IO Id': "",
                    // 'Bitget Id': "",
                    // 'Crypto.com Id': "",
                    // 'Vinter Id': "",
                    // 'Bitmex Id': "",
                    // 'KuCoin Id': "",
                    // 'Okex Futures Id': "",
                    // 'Binance Futures Id': "",
                    // 'quodd Id': "",
                    // 'Huobi Id': "",
                    // 'Bybit Id': "",
                    // 'Deribit Id': "",
                    'FS ProductTypes': "",
                    'FS Sector': "",
                    'FS Investment Type for SOI & SOFC': "",
                    'FS Investment Type for Cash Flow': "",
                    'FS Ranking for Cash Flow': "",
                    'FS Country': "",
                    'FS Level': "",
                    'Primary Underlying Risk': "",
                    'Valuation Techniques': "",
                    'Unobservable inputs': "",
                    'Range of inputs': "",
                    // 'Ftx Id': "",
                    // 'Copper Id': "",
                    'PricingSource Id': ""
                }];
                break;
            case "vendorsupload":
                fields = [{
                    'vendorId': "",
                    'vendorName': "",
                    'jeId': "",
                }];
                break;
            case "smfutures":
                fields = [{
                    'Ticker': "",
                    'Name of Security': "",
                    'Multiplier': "",
                    'Effective Date From': "",
                    'Effective Date To': "",
                    'Date of Record Creation': "",
                    'Exchange': "",
                    '1256 Contract': "",
                    // 'Bloomberg ID': "",
                    'RIC Code': "",
                    'IDC ID': "",
                    'Contract Size': "",
                    'Type of Future': "",
                    'Underlying': "",
                    'Product Master ID of Underlying': "",
                    'Last Trading Day': "",
                    'Country': "",
                    'Currency': "",
                    'Tick Size': "",
                    'Asset Class': "",
                    'Regulator': "",
                    'Sector': "",
                    'Sub Sector': "",
                    'Quandl Ticker': "",
                    'Bar Chart Ticker': "",
                    'ISIN': "",
                    'CUSIP': "",
                    'SEDOL': "",
                    'Custom Field 1': "",
                    'Market': "",
                    'Custom Field 3': "",
                    'Custom Field 4': "",
                    'Custom Field 5': "",
                    'Custom Sub Market': "",
                    'Custom Asset Class': "",
                    'Custom Maturity': "",
                    'Bloomberg Security ShortName': "",
                    'Custom Exposure Attribute': "",
                    'Month Year': "",
                    // 'CME ID': "",
                    // 'LME ID': "",
                    'Pricing Source Priority': "",
                    'Data Source Priority': "",
                    'TimeStamp': "",
                    // 'Reuters Id': "",
                    // 'nyse Id': "",
                    // 'Kraken Id': "",
                    'Market Price Multiplier ': "",
                    // 'Coin Market Cap Id': "",
                    // 'Coingecko Id': "",
                    // 'Coinbase Id': "",
                    // 'MEXC Id': "",
                    // 'Bitfinex Id': "",
                    // 'DYDX Id': "",
                    // 'Gate IO Id': "",
                    // 'Bitget Id': "",
                    // 'Crypto.com Id': "",
                    // 'Vinter Id': "",
                    // 'Bitmex Id': "",
                    // 'KuCoin Id': "",
                    // 'Okex Futures Id': "",
                    // 'Binance Futures Id': "",
                    // 'quodd Id': "",
                    // 'Huobi Id': "",
                    // 'Bybit Id': "",
                    // 'Deribit Id': "",
                    'FS ProductTypes': "",
                    'FS Sector': "",
                    'FS Investment Type for SOI & SOFC': "",
                    'FS Investment Type for Cash Flow': "",
                    'FS Ranking for Cash Flow': "",
                    'FS Country': "",
                    'FS Level': "",
                    'Primary Underlying Risk': "",
                    'Valuation Techniques': "",
                    'Unobservable inputs': "",
                    'Range of inputs': "",
                    // 'Ftx Id': "",
                    // 'Copper Id': "",
                    'PricingSource Id': ""
                }];
                break;

            case "smfixedincome":
                fields = [{
                    'Ticker': "",
                    'Name of Security': "",
                    'Multiplier': "",
                    'Effective Date From': "",
                    'Effective Date To': "",
                    'Date of Record Creation': "",
                    'ISIN': "",
                    'CUSIP': "",
                    'SEDOL': "",
                    // 'Bloomberg ID': "",
                     'RIC Code': "",
                     'IDC ID': "",
                    'Name of Issuer': "",
                    'Coupon': "",
                    'Day Count Method': "",
                    'Compounding Frequency': "",
                    'Interest Payment Frequency': "",
                    "Ref Rate":"",
                    "Spread":"",
                    'Issue Date': "",
                    'Maturity Date': "",
                    'Exchange': "",
                    'Currency': "",
                    'Asset Class': "",
                    'Regulator': "",
                    'Bond Rating': "",
                    'Inv. Grade (Y/N)': "",
                    'Bond Default Date': "",
                    'Zero Coupon Bond (Y/N)': "",
                    'Sector': "",
                    'Sub Sector': "",
                    'Country': "",
                    'US Tax Exempt (Y/N)': "",
                    'Voting Rights': "",
                    'Cumulative': "",
                    'Convertible': "",
                    'Market Cap': "",
                    'Bond Type': "",
                    'customField1': "",
                    'Market': "",
                    'customField3': "",
                    'customField4': "",
                    'customField5': "",
                    'customField6': "",
                    'customField7': "",
                    'customField8': "",
                    'customField9': "",
                    'customField10': "",
                    'Coupon Type': "",
                    'Factor': "",
                    'Interest Deferrment Period': "",
                    'Lookup Days': "",
                    'Holiday Calendar': "",
                    "Custom Sub Market": "",
                    "Custom Asset Class": "",
                    "Custom Maturity": "",
                    "Bloomberg Security Short Name": "",
                    'Pricing Source Priority': "",
                    'Data Source Priority': "",
                    'TimeStamp': "",
                    // 'Reuters Id': "",
                    // 'nyse Id': "",
                    // 'Kraken Id': "",
                    'Market Price Multiplier ': "",
                    // 'Coin Market Cap Id': "",
                    // 'Coingecko Id': "",
                    // 'Coinbase Id': "",
                    // 'Bitmex Id': "",
                    // 'KuCoin Id': "",
                    // 'Okex Futures Id': "",
                    // 'Binance Futures Id': "",
                    // 'quodd Id': "",
                    // 'Huobi Id': "",
                    // 'Bybit Id': "",
                    // 'Deribit Id': "",
                    'FS ProductTypes': "",
                    'FS Sector': "",
                    'FS Investment Type for SOI & SOFC': "",
                    'FS Investment Type for Cash Flow': "",
                    'FS Ranking for Cash Flow': "",
                    'FS Country': "",
                    'FS Level': "",
                    'Primary Underlying Risk': "",
                    'Valuation Techniques': "",
                    'Unobservable inputs': "",
                    'Range of inputs': "",
                    // 'Ftx Id': "",
                    // 'Copper Id': "",
                    'PricingSource Id': ""
                }];
                break;

            case "smpools":
                fields = [{
                    'ticker': "",
                    'effectiveDateFrom': "",
                    'effectiveDateTo': "",
                    'dateOfRecordCreation': "",
                    'securityName': "",
                    'country': "",
                    'tradingStrategy': "",
                    'regulator': "",
                    'accountingMethod': "",
                    'currency': "",
                    'sector': "",
                    'subSector': "",
                    'redemptionTime': "",
                    'noticePeriodDays': "",
                    'lockUpPeriod': "",
                    'earlyRedemptionFee': "",
                    'multiplier': "",
                    'fundName': "",
                    'holdingName': "",
                    'portfolioCompanyName': "",
                    'strategy2': "",
                    'subStrategy': "",
                    'sector1': "",
                    'subSector1': "",
                    'sector2': "",
                    'subSector2': "",
                    'subStrategy2': "",
                    'customSector': "",
                    'customSubSector': "",
                    'region': "",
                    'investmentType': "",
                    'assetClass': "",
                    'assetStructure': "",
                    'assetType': "",
                    'distributionFrequency': "",
                    'targetReturn': "",
                    'moic': "",
                    'irr': "",
                    'vintageDate': "",
                    'purchaseDate': "",
                    'tenureEndDate': "",
                    'customDate1': "",
                    'customDate2': "",
                    'publiclyTraded': "",
                    'commitmentAmount': "",
                    'customField1': "",
                    'Market': "",
                    'customField3': "",
                    'customField4': "",
                    'customField5': "",
                    'Bloomberg Id': "",
                    'Bloomberg Security Short Name': "",
                    'Custom Asset Class': "",
                    'Custom Maturity': "",
                    'Custom Sub Market': "",
                    'peFundId': "",
                    'peHoldingId': "",
                    'pePortCoId': "",
                    'fundRegion': "",
                    'project': "",
                    'Pricing Source Priority': "",
                    'Data Source Priority': "",
                    'TimeStamp': "",
                    'Reuters Id': "",
                    'nyse Id': "",
                    'Kraken Id': "",
                    'Market Price Multiplier ': "",
                    'Coin Market Cap Id': "",
                    'Coingecko Id': "",
                    'Coinbase Id': "",
                    'Bitmex Id': "",
                    'KuCoin Id': "",
                    'Okex Futures Id': "",
                    'Binance Futures Id': "",
                    'quodd Id': "",
                    'Huobi Id': "",
                    'Bybit Id': "",
                    'Deribit Id': "",
                    'ISIN': "",
                    'CUSIP': "",
                    'SEDOL': "",
                    'FS ProductTypes': "",
                    'FS Sector': "",
                    'FS Investment Type for SOI & SOFC': "",
                    'FS Investment Type for Cash Flow': "",
                    'FS Ranking for Cash Flow': "",
                    'FS Country': "",
                    'FS Level': "",
                    'Primary Underlying Risk': "",
                    'Valuation Techniques': "",
                    'Unobservable inputs': "",
                    'Range of inputs': "",
                    'Ftx Id': "",
                    'Copper Id': ""
                }];
                break;

            case "smprivatefund":
                fields = [{
                    'ticker': "",
                    'securityName': "",
                    'effectiveDateFrom': "",
                    'effectiveDateTo': "",
                    'dateOfRecordCreation': "",
                    'country': "",
                    'tradingStrategy': "",
                    'regulator': "",
                    'accountingMethod': "",
                    'currency': "",
                    'sector': "",
                    'subSector': "",
                    'redemptionTime': "",
                    'noticePeriodDays': "",
                    'lockUpPeriod': "",
                    'earlyRedemptionFee': "",
                    'multiplier': "",
                    'fundName': "",
                    'holdingName': "",
                    'portfolioCompanyName': "",
                    'strategy2': "",
                    'subStrategy': "",
                    'sector1': "",
                    'subSector1': "",
                    'sector2': "",
                    'subSector2': "",
                    'subStrategy2': "",
                    'customSector': "",
                    'customSubSector': "",
                    'region': "",
                    'investmentType': "",
                    'assetClass': "",
                    'assetStructure': "",
                    'assetType': "",
                    'distributionFrequency': "",
                    'targetReturn': "",
                    'moic': "",
                    'irr': "",
                    'vintageDate': "",
                    'purchaseDate': "",
                    'tenureEndDate': "",
                    'customDate1': "",
                    'customDate2': "",
                    'publiclyTraded': "",
                    'commitmentAmount': "",
                    'customField1': "",
                    'Market': "",
                    'customField3': "",
                    'customField4': "",
                    'customField5': "",
                    // 'Bloomberg Id': "",
                    'Bloomberg Security Short Name': "",
                    'Custom Asset Class': "",
                    'Custom Maturity': "",
                    'Custom Sub Market': "",
                    'peFundId': "",
                    'peHoldingId': "",
                    'pePortCoId': "",
                    'fundRegion': "",
                    'project': "",
                    'Pricing Source Priority': "",
                    'Data Source Priority': "",
                    'TimeStamp': "",
                    // 'Reuters Id': "",
                    // 'nyse Id': "",
                    // 'Kraken Id': "",
                    'Market Price Multiplier ': "",
                    // 'Coin Market Cap Id': "",
                    // 'Coingecko Id': "",
                    // 'Coinbase Id': "",
                    // 'MEXC Id': "",
                    // 'Bitfinex Id': "",
                    // 'DYDX Id': "",
                    // 'Gate IO Id': "",
                    // 'Bitget Id': "",
                    // 'Crypto.com Id': "",
                    // 'Vinter Id': "",
                    // 'Bitmex Id': "",
                    // 'KuCoin Id': "",
                    // 'Okex Futures Id': "",
                    // 'Binance Futures Id': "",
                    // 'quodd Id': "",
                    // 'Huobi Id': "",
                    // 'Bybit Id': "",
                    // 'Deribit Id': "",
                    'ISIN': "",
                    'CUSIP': "",
                    'SEDOL': "",
                    'FS ProductTypes': "",
                    'FS Sector': "",
                    'FS Investment Type for SOI & SOFC': "",
                    'FS Investment Type for Cash Flow': "",
                    'FS Ranking for Cash Flow': "",
                    'FS Country': "",
                    'FS Level': "",
                    'Primary Underlying Risk': "",
                    'Valuation Techniques': "",
                    'Unobservable inputs': "",
                    'Range of inputs': "",
                    // 'Ftx Id': "",
                    // 'Copper Id': "",
                    'PricingSource Id': ""
                }];
                break;

            case "smfxforward":
                fields = [{
                    'Ticker': "",
                    'Name of Security': "",
                    'Asset Class': "",
                    'Effective Date From': "",
                    'Effective Date To': "",
                    'Date of Record Creation': "",
                    // 'Bloomberg ID': "",
                    'RIC Code': "",
                    'IDC ID': "",
                    'FX Type': "",
                    'Currency 1': "",
                    'Currency 2': "",
                    'Expiry Date': "",
                    'Multiplier': "",
                    'Sector': "",
                    'Sub Sector': "",
                    'Price Inversion': "",
                    'Custom Market': "",
                    'Custom Sub Market': "",
                    'Custom Asset Class': "",
                    'Custom Maturity': "",
                    'Bloomberg Security Short Name': "",
                    'Currency Pair': "",
                    'Country': "",
                    'Pricing Source Priority': "",
                    'Data Source Priority': "",
                    'TimeStamp': "",
                    // 'Reuters Id': "",
                    // 'nyse Id': "",
                    // 'Kraken Id': "",
                    'Market Price Multiplier ': "",
                    // 'Coin Market Cap Id': "",
                    // 'Coingecko Id': "",
                    // 'Coinbase Id': "",
                    // 'Bitmex Id': "",
                    // 'KuCoin Id': "",
                    // 'Okex Futures Id': "",
                    // 'Binance Futures Id': "",
                    // 'quodd Id': "",
                    // 'Huobi Id': "",
                    // 'Bybit Id': "",
                    // 'Deribit Id': "",
                    'ISIN': "",
                    'CUSIP': "",
                    'SEDOL': "",
                    'FS ProductTypes': "",
                    'FS Sector': "",
                    'FS Investment Type for SOI & SOFC': "",
                    'FS Investment Type for Cash Flow': "",
                    'FS Ranking for Cash Flow': "",
                    'FS Country': "",
                    'FS Level': "",
                    'Primary Underlying Risk': "",
                    'Valuation Techniques': "",
                    'Unobservable inputs': "",
                    'Range of inputs': "",
                    // 'Ftx Id': "",
                    // 'Copper Id': "",
                    'PricingSource Id': ""

                }];
                break;
            case "smrealestate":
                fields = [{
                    'Entity #': "",
                    'Entity Name': "",
                    'Fund': "",
                    'Entity Type': "",
                    'Acquisition Date': "",
                    'Disposition Date': "",
                    'Joint Venture': "",
                    'Contract Share': "",
                    'Cash Flow Share': "",
                    'Effective Share': "",
                    'Legal Entity': "",
                    'Property Address': "",
                    'City': "",
                    'State': "",
                    'Country': "",
                    'Neighborhood (MSA)': "",
                    'Currency': "",
                    'Gross Square Feet': "",
                    'Net Rentable Area': "",
                    'Leased Square Footage': "",
                    'Number of Properties': "",
                    'Number Of Buildings': "",
                    'Number Of Units': "",
                    'Number Of Floors': "",
                    'Appraisal Type': "",
                    'Gross Price': "",
                    'Net Price': "",
                    'Acquisition Cost': "",
                    'Property Type': "",
                    'Property Sub-Type': "",
                    'Lifecycle': "",
                }];
                break;
            case "recurringtransactiondemo":
                fields = [{
                    'Recurring Transaction': "",
                    'Frequency': "",
                    'Dr. Account': "",
                    'Dr. Amount': "",
                    'Cr. Account': "",
                    'Cr. Amount': "",
                    'Start Date': "",
                    'End Date': "",
                    'Currency': "",
                    'custodianNumberDr': "",
                    'custodianNumberCr': "",
                    'Fund Name': "",
                    'investorNumberDr': "",
                    'investorNumberCr': "",
                    'vendorNumberDr': "",
                    'vendorNumberCr': "",
                    'Entity Name': "",
                    'tickerCr': "",
                    'tickerDr': "",
                    'description': "",
                }];
                break;




            case "chartofaccount":
                fields = [{
                    'code': "",
                    'nameOfGLAccount': "",
                    'primarySub': "",
                    'nameOfPrimaryAC': "",
                    'typeOfGLAccount': "",
                    'balanceSheetGroup': "",
                    'incomeStatementGroup': "",
                    'cashFlowStatementCategory': "",
                    'customGLGroup': "",
                    'accountGroupForPerformanceTable': "",
                    'customField1': "",
                    'customField2': "",
                    'customField3': "",
                    'customField4': "",
                    'customField5': "",
                    'effectiveReportingGroup': ""


                }];
                break;

            case "bondinterestaccsample":
                fields = [{
                    'Ticker': "",
                    'Accrual Start Date': "",
                    'Coupon Rate %': "",
                    'Day Count Method': "",
                    'Interest Payment Frequency': "",
                    'Maturity Date': "",
                    'Asset Class': "",
                    'Security Name': "",
                    'Currency': "",
                    'Accrual End Date': "",
                    'Tax Withholding %': "",
                    'Lag Days': "",
                }];
                break;
            case "associationofuserfunddemo":
                fields = [{
                    'userName': "",
                    'fundName': ""
                }];
                break;
            case "associationofuserfundinvestordemo":
                fields = [{
                    'userName': "",
                    'entityName': "",
                    'investorName': "",
                    "investorNumber": ""
                }];
                break;
            case "tradeallocationconfig":
                fields = [{
                    'Trade Allocation Config Name': "",
                    'AMC/CTA': "",
                    'percentage': "",
                    'amount': "",
                    'custodianAccountNo': "",
                    'Default(Yes/No)': "",
                }];
                break;
            case "predefinejournal":
                fields = [{
                    'activityType': "",
                    'transactionType': "",
                    'accountDr1': "",
                    'accountCr1': "",
                    'assetClass': "",
                    'transaction': "",
                    'accountCr2': "",
                    'accountDr2': "",
                    'positionDirection': "",
                    'directionOfCloseLot': "",
                }];
                break;



            case "exchangeratedemo":
                fields = [{
                    'Destination Currency': "",
                    'Exchange Date': "",
                    'Exchange Rate': "",
                    'Entity': "",
                    'Quotation': "",
                    'Source Currency': "",
                }];
                break;

            case "investordemo":
                fields = [{
                    'Investor Number': "",
                    'Investor Name': "",
                    'Prefix':"",
                    'Investor First Name':"",
                    'Investor Last Name':"",
                    'Entity': "",
                    'Investor Group': "",
                    'Phone Number': "",
                    'Email Address': "",
                    'Website': "",
                    'Primary Contact First Name': "",
                    'Primary Contact Last Name': "",
                    'ZIP Code': "",
                    'Address': "",
                    'City': "",
                    'State': "",
                    'Country': "",
                    'Country of Citizenship': "",
                    'Country of Birth': "",
                    'Swift Code': "",
                    'Type of Investor': "",
                    'Wiring Instruction': "",
                    'Tax Entity': "",
                    'Alternate Contact': "",
                    'Alternate ZIP Code': "",
                    'Alternate Address': "",
                    'Alternate City': "",
                    'Alternate State': "",
                    'Alternate Country': "",
                    'Name of Segment': "",
                    'ABA Number': "",
                    'Intermediary Bank': "",
                    'Intermediary SWIFT Code': "",
                    'Intermediary ABA': "",
                    'Intermediary Account Number': "",
                    'GIIN': "",
                    'Tax Id': "",
                    'Tax Country': "",
                    'Doc Type': "",
                    'Date of Document': "",
                    'Password Preference': "",
                    'State Id': "",
                    'Investor Short Name': "",
                    'Disable Allocation Ratio': ""
                }];
                break;


            case "capitalactivitydemo":
                fields = [{
                    'Fund': "",
                    'Transaction Type': "",
                    'Currency': "",
                    'Timing': "",
                    'Effective Date': "",
                    'Date of Entry / Notice': "",
                    'Investor Number': "",
                    'Amount': "",
                    'Share Units For Investor': "",
                    'Class For Investor': "",
                    'Series For Investor': "",
                    'Asset Based Fee For Investor': "",
                    'Performance Fee For Investor': "",
                    'Funding Method For Investor': "",
                    '% Value of Account For Investor': "",
                    'Redemption Fee For Investor': "",
                    'Reduction in LCF For Investor': "",
                    'Either OR Method For Investor': "",
                    'Transferor / Assignor Number': "",
                    'Share Units For Transferor / Assignor': "",
                    '% Value of Account For Transferor / Assignor': "",
                    'Transfer HWM For Transferor / Assignor': "",
                    'Class For Transferor / Assignor': "",
                    'Series For Transferor / Assignor': "",
                    'Transferee / Assignee Number': "",
                    '% Value of Account For Transferee / Assignee': "",
                    'Transfer HWM For Transferee / Assignee': "",
                    'Class For Transferee / Assignee': "",
                    'Series For Transferee / Assignee': "",
                    'Funding Factor': "",
                    'Effective Amount': "",
                    'Funded Commitment Applicable': ""
                }];
                break;

            case "journalentrydemo":
                fields = [{
                    'Cr. Account 1': "",
                    'Cr. Amount 1': "",
                    'Custodian No. (Cr1)': "",
                    'Custodian No. (Dr1)': "",
                    'Date': "",
                    'Entity': "",
                    'Transaction Type': "",
                    'Dr. Account 1': "",
                    'Dr. Amount 1': "",
                    'Fund': "",
                    'Currency Cr. Amount 1': "",
                    'Currency Dr. Amount 1': "",
                    'Description / Notes': "",
                    'FX Rate Cr. 1': "",
                    'FX Rate Dr. 1': "",
                    'Investor No. (Cr1)': "",
                    'Investor No. (Dr1)': "",
                    'Ticker (Cr1)': "",
                    'Ticker (Dr1)': "",
                    'Vendor (Cr1)': "",
                    'Vendor (Dr1)': "",


                }];
                break;


            case "tradinglevelchangedemo":
                fields = [{
                    'fundName': "",
                    'traderName': "",
                    'transactionType': "",
                    'currency': "",
                    'timing': "",
                    'effectivedate': "",
                    'amount': "",
                    'dateOfEntry': "",
                    'perValueOfAc': "",

                }];
                break;


            case "predefinejournalentrydemo":
                fields = [{
                    'Activity Type': "",
                    'Asset Class': "",
                    'Transaction Type': "",
                    'Transaction': "",
                    'Account 1 (Dr.)': "",
                    'Account 1 (Cr.)': "",
                    'Account 2 (Cr.)': "",
                    'Account 2 (Dr.)': "",
                    'Position / Direction': "",
                    'Direction of Close Lot': ""
                }];
                break;


            case "subsector":
                fields = [{
                    'subSectorName': "",
                    'subSectorCode': "",
                    'sectorName': ""
                }];
                break;

            case "chartofaccountdemo":
                fields = [{
                    'Code': "",
                    'Name of GL Account': "",
                    'Short/Reporting Name of GL A/c': "",
                    'Primary/Sub?': "",
                    'Name of Primary A/c': "",
                    'Type of GL Account': "",
                    'Balance Sheet Group': "",
                    'Income Statement Group': "",
                    'Cash Flow Statement Category': "",
                    'Custom GL Group': "",
                    'Account Group for Performance Table': "",
                    'Custom Field 1': "",
                    'Custom Field 2': "",
                    'Custom Field 3': "",
                    'Custom Field 4': "",
                    'Portfolio': "",
                    'NFA Table Group': "",
                    'Reporting Group': "",
                    'IRR Classification': "",
                    'Connected Payable Account': "",
                    'Connected Book-To-Tax': "",
                    'FIHI/SOO Report Category': ""
                }];
                break;


            case "dividendaccrualdemo":
                fields = [{
                    'Symbol': "",
                    'Ex Date': "",
                    'Dividend Per Share': "",
                    'Pay Date': "",
                    'Entry Date': "",
                    'Name': "",
                    'Fund Name': "",
                    'Currency': "",
                    'Tax Withholding': "",
                    'Country': "",
                    'Payment In Kind': "",
                    'Option Of Cash Pay': "",
                    'Dividend Adjustment': "",
                    'Tax Withholding Adjustment': "",
                }];
                break;


            case "riskmasterdemo":
                fields = [{
                    'Valuation Date': "",
                    'Ticker': "",
                    'ISIN': "",
                    'CUSIP': "",
                    'SEDOL': "",
                    'Bloomberg ID': "",
                    'RIC Code': "",
                    'IDC ID': "",
                    'OSI Symbol': "",
                    'Delta': "",
                    'Gamma': "",
                    'Vega': "",
                    'Theta': "",
                    'Implied Volatility': "",
                    'Realized Volatility': "",
                    'Beta': "",
                    'Trading Volume': "",
                    'Trading Volume Last 30 Days': "",
                    'Trading Volume Last 200 Days': "",
                    'Market Cap': ""
                }];
                break;

            case "securityidentifiersdemo":
                fields = [{
                    'Ticker': "",
                    'Asset Class': "",
                    'Security Name': "",
                    'Issuer': "",
                    'ISIN': "",
                    'CUSIP': "",
                    'SEDOL': "",
                    'Bloomberg ID': "",
                    'RIC Code': "",
                    'IDC ID': "",
                    'OSI Symbol': "",
                    'CQG Id': "",
                    'IRR Group': "",
                    'Ticker Group': ""
                }];
                break;

            case "targetallocationdemo":
                fields = [{
                    'Effective Date From': "",
                    'Effective Date To': "",
                    'Asset Class': "",
                    'Sector': "",
                    'Sub Sector': "",
                    'Symbol': "",
                    'Target Allocation Asset Class': "",
                    'Target Allocation Sector': "",
                    'Target Allocation Sub Sector': "",
                    'Target Allocation Symbol': "",
                    'Fund': ""
                }];
                break;

            case "hurdleratedemo":
                fields = [{
                    'Fund Name': "",
                    'Date': "",
                    'Hurdle Rate': "",
                    'Investor Number': "",
                    'Hurdle Rate1': "",
                    'Hurdle Rate2': "",
                    'Hurdle Rate3': "",
                    'Hurdle Rate4': "",
                    'Hurdle Rate5': "",
                    'Hurdle Rate6': "",
                    'Hurdle Rate7': "",
                    'Index': "",
                    'Effective Index': ""
                }];
                break;
            case "ratiodemo":
                fields = [{
                    'Entity Name': "",
                    'Investor Number': "",
                    'Effective Date': "",
                    'Ratio': ""
                }];
                break;
            case "irrworkingdatademo":
                fields = [{
                    'jeDate': "",
                    'fundName': "",
                    'ticker': "",
                    'localAmount': "",
                    'typeOfGLAccount': ""
                }];
                break;

            case "bondinterestadjustmentaccsample":
                fields = [{
                    'Date': "",
                    'Fund Name': "",
                    'Broker Number': "",
                    'Ticker': "",
                    'Interest Adjustment': "",
                    'Tax With Holding Adjustment': ""
                }];
                break;

            case "dividendadjustmentccsample":
                fields = [{
                    'Date': "",
                    'Fund Name': "",
                    'Broker Number': "",
                    'Ticker': "",
                    'Currency Name': "",
                    'Dividend Adjustment': "",
                    'Pay Date': "",
                    'Security Name': "",
                    'Tax With Holding Adjustment': "",

                }];
                break;

            case "investorupdate":
                fields = [{
                    'Investor Number': "",
                    'Investor Name': "",
                    'Fund': "",
                    'Investor ID': "",
                    'Tax ID 1': "",
                    'Tax ID 2': "",
                    'Tax Entity': "",
                    'Disregarded': "",
                    "Beneficial Owner's Name": "",
                    'Beneficial Street Address': "",
                    'Beneficial Tax ID': "",
                    'Beneficial Tax Entity': "",
                    'Beneficial City': "",
                    'Beneficial State': "",
                    'Beneficial Country': "",
                    'Beneficial ZIP Code': "",
                    'Address': "",
                    'City': "",
                    'State': "",
                    'ZIP Code': ""
                }];
                break;

            case "taxadditionk1details":
                fields = [{
                    "Fund Name": "",
                    "Config Number": "",
                    "Investor Name": "",
                    "Investor Number": "",
                    "Did the partner contribute property with a built-in gain (loss)": "",
                    "Partner's Beginning Percentage Of - Profit": "",
                    "Partner's Beginning Percentage Of - Loss": "",
                    "Partner's Beginning Percentage Of - Capital": "",
                    "Partner's Share of Net Unrecognized Section 704(c) Gain or (Loss) - Beginning": "",
                    "Partner's Share of Net Unrecognized Section 704(c) Gain or (Loss) - Ending": "",
                    "Partner's share of liabilities - Beginning - Nonrecourse": "",
                    "Partner's share of liabilities - Beginning - Qualified nonrecourse financing": "",
                    "Partner's share of liabilities - Beginning - Recourse": "",
                    "Partner's share of liabilities - Ending - Nonrecourse": "",
                    "Partner's share of liabilities - Ending - Qualified nonrecourse financing": "",
                    "Partner's share of liabilities - Ending - Recourse": "",
                    "Partner's share of liabilities includes liability amounts from lower-tier partnerships": "",
                    "Liability is subject to guarantees or other payment obligations by the partner": "",
                    "If decrease in Partner's share of profit, loss or capital is due to": "",
                }];
                break;

            case "taxk1boxconfiguration":
                fields = [{
                    "K-1 Box Config Name": "",
                    "Tax Year": "",
                    "K-1 Sub-Box Number & Item": "",
                    "K-1 Box Number & Item": "",
                    "K-1 Sub-Box Direction": "",
                    "Reporting Section": "",
                    "Nature of Income": ""
                }];
                break;


            case "managerlistdemo":
                fields = [{
                    'Acct Minimum': "",
                    'ctaManagerStartDate': "",
                    'ctaManagerEndDate': "",
                    'Allocation Type': "",
                    'Average ME': "",
                    'Max Historical ME': "",
                    'MaxPortfolio': "",
                    'Fund Name': "",
                    'Max Notional': "",
                    'Max Portfolio Allocation': "",
                    'Rebalancing Period': "",
                    'Renotionalizing Period': "",
                    'Target Annual Volatility': "",
                    'Type': "",
                    'Manager Id': ""
                }];
                break;

            case "programhistoricaldatalist":
                fields = [{
                    'Name of Program': "",
                    'Month Year': "",
                    'Beginning Balance': "",
                    'Additions': "",
                    'Withdrawals': "",
                    'Gross Realized Gain(Loss)': "",
                    'Commission': "",
                    'Net Realized Gain(Loss)': "",
                    'Change In Unrealized Gain(Loss)': "",
                    'Interest Income/Expense': "",
                    'Operating Expense': "",
                    'Management Fee': "",
                    'Performance Fee': "",
                    'Net Performance': "",
                    'Ending NAV': "",
                    'Net ROR': "",
                    'VAMI': "",
                    'YTD Net ROR': ""
                }];
                break;


            case "performanceanalyticssettingsdemo":
                fields = [{
                    'fundName': "",
                    'defaultBoyVAMI': "",
                    'defaultFundVAMI': "",
                    'defaultHWM': "",
                    'defaultEndPeriodOfDrawdown': "",
                    'defaultMaxDrawdown': "",
                    'defaultStartPeriodOfDrawdown': "",
                    'defaultHigh': "",
                    'defaultCompoundedAnnualROR': "",
                    'defaultAnnualizedStdDev': "",
                    'defaultRiskFreeRateOfReturn': "",
                    'defaultAnnualizedStdDevCalc': "",
                    'defaultMonthlyStdDev': "",
                    'defaultSharpeRatio': "",
                    'defaultSharpeRatioCalc': "",
                    'defaultSortinoRatio': "",
                    'defaultFundVAMICalcString': "",
                    'annualizedDataHistoryCheckForSharpeRatio': "",
                    'dataHistoryCheckForAnnualizedROR': '',
                    'firstBenchmarkFundCode': "",
                    'secondBenchmarkFundCode': "",
                    'fundVAMICalcSincePeriod': ''
                }];
                break;

            case "performanceAdjustmentdemo":
                fields = [{
                    'investor': "",
                    'date': '',
                    'amount': "",
                    'comment': ""
                }];
                break;

            case "externalrordemo":
                fields = [{
                    'Manager Id': "",
                    'Date': "",
                    'Month ROR': "",
                    'Ending Capital': "",
                    'Gross ROR Day': ""
                }];
                break;

            case "benchmarkdemo":
                fields = [{
                    'Benchmark Name': "",
                    'Benchmark Code': "",
                    'Date': "",
                    'Net Return': "",
                    'ROR Month': "",
                    'Index': ""
                }];
                break;

            case "tlregimedemo":
                fields = [{
                    'Date': "",
                    'Fund Name': "",
                    'Fear Flag': "",
                    'Fear Flag Multiplier': ""
                }];
                break;

            case "excludecompsitedemo":
                fields = [{
                    'Program': "",
                    'Fund Name': "",
                    'Month Year': "",
                    'Notes': ""
                }];
                break;

            case "historicalwashsaleopenlotlist":
                fields = [
                    {
                        'uniqueID': "",
                        'sequenceID': "",
                        'pnlDate': "",
                        'exchangeRateTrade': "",
                        'fundName': "",
                        'ticker': "",
                        'securityName': "",
                        'assetClassName': "",
                        'nameOfCustodian': "",
                        'accountNumber': "",
                        'currencyCode': "",
                        'quantity': "",
                        'balance': "",
                        'totalCostBase': "",
                        'costPerUnitBase': "",
                        'positionDirection': "",
                        'taxLotDate': "",
                        'wsLossPerUnit': "",
                        'wsAdjCostPerUnit': "",
                        'wsAdjustedCost': "",
                        'totalWsLoss': "",
                        'tradeDateTime': "",
                        'tradePrice': "",
                        'totalCostLocal': "",
                        'costPerUnitLocal': "",
                        'sector': "",

                    }];
                break;


            case "RapidSeamlessmarketdata":
                fields = [{
                    valuationDateAndTime: "",
                    ticker: "",
                    primaryAssetClassName: "",
                    currency: "",
                    bidPrice: "",
                    askPrice: "",
                    openPrice: "",
                    highPrice: "",
                    lowPrice: "",
                    lastTradePrice: "",
                    closePrice: "",
                    tradingVolume: "",
                    delta: "",
                    gamma: "",
                    vega: "",
                    theta: "",
                    impliedVolatility: "",
                    realizedVolatility: "",
                    beta: "",
                    marketCapitalization: "",
                    pAndE: "",
                    ytm: "",
                    macaulayDuration: "",
                    modifiedDuration: "",
                    convexity: "",
                    exchangeRate: "",
                    fundName: "",
                    dataSource: "",
                    dataSourceIdentifier: ""
                }];
                break;
            case "seamlessmarketdata":
                fields = [{
                    valuationDateAndTime: "",
                    ticker: "",
                    lastTradePrice: "",
                    fundName: "",
                    dataSource: "",
                    dataSourceIdentifier: "",
                    primaryAssetClassName: "",
                    currency: "",
                    bidPrice: "",
                    askPrice: "",
                    openPrice: "",
                    highPrice: "",
                    lowPrice: "",
                    closePrice: "",
                    tradingVolume: "",
                    delta: "",
                    gamma: "",
                    vega: "",
                    theta: "",
                    impliedVolatility: "",
                    realizedVolatility: "",
                    beta: "",
                    marketCapitalization: "",
                    pAndE: "",
                    ytm: "",
                    macaulayDuration: "",
                    modifiedDuration: "",
                    convexity: "",
                    exchangeRate: "",

                }];
                break;

            case "investorportaluserdemo":
                fields = [{
                    'First Name': "",
                    'Last Name': "",
                    'User Name': "",
                    'Email': "",
                    'Fund Name': "",
                    'Investor Number': "",
                    'Role': "",
                    'User Type': ""
                }];
                break;


            case "custodiandemo":
                fields = [{
                    'Custodian Name': "",
                    'Account Number/Name': "",
                    'Fund': "",
                    'AliasNameofCustodian': "",
                    'AliasAccountNumber': "",
                    'Name Of Trade/Advisor': "",
                    'Trader': "",
                    'Commission Rates': "",
                    'Close Date': "",
                    'Email Address': "",
                    'Intermediary Bank': "",
                    'Clearing House ID': "",
                    'Service Start Date': "",
                    'Phone Number': "",
                    'Website': "",
                    'ABA Number': "",
                    'Is Account Closed': "",
                    'Account Type': "",
                    'Intermediary Account Number': "",
                    'Intermediary SWIFT': "",
                    'Primary Contact': "",
                    'SWIFT Code': "",
                    'Intermediary ABA': "",
                    'Address Line 1': "",
                    'Address Line 2': "",
                    'Country': "",
                    'City': "",
                    'State': "",
                    'ZIP Code': "",
                    'Wiring Instruction': "",
                    'Connection ID/Account Number in Source File': "",
                    'Wallet Sources': "",
                    'Wallet Address': "",
                    'Wallet Network Type': "",
                    'Books': "",
                    'Key': "",
                    'Secret': "",
                    'Pass Phrase': "",
                    'Is this account Whitelisted': "",
                    'IP Address 1': "",
                    'IP Address 2': "",
                    'IP Address 3': "",
                    'IP Address 4': "",
                    'IP Address 5': "",
                    'Others': "",
                    'IA Source Enterprise ID': "",
                    'IA Source Custodian ID': "",
                    'Accounting Time':""

                }];
                break;

            case "vendorlistdemo":
                fields = [{
                    'Vendor': "",
                    'Underlying Enterprise': "",
                    'Active': "",
                    'Type': "",
                    'Type of Entity': "",
                    'Date Of incorporation': "",
                    'Country of Incorporation': "",
                    'State of Incorporation': "",
                    'Primary Contact Name': "",
                    'Address Line 1': "",
                    'Address Line 2': "",
                    'Address Line 3': "",
                    'City': "",
                    'State': "",
                    'Zip code': "",
                    'Country': "",
                    'Phone number': "",
                    'Email Address': "",
                    'Website': "",
                    'Wiring Instruction': "",
                    'Bank A/c Number': "",
                    'Aba Number': "",
                    'Swift Code': "",
                    'Intermediary Bank': "",
                    'Intermediary Account Number': "",
                    'Intermediary Aba': "",
                    'Intermediary Swift': "",
                    'Subject to 1099': "",
                    'Tax Id': "",
                    'Country of Tax Id': "",
                    'Tax Document': "",
                    'Type Of Tax Doc': "",
                    'Category Name': "",
                    'Date Of Tax Doc': ""

                }];
                break;
                case "customerlistdemo":
                    fields = [{
                        'Customer': "",
                        'Active': "",
                        'Type': "",
                        'Type of Entity': "",
                        'Payment Mode':"",
                        'Date Of incorporation': "",
                        'Country of Incorporation': "",
                        'State of Incorporation': "",
                        'Primary Contact Name': "",
                        'Address Line 1': "",
                        'Address Line 2': "",
                        'Address Line 3': "",
                        'City': "",
                        'State': "",
                        'Zip code': "",
                        'Country': "",
                        'Phone number': "",
                        'Email Address': "",
                        'Website': "",
                        'Wiring Instruction': "",
                        'Bank A/c Number': "",
                        'Aba Number': "",
                        'Swift Code': "",
                        'Intermediary Bank': "",
                        'Intermediary Account Number': "",
                        'Intermediary Aba': "",
                        'Intermediary Swift': "",
                        'Subject to 1099': "",
                        'Tax Id': "",
                        'Country of Tax Id': "",
                        'Tax Document': "",
                        'Type Of Tax Doc': "",
                        'Category Name': "",
                        'Date Of Tax Doc': "",
                        'Relationship':""
    
                    }];
                    break;
            case "funddemo":
                fields = [{
                    'Entity Name': "",
                    'AliasEntity': "",
                    'Underlying Client': "",
                    'Entity Group': "",
                    'Entity Start Date': "",
                    'Primary Contact Name': "",
                    'Address Line 1': "",
                    'Address Line 2': "",
                    'Address Line 3': "",
                    'Address Line 4': "",
                    'City': "",
                    'Country': "",
                    'State': "",
                    'Zip Code': "",
                    'Wiring Instruction': "",
                    'Logo': "",
                    'Tax ID': "",
                    'Country of Tax ID': "",
                    'Date Of Tax Doc': "",
                    'Type Of Tax Doc': "",
                    'Fiscal Year End': "",
                    'Tax Year End': "",
                    'Operating Currency': "",
                    'Basis of Accounting': "",
                    'Tax Accounting Method': "",
                    'Type of Business': "",
                    'Tax Document': "",
                    'Entity End Date': "",
                    'Share Unit': "",
                    "Decimal NAV": "",
                    "Decimal Share Unit": "",
                    "Period Lock Date": "",
                    "G/L A/c Report Decimal Value": "",
                    
                }];
                break;
            case "misfund":
                fields = [{
                    'Fund Name': "",
                    'Fund ID': "",
                    'Enterprise Name': "",
                    'Enterprise ID': "",
                    "IA Non Legal Entity Reason": "",
                    "IA Fund Current Status": "",
                    "IA Canopy Id": "",
                    "IA Universe Id": "",
                    "IA CSD Id": "",
                    "IA Fund Legal Entity Status": "",
                    "IA Service Admin": "",
                    "IA Service Accounting": "",
                    "IA Service Tax": "",
                    "IA Service Fs": "",
                    "IA Service Investor Support Only": "",
                    "IA Service Mgmt Co Accounting": "",
                    "IA Service Csd Subscription Only": "",
                    "IA Service Backoffice": "",
                }];
                break;

            case "fundclient":
                fields = [{
                    'Fund ID': "",
                    'Tax ID1': "",
                    'Tax ID2': "",
                    'Tax ID3': "",
                    'Country of Tax ID 1': "",
                    'Country of Tax ID 2': "",
                    'Country of Tax ID 3': "",
                    'Type Of Tax Doc': "",
                    'Date Of Tax Doc': "",
                    'Tax Year End': "",
                    'Tax Accounting Method': "",
                    'Principal Business Activity': "",
                    'Principal Product or Service': "",
                    'Fund Start Date': "",
                    'Basis of Accounting': "",
                }];
                break;

            case "clientcoademo":
                fields = [{
                    'Code': "",
                    'Name Of Gl Acct': "",
                    'Type of Gl Acct': "",
                    'Description': "",
                }];
                break;
            case "reviewcoademo":
                fields = [{
                    'Id': "",
                    'Code': "",
                    'Name Of Gl Acct': "",
                    'Type of Gl Acct': "",
                    'Description': "",
                }];
                break;
            case "activityuploaddemo":
                fields = [{
                    'Entity': "",
                    'Date': "",
                    'PM Account': "",
                    'Name Of Gl Acct': "",
                    'Amount': "",
                    'Notes/Explanation': "",
                }];
                break;
            case "taxclassificationdemo":
                fields = [{
                    'K-1 Box Config Name': "",
                    'Tax Source 1': "",
                    'Source 1 Tax Code (Federal)': "",
                    'Source 1 Tax Code Description (Federal)': "",
                    'Source 1 Tax Sub-code (Federal)': "",
                    'Source 1 Tax Sub-Code Description (Federal)': "",
                    'Tax Source 2': "",
                    'Source 2 Tax Code (Federal)': "",
                    'Source 2 Tax Code  Description (Federal)': "",
                    'Source 2 Tax Sub-code (Federal)': "",
                    'Source 2 Tax Sub-code Description (Federal)': "",

                }];
                break;
            case "setupjetransferdemo":
                fields = [{
                    'Name Of Configuration': "",
                    "Fund (Transferor)": "",
                    'Custodian (Transferor)': "",
                    'Fund (Transferee)': "",
                    'Custodian (Transferee)': "",
                    'Trade': "",
                    'Capital Activity': "",
                    "Non Trade Transactions": "",
                    'Journal Entry Unrealized': "",
                    'Journal Entry Realized': "",
                    "Recurring Transaction": "",
                    'Journal Entry Feeder Fund Details': "",
                    'Journal Entry Pref Based': "",
                    'Journal Entry Asset Based': "",
                    'Investor Number': "",
                    'FX Gain Loss': "",
                    "Manual JE": "",
                    "JE Transfer": "",
                    "Rollup-Consolidation": ""

                }];
                break;

            case "rebudgetdemo":
                fields = [{
                    'Entity': "",
                    'Year':"",
                    'Month':"",
                    "Custodian No.": "",
                    'Account(COA)': "",
                    'Amount': "",
                    'Currency': "",
                }];
                break;

            case "historicalinvestorcapitalsummarydemo":
                fields = [{
                    'Date': "",
                    'Investor Number': "",
                    'Fund Name': "",
                    'Begin Capital': "",
                    'Shares Begin': "",
                    'Shares Subscription Begin': "",
                    'Capital Subscription Begin': "",
                    'Shares Redemptions Begin': "",
                    'Capital Redemptions Begin': "",
                    'Shares Transfer AssignmentIn': "",
                    'Capital TransferAssignmentIn': "",
                    'Shares TransferAssignmentOut': "",
                    'Capital TransferAssignmentOut': "",
                    'Share Net Beginning': "",
                    'Net Beginning Capital': "",
                    'Undue Performance Fee Perf Allocation': "",
                    'Shares Before Incentive Fee Perf Alloc': "",
                    'Gross Capital Before Incentive Fee Perf Alloc': "",
                    'Net Allocation Ratio': "",
                    'Gross Allocation Ratio': "",
                    'Total PAndL': "",
                    'Ending Capital AfterPL': "",
                    'Management Fee1': "",
                    'Management Fee2': "",
                    'Management Fee3': "",
                    'Management Fee4': "",
                    'Management Fee5': "",
                    'Management Fee6': "",
                    'Management Fee7': "",
                    'Management Fee8': "",
                    'Management Fee Miscellaneous': "",
                    'Ending Capital After Management Fee': "",
                    'Performance Fee1': "",
                    'Performance Fee2': "",
                    'Performance Fee3': "",
                    'Performance Fee Miscellaneous': "",
                    'Total PAndL AfterManagemnet And Performance Fee': "",
                    'Ending Capital After Management And Performace Fee': "",
                    'Share Subscription End': "",
                    'Capital Subscription End': "",
                    'Share Redemptions End': "",
                    'Capital Redemptions End': "",
                    'Share Transfer AssignmentIn End': "",
                    'Capital Transfer AssignmentIn End': "",
                    'Share Transfer AssignmentOut End': "",
                    'Capital Transfer AssignmentOut End': "",
                    'Share Ending': "",
                    'Ending Capital': "",
                    'Asset Based Fee Name': "",
                    'Net Return': "",
                    'Nav Per Share': "",
                    'Ending Capital Ratio': "",
                    'Cummulative Performance Based Fee': "",
                    'Cummulative Asset Based Fee': "",
                    'Special Allocation Ratio1': "",
                    'Special Allocation Ratio2': "",
                    'Special Allocation Ratio3': "",
                    'Special Allocation Ratio4': "",
                    'Special Allocation Ratio5': "",
                    'Special Allocation Ratio6': "",
                    'Special Allocation Ratio7': "",
                    'Special Allocation Ratio8': "",
                    'Daily Ratio 1': "",
                    'Daily Ratio 2': "",
                    'ROR Week': "",
                    'ROR Month': "",
                    'ROR Quarter': "",
                    'ROR Year': "",
                    "ROR Inception": "",
                    "Undue": "",
                    "Refer Id": "",
                    "Undue Perf Fee": "",
                    "Count Management Fee": "",
                    "Count Performance Fee": "",
                    "Trading Level": "",
                    "Fee RateNamePerfFee": "",
                    'Fee RateNameManagementFee': "",
                    'Operating Expense': "",
                    'Commission': "",
                    'Management Fee': "",
                    'Interest Income': "",
                    'Other Income': "",
                    "Performance Fee": "",
                    "Change In Unrealized Gain": "",
                    "Gross Realized Gain": "",
                    "Net Realized Gain": "",
                    "Asset Management Id": "",
                    "Asset Management Name": "",
                    "MtdTotalPAndLAfterManagemnetAndPerformanceFee": "",
                    'MtdCapitalSubscriptionBegin': "",
                    'MtdCapitalRedemptionsBegin': "",
                    'MtdCapitalTransferAssignmentIn': "",
                    'MtdCapitalTransferAssignmentOut': "",
                    "MtdCapitalSubscriptionEnd": "",
                    "MtdCapitalRedemptionsEnd": "",
                    "MtdCapitalTransferAssignmentInEnd": "",
                    "MtdCapitalTransferAssignmentOutEnd": "",
                    "MtdBeginCapital": "",
                    "MtdCummulativePerformanceBasedFee": "",
                    "MtdCummulativeAssetBasedFee": "",

                    "QtdTotalPAndLAfterManagemnetAndPerformanceFee": "",
                    'QtdCapitalSubscriptionBegin': "",
                    'QtdCapitalRedemptionsBegin': "",
                    'QtdCapitalTransferAssignmentIn': "",
                    'QtdCapitalTransferAssignmentOut': "",
                    "QtdCapitalSubscriptionEnd": "",
                    "QtdCapitalRedemptionsEnd": "",
                    "QtdCapitalTransferAssignmentInEnd": "",
                    "QtdCapitalTransferAssignmentOutEnd": "",
                    "QtdBeginCapital": "",
                    "QtdCummulativePerformanceBasedFee": "",
                    "QtdCummulativeAssetBasedFee": "",

                    "YtdTotalPAndLAfterManagemnetAndPerformanceFee": "",
                    'YtdCapitalSubscriptionBegin': "",
                    'YtdCapitalRedemptionsBegin': "",
                    'YtdCapitalTransferAssignmentIn': "",
                    'YtdCapitalTransferAssignmentOut': "",
                    "YtdCapitalSubscriptionEnd": "",
                    "YtdCapitalRedemptionsEnd": "",
                    "YtdCapitalTransferAssignmentInEnd": "",
                    "YtdCapitalTransferAssignmentOutEnd": "",
                    "YtdBeginCapital": "",
                    "YtdCummulativePerformanceBasedFee": "",
                    "YtdCummulativeAssetBasedFee": "",

                    "ItdTotalPAndLAfterManagemnetAndPerformanceFee": "",
                    'ItdCapitalSubscriptionBegin': "",
                    'ItdCapitalRedemptionsBegin': "",
                    'ItdCapitalTransferAssignmentIn': "",
                    'ItdCapitalTransferAssignmentOut': "",
                    "ItdCapitalSubscriptionEnd": "",
                    "ItdCapitalRedemptionsEnd": "",
                    "ItdCapitalTransferAssignmentInEnd": "",
                    "ItdCapitalTransferAssignmentOutEnd": "",
                    "ItdBeginCapital": "",
                    "ItdCummulativePerformanceBasedFee": "",
                    "ItdCummulativeAssetBasedFee": "",

                    "Vami": "",
                    'Gross ROR Day': "",
                    'Gross ROR Week': "",
                    'Gross ROR Month': "",
                    'Gross ROR Quarter': "",
                    "Gross ROR Year": "",
                    "Gross ROR Inception": "",
                    "Share Class Name For Investor": "",
                    "Series Name For Investor": "",
                    "Quarter": "",
                    "Daily BenchMark ROR": "",
                    "MTD BenchMark ROR": "",
                    "QTD BenchMark ROR": "",
                    "YTD BenchMark ROR": "",
                    "ITD BenchMark ROR": "",
                    "WTD BenchMark ROR": "",

                    "Class Nav Per Share": "",
                    "Untized Capital Balance Ending": "",
                    "Untized Capital Balance Begin": "",
                    'Distribution Principal Beginning': "",
                    'Distribution Principal Ending': "",
                    'Distribution Interest Beginning': "",
                    'Distribution Interest Ending': "",

                    "Capital Call Beginning": "",
                    "Capital Call Ending": "",
                    "Capital Call Expense Beginning": "",
                    "Capital Call Expense Ending": "",
                    "Capital Call Others Beginning": "",
                    "Capital Call Others Ending": "",
                    "Reinvestment Beginning": "",
                    "Reinvestment Ending": "",

                    "MtdDistributionPrincipalBeginning": "",
                    "MtdDistributionPrincipalEnding": "",
                    "MtdDistributionInterestBeginning": "",
                    "MtdDistributionInterestEnding": "",
                    "MtdCapitalCallBeginning": "",
                    "MtdCapitalCallEnding": "",
                    "MtdCapitalCallExpenseBeginning": "",
                    "MtdCapitalCallExpenseEnding": "",
                    "MtdCapitalCallOthersBeginning": "",
                    "MtdCapitalCallOthersEnding": "",
                    "MtdReinvestmentBeginning": "",
                    "MtdReinvestmentEnding": "",

                    "QtdDistributionPrincipalBeginning": "",
                    "QtdDistributionPrincipalEnding": "",
                    "QtdDistributionInterestBeginning": "",
                    "QtdDistributionInterestEnding": "",
                    "QtdCapitalCallBeginning": "",
                    "QtdCapitalCallEnding": "",
                    "QtdCapitalCallExpenseBeginning": "",
                    "QtdCapitalCallExpenseEnding": "",
                    "QtdCapitalCallOthersBeginning": "",
                    "QtdCapitalCallOthersEnding": "",
                    "QtdReinvestmentBeginning": "",
                    "QtdReinvestmentEnding": "",

                    "YtdDistributionPrincipalBeginning": "",
                    "YtdDistributionPrincipalEnding": "",
                    "YtdDistributionInterestBeginning": "",
                    "YtdDistributionInterestEnding": "",
                    "YtdCapitalCallBeginning": "",
                    "YtdCapitalCallEnding": "",
                    "YtdCapitalCallExpenseBeginning": "",
                    "YtdCapitalCallExpenseEnding": "",
                    "YtdCapitalCallOthersBeginning": "",
                    "YtdCapitalCallOthersEnding": "",
                    "YtdReinvestmentBeginning": "",
                    "YtdReinvestmentEnding": "",

                    "ItdDistributionPrincipalBeginning": "",
                    "ItdDistributionPrincipalEnding": "",
                    "ItdDistributionInterestBeginning": "",
                    "ItdDistributionInterestEnding": "",
                    "ItdCapitalCallBeginning": "",
                    "ItdCapitalCallEnding": "",
                    "ItdCapitalCallExpenseBeginning": "",
                    "ItdCapitalCallExpenseEnding": "",
                    "ItdCapitalCallOthersBeginning": "",
                    "ItdCapitalCallOthersEnding": "",
                    "ItdReinvestmentBeginning": "",
                    "ItdReinvestmentEnding": "",
                    "Investor Name": "",

                }];
                break;
            case "sidepocketcreation":
                fields = [{
                    "Side Pocket Name": "",
                    "Effective Date": "",
                    "Fund Name": "",
                    "Ticker": "",
                    "Total Amount": "",
                    "Investor Number": "",
                    "Investor Percentage": "",
                    "Contribution Amount": "",
                    "Broker Account": ""
                }];
                break;
            case "nontradetransactions":
                fields = [{
                  
                    'amount': "",
                    'custodianAccNo': "",
                    'date': "",
                    'entityName': "",
                    'transactionTypeName': "",
                    'currencyName': "",
                    'exchangeRate': "",
                    'description': "",
                    'investorNo': "",
                    'thirdPartySource': "",
                    'tickerSymbol': "",
                    'vendorName': "",

                }];
                break;
            case "position":
                fields = [{
                    'ticker': "",
                    'pnlDate': "",
                    'custodianBroker': "",
                    'quantity': "",
                    'localAmount': "",
                    'baseAmount': "",
                    'securityName': "",
                    'fundName': "",
                    'marketPrice': "",
                    'marketValueTotalLocal': "",
                    'currency': "",
                    'totalCostBase': "",
                    'totalCostLocal': "",
                    'marketValueTotalBase': "",
                    'positionDirection': "",
                    'assetClassName': "",
                    'thirdPartySource': "",

                }];
                break;
            case "cashbalance":
                fields = [{
                    'transactionCurrency': "",
                    'localClosingBalance': "",
                    'exchangeRate': "",
                    'baseClosingBalance': "",
                    'custodianBroker': "",
                    'fundName': "",
                    'date': "",
                    'thirdPartySource': ""
                }];
                break;

            case "workflowconfigdemo":
                fields = [{
                    'processName': "",
                    'fundListStr': "",
                    'makerName': "",
                    'makerEmailId': "",
                    'checkerName': "",
                    'checkerEmailId': "",
                    'mailPreviewerName': "",
                    'mailPreviewerEmailId': "",
                    'clientName': "",
                    'clientEmailId': "",
                    'ccClientEmailId': "",
                    'postClientApproval': "",
                    'frequency': "",
                    'dueDays': "",
                    'CC in Investor Email ': "",
                    'BCC in Investor Email ': "",
                    'Maker Checklist Enabled': "",
                    'Checker Checklist Enabled': ""

                }];
                break;

            case "allsmupdate":
                fields = [{
                    'id': "",
                    'Ticker': "",
                    'Exchange': "",
                    'Primary Exchange': ""
                }];
                break;

            case "fsallsmupdate":
                fields = [{
                    'Id': "",
                    'Ticker': "",
                    'FS ProductTypes': "",
                    'FS Sector': "",
                    'FS Investment Type for SOI & SOFC': "",
                    'FS Investment Type for Cash Flow': "",
                    'FS Ranking for Cash Flow': "",
                    'FS Country': "",
                    'FS Level': "",
                    'Primary Underlying Risk': "",
                    'FS Valuation Techniques': "",
                    'FS Unobservable Inputs': "",
                    'FS Range Of Inputs': ""
                }];
                break;
            case "fschartofaccounts":
                fields = [{
                    'Id': "",
                    "FS Ranking": "",
                    'Name of GL Account': "",
                    'Cash Flow Statement Category': "",
                    'Custom Field 2': "",
                    'FIHI/SOO Report Category': "",
                }];
                break;
                case "fschartofaccountsrapid":
                fields = [{
                    'COA Id': "",
                    "FS Ranking": "",
                    'Name of GL Account': "",
                    'Cash Flow Statement Category': "",
                    'Custom Field 2': "",
                    'FIHI/SOO Report Category': "",
                }];
                break;
            case "fsInvestor":
                fields = [{
                    "Fund Name": "",
                    "Investor Name": "",
                    "Investor Number": "",
                    "Share Class": "",
                    "Series": "",
                    "Investor Type": "",
                    "Investor for FIHI": "",
                    "Investor Type Ranking":"",
                    "Class Ranking":"",
                    "Series Ranking":""
                }];
                break;
                case "fsportfolio":
                fields = [{
                    "SM Id": "",
                    "Ticker": "",
                    "Custodian/Exchange/wallet/bank (name)": "",
                    "Redemption frequency/terms": "",
                    "Redemption notice period": "",
                    "Liquidity terms": "",
                    "Unfunded commitments": "",
                    "FS Investment Type": "",
                    "Security Name": "",
                    "FS ProductTypes": "",
                    "FS Country": "",
                    "FS Sector": "",
                    "FS Level": "",
                    "FS Ranking": "",
                    "primaryUnderlyingRisk": "",
                    "FS Valuation Techniques": "",
                    "FS Unobservable Inputs": "",
                    "rangeOfInputs": "",
                    "maturityDate": "",
                    "Investment Objective/Strategy for FOF Fund": ""
                }];
                break;
            case "periodendindicatorsbyfund":
                fields = [{
                    'fundName': "",
                    "currentDate": "",
                    'businessDayIndicator': "",
                    'endCustomPeriod': "",
                    'endOfHalfYear': "",
                    'endOfMonth': "",
                    'endOfQuarter': "",
                    'endOfWeekFriday': "",
                    'endOfWeekMonday': "",
                    'endOfWeekSaturday': "",
                    'endOfWeekSunday': "",
                    'endOfWeekThursday': "",
                    'endOfWeekTuesday': "",
                    'endOfWeekWednesday': "",
                    'endOfYear': "",
                    'numberOfDaysSoFarOfDaysInHalfYear': "",
                    'numberOfDaysSoFarOfDaysInMonth': "",
                    'numberOfDaysSoFarOfDaysInQuarter': "",
                    'numberOfDaysSoFarOfDaysInYear': "",
                    'totalNoOfDaysInHalfYear': "",
                    'totalNoOfDaysInMonth': "",
                    'totalNoOfDaysInQuarter': "",
                    'totalNoOfDaysInYear': "",
                    'dayCounterWorkingDay': "",
                    'numberOfDaysSoFarOfDays': "",
                    'numberOfDaysSoFarOfDaysInWeekFriday': "",
                    'numberOfDaysSoFarOfDaysInWeekMonday': "",
                    'numberOfDaysSoFarOfDaysInWeekSaturday': "",
                    'numberOfDaysSoFarOfDaysInWeekSunday': "",
                    'numberOfDaysSoFarOfDaysInWeekThrusday': "",
                    'numberOfDaysSoFarOfDaysInWeekTuesday': "",
                    'numberOfDaysSoFarOfDaysInWeekWednesday': "",
                    'weekendCompoundingApplicable': "",
                }];
                break;

            case "defaultchartofaccountsdemo":
                fields = [{
                    'Code': "",
                    'Name of GL Account': "",
                    'Short/Reporting Name of GL A/c': "",
                    'Primary/Sub?': "",
                    'Name of Primary A/c': "",
                    'Type of GL Account': "",
                    'Balance Sheet Group': "",
                    'Income Statement Group': "",
                    'Cash Flow Statement Category': "",
                    'Custom GL Group': "",
                    'accountGroupForPerformanceTable': "",
                    'Custom Field 1': "",
                    'Custom Field 2': "",
                    "Is this a cost account?": "",
                    'Custom Field 4': "",
                    "Is this a portfolio account?": "",
                    'NFA Table Group': "",
                    'Reporting Group': "",
                    'IRR Classification': "",
                    'Connected Payable Account': "",
                    'Connected Book-To-Tax': "",
                    'FIHI/SOO Report Category': "",
                    'Reporting Group for Investor Statement': "",
                    'COA Ranking': "",
                    'Seamless Tax Code': "",
                    'Book to tax': ""
                }];
                break;
            case "amcupload":
                fields = [{
                    'Entity Name': "",
                    'Asset Manager': "",
                    'Type of Entity': "",
                    'Underlying Enterprise': "",
                    'Alias(Entity Name)': "",
                    'Disclaimer':"",
                    'Disclaimer Composite': "",
                    'Primary contact name':"",
                    'Address Line 1': "",
                    'Address Line 2': "",
                    'Address Line 3': "",
                    'City': "",
                    'State': "",
                    'Country': "",
                    'Zip Code': "",
                    'Phone Number': "",
                    'Email Address': "",
                    'Website': "",
                    'Bank Account Number': "",
                    'ABA Number': "",
                    'SWIFT Code': "",
                    'Intermediary Bank': "",
                    'Intermediary Account Number': "",
                    'Intermediary ABA': "",
                    'Intermediary SWIFT': "",
                    'TAX ID': "",
                    'Date of TAX Document': "",
                    'Country of TAX ID': "",
                    'Type of TAX Document': "",
                    'Subject to 1099? ': "",
                    'ROR Level': "",
                    'Date of Incorporation': "",
                    'State of Incorporation': "",
                    'Country of Incorporation': "",
                    'Inception of Trading': "",
                    'Historical Blend date': "",
                    'Active?': "",
                    'Exclude from CTA AUM?': "",
                    'Proprietary Or Not?': "",
                    'Fix Trading Level or Not?': "",
                    
                }]
                break;
                case "updatefstransactioncode":
                    fields = [{
                        'tradeId': "",
                        "fsTransactionCode": "",
                    }];
                break; case "grosscashflowadjustment":
                fields = [{
                    'Fund Name': "",
                    'Date': "",
                    'Deal Name': "",
                    'Amount': "",
                    'Comments': "",
                }];
                break;
              case "contributiondistributionadjustment":
                fields = [{
                    'Fund Name': "",
                    'Date': "",
                    'Transaction Type':"",
                    'Amount': "",
                    'Comments': "",
                }];
                break;
                case "investorallocationprogram":
                    fields = [{
                        'Fund Name': "",
                        "Investor Name": "",
                        'Investor Number': "",
                        "key": "",
                        'Value': "",
                        "Start Date": "",
                        "End Date": "",
                        "Description": ""
                    }];
                    break;
            default:
                fields = [];
        }

        return fields;
    }
}
