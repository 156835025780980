/*******************************************************************************
 * FORMIDIUM Pvt. Ltd COPYRIGHT STATEMENT
 *  __________________
 *
 *  2017 - 2018 FORMIDIUM Pvt. Ltd
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of FORMIDIUM Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to FORMIDIUM Pvt. Ltd
 * and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret and/or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from FORMIDIUM Pvt. Ltd. Contact information for FORMIDIUM Pvt. Ltd may be obtained
 * by visitng www.formidium.com.
 ******************************************************************************/
import { Component } from "@angular/core";

import { ICellEditorAngularComp } from "ag-grid-angular";
import { ICellEditorParams } from "ag-grid-community";

@Component({
    selector: 'edit-cell-dropdown',
    templateUrl: './edit-cell-dropdown.component.html',
})
export class EditCellDropDownEditor implements ICellEditorAngularComp {
    public params: any;
    public value: any;
    public isCurrentRowEditing: boolean;
    public list: any = [];
    public data: any;
    public selectedValue: any;
    oldSelectedValue: any;
    isLabelType: any;
    dropdownFieldValue: any;
    public formData: any;
    public columnDef: any;
    public isDisabled: boolean = false;
    public editableRowData: any;
    componentName: any;

    agInit(params: ICellEditorParams): void {
        this.params = params;
        //set columns dropdown values
        let colDef = this.params.colDef;
        let cellEditorParams = colDef.cellEditorParams;
        this.isLabelType = colDef.isLabelType;
        this.list = cellEditorParams.values || [];
        if (this.params.api.componentName == "fSPortfolio" || this.params.api.componentName == "fSInvestor" || this.params.api.componentName == "fschartOfAccountsRapid") {
            let firstOption = { 'label': 'Select ' + colDef.headerName, 'value': '' }
            this.list = [firstOption, ...cellEditorParams.values];
        }
        this.componentName = this.params.api.componentName
        this.editableRowData = this.params.data;
        this.columnDef = this.params.colDef;
        this.formData = this.params.api['caFormData'];
	 if (this.params.api.componentName == "fSPortfolio" || this.params.api.componentName == "fSInvestor") {
            let firstOption = { 'label': 'Select ' + colDef.headerName, 'value': '' }
            this.list = [firstOption, ...cellEditorParams.values];
        }
        //set column values
        this.data = this.params.node.data;
        this.columnDef.isDisabled = false;
        if (this.componentName == 'redemptionActivityUpdate' || this.componentName == 'subscriptionActivityUpdate') {
            this.getDisabledFlag();
        }
        this.value = this.getValueByPath(this.data, colDef.field);
        this.dropdownFieldValue = this.getValueByPath(this.data, colDef.dropdownField);
        if (this.isLabelType && this.value) {
            this.list.forEach(entry => {
                if (entry.label == this.value) {
                    this.oldSelectedValue = entry.value;
                    this.selectedValue = entry.value;
                    this.setValueByPath(colDef.dropdownField, entry.value);
                    // this.params.node.data[colDef.dropdownField] = entry.value;
                }
            })
        } else {
            if (this.dropdownFieldValue && Array.isArray(this.dropdownFieldValue)) {
                this.oldSelectedValue = this.dropdownFieldValue[0];
                this.selectedValue = this.dropdownFieldValue[0];
            } else {
                this.oldSelectedValue = this.dropdownFieldValue;
                this.selectedValue = this.dropdownFieldValue;
            }
        }
    }

    getValueByPath(obj, path) {
        let current = obj;
        for (const key of path.split('.')) {
            if (current && current.hasOwnProperty(key)) {
                current = current[key];
            } else {
                return null;
            }
        }
        return current;
    }

    setValueByPath(path, value) {
        if (path.split('.').length > 1) {
            let keys = path.split('.');
            this.params.node.data[keys[0]] = this.params.node.data[keys[0]] ? this.params.node.data[keys[0]] : {};
            this.params.node.data[keys[0]][keys[1]] = value;
        } else {
            this.params.node.data[path] = value;
        }
    }

    afterGuiAttached = () => {
        if (!this.params.value) {
            return;
        } else {
            let colDef = this.params.colDef;
            this.value = this.getValueByPath(this.data, colDef.field);
        }
    };

    onSelect() {
        let colDef = this.params.colDef;
        let selectedOption = this.list.filter(data => { return data.value == this.selectedValue });
        if (selectedOption.length) {
            this.setValueByPath(colDef.dropdownField, this.selectedValue);
            // this.params.node.data[colDef.dropdownField] = this.selectedValue;
            if (selectedOption[0].value) {
            this.setValueByPath(colDef.field, selectedOption[0].label);
            // this.params.node.data[colDef.field] = selectedOption[0].label;
            if (colDef.field == "investorNumber") {
                this.params.node.data["investorName"] = selectedOption[0].name;
            }
            if (colDef.field == "fund.fundName") {
                this.params.node.data["fund"]["id"] = selectedOption[0].value;
                this.params.node.data["fundId"] = selectedOption[0].value;
            }
            if (colDef.field == "assetClassName" && this.componentName == 'rapidSetupbondinterest') {
                this.params.node.data["assetClass"]["id"] = selectedOption[0].value;
            }
            this.params.value = selectedOption[0].label;
            this.setValue(selectedOption[0].label);
        }
        } else {
            this.params.value = selectedOption[0].value;
            this.setValue(selectedOption[0].value);
        }
    }

    getValue(): any {
        if (this.params.node.data.isEditingSave) {
            return this.params.value;
        } else {
            let colDef = this.params.colDef;
            this.setValueByPath(colDef.field, this.value);
            // this.params.node.data[colDef.field] = this.value;
            this.setValueByPath(colDef.dropdownField, this.oldSelectedValue);
            // this.params.node.data[colDef.dropdownField] = this.oldSelectedValue;
            return this.value;
        }
    }

    setValue(label): any {
        var colId = this.params.column.getId();
        this.params.node.setDataValue(colId, label);
    }

    refresh(params?: any): boolean {
        return true;
    }

    getDisabledFlag() {
        if (this.formData) {
            const transactionType = this.formData?.transactionType?.id || '';
            const selectedNodeCsdEntId = this.editableRowData?.['csdEnterpriseId'] || '';
            const isCsdIntegrated = this.editableRowData?.['csdIntegrated'] || '';

            if (transactionType == 'Redemption - Partial by Amount' || transactionType == 'Redemption - Transfer') {
                if (selectedNodeCsdEntId && isCsdIntegrated) {
                    if (this.params.data?.disableAutoUpdateViaAllocation == false) {
                        if (this.columnDef && (this.columnDef.field == "timing" || this.columnDef.field == "effectivedate" || this.columnDef.field == "amount" || this.columnDef.field == "shares" || this.columnDef.field == "perValueOfAc")) {
                            this.columnDef.isDisabled = true;
                        } else {
                            this.columnDef.isDisabled = false;
                        }
                    } else if (this.params.data?.disableAutoUpdateViaAllocation == true) {
                        if (this.columnDef && (this.columnDef.field == "timing" || this.columnDef.field == "effectivedate" || this.columnDef.field == "amount")) {
                            this.columnDef.isDisabled = true;
                        } else {
                            this.columnDef.isDisabled = false;
                        }
                    }
                } else {
                    if (this.params.data?.disableAutoUpdateViaAllocation == false) {
                        if (this.columnDef && (this.columnDef.field == "shares" || this.columnDef.field == "perValueOfAc" || this.columnDef.field == "amount")) {
                            this.columnDef.isDisabled = true;
                        } else {
                            this.columnDef.isDisabled = false;
                        }
                    } else if (this.params.data?.disableAutoUpdateViaAllocation == true) {
                        if (this.columnDef.field == "amount") {
                            this.columnDef.isDisabled = true;
                        }
                        else {
                            this.columnDef.isDisabled = false;
                        }
                    }
                }
            } else if (transactionType == 'Redemption - Full') {
                if (selectedNodeCsdEntId && isCsdIntegrated) {
                    if (this.params.data?.disableAutoUpdateViaAllocation == false) {
                        if (this.columnDef && (this.columnDef.field == "timing" || this.columnDef.field == "effectivedate" || this.columnDef.field == "amount" || this.columnDef.field == "shares" || this.columnDef.field == "perValueOfAc")) {
                            this.columnDef.isDisabled = true;
                        } else {
                            this.columnDef.isDisabled = false;
                        }
                    } else if (this.params.data?.disableAutoUpdateViaAllocation == true) {
                        if (this.columnDef && (this.columnDef.field == "timing" || this.columnDef.field == "effectivedate" || this.columnDef.field == "perValueOfAc")) {
                            this.columnDef.isDisabled = true;
                        } else {
                            this.columnDef.isDisabled = false;
                        }
                    }
                } else {
                    if (this.params.data?.disableAutoUpdateViaAllocation == false) {
                        this.amountSharePerValueAcDisable();
                    } else if (this.params.data?.disableAutoUpdateViaAllocation == true) {
                        if (this.columnDef && (this.columnDef.field == "perValueOfAc")) {
                            this.columnDef.isDisabled = true;
                        } else {
                            this.columnDef.isDisabled = false;
                        }
                    }
                }
            } else if (transactionType == 'Redemption - Partial - by share or Unit') {

                if (selectedNodeCsdEntId && isCsdIntegrated) {
                    if (this.params.data?.disableAutoUpdateViaAllocation == false) {
                        if (this.columnDef && (this.columnDef.field == "timing" || this.columnDef.field == "effectivedate" || this.columnDef.field == "amount" || this.columnDef.field == "shares" || this.columnDef.field == "perValueOfAc")) {
                            this.columnDef.isDisabled = true;
                        } else {
                            this.columnDef.isDisabled = false;
                        }
                    } else if (this.params.data?.disableAutoUpdateViaAllocation == true) {
                        if (this.columnDef && (this.columnDef.field == "timing" || this.columnDef.field == "effectivedate" || this.columnDef.field == "shares")) {
                            this.columnDef.isDisabled = true;
                        } else {
                            this.columnDef.isDisabled = false;
                        }
                    }
                } else {
                    if (this.params.data?.disableAutoUpdateViaAllocation == false) {
                        this.amountSharePerValueAcDisable();
                    } else if (this.params.data?.disableAutoUpdateViaAllocation == true) {
                        if (this.columnDef && (this.columnDef.field == "shares")) {
                            this.columnDef.isDisabled = true;
                        } else {
                            this.columnDef.isDisabled = false;
                        }
                    }
                }
            } else if (transactionType == 'Redemption - Partial - by % value of Account') {

                if (selectedNodeCsdEntId && isCsdIntegrated) {
                    if (this.params.data?.disableAutoUpdateViaAllocation == false) {
                        if (this.columnDef && (this.columnDef.field == "timing" || this.columnDef.field == "effectivedate" || this.columnDef.field == "amount" || this.columnDef.field == "shares" || this.columnDef.field == "perValueOfAc")) {
                            this.columnDef.isDisabled = true;
                        } else {
                            this.columnDef.isDisabled = false;
                        }
                    } else if (this.params.data?.disableAutoUpdateViaAllocation == true) {
                        if (this.columnDef && (this.columnDef.field == "timing" || this.columnDef.field == "effectivedate" || this.columnDef.field == "perValueOfAc")) {
                            this.columnDef.isDisabled = true;
                        } else {
                            this.columnDef.isDisabled = false;
                        }
                    }
                } else {
                    if (this.params.data?.disableAutoUpdateViaAllocation == false) {
                        this.amountSharePerValueAcDisable();
                    } else if (this.params.data?.disableAutoUpdateViaAllocation == true) {
                        if (this.columnDef && (this.columnDef.field == "perValueOfAc")) {
                            this.columnDef.isDisabled = true;
                        } else {
                            this.columnDef.isDisabled = false;
                        }
                    }
                }

            } if (transactionType == 'Subscription - First Time' || transactionType == 'Subscription - Additional' || transactionType == 'Subscription - Transfer') {
                if (selectedNodeCsdEntId && isCsdIntegrated) {
                    if (this.columnDef && (this.columnDef.field == "timing" || this.columnDef.field == "effectivedate" || this.columnDef.field == "amount" || this.columnDef.field == "shares")) {
                        this.columnDef.isDisabled = true;
                    }
                    else {
                        this.columnDef.isDisabled = false;
                    }
                } else {
                    if (this.columnDef && (this.columnDef.field == "shares" || this.columnDef.field == "amount")) {
                        this.columnDef.isDisabled = true;
                    } else {
                        this.columnDef.isDisabled = false;
                    }
                }
            }
        }
    }

    amountSharePerValueAcDisable() {
        if (this.columnDef && (this.columnDef.field == "shares" || this.columnDef.field == "amount" || this.columnDef.field == "perValueOfAc")) {
            this.columnDef.isDisabled = true;
        } else {
            this.columnDef.isDisabled = false;
        }
    }
}