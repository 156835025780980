
/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Injectable } from '@angular/core';
 
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SetupPercentageService {
  [x: string]: any;

  constructor() { }
  private source = new Subject<any>();
  private uploadSubject = new Subject<any>();
  private DeleteSubject = new Subject<any>();
  private formAddSubject = new Subject<any>();
  private fields :any;
  private showGridSubject = new Subject<any>();
  private custodianSubject = new Subject<any>();
  private VideoSubject = new Subject<any>();
  private VideoPlay = new Subject<any>();
  private taxSubject = new Subject<any>();
  private mappingSubject = new Subject<any>();

  private selectAllSubject = new Subject<any>();

  add(isDone: boolean) {
    this.source.next(isDone);
  }
  clear() {
    this.source.next({});
  }
  getMessage(): Observable<any> {
    return this.source.asObservable();
  }

  setUploadTrigger(isDone: boolean) {
    this.uploadSubject.next(isDone);
  }

  getUploadmessage() {
    return this.uploadSubject.asObservable();
  }

  setDeleteTrigger(isDone: boolean) {
    this.DeleteSubject.next(isDone);
  }

  getDeletemessage() {
    return this.DeleteSubject.asObservable();
  }

  setExcelFeild(data: any) {
    this.fields = data;
  }

  getExcelFeild() {
    return this.fields;
  }
  setAccountSummaryReconcileGrid(data: any) {
    this.reconcileResponse = data;
  }

  getAccountSummaryReconcileGrid() {
    return this.reconcileResponse;
  }
  setAddPopupTrigger(data: any) {
    this.formAddSubject.next(data);
  }

  getPopupmessage() {
    return this.formAddSubject.asObservable();
  }
  setShowGridMsg(data: any) {
    this.showGridSubject.next(data);
  }

  getShowGridMsg() {
    return this.showGridSubject.asObservable();
  }
  setCustodianType(data: any) {
    this.custodianSubject.next(data);
  }

  getCustodianType() {
    return this.custodianSubject.asObservable();
  }

  setVideoStatus(data: any) {
    this.VideoSubject.next(data);
  }

  getVideoStatus() {
    return this.VideoSubject.asObservable();
  }

  setIsPlay(data: any) {
    this.VideoPlay.next(data);
  }

  getIsPlay() {
    return this.VideoPlay.asObservable();
  }

  setTaxDataTrigger(data: boolean) {
    this.taxSubject.next(data);
  }

  getTaxData() {
    return this.taxSubject.asObservable();
  }


  setTaxGridDataTrigger(data: boolean) {
    this.taxGridSubject = data;
  }

  getTaxGridData() {
    return this.taxGridSubject;
  }

  setGridDataTypeTrigger(data) {
    this.gridDataSubject = data;
  }

  getGridDataType() {
    return this.gridDataSubject;
  }

  setMappingDataTrigger(data: any) {
    this.mappingSubject.next(data);
  }

  getMappingData() {
    return this.mappingSubject.asObservable();
  }

  setSelectAllMappingTrigger(data: any) {
    this.selectAllSubject.next(data);
  }

  getSelectAllMappingData() {
    return this.selectAllSubject.asObservable();
  }


  setTaxYear(data) {
    this.taxYear = data;
  }

  getTaxYear() {
    return this.taxYear;
  }
}
