/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { FileDynamicUploadComponent } from './custom-components/file-dynamic-upload/file-dynamic-upload.component';
import { CommonModule } from '@angular/common';
import { ViewBtnCellRenderer } from './buttons/view-button-cell-renderer.component';
import { GridComponent } from './custom-components/grid/grid.component';
import { PrimeNgModule } from './primeNg.module';
import { MaterialModule } from '../../../src/@fury/shared/material-components.module';
import { FurySharedModule } from '../../../src/@fury/fury-shared.module';
import { ListModule } from '../../../src/@fury/shared/list/list.module';
import { CustomerCreateUpdateModule } from '../pages/tables/all-in-one-table/customer-create-update/customer-create-update.module';
import { BreadcrumbsModule } from '../../../src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { ConfirmationDialog } from './dialogs/confirmation-dialog.component';
import { DemoDialogComponent } from '../pages/components/dialogs/dialogs.component';
import { UploadDialogComponent } from './dialogs/upload-dialog-component';
import { MatRippleModule } from '@angular/material/core';
import { FuryCardModule } from '../../../src/@fury/shared/card/card.module';
import { MarketDataBtnCellRenderer } from './buttons/marketDataButtons/market-data-button';
import { RunAlertThresholdButton } from './buttons/run-alert-threshold-button/run-alert-threshold-button';
import { AccountSummaryDateComponent } from './buttons/account-summary-date/account-summary-date.component';
import { PipesModule } from './pipes/decimalFormat.pipe';
import { OverAllStatusButton } from './buttons/over-all-status-button/over-all-status-button.component';
import { ApproveWorkflowButton } from './buttons/approve-workflow-button/approve-workflow-button.component';
import { DownloadTempButton } from './buttons/download-temp-button/download-temp-button.component';
import { RunDataImportErrorFileButton } from './buttons/run-data-import-error-file-button/run-data-import-error-file-button.component';
import { RefreshButton } from './buttons/refresh-button/refresh-button.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SmartViewTableComponent } from './custom-components/smart-table-view/smart-view-table.component';
import { SendMailClientTemplate } from './buttons/send-mail-client-template/send-mail-client-template.component';
import { GrdbtnErrRowDownComponent } from './buttons/grdbtn-row-down/grdbtn-row-err-down.component';
import { GrdbtnPrvRowDownComponent } from './buttons/grdbtn-row-down/grdbtn-row-prv-down.component';
import { ApproveWorkflowStatusButton } from './buttons/approve-workflow-status-button/approve-workflow-status-button.component';
import { GrdbtnRowEditComponent } from './buttons/grdbtn-row-edit/grdbtn-row-edit.component';
import { ResumeDailyProcessingButton } from './buttons/resume-daily-processing-button/resume-daily-processing-button';
import { CaDownloadFileButton } from './buttons/Capital-Activity-download-button/Capital-Activity-download-button.component';
import { AttachmentDownloadButton } from './buttons/attachment-download-button/attachment-download-button.component';
import { GridHederBtnComponent } from './buttons/grid-heder-btn/grid-heder-btn.component';
import { CheckboxRenderer } from './buttons/checkbox-renderer.component';
import { GrdbtnPendingDataComponent } from './buttons/grdbtn-row-down/grdbtn-row-pending-data.component';
import { GridFilterComponent } from './custom-components/grid-filter/grid-filter.component';
import { DynamicFormModule } from '../../../src/app/shared/dynamic-form/dynamic-form.module';
import { GrdSchdBtnComponent } from './buttons/grd-schd-btn/grd-schd-btn.component';
import { BatchSessionButton } from './buttons/batch-session-button/batch-session-button.component';
import { CronEditorModule } from 'cron-editor';
import { DynamicFilesUploadComponent } from 'src/app/shared/custom-components/rapid/dynamic-files-upload/dynamic-files-upload.component'
import { IsdButtonComponent } from './buttons/isd-button/isd-button.component';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { UploadButtonComponent } from './buttons/upload-button/upload-button.component';
import { GridCellDatepicker } from './buttons/grid-Cell-datepicker/grid-cell-datepicker.component';
import { ReClientUploadComponent } from './buttons/re-client-upload/re-client-upload.component';
import { ClientGridComponent } from './custom-components/client-grid/client-grid.component';
import { DuplicateButton } from './buttons/duplicate-button/duplicate-button.component';
import { ButtonRendererComponent } from './buttons/edit-cell-button/edit-cell-button.component';
import { EditCellDateRenderer } from './buttons/edit-cell-date/edit-cell-date-editor-renderer.component';
import { EditCellDateEditor } from './buttons/edit-cell-date/edit-cell-date-editor.compoent';
import { EditCellDropDownRenderer } from './buttons/edit-cell-dropdown/edit-cell-dropdown-renderer.component';
import { EditCellDropDownEditor } from './buttons/edit-cell-dropdown/edit-cell-dropdown.component';
import { EditCellNumberEditor } from './buttons/edit-cell-number/edit-cell-number-editor.compoent';
import { EditCellNumberRenderer } from './buttons/edit-cell-number/edit-cell-number-renderer.component';
import { IsdEmailPreviewButtonComponent } from './buttons/isd-email-preview-button/isd-email-preview-button.component';
import { DynamicFormTableComponent } from './custom-components/dynamic-form-table/dynamic-form-table.component';
import { RemoveAriaOwnsDirective } from './dynamic-form/directive remove-aria-owns.directive';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new MultiTranslateHttpLoader(httpClient, [
      {prefix: "./assets/i18n/", suffix: ".json"},
      {prefix: "buttonName/getButtonNameJSON/", suffix: " "},
  ]);
}
@NgModule({
  imports: [
    PipesModule,
    CommonModule,
    PerfectScrollbarModule,
    MatRippleModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PrimeNgModule,
    AgGridModule.withComponents([ViewBtnCellRenderer,CheckboxRenderer,GridCellDatepicker, EditCellDropDownEditor, EditCellDropDownRenderer, EditCellDateEditor, EditCellDateRenderer, ButtonRendererComponent, EditCellNumberEditor, EditCellNumberRenderer]),
    MaterialModule,
    FurySharedModule,
    FuryCardModule,CronEditorModule,
    // Core
    ListModule,
    CustomerCreateUpdateModule,
    BreadcrumbsModule,
    DynamicFormModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [PipesModule],
  entryComponents: [ConfirmationDialog, DemoDialogComponent, UploadDialogComponent],
  declarations: [
    GridComponent,GridFilterComponent, SmartViewTableComponent, ViewBtnCellRenderer, MarketDataBtnCellRenderer,DynamicFormTableComponent,
    FileDynamicUploadComponent, ConfirmationDialog, DemoDialogComponent, UploadDialogComponent, RunAlertThresholdButton,
    OverAllStatusButton, ApproveWorkflowButton, DownloadTempButton, RunDataImportErrorFileButton, RefreshButton,GrdbtnPendingDataComponent,
    GrdbtnPrvRowDownComponent, SendMailClientTemplate, GrdbtnErrRowDownComponent, ApproveWorkflowStatusButton,GrdbtnRowEditComponent,
    ResumeDailyProcessingButton,CaDownloadFileButton,AttachmentDownloadButton,GridHederBtnComponent,CheckboxRenderer,GrdSchdBtnComponent,DynamicFilesUploadComponent,IsdButtonComponent,UploadButtonComponent,GridCellDatepicker,AccountSummaryDateComponent,ReClientUploadComponent,
    ClientGridComponent,DuplicateButton, EditCellDropDownEditor, EditCellDropDownRenderer, EditCellDateEditor, EditCellDateRenderer, ButtonRendererComponent, EditCellNumberEditor, EditCellNumberRenderer,IsdEmailPreviewButtonComponent,RemoveAriaOwnsDirective
  ],
  exports: [
    GridComponent,GridFilterComponent, SmartViewTableComponent, FileDynamicUploadComponent, PrimeNgModule, MaterialModule, CommonModule,
    ConfirmationDialog, UploadDialogComponent, MatRippleModule, PipesModule,GrdSchdBtnComponent,DynamicFilesUploadComponent,DynamicFormTableComponent,
    ClientGridComponent,RemoveAriaOwnsDirective
  ]
})
export class SharedModule { }
