<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->

<style>
    .blueColor {
        background-color: transparent !important;
        text-align: center;
        position: relative;
        top: 50%;
        cursor: pointer;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        align-items: center;
        text-align: center;
        align-content: center;
        color: #1976d2;
        border-style: none
    }

    .grayColor {
        background-color: transparent !important;
        text-align: center;
        position: relative;
        top: 50%;
        cursor: not-allowed;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        align-items: center;
        text-align: center;
        align-content: center;
        color: gray;
        border-style: none;
    }

    .table-bordered th {
        border-left: 1px solid #dee2e6;
    }

    .table-bordered td {
        border: 1px solid #dee2e6;
    }

    .table th,
    .table td {
        padding: 0.75rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
    }
</style>

<button *ngIf="moduleName =='dailyProcessingStatus' && this.componentName !='automate-process'"
    [ngStyle]="overAllStatus == 'Failed' && {'color':'red', cursor: 'not-allowed'}|| overAllStatus == 'Pending' && {'color':'#ffc107', cursor: 'not-allowed'}|| overAllStatus == 'Completed' && {'color':'green', cursor: 'not-allowed'}"
    style=" background-color: transparent
    !important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%); transform: translateY(-50%); align-items: center; text-align: center;
    align-content: center; color:  #1976d2; border-style: none; ">{{overAllStatus}}</button>

<button *ngIf="this.componentName =='automate-process'" (click)="showProcessDailog()"
    [ngStyle]="overAllStatus == 'Failed' && {'color':'red'}|| overAllStatus == 'Pending' && {'color':'#ffc107'}|| overAllStatus == 'Completed' && {'color':'green'}"
    style=" background-color: transparent
!important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%); transform: translateY(-50%); align-items: center; text-align: center;
align-content: center; color:  #1976d2; border-style: none; ">{{overAllStatus}}</button>


<button *ngIf="this.componentName =='activityClient'"
    [ngStyle]="overAllStatus == 'Pending' && {'color':'rgb(128,128,128)', cursor: 'not-allowed'}|| overAllStatus == 'In Progress' && {'color':'#F4B183', cursor: 'not-allowed'}|| overAllStatus == 'Rejected' && {'color':'#FF6700', cursor: 'not-allowed'}||overAllStatus == 'Completed' && {'color':'#1976d2', cursor: 'not-allowed'}"
    style=" background-color: transparent
    !important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%); transform: translateY(-50%); align-items: center; text-align: center;
    align-content: center; color: rgb(83, 156, 83); border-style: none; ">{{overAllStatus}}</button>

<button *ngIf="moduleName =='taxLotStatus'"
    [ngStyle]="taxLotsProcessStatus == 'Failed' && {'color':'red', cursor: 'not-allowed'}|| taxLotsProcessStatus == 'Pending' && {'color':'#ffc107', cursor: 'not-allowed'}|| taxLotsProcessStatus == 'Done' && {'color':'green', cursor: 'not-allowed'}"
    style=" background-color: transparent
    !important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%); transform: translateY(-50%); align-items: center; text-align: center;
    align-content: center; color: #1976d2; border-style: none; ">{{taxLotsProcessStatus}}</button>

<button
    *ngIf="overAllStatus == 'Error' && colDef != 'executionStatus' && (moduleName =='workflowStatus' || moduleName =='dataImportStatusManager' || moduleName =='dataImportStatus')"
    [ngStyle]="overAllStatus== 'Error' && colDef != 'executionStatus' && {'color':'red'}" style=" background-color: transparent
!important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%); transform: translateY(-50%);
 border-style: none; cursor: unset;"> {{overAllStatus}}</button>

<button
    *ngIf="(overAllStatus == 'Success' && colDef != 'executionStatus' || overAllStatus == 'No Data') && (moduleName =='dataImportStatusManager' || moduleName =='dataImportStatus' || moduleName == 'executeWorkflow')"
    [ngStyle]="overAllStatus== 'Success' && colDef != 'executionStatus' && {'color':'green'}" style=" background-color: transparent
!important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%); transform: translateY(-50%);
 border-style: none; cursor: unset;"> {{overAllStatus}}</button>

<button
    *ngIf="(dispositionStatus == 'Success' || dispositionStatus == 'No Data') && (colDef != 'executionStatus' && colDef == 'disposition' && ( moduleName == 'workflowStatus'))"
    [ngStyle]="dispositionStatus== 'Success' && colDef != 'executionStatus' && {'color':'green'}" style=" background-color: transparent
 !important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
 -ms-transform: translateY(-50%); transform: translateY(-50%);
  border-style: none; cursor: unset;">{{dispositionStatus}}</button>


<button
    *ngIf="executionStatus == 'Error' && colDef == 'executionStatus' && colDef != 'disposition'  && (moduleName =='workflowStatus' || moduleName =='dataImportStatusManager')"
    [ngStyle]="executionStatus== 'Error'  && colDef == 'executionStatus' && {'color':'red'}" style=" background-color: transparent
!important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%); transform: translateY(-50%);
 border-style: none; cursor: unset;" matTooltip="{{toolTipValue|translate}}"> {{executionStatus}}</button>

<button
    *ngIf="executionStatus == 'Success' && colDef == 'executionStatus' && colDef != 'disposition'  && (moduleName =='workflowStatus'|| moduleName =='dataImportStatusManager')"
    [ngStyle]="executionStatus == 'Success' && colDef == 'executionStatus' && {'color':'green'}" style=" background-color: transparent
!important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%); transform: translateY(-50%);
 border-style: none; cursor: unset;" matTooltip="{{toolTipValue|translate}}"> {{executionStatus}}</button>

<button
    *ngIf="(executionStatus == 'No Data' || executionStatus == 'no data') && (colDef == 'executionStatus' && colDef != 'disposition'  && (moduleName =='workflowStatus'))"
    style=" background-color: transparent
!important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%); transform: translateY(-50%);
 border-style: none; cursor: unset;" matTooltip="{{toolTipValue|translate}}"> {{executionStatus}}</button>

<button (click)="referDownloadFile()"
    *ngIf="(executionStatus == 'Refer Downloaded file' || executionStatus == 'Refer Download File' || executionStatus == 'Refer Downloaded File') && (colDef == 'executionStatus' && colDef != 'disposition' && (moduleName =='workflowStatus'||moduleName =='dataImportStatusManager'))"
    style=" background-color: transparent
!important; text-align:center; position: relative; top: 50%; -webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%); transform: translateY(-50%);
 border-style: none; cursor: unset;color: #1976d2;text-decoration: underline; cursor: pointer !important;"
    matTooltip="{{toolTipValue|translate}}"> {{executionStatus}}</button>


<button
    *ngIf="executionStatus != 'Success' && executionStatus != 'Error' && executionStatus != 'No Data' && executionStatus != 'Refer Downloaded File' && executionStatus != 'Refer Downloaded file' && executionStatus != 'Refer Download file' && executionStatus != 'Refer Download File' && colDef != 'disposition' && colDef == 'executionStatus' && (moduleName =='workflowStatus'||moduleName =='dataImportStatusManager')"
    [ngStyle]="executionStatus != 'Success' && executionStatus != 'Error' && colDef == 'executionStatus' && {'color':'gray'}"
    style=" background-color: transparent
!important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%); transform: translateY(-50%);
 border-style: none; cursor: unset;" matTooltip="{{toolTipValue|translate}}"> {{executionStatus}}</button>

<div *ngIf="moduleName =='dataProcessingConfiguration'" class="material-icons-outlined rundailyprocess"
    (click)="runDailyProcessingCompliance($event)">play_arrow</div>

<a *ngIf="this.componentName =='setupcustodianList'" href={{custodianURL}} target="_blank" style="color: '#1976d2;'">
    {{ custodianURL }}
</a>
<a *ngIf="componentName =='accountSummaryReconcile'|| componentName == 'rapidAccountSummaryReconcile'"
    [ngStyle]="brsAmountCheck == 'CR' && {'color':'red'} || isMemoEntryCheck == true && {'color':'orange'} || brsAmountCheck == 'DR' && {'color':'green'}">
    {{ brsAmountCheck == 'CR' || isNegetive(brsAmount)? '('+amountCheck+')' : brsAmount}}
</a>
<ngContainer *ngIf="moduleName =='reportNotificationStatus'">
    <button [ngClass]="{'blueColor': sendStatementToInvesor == true,'grayColor':  sendStatementToInvesor == null }"
        (click)="reportNotisficationCompliance($event)"><u>Send Mail To Investor </u></button>
</ngContainer>

<a *ngIf="componentName =='calculateTaxAllocationStatus' || componentName == 'taxReturnPackageNew'"
    [ngClass]="{'greenColor': taxStatus == 'Successful','redColorFail':  taxStatus == 'Failed-COA duplicate','redColor':  taxStatus == 'Income Allocation not exist','blueColor':  taxStatus == 'Already processed' }">
    {{taxStatus}}</a>

<span class="material-icons-outlined viewrowdatavisibility" *ngIf="moduleName=='newhome'"
    (click)="viewRowData($event)">visibility</span>
<span class="material-icons-outlined viewrowfile_download" *ngIf="moduleName=='newhome'"
    (click)="viewRowData($event)">file_download</span>

<div *ngIf="moduleName =='enterprise'">
    <i class="fa fa-check" *ngIf="status=='active'" (click)="enterpriseRowEventActivate($event,'Deactivate')" style=" 
    text-align:center;
    position: relative;
    top: 50%;
    cursor: pointer;
    color:green; 
    font-size:16px;
    padding-left:5px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    align-items:  center;
    text-align: center;
    align-content:  center;
" tooltip="Active" tooltip-placement="right" tooltip-append-to-body="true"><b> Active</b></i>
    <i class="fa fa-close" *ngIf="status=='inactive' || status=='deactive'"
        (click)="enterpriseRowEventActivate($event, 'Activate')" style="
    text-align:center;
    position: relative;
    top: 50%;
    cursor: pointer; 
    color:red;
    font-size:16px; 
    padding-left:5px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); 
    align-items:  center;
    text-align: center;
    align-content:  center;
" tooltip="Deactive" tooltip-placement="right" tooltip-append-to-body="true"><b> Deactive</b></i>
    <i class="fa fa-close" *ngIf="status=='init' || status=='pending'" style="
text-align:center;
position: relative;
top: 50%;
cursor: pointer; 
color:orange;
font-size:16px; 
padding-left:5px;
-webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%);
transform: translateY(-50%); 
align-items:  center;
text-align: center;
align-content:  center;
" tooltip="Deactive" tooltip-placement="right" tooltip-append-to-body="true"><b> Pending</b></i>
</div>

<!-- Bill payable Real Estate -->
<div *ngIf = "moduleName == 'bill' || moduleName == 'unpaidBills' || moduleName == 'paidBills'
|| moduleName == 'rapidBill' || moduleName == 'rapidUnpaidBills' || moduleName == 'rapidPaidBills' || moduleName == 'Invoice'  || moduleName == 'paymentRecipt' || moduleName == 'OutstandingInvoice'">
    <i class="fa paid" *ngIf="status=='Paid'" (click)="billRowEventPaidUnpaid($event,'Paid')" tooltip="Paid" tooltip-placement="right" tooltip-append-to-body="true"><b> Paid</b></i>
    <i class="fa unpaid" *ngIf="status=='Due'" (click)="billRowEventPaidUnpaid($event, 'Due')" tooltip="Due" tooltip-placement="right" tooltip-append-to-body="true"><b> Due</b></i>
    <i class="fa partiallypaid" *ngIf="status=='Partially Paid'" (click)="billRowEventPaidUnpaid($event, 'Partially Paid')" tooltip="Partially Paid" tooltip-placement="right" tooltip-append-to-body="true"><b> Partially Paid</b></i>
</div>
<div *ngIf="moduleName == 'clientChartofAccount' || moduleName == 'reviewChartofAccount'">
    <i class="fa paid" *ngIf="coaStatus=='Mapped'" tooltip="Mapped" tooltip-placement="right"
        tooltip-append-to-body="true"><b> Mapped</b></i>
    <i class="fa unpaid" *ngIf="coaStatus=='More info Needed'" tooltip="More info Needed" tooltip-placement="right"
        tooltip-append-to-body="true"><b>More info Needed</b></i>
    <i class="fa partiallypaid" *ngIf="coaStatus=='Submitted'" tooltip="Submitted" tooltip-placement="right"
        tooltip-append-to-body="true"><b> Submitted</b></i>
</div>
<button  *ngIf = "moduleName == 'paymentsPayable' || moduleName == 'reciptsPayable'"
    [ngStyle]="status == 'Due' && {'color':'red'}|| status == 'Partially Paid' && {'color':'orange'}|| status == 'Paid' && {'color':'green'}"
    style=" background-color: transparent
    !important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%); transform: translateY(-50%); align-items: center; text-align: center;
    align-content: center; color:  #1976d2; border-style: none; ">{{status}}</button>
<div *ngIf="moduleName =='user' || moduleName =='usersList' || moduleName =='Seamless'">
    <i class="fa fa-check" *ngIf="status=='active'" style=" 
        text-align:center;
        position: relative;
        top: 50%;
        cursor: pointer;
        color:green; 
        font-size:14px;
        font-style: normal;
        padding-left:5px;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        align-items:  center;
        text-align: center;
        align-content:  center;
    " tooltip="Active" tooltip-placement="right" tooltip-append-to-body="true"><b
            (click)="allUsersRowEventActivate($event,'deactive')"> Active</b></i>
    <i class="fa fa-close" *ngIf="status=='inactive'||status=='deactive'"
        (click)="allUsersRowEventActivate($event, 'active')" style="
        text-align:center;
        position: relative;
        top: 50%;
        cursor: pointer; 
        color:red;
        font-size:16px; 
        padding-left:5px;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%); 
        align-items:  center;
        text-align: center;
        align-content:  center;
    " tooltip="Deactive" tooltip-placement="right" tooltip-append-to-body="true"><b> Deactive</b></i>
</div>
<div *ngIf="moduleName =='programAcctExclusion'">{{monthyear}}</div>


<p-dialog class="Dialog_width_Large confirm_undo" appendTo="body" [(header)]="headerProcessStatusDialogbox"
    [(visible)]="display" [baseZIndex]="10000" (onHide)="dialogClose(false)" [modal]="true">
    <p-header class="processing-refresh-btn">
        <button (click)="refreshProcess()" type="button" mat-mini-fab color="primary" matTooltip=""
            matTooltipPosition="above">
            <mat-icon data-md-tooltip="Refresh">refresh</mat-icon>

        </button>
    </p-header>
    <div class="modal-popup">
        <div class="modal-body">

            <table class="table table-striped table-bordered modal-autoprocess-processing"
                style="width:100%; border-collapse: collapse;">
                <thead>
                    <tr>
                        <th style="width: 100px;">Sequence</th>
                        <th>Process Name</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>Tax Lot Process</td>
                        <td [ngStyle]="processingData.calculateTaxLotProcessStatus == 'Failed' && {'color':'red'}|| processingData.calculateTaxLotProcessStatus == 'Pending' && {'color':'#ffc107'}|| processingData.calculateTaxLotProcessStatus == 'Completed' && {'color':'green'}"
                            class="processing-status-btn">
                            {{processingData.calculateTaxLotProcessStatus}}
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Transfer Trade</td>
                        <td class="processing-status-btn"
                            [ngStyle]="processingData.calculateTransferTradeStatus == 'Failed' && {'color':'red'}|| processingData.calculateTransferTradeStatus == 'Pending' && {'color':'#ffc107'}|| processingData.calculateTransferTradeStatus == 'Completed' && {'color':'green'}">
                            {{processingData.calculateTransferTradeStatus}}</td>
                            <td></td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Auto Trade Creation - Crypto Futures</td>
                        <td [ngStyle]="processingData.calculateAutoTradeCreationStatus == 'Failed' && {'color':'red'}|| processingData.calculateAutoTradeCreationStatus == 'Pending' && {'color':'#ffc107'}|| processingData.calculateAutoTradeCreationStatus == 'Completed' && {'color':'green'}"
                            class="processing-status-btn">{{processingData.calculateAutoTradeCreationStatus}}</td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>CSD Bank Transaction</td>
                        <td [ngStyle]="processingData.fetchCSDBankTransactionStatus == 'Failed' && {'color':'red'}|| processingData.fetchCSDBankTransactionStatus == 'Pending' && {'color':'#ffc107'}|| processingData.fetchCSDBankTransactionStatus == 'Completed' && {'color':'green'}"
                            class="processing-status-btn">{{processingData.fetchCSDBankTransactionStatus}}</td>
                            <td nowrap>
                                <button class="material-icons-outlined tdicon blue adisabled"
                                [disabled]="!disableAction" (click)="csdDownload()"
                                  data-md-tooltip="Export">cloud_download</button>
                              </td>
                    </tr> 
                    <tr>
                        <td>5</td>
                        <td>JE Custodian</td>
                        <td [ngStyle]="processingData.postJeCustodianStatus == 'Failed' && {'color':'red'}|| processingData.postJeCustodianStatus == 'Pending' && {'color':'#ffc107'}|| processingData.postJeCustodianStatus == 'Completed' && {'color':'green'}"
                            class="processing-status-btn">{{processingData.postJeCustodianStatus}}</td>
                            <td></td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>FX Gain/Loss</td>
                        <td [ngStyle]="processingData.runFxGlStatus == 'Failed' && {'color':'red'}|| processingData.runFxGlStatus == 'Pending' && {'color':'#ffc107'}|| processingData.runFxGlStatus == 'Completed' && {'color':'green'}"
                            class="processing-status-btn">{{processingData.runFxGlStatus}}</td>
                            <td></td>
                    </tr>

                    <tr>
                        <td>7</td>
                        <td>Income Allocation</td>
                        <td [ngStyle]="processingData.incomeAllocationStatus == 'Failed' && {'color':'red'}|| processingData.incomeAllocationStatus == 'Pending' && {'color':'#ffc107'}|| processingData.incomeAllocationStatus == 'Completed' && {'color':'green'}"
                            class="processing-status-btn">{{processingData.incomeAllocationStatus}}</td>
                            <td></td>
                    </tr>
                   <!-- <tr *ngIf="processingData.postJeFeesCheck">
                        <td>7</td>
                        <td>JE Feeder</td>
                        <td [ngStyle]="processingData.postJeFeesFeederStatus == 'Failed' && {'color':'red'}|| processingData.postJeFeesFeederStatus == 'Pending' && {'color':'#ffc107'}|| processingData.postJeFeesFeederStatus == 'Completed' && {'color':'green'}"
                            class="processing-status-btn">{{processingData.postJeFeesFeederStatus}}</td>

                    </tr> -->
                    <tr *ngIf="processingData.runJeTransferCheck">
                        <td>8</td>
                        <td>JE Transfer (Fund Level)</td>
                        <td [ngStyle]="processingData.runJeTransferStatus == 'Failed' && {'color':'red'}|| processingData.runJeTransferStatus == 'Pending' && {'color':'#ffc107'}|| processingData.runJeTransferStatus == 'Completed' && {'color':'green'}"
                            class="processing-status-btn">{{processingData.runJeTransferStatus}}</td>
                            <td></td>
                    </tr>
                   <!-- <tr *ngIf="processingData.incomeAllocationSummaryCheck">
                        <td>9</td>
                        <td>Income Allocation Summary</td>
                        <td [ngStyle]="processingData.incomeAllocationSummaryStatus == 'Failed' && {'color':'red'}|| processingData.incomeAllocationSummaryStatus == 'Pending' && {'color':'#ffc107'}|| processingData.incomeAllocationSummaryStatus == 'Completed' && {'color':'green'}"
                            class="processing-status-btn">{{processingData.incomeAllocationSummaryStatus}}</td>

                    </tr> -->
                    <tr *ngIf="processingData.postJeFeesCheck">
                        <td>9</td>
                        <td>JE Fees</td>
                        <td [ngStyle]="processingData.postJeFeesStatus == 'Failed' && {'color':'red'}|| processingData.postJeFeesStatus == 'Pending' && {'color':'#ffc107'}|| processingData.postJeFeesStatus == 'Completed' && {'color':'green'}"
                            class="processing-status-btn">{{processingData.postJeFeesStatus}}</td>
                            <td></td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>Post JE Feeder</td>
                        <td [ngStyle]="processingData.runPostJeFeederStatus == 'Failed' && {'color':'red'}|| processingData.runPostJeFeederStatus == 'Pending' && {'color':'#ffc107'}|| processingData.runPostJeFeederStatus == 'Completed' && {'color':'green'}"
                            class="processing-status-btn">{{processingData.runPostJeFeederStatus}}</td>
                            <td></td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>Post JE Master</td>
                        <td [ngStyle]="processingData.runPostJeMasterStatus == 'Failed' && {'color':'red'}|| processingData.runPostJeMasterStatus == 'Pending' && {'color':'#ffc107'}|| processingData.runPostJeMasterStatus == 'Completed' && {'color':'green'}"
                            class="processing-status-btn">{{processingData.runPostJeMasterStatus}}</td>
                            <td></td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>Total Gain/Loss</td>
                        <td [ngStyle]="processingData.runTotalGlStatus == 'Failed' && {'color':'red'}|| processingData.runTotalGlStatus == 'Pending' && {'color':'#ffc107'}|| processingData.runTotalGlStatus == 'Completed' && {'color':'green'}"
                            class="processing-status-btn">{{processingData.runTotalGlStatus}}</td>
                            <td></td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>PNL By Symbol</td>
                        <td [ngStyle]="processingData.pnlContributionStatus == 'Failed' && {'color':'red'}|| processingData.pnlContributionStatus == 'Pending' && {'color':'#ffc107'}|| processingData.pnlContributionStatus == 'Completed' && {'color':'green'}"
                            class="processing-status-btn">{{processingData.pnlContributionStatus}}</td>
                            <td></td>
                    </tr>
                    <!-- <tr>
                        <td>13</td>
                        <td>JE Summary</td>
                        <td [ngStyle]="processingData.runJeSummaryStatus == 'Failed' && {'color':'red'}|| processingData.runJeSummaryStatus == 'Pending' && {'color':'#ffc107'}|| processingData.runJeSummaryStatus == 'Completed' && {'color':'green'}"
                            class="processing-status-btn">{{processingData.runJeSummaryStatus}}</td>

                    </tr>-->
                    <tr>
                        <td>14</td>
                        <td>Cash Balance Report</td>
                        <td [ngStyle]="processingData.runCashBalanceStatus == 'Failed' && {'color':'red'}|| processingData.runCashBalanceStatus == 'Pending' && {'color':'#ffc107'}|| processingData.runCashBalanceStatus == 'Completed' && {'color':'green'}"
                            class="processing-status-btn">{{processingData.runCashBalanceStatus}}</td>
                            <td></td>
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>Cash Reconciliation</td>
                        <td [ngStyle]="processingData.runCashReconStatus == 'Failed' && {'color':'red'}|| processingData.runCashReconStatus == 'Pending' && {'color':'#ffc107'}|| processingData.runCashReconStatus == 'Completed' && {'color':'green'}"
                            class="processing-status-btn">{{processingData.runCashReconStatus}}</td>
                            <td></td>
                    </tr>
                    <tr>
                        <td>16</td>
                        <td>Position Reconciliation</td>
                        <td [ngStyle]="processingData.runPositionReconStatus == 'Failed' && {'color':'red'}|| processingData.runPositionReconStatus == 'Pending' && {'color':'#ffc107'}|| processingData.runPositionReconStatus == 'Completed' && {'color':'green'}"
                            class="processing-status-btn">{{processingData.runPositionReconStatus}}</td>
                            <td></td>
                    </tr>
                    <tr>
                        <td>17</td>
                        <td>Top Reconciliation</td>
                        <td [ngStyle]="processingData.runTopReconStatus == 'Failed' && {'color':'red'}|| processingData.runTopReconStatus == 'Pending' && {'color':'#ffc107'}|| processingData.runTopReconStatus == 'Completed' && {'color':'green'}"
                            class="processing-status-btn">{{processingData.runTopReconStatus}}</td>
                            <td></td>
                    </tr>
                    <tr>
                        <td>18</td>
                        <td>EOD Check Sheet</td>
                        <td [ngStyle]="processingData.eodCheckSheetStatus == 'Failed' && {'color':'red'}|| processingData.eodCheckSheetStatus == 'Pending' && {'color':'#ffc107'}|| processingData.eodCheckSheetStatus == 'Completed' && {'color':'green'}"
                            class="processing-status-btn">{{processingData.eodCheckSheetStatus}}</td>
                            <td nowrap>
                                <button class="material-icons-outlined tdicon blue adisabled"
                                [disabled]="!isEODDownloadEnable" (click)="exportDocForEODOneButton()"
                                  data-md-tooltip="Export">cloud_download</button>
                              </td>
                    </tr>
                    <!-- <tr>
                <td>18</td>
                <td>Custodian Recon</td>
                <td [ngStyle]="processingData.pnlContributionStatus == 'Failed' && {'color':'red'}|| processingData.pnlContributionStatus == 'Pending' && {'color':'#ffc107'}|| processingData.pnlContributionStatus == 'Completed' && {'color':'green'}" class="processing-status-btn">{{processingData.pnlContributionStatus}}</td>
                
            </tr>
            <tr>
                <td>19</td>
                <td>EOD Check</td>
                <td [ngStyle]="processingData.pnlContributionStatus == 'Failed' && {'color':'red'}|| processingData.pnlContributionStatus == 'Pending' && {'color':'#ffc107'}|| processingData.pnlContributionStatus == 'Completed' && {'color':'green'}" class="processing-status-btn">{{processingData.pnlContributionStatus}}</td>
                
            </tr> -->



                </tbody>
                <!-- <tbody *ngIf="!(processingData.postJeFeesCheck && processingData.runJeTransferCheck && processingData.incomeAllocationSummaryCheck && processingData.postJeFeesCheck )">
            <tr>
                <td>1</td>
                <td>Tax Lot Process</td>
                <td [ngStyle]="processingData.createOpenTaxLotsStatus == 'Failed' && {'color':'red'}|| processingData.createOpenTaxLotsStatus == 'Pending' && {'color':'#ee8434'}|| processingData.createOpenTaxLotsStatus == 'Completed' && {'color':'green'}"
                class="processing-status-btn" >
                    {{processingData.createOpenTaxLotsStatus}}
                </td>
                
            </tr>
            <tr>
                <td>2</td>
                <td>Dividend Processing</td>
                <td class="processing-status-btn" [ngStyle]="processingData.calculateDividendStatus == 'Failed' && {'color':'red'}|| processingData.calculateDividendStatus == 'Pending' && {'color':'#ee8434'}|| processingData.calculateDividendStatus == 'Completed' && {'color':'green'}">
                    {{processingData.calculateDividendStatus}}</td>
               
            </tr>
            
            <tr>
                <td>3</td>
                <td>Bond Intrest Processing</td>
                <td [ngStyle]="processingData.calculateBondInterestStatus == 'Failed' && {'color':'red'}|| processingData.calculateBondInterestStatus == 'Pending' && {'color':'#ee8434'}|| processingData.calculateBondInterestStatus == 'Completed' && {'color':'green'}" class="processing-status-btn">{{processingData.calculateBondInterestStatus}}</td>
               
            </tr>
            <tr>
                <td>4</td>
                <td>JE Custodian</td>
                <td [ngStyle]="processingData.postJeCustodianStatus == 'Failed' && {'color':'red'}|| processingData.postJeCustodianStatus == 'Pending' && {'color':'#ee8434'}|| processingData.postJeCustodianStatus == 'Completed' && {'color':'green'}" class="processing-status-btn">{{processingData.postJeCustodianStatus}}</td>
                
            </tr>
            <tr>
                <td>5</td>
                <td>FX Gain/Loss</td>
                <td [ngStyle]="processingData.runFxGlStatus == 'Failed' && {'color':'red'}|| processingData.runFxGlStatus == 'Pending' && {'color':'#ee8434'}|| processingData.runFxGlStatus == 'Completed' && {'color':'green'}"
                    class="processing-status-btn">{{processingData.runFxGlStatus}}</td>
            
            </tr>
            
            
            
            <tr>
                <td>6</td>
                <td>Total Gain/Loss</td>
                <td [ngStyle]="processingData.runTotalGlStatus == 'Failed' && {'color':'red'}|| processingData.runTotalGlStatus == 'Pending' && {'color':'#ee8434'}|| processingData.runTotalGlStatus == 'Completed' && {'color':'green'}"
                    class="processing-status-btn">{{processingData.runTotalGlStatus}}</td>
            
            </tr>
            <tr>
                <td>7</td>
                <td>PNL By Symbol</td>
                <td [ngStyle]="processingData.pnlContributionStatus == 'Failed' && {'color':'red'}|| processingData.pnlContributionStatus == 'Pending' && {'color':'#ee8434'}|| processingData.pnlContributionStatus == 'Completed' && {'color':'green'}"
                    class="processing-status-btn">{{processingData.pnlContributionStatus}}</td>
            
            </tr>
            <tr>
                <td>8</td>
                <td>JE Summary</td>
                <td [ngStyle]="processingData.runJeSummaryStatus == 'Failed' && {'color':'red'}|| processingData.runJeSummaryStatus == 'Pending' && {'color':'#ee8434'}|| processingData.runJeSummaryStatus == 'Completed' && {'color':'green'}"
                    class="processing-status-btn">{{processingData.runJeSummaryStatus}}</td>
            
            </tr>
            <tr>
                <td>9</td>
                <td>Cash Balance Report</td>
                <td [ngStyle]="processingData.runCashBalanceStatus == 'Failed' && {'color':'red'}|| processingData.runCashBalanceStatus == 'Pending' && {'color':'#ee8434'}|| processingData.runCashBalanceStatus == 'Completed' && {'color':'green'}"
                    class="processing-status-btn">{{processingData.runCashBalanceStatus}}</td>
            
            </tr>
            <tr>
                <td>10</td>
                <td>Cash Reconciliation</td>
                <td [ngStyle]="processingData.runCashReconStatus == 'Failed' && {'color':'red'}|| processingData.runCashReconStatus == 'Pending' && {'color':'#ee8434'}|| processingData.runCashReconStatus == 'Completed' && {'color':'green'}"
                    class="processing-status-btn">{{processingData.runCashReconStatus}}</td>
            
            </tr>
            <tr>
                <td>11</td>
                <td>Position Reconciliation</td>
                <td [ngStyle]="processingData.runPositionReconStatus == 'Failed' && {'color':'red'}|| processingData.runPositionReconStatus == 'Pending' && {'color':'#ee8434'}|| processingData.runPositionReconStatus == 'Completed' && {'color':'green'}"
                    class="processing-status-btn">{{processingData.runPositionReconStatus}}</td>
            
            </tr>
            <tr>
                <td>12</td>
                <td>Top Reconciliation</td>
                <td [ngStyle]="processingData.runTopReconStatus == 'Failed' && {'color':'red'}|| processingData.runTopReconStatus == 'Pending' && {'color':'#ee8434'}|| processingData.runTopReconStatus == 'Completed' && {'color':'green'}"
                    class="processing-status-btn">{{processingData.runTopReconStatus}}</td>
            
            </tr>



        </tbody> -->
            </table>
        </div>
    </div>
</p-dialog>

<p-dialog [modal]="true" *ngIf="moduleName =='user'" appendTo="body" header="{{'confirmation'|translate}}"
    class="Dialog_width_Small confirm_undo" [(visible)]="displayDeactive" [baseZIndex]="10000">

    <div class="modal-body">
        <p>{{'allusers_deactive'|translate}}</p>
    </div>

    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" label="{{'yes'|translate}}" class="p-button-text"
            (click)=" confirmationAllUsersStatus('inactive')">
        </p-button>
        <p-button icon="pi pi-times" (click)="displayDeactive=false" label="{{'no'|translate}}"></p-button>

    </ng-template>

</p-dialog>

<p-dialog [modal]="true" *ngIf="moduleName =='user'" appendTo="body" header="{{'confirmation'|translate}}"
    class="Dialog_width_Small confirm_undo" [(visible)]="displayActive" [baseZIndex]="10000">

    <div class="modal-body">
        <p>{{'allusers_active'|translate}}</p>
    </div>

    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" label="{{'yes'|translate}}" class="p-button-text"
            (click)="confirmationAllUsersStatus('active')"></p-button>
        <p-button icon="pi pi-times" (click)="displayActive=false" label="{{'no'|translate}}"></p-button>

    </ng-template>

</p-dialog>


<p-dialog [modal]="true"  *ngIf="moduleName == 'enterprise'" appendTo="body" header="{{'confirmation'|translate}}" class="Dialog_width_Small" [(visible)]="display1"  [baseZIndex]="10000">

    <div class="modal-body">
      <p>{{'enterprise_deactive'|translate}}</p>
    </div>
  
    <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" label="{{'yes'|translate}}" class="p-button-text" (click)="confirmationEnterpriseStatus('inactive')">
      </p-button>
      <p-button icon="pi pi-times" (click)="display1=false" label="{{'no'|translate}}"></p-button>
  
    </ng-template>
  
  </p-dialog>
  
  <p-dialog  *ngIf="moduleName == 'enterprise'" [modal]="true" appendTo="body" header="{{'confirmation'|translate}}" class="Dialog_width_Small" [(visible)]="displayStatus"  [baseZIndex]="10000">
  
    <div class="modal-body">
      <p>{{'enterprise_active'|translate}}</p>
    </div>
  
    <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" label="{{'yes' | translate}}" class="p-button-text"
        (click)="confirmationEnterpriseStatus('active')"></p-button>
      <p-button icon="pi pi-times" (click)="displayStatus=false" label="{{'no' | translate}}"></p-button>
  
    </ng-template>
  </p-dialog>