/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Injectable } from '@angular/core';
import { LocalStoreService } from '../../../../src/app/shared/services/commonServices/local-store.service';
// import { link } from 'node:fs';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  constructor(private localService : LocalStoreService){}
  currentUser = JSON.parse(this.localService.getItem('fss'));

  menu = {
    "lookupTables": [
      {
        "component_name": "lookup",
        "link": "/setting/lookups",
        "createRouterlink": "",
        "uploadRouterlink": '',
        "authView": "LOOKUP_VIEW",
        "authCreate": '',
        "authUpload": ''
      },
      {
        "component_name": "currencies",
        "link": "/setting/currencies",
        "createRouterlink": "/setting/currencies-entry",
        "uploadRouterlink": '',
        "authView": "CURRENCY_VIEW",
        "authCreate": 'CURRENCY_CREATE',
        "authUpload": ''
      },
    //   {
    //     "component_name": "regulators",
    //     "link": "/setting/regulators",
    //     "uploadRouterlink": '',
    //     "createRouterlink": "/setting/regulators-entry",
    //     "authView": "SECURITYREGULATOR_VIEW",
    //     "authCreate": 'SECURITYREGULATOR_CREATE',
    //     "authUpload": ''
    //   },
    //   {
    //     "component_name": "exchanges",
    //     "link": "/setting/exchanges",
    //     "uploadRouterlink": '',
    //     "createRouterlink": "/setting/exchanges-entry",
    //     "authView": "EXCHANGE_VIEW",
    //     "authCreate": 'EXCHANGE_CREATE',
    //     "authUpload": ''
    //   },
    //   {
    //     "component_name": "identifiers",
    //     "link": "/setting/identifiers",
    //     "createRouterlink": "/setting/identifiers-entry",
    //     "uploadRouterlink": '/setting/identifiers-upload',
    //     "authView": "SECURITY_IDENTIFIERS_VIEW",
    //     "authCreate": 'SECURITY_IDENTIFIERS_CREATE',
    //     "authUpload": 'SECURITY_IDENTIFIERS_CREATE'
    //   },
    //   // {
    //   //   "component_name": "countries",
    //   //   "link": "/setting/countries",
    //   //   "createRouterlink": "/setting/country-entry",
    //   //   "uploadRouterlink": '',
    //   //   "authView": "COUNTRY_VIEW"
    //   // },
    //   {
    //     "component_name": "calendar",
    //     "link": "/setting/calendar",
    //     "createRouterlink": "/setting/calendar-entry",
    //     "uploadRouterlink": '',
    //     "authView": "CALENDAR_VIEW",
    //     "authCreate": 'CALENDAR_CREATE',
    //     "authUpload": ''
    //   },

    ],
    // "apiManagement":[
    //   {
    //     "component_name": "api_key",
    //     "link": "/setting/api-key",
    //     "createRouterlink": "/setting/api-key-entry",
    //     "uploadRouterlink": '',
    //     "authView": "SEAMLESS_SETTINGS_API_KEY_VIEW",
    //     "authCreate": 'SEAMLESS_SETTINGS_API_KEY_CREATE',
    //     "authUpload": ''
    //   },
    //   {
    //     "component_name": "connect_key_plan",
    //     "link": "/setting/api-key-usage/create",
    //     "createRouterlink": "/setting/api-key-usage",
    //     "uploadRouterlink": '',
    //     "authView": "",
    //     "authCreate": 'SEAMLESS_SETTINGS_API_USAGE_PLAN_MAPPING',
    //     "authUpload": '',
    //     "isCreateAuth":true,
    //   },
    //   {
    //     "component_name": "api_reference",
    //     "link": "/api-reference",
    //     "createRouterlink": "/api-reference",
    //     "uploadRouterlink": '',
    //     "authView": "SEAMLESS_SETTINGS_API_DOC_VIEW",
    //     "authCreate": '',
    //     "authUpload": '',
    //   },
    // ],
    "configuration": [

      {
        "component_name": "upload_data_config",
        "link": "/setting/upload-data-config",
        "createRouterlink": "",
        "uploadRouterlink": '',
        "authView": "DATACONFIGURATION_VIEW",
        "authCreate": '',
        "authUpload": '',       
        "isVideo":true,
        "displayOrder":1
      },
      {
        "component_name": "doc_management",
        "link": "/setting/docManagement",
        "createRouterlink": "",
        "uploadRouterlink": '',
        "authView": "SETTING_CONFIG_DOC_MANAGEMENT",
        "authCreate": '',
        "authUpload": '',       
        "isVideo":true,
        "displayOrder":2
      },
      {
        "component_name": "Report Delivery Configuration",
        "link": "/setting/report-delivery-config",
        "createRouterlink": "/setting/report-delivery-config-entry",
        "uploadRouterlink": '/setting/report-delivery-config-upload',
        "authView": "ENT_WORKFLOW_CONFIG",
        "authCreate": 'ENT_WORKFLOW_CONFIG',
        "authUpload": 'ENT_WORKFLOW_CONFIG',       
        "isVideo":true,
        "displayOrder":3
      },
      {
        "component_name": "Grouping Configuration",
        "createRouterlink": "/setting/re-report-config-entry",
        "link": "/setting/re-report-config",
        "uploadRouterlink": '',
        "authView": "SEAMLESS_SETTINGS_RE_GROUPING_CONFIG",
        "authCreate": 'SEAMLESS_SETTINGS_RE_GROUPING_CONFIG',
        "authUpload": '',
        "isVideo":false,
        
      },
      {
        "component_name": "period_end_indicator",
        "createRouterlink": "",
        "link": "/setting/period-end-indicator-upload",
        "uploadRouterlink": '/setting/period-end-indicator-upload',
        "authView": "",
        "authCreate": '',
        "authUpload": 'SEAMLESS_SETTINGS_UPLOAD_PERIODENDINDICATOR',
        "isUploadAuth":true,
        "isVideo":false,
        
      },
      {
        "component_name": "allocation_program",
        "createRouterlink": "",
        "link": "/setting/allocation-program-upload",
        "uploadRouterlink": '/setting/allocation-program-upload',
        "authView": "",
        "authCreate": '',
        "authUpload": 'SEAMLESS_SETTINGS_INVESTORSETTINGS_ALLOCATIONPROGRAM_UPLOAD',
        "isUploadAuth":true,
        "isVideo":false,
        
      },
     
      {
        "component_name": "fs_transaction_code_update",
        "createRouterlink": "",
        "link": "/setting/fs-transaction-code-update",
        "uploadRouterlink": '/setting/fs-transaction-code-update',
        "authView": "",
        "authCreate": '',
        "authUpload": 'SEAMLESS_SETTINGS_FS_TRAXN_UPDATE_UPLOAD',
        "isUploadAuth":true,
        "isVideo":false,
        
      },

      {
        "component_name": "investor_statement_footer_customization",
        "createRouterlink": "/setting/investor-statement-footer-customization-entry",
        "link": "/setting/investor-statement-footer-customization",
        "uploadRouterlink": '',
        "authView": "SETTING_CONFIG_INVESTOR_STATEMENT_FOOTER_CUSTOMIZATION",
        "authCreate": 'SETTING_CONFIG_INVESTOR_STATEMENT_FOOTER_CUSTOMIZATION',
        "authUpload": '',
        "isUploadAuth":true,
        "isVideo":false,
        
      },
      {
        "component_name": "gross_cash_flow_adjustment",
        "createRouterlink": "/setting/gross-cash-flow-entry",
        "link": "/setting/gross-cash-flow-adjustment",
        "uploadRouterlink": '/setting/gross-cash-flow-adjustment-upload',
        "authView": "SETTING_CONFIGURATION_GROSS_CASHFLOW_ADJUSTMENT_VIEW",
        "authCreate": 'SETTING_CONFIGURATION_GROSS_CASHFLOW_ADJUSTMENT_CREATE',
        "authUpload": 'SETTING_CONFIGURATION_GROSS_CASHFLOW_ADJUSTMENT_CREATE',
        "isVideo": false,

      },
      {
        "component_name": "contribution_distribution_adjustment",
        "createRouterlink": "/setting/conttribution-distribution-adjustment-entry",
        "link": "/setting/conttribution-distribution-adjustment",
        "uploadRouterlink": '/setting/conttribution-distribution-adjustment-upload',
        "authView": "SETTING_CONFIGURATION_GROSS_CASHFLOW_ADJUSTMENT_VIEW",
        "authCreate": 'SETTING_CONFIGURATION_CONTRIBUTIONANDDISTRIBUTION_ADJUSTMENT_CREATE',
        "authUpload": 'SETTING_CONFIGURATION_CONTRIBUTIONANDDISTRIBUTION_ADJUSTMENT_CREATE',
        "isVideo": false,
      }
    ],
    "createNew": [
      // {
      //   "component_name": "vendors",
      //   "link": "/setting/vendors",
      //   "createRouterlink": "/setting/vendors-entry",
      //   "uploadRouterlink": '/setting/vendors-upload',
      //   "authView": "VENDOR_VIEW",
      //   "authCreate": 'VENDOR_CREATE',
      //   "authUpload": 'VENDOR_CREATE'
      // },
      // {
      //   "component_name": "fund_addresses",
      //   "createRouterlink": "/setting/fund-addresses-entry",
      //   "link": "/setting/fund-addresses",
      //   "uploadRouterlink": '',
      //   "authView": "FUNDADDRESS_VIEW",
      //   "authCreate": 'FUNDADDRESS_CREATE',
      //   "authUpload": ''
      // },
      {
        "component_name": "trading_programs",
        "createRouterlink": "/setting/trading-programs-entry",
        "link": "/setting/trading-programs",
        "uploadRouterlink": '',
        "authView": "TRADINGPROGRAM_VIEW",
        "authCreate": 'TRADINGPROGRAM_CREATE',
        "authUpload": '',       
        "videoedit":false,
        "isVideo":false,
      },
      // {
      //   "component_name": "configure_notes",
      //   "createRouterlink": "/setting/configure-notes-entry",
      //   "link": "/setting/configure-notes",
      //   "authView": "CONFIGURENOTES_VIEW",
      //   "uploadRouterlink": '',
      //   "authCreate": 'CONFIGURENOTES_CREATE',
      //   "authUpload": ''
      // }

    ],
    "accountingSetting": [
      // {
      //   "component_name": "asset_based_fees",
      //   "link": "/setting/asset-based-fee",
      //   "createRouterlink": "/setting/asset-based-fee-entry",
      //   "uploadRouterlink": '',
      //   "authView": "ASSETBASEDFEE_VIEW",
      //   "authCreate": 'ASSETBASEDFEE_CREATE',
      //   "authUpload": ''
      // },
      // {
      //   "component_name": "performance_based_fee",
      //   "createRouterlink": "/setting/performanc-based-fee-entry",
      //   "link": "/setting/performanc-based-fee",
      //   "uploadRouterlink": '',
      //   "authView": "MANAGERPERFBASEDFEE_VIEW",
      //   "authCreate": 'MANAGERPERFBASEDFEE_CREATE',
      //   "authUpload": ''
      // },
      // {
      //   "component_name": "income_distribution_base",
      //   "createRouterlink": "/setting/income-distribution-base-entry",
      //   "link": "/setting/income-distribution-base",
      //   "uploadRouterlink": '',
      //   "authView": "INCOMEDISTRIBUTIONBASE_VIEW",
      //   "authCreate": 'INCOMEDISTRIBUTIONBASE_CREATE',
      //   "authUpload": ''
      // },
      // {
      //   "component_name": "fee_sharing",
      //   "createRouterlink": "/setting/Fee-sharing-entry",
      //   "link": "/setting/Fee-sharing",
      //   "authView": "FEESHARINGSETUP_VIEW",
      //   "uploadRouterlink": '',
      //   "authCreate": 'FEESHARINGSETUP_CREATE',
      //   "authUpload": ''
      // },
      {
        "component_name": "recurring_transaction",
        "link": "/setting/recurring-transaction",
        "createRouterlink": "/setting/recurring-transaction-entry",
        "uploadRouterlink": '',
        "authView": "RECURRINGTRANSACTION_VIEW",
        "authCreate": 'RECURRINGTRANSACTION_CREATE',
        "authUpload": '',       
        "videoedit":true,
        "isVideo":true,
        "displayOrder":4

      },

      // {
      //   "component_name": "set_up_expense_amortization",
      //   "createRouterlink": "/setting/setup-expense-amortization-entry",
      //   "link": "/setting/setup-expense-amortization",
      //   "uploadRouterlink": '',
      //   "authView": "SETUPEXPENSEAMORTIZATION_VIEW",
      //   "authCreate": 'SETUPEXPENSEAMORTIZATION_CREATE',
      //   "authUpload": ''
      // },
      {
        "component_name": "chart_of_accounts",
        "createRouterlink": "/setting/chart-of-accounts-entry",
        "link": "/setting/chart-of-accounts",
        "uploadRouterlink": '/setting/chart-of-accounts-upload',
        "authView": "CHARTOFACCOUNTS_VIEW",
        "authCreate": 'CHARTOFACCOUNTS_CREATE',
        "authUpload": 'CHARTOFACCOUNTS_CREATE',
        "isVideo":true,       
        "videoedit":true,
        "displayOrder":5

      },
      // {
      //   "component_name": "chartOfAccountTaxUpdate",
      //   "link": "/setting/chart-of-accounts-update",
      //   "uploadRouterlink": '/setting/chart-of-accounts-update',
      //   "authView": "TAX_CHARTOFACCOUNT_UPDATE",
      //   "authUpload": 'TAX_CHARTOFACCOUNT_UPDATE'
      //   },
      // {
      //   "component_name": "review_chart_of_accounts",
      //   "createRouterlink": "",
      //   "link": "/setting/review-chart-of-accounts",
      //   "uploadRouterlink": '/setting/review-chart-of-accounts-upload',
      //   "authView": "SEAMLESS_SETTINGS_REVIEW_CHARTOFACCOUNTS_VIEW",
      //   "authCreate": '',
      //   "authUpload": 'SEAMLESS_SETTINGS_REVIEW_CHARTOFACCOUNTS_UPLOAD',
      //   "isVideo":false,       
      //   "videoedit":true,
      // },
      {
        "component_name": "chart_of_accounts_fs_update",
        "link": "",
        "uploadRouterlink": '/setting/fs-chart-of-accounts-upload',
        "authView": "FS_CHARTOFACCOUNTS_CREATE",
        "authUpload": 'FS_CHARTOFACCOUNTS_CREATE',
        "isVideo":false,       
        "videoedit":true,
        },
      {
        "component_name": "predefined_je",
        "createRouterlink": "/setting/predefinedJe-entry",
        "link": "/setting/predefinedJe",
        "authView": "PREDEFINED_JOURNAL_ENTRY_VIEW",
        "uploadRouterlink": '/setting/predefinedJe-upload',
        "authCreate": 'PREDEFINED_JOURNAL_ENTRY_CREATE',
        "authUpload": 'PREDEFINED_JOURNAL_ENTRY_CREATE',
        "isVideo":true,       
        "videoedit":true,
        "displayOrder":7

      },
      // {
      //   "component_name": "performance_analytics_settings",
      //   "createRouterlink": "/setting/performance-analytics-settings-entry",
      //   "link": "/setting/performance-analytics-settings",
      //   "authView": "PERFORMANCE_ANALYTICS_SETTINGS_VIEW",
      //   "uploadRouterlink": '/setting/performance-analytics-settings-upload',
      //   "authCreate": 'PERFORMANCE_ANALYTICS_SETTINGS_CREATE',
      //   "authUpload": 'PERFORMANCE_ANALYTICS_SETTINGS_CREATE',
      //   "isVideo":true,       
      //   "videoedit":true,
      //   "displayOrder":10
      // },
      // {
      //   "component_name": "hurdle_tier_incentive_setup",
      //   "createRouterlink": "/setting/hurdle-tire-incentive-entry",
      //   "link": "/setting/hurdle-tire-incentive",
      //   "authView": "HURDLE_TIER_INCENTIVE_VIEW",
      //   "uploadRouterlink": '',
      //   "authCreate": 'HURDLE_TIER_INCENTIVE_CREATE',
      //   "authUpload": '',
      //   "isVideo":true,       
      //   "videoedit":true,
      //   "displayOrder":9

      // },
      // {
      //   "component_name": "hurdle",
      //   "createRouterlink": "/setting/hurdle-entry",
      //   "link": "/setting/hurdle",
      //   "authView": "HURDLE_VIEW",
      //   "uploadRouterlink": '',
      //   "authCreate": 'HURDLE_CREATE',
      //   "authUpload": '',
      //   "isVideo":false,       
      //   "videoedit":true,
      // },
      // {
      //   "component_name": "configure_hurdle",
      //   "createRouterlink": "/setting/hurdle-entry",
      //   "link": "/setting/hurdle-tire-incentive",
      //   "authView": "HURDLE_CREATE",
      //   "uploadRouterlink": '',
      // },
      // {
      //   "component_name": "Fund Waterfall",
      //   "createRouterlink": "/setting/waterfall-entry",
      //   "link": "",
      //   "authView": "SEAMLESS_SETTINGS_ACCOUNTING_FUNDWATERFALL",
      //   "uploadRouterlink": '',
      //   "authCreate": 'SEAMLESS_SETTINGS_ACCOUNTING_FUNDWATERFALL',
      //   "authUpload": ''
      // },





    ],
    "userAccountingSetting": [
      {
        "component_name": "asset_based_fees",
        "link": "/setting/asset-based-fee",
        "createRouterlink": "/setting/asset-based-fee-entry",
        "uploadRouterlink": '',
        "authView": "ASSETBASEDFEE_VIEW",
        "authCreate": 'ASSETBASEDFEE_CREATE',
        "authUpload": '',
        "isVideo":true,       
        "videoedit":true,
        "displayOrder":3

      },
      {
        "component_name": "performance_based_fee",
        "createRouterlink": "/setting/performanc-based-fee-entry",
        "link": "/setting/performanc-based-fee",
        "uploadRouterlink": '',
        "authView": "MANAGERPERFBASEDFEE_VIEW",
        "authCreate": 'MANAGERPERFBASEDFEE_CREATE',
        "authUpload": '',
        "isVideo":true,       
        "videoedit":true,
        "displayOrder":2

      },
      // {
      //   "component_name": "income_distribution_base",
      //   "createRouterlink": "/setting/income-distribution-base-entry",
      //   "link": "/setting/income-distribution-base",
      //   "uploadRouterlink": '',
      //   "authView": "INCOMEDISTRIBUTIONBASE_VIEW",
      //   "authCreate": 'INCOMEDISTRIBUTIONBASE_CREATE',
      //   "authUpload": ''
      // },
      {
        "component_name": "fee_sharing",
        "createRouterlink": "/setting/Fee-sharing-entry",
        "link": "/setting/Fee-sharing",
        "authView": "FEESHARINGSETUP_VIEW",
        "uploadRouterlink": '',
        "authCreate": 'FEESHARINGSETUP_CREATE',
        "authUpload": '',
        "isVideo":false,       
        "videoedit":false,
      },
      {
        "component_name": "recurring_transaction",
        "link": "/setting/recurring-transaction",
        "createRouterlink": "/setting/recurring-transaction-entry",
        "uploadRouterlink": '/setting/recurring-transaction-upload',
        "authView": "RECURRINGTRANSACTION_VIEW",
        "authCreate": 'RECURRINGTRANSACTION_CREATE',
        "authUpload": 'RECURRINGTRANSACTION_CREATE',
        "isVideo":true,       
        "videoedit":true,
        "displayOrder":4
      },

      {
        "component_name": "set_up_expense_amortization",
        "createRouterlink": "/setting/setup-expense-amortization-entry",
        "link": "/setting/setup-expense-amortization",
        "uploadRouterlink": '',
        "authView": "SETUPEXPENSEAMORTIZATION_VIEW",
        "authCreate": 'SETUPEXPENSEAMORTIZATION_CREATE',
        "authUpload": '',
        "isVideo":true,       
        "videoedit":true,
      },
      {
        "component_name": "chart_of_accounts",
        "createRouterlink": "/setting/chart-of-accounts-entry",
        "link": "/setting/chart-of-accounts",
        "uploadRouterlink": '/setting/chart-of-accounts-upload',
        "authView": "CHARTOFACCOUNTS_VIEW",
        "authCreate": 'CHARTOFACCOUNTS_CREATE',
        "authUpload": 'CHARTOFACCOUNTS_CREATE',
        "isVideo":true,       
        "videoedit":true,
        "displayOrder":5

      },
      {
        "component_name": "review_chart_of_accounts",
        "createRouterlink": "",
        "link": "/setting/review-chart-of-accounts",
        "uploadRouterlink": '/setting/review-chart-of-accounts-upload',
        "authView": "SEAMLESS_SETTINGS_REVIEW_CHARTOFACCOUNTS_VIEW",
        "authCreate": '',
        "authUpload": 'SEAMLESS_SETTINGS_REVIEW_CHARTOFACCOUNTS_UPLOAD',
        "isVideo":false,       
        "videoedit":true,
      },
      {
        "component_name": "predefined_je",
        "createRouterlink": "/setting/predefinedJe-entry",
        "link": "/setting/predefinedJe",
        "authView": "PREDEFINED_JOURNAL_ENTRY_VIEW",
        "uploadRouterlink": '/setting/predefinedJe-upload',
        "authCreate": 'PREDEFINED_JOURNAL_ENTRY_CREATE',
        "authUpload": 'PREDEFINED_JOURNAL_ENTRY_CREATE',
        "isVideo":true,       
        "videoedit":true,
        "displayOrder":7
      },
      {
        "component_name": "performance_analytics_settings",
        "createRouterlink": "/setting/performance-analytics-settings-entry",
        "link": "/setting/performance-analytics-settings",
        "authView": "PERFORMANCE_ANALYTICS_SETTINGS_VIEW",
        "uploadRouterlink": '/setting/performance-analytics-settings-upload',
        "authCreate": 'PERFORMANCE_ANALYTICS_SETTINGS_CREATE',
        "authUpload": 'PERFORMANCE_ANALYTICS_SETTINGS_CREATE',
        "isVideo":true,       
        "videoedit":true,
        "displayOrder":10
      },
      {
        "component_name": "hurdle_tier_incentive_setup",
        "createRouterlink": "/setting/hurdle-tire-incentive-entry",
        "link": "/setting/hurdle-tire-incentive",
        "authView": "HURDLE_TIER_INCENTIVE_VIEW",
        "uploadRouterlink": '',
        "authCreate": 'HURDLE_TIER_INCENTIVE_CREATE',
        "authUpload": '',
        "isVideo":true,       
        "videoedit":true,
        "displayOrder":9

      },
      {
        "component_name": "hurdle",
        "createRouterlink": "/setting/hurdle-entry",
        "link": "/setting/hurdle",
        "authView": "HURDLE_VIEW",
        "uploadRouterlink": '',
        "authCreate": 'HURDLE_CREATE',
        "authUpload": '',
        "isVideo":false,       
        "videoedit":false,
      },
      // {
      //   "component_name": "configure_hurdle",
      //   "createRouterlink": "/setting/hurdle-entry",
      //   "link": "/setting/hurdle-tire-incentive",
      //   "authView": "HURDLE_CREATE",
      //   "uploadRouterlink": '',
      // },





    ],
    "tradeSetting": [
      {
        "component_name": "asset_class",
        "link": "/setting/asset-class",
        "createRouterlink": "/setting/asset-class-entry",
        "uploadRouterlink": '',
        "authView": "ASSETCLASS_VIEW",
        "authCreate": 'ASSETCLASS_CREATE',
        "authUpload": '',       
        "videoedit":true,
        "isVideo":true,
        "displayOrder":1

      },
      {
        "component_name": "commision",
        "createRouterlink": "/setting/commission-entry",
        "link": "/setting/commission",
        "uploadRouterlink": '',
        "authView": "COMMISSION_VIEW",
        "authCreate": 'COMMISSION_CREATE',
        "authUpload": '',       
        "videoedit":false,
        "isVideo":false,
      },
      {
        "component_name": "trade_allocation_config",
        "createRouterlink": "/setting/trade-allocation-config-entry",
        "link": "/setting/trade-allocation-config",
        "uploadRouterlink": "/setting/tradeAllocationConfigUpload",
        "authView": "TRADEALLOCATIONCONFIG_VIEW",
        "authCreate": 'TRADEALLOCATIONCONFIG_CREATE',
        "authUpload": 'TRADEALLOCATIONCONFIG_CREATE',       
        "videoedit":false,
        "isVideo":false,
      },
      {
        "component_name": "trade_allocation",
        "createRouterlink": "/setting/trade-allocation-entry",
        "link": "/setting/trade-allocation",
        "uploadRouterlink": '',
        "authView": "TRADEALLOCATION_VIEW",
        "authCreate": 'TRADEALLOCATION_CREATE',
        "authUpload": '',       
        "videoedit":false,
        "isVideo":false,
      },

    ],
    "portfolioSetting": [
      {
        "component_name": "closing_methods",
        "link": "/setting/closing-methods",
        "createRouterlink": "/setting/closing-methods-entry",
        "uploadRouterlink": '',
        "authView": "TAXLOTCLOSINGMETHOD_VIEW",
        "authCreate": 'TAXLOTCLOSINGMETHOD_CREATE',
        "authUpload": ''
      },
      {
        "component_name": "risk_master",
        "createRouterlink": "/setting/risk-master-entry",
        "link": "/setting/risk-master",
        "uploadRouterlink": '/setting/risk-master-upload',
        "authView": "RISKMASTER_VIEW",
        "authCreate": 'RISKMASTER_CREATE',
        "authUpload": 'RISKMASTER_CREATE'
      },
      {
        "component_name": "sm_update",
        "link": "/setting/sm-update",
        "uploadRouterlink": '/setting/sm-update',
        "authUpload": 'SETTINGS_PORTFOLIO_SMUPDATE',
        "authView": "SETTINGS_PORTFOLIO_SMUPDATE",

       },
       {
        "component_name": "sm_update",
        "link": "/setting/sm-upload",
        "uploadRouterlink": '/setting/sm-upload',
        "authUpload": 'SETTINGS_PORTFOLIO_SMUPDATE_FS',
        "authView": "SETTINGS_PORTFOLIO_SMUPDATE_FS",

       }

    ],
    "userInvestorSetting": [
      {
        "component_name": "share_classes",
        "link": "/setting/share-classes",
        "createRouterlink": "/setting/share-classes-entry",
        "uploadRouterlink": '',
        "authView": "FUNDCLASS_VIEW",
        "authCreate": 'FUNDCLASS_CREATE',
        "authUpload": '',       
        "isVideo":true,
        "displayOrder":1

      },
      {
        "component_name": "share_series",
        "createRouterlink": "/setting/share-series-entry",
        "link": "/setting/share-series",
        "uploadRouterlink": '',
        "authView": "SHARESERIES_VIEW",
        "authCreate": 'SHARESERIES_CREATE',
        "authUpload": '',       
        "isVideo":true,
        "videoedit":true,
        "displayOrder":2


      },
      {
        "component_name": "redemption_fees",
        "createRouterlink": "/setting/redemption-fees-entry",
        "link": "/setting/redemption-fees",
        "uploadRouterlink": '',
        "authView": "REDEMPTION_VIEW",
        "authCreate": 'REDEMPTION_CREATE',
        "authUpload": '',       
        "isVideo":true,
        "videoedit":true,
        "displayOrder":3


      },
      {
        "component_name": "hurdle_rate",
        "link": "/setting/hurdle-rate",
        "createRouterlink": "/setting/hurdle-rate-entry",
        "uploadRouterlink": '/setting/hurdle-rate-upload',
        "authView": "HURDLERATE_VIEW",
        "authCreate": 'HURDLERATE_CREATE',
        "authUpload": 'HURDLERATE_CREATE',       
        "isVideo":false,
        "videoedit":false,

      },
      {
        "component_name": "investor_update",
        "link": "/setting/investor-update",
        "createRouterlink": '',
        "uploadRouterlink": '/setting/investor-update',
        "authView": "SETTINGS_INVESTORSETTINGS_INVESTOR_UPDATE_TAX",
        //"authCreate": 'SETTINGS_INVESTORSETTINGS_INVESTOR_UPDATE_TAX',
        "authUpload": 'SETTINGS_INVESTORSETTINGS_INVESTOR_UPDATE_TAX',
        "isVideo":false,       
        "videoedit":false,

      },



    ],
    "investorSetting": [
      {
        "component_name": "share_classes",
        "link": "/setting/share-classes",
        "createRouterlink": "/setting/share-classes-entry",
        "uploadRouterlink": '',
        "authView": "FUNDCLASS_VIEW",
        "authCreate": 'FUNDCLASS_CREATE',
        "authUpload": '',
        "isVideo":true,       
        "videoedit":true,
        "displayOrder":1


      },
      {
        "component_name": "share_series",
        "createRouterlink": "/setting/share-series-entry",
        "link": "/setting/share-series",
        "uploadRouterlink": '',
        "authView": "SHARESERIES_VIEW",
        "authCreate": 'SHARESERIES_CREATE',
        "authUpload": '',
        "isVideo":true,       
        "videoedit":true,
        "displayOrder":2


      },
      {
        "component_name": "redemption_fees",
        "createRouterlink": "/setting/redemption-fees-entry",
        "link": "/setting/redemption-fees",
        "uploadRouterlink": '',
        "authView": "REDEMPTION_VIEW",
        "authCreate": 'REDEMPTION_CREATE',
        "authUpload": '',
        "isVideo":true,       
        "videoedit":true,
        "displayOrder":3


      },
      {
        "component_name": "hurdle_rate",
        "link": "/setting/hurdle-rate",
        "createRouterlink": "/setting/hurdle-rate-entry",
        "uploadRouterlink": '',
        "authView": "HURDLERATE_VIEW",
        "authCreate": 'HURDLERATE_CREATE',
        "authUpload": '',
        "isVideo":false,       
        "videoedit":false,

      },



    ],
    "UsertradeSetting": [
      {
        "component_name": "asset_class",
        "link": "/setting/asset-class",
        "createRouterlink": "/setting/asset-class-entry",
        "uploadRouterlink": '',
        "authView": "ASSETCLASS_VIEW",
        "authCreate": 'ASSETCLASS_CREATE',
        "authUpload": '',
        "videoedit":true,
        "isVideo":true,
      },
      {
        "component_name": "commision",
        "createRouterlink": "/setting/commission-entry",
        "link": "/setting/commission",
        "uploadRouterlink": '',
        "authView": "COMMISSION_VIEW",
        "authCreate": 'COMMISSION_CREATE',
        "authUpload": ''
      },
      {
        "component_name": "trade_allocation_config",
        "createRouterlink": "/setting/trade-allocation-config-entry",
        "link": "/setting/trade-allocation-config",
        "uploadRouterlink": "/setting/tradeAllocationConfigUpload",
        "authView": "TRADEALLOCATIONCONFIG_VIEW",
        "authCreate": 'TRADEALLOCATIONCONFIG_CREATE',
        "authUpload": 'TRADEALLOCATIONCONFIG_CREATE'
      },
      {
        "component_name": "trade_allocation",
        "createRouterlink": "/setting/trade-allocation-entry",
        "link": "/setting/trade-allocation",
        "uploadRouterlink": '',
        "authView": "TRADEALLOCATION_VIEW",
        "authCreate": 'TRADEALLOCATION_CREATE',
        "authUpload": ''
      },

    ],
    "toolbarSetting": [
      {
        "component_name": "merge_pdf",
        "link": "/setting/asset-class",
        "uploadRouterlink": '',
        "authView": "SEAMLESS_PDF_MERGE_IN_SEQUENCE",
        "authUpload": '',
        "videoedit":true,
        "isVideo":true,
      },
      

    ],

  }
  isEnt: any;
 




}